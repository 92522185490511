import React, {Component} from 'react'
import timezones from 'utils/timezones'

import Checkbox from 'ipmp-react-ui/Checkbox'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'

import {__} from 'utils/i18n'
import {FormSection} from 'ipmp-react-ui/Form'
import InputTimePicker from 'ipmp-react-ui/InputTimePicker'
import {FormRow} from 'ipmp-react-ui/Form'

export default class extends Component {
    state = {
        startReportingRightNow: true,
    }

    changeStartReporting = () => {
        this.setState(({startReportingRightNow}) => ({
            startReportingRightNow: !startReportingRightNow,
        }))
    }

    renderDateTime() {
        if (this.state.startReportingRightNow) {
            return
        }
        return (
            <FormRow>
                <InputDatePicker name="schedule.schedule" min={timezones.server().startOf('day')} defaultValue={timezones.server()}/>
                {__('at')}
                <InputTimePicker name="schedule.schedule_time"/>
            </FormRow>
        )
    }

    render() {
        const {startReportingRightNow} = this.state

        return (
            <FormSection title={__('Schedule start')}>
                <Checkbox {...{
                    onChange: this.changeStartReporting,
                    checked: startReportingRightNow,
                    name: '_startRightNow',
                    label: __('Start Right Now'),
                }} />

                {this.renderDateTime()}
            </FormSection>
        )
    }
}