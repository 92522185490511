import createLogoutReducer from 'modules/auth/createLogoutReducer'
import {combineReducers, compose} from 'redux'
import {routerReducer as router} from 'react-router-redux'

import locales from './locales/reducer'
import auth from './auth/reducer'
import modals from './modals/reducer'
import events from './events/reducer'
import forms from './forms/reducer'
import devices from './devices/reducer'
import pgm from './pgm/reducer'
import settings from './settings/reducer'
import selection from './selection/reducer'
import snacks from './snacks'
import panels from './panels/reducer'
import groups from './groups/reducer'
import reports from './reports/reducer'
import remoteInspections from './remoteInspections/reducer'
import centralStations from './centralStations/reducer'
import countries from './countries/reducer'
import users from './users/reducer'
import processes from './processes/reducer'
import roles from './roles/reducer'
import languages from './languages/reducer'
import actionLog from './actionLog/reducer'
import firmware from './firmware/reducer'
import basicConfiguration from './basicConfiguration/reducer'
import installers from './installers/reducer'
import interactiveUsers from './interactiveUsers/reducer'
import upgradeStatus from './upgradeStatus/reducer'
import dashboard from './dashboard/reducer'
import batches from './batches/reducer'
import runners from './runners/reducer'
import features from './features/reducer'
import system from './system/reducer'
import servers from './servers/reducer'

const appReducer = combineReducers({
    locales,
    forms,
    router,
    auth,
    modals,
    processes,
    events,
    devices,
    pgm,
    selection,
    settings,
    snacks,
    panels,
    groups,
    reports,
    remoteInspections,
    centralStations,
    users,
    countries,
    languages,
    firmware,
    roles,
    actionLog,
    basicConfiguration,
    installers,
    interactiveUsers,
    upgradeStatus,
    dashboard,
    batches,
    runners,
    features,
    system,
    servers,
})

export default compose(
    createLogoutReducer,
)(appReducer)
