import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Select, {Option} from 'ipmp-react-ui/Select'

import * as actions from 'modules/system/settings/actions'
import {selectAvailableSerialPorts} from 'modules/system/settings/selectors'

import withLoader from 'containers/withLoader'

import {__} from 'utils/i18n'

export class SelectPort extends Component {
    static propTypes = {
        serialPorts: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }

    render() {
        const {serialPorts, ...props} = this.props

        return (
            <Select
                optional={__('Without serial port')}
                {...props}
            >
                {serialPorts.map(({id, name}) => <Option key={id} label={name} value={id}/>)}
            </Select>
        )
    }
}

export default compose(
    connect(
        selectAvailableSerialPorts,
        dispatch => bindActionCreators({
            fetchSerialPorts: actions.fetchSerialPorts,
        }, dispatch),
    ),
    withLoader(
        ({fetchSerialPorts}) => fetchSerialPorts(),
    ),
)(SelectPort)