import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {refreshState as permission} from 'permissions/panels/selection'

import {refreshState} from 'modules/panels/store/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import {withSelectionFeature} from 'containers/withFeature'

import {MenuItem} from 'ipmp-react-ui/Menu'
import IconRefresh from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'
import {REFRESH_STATE} from 'constants/features'

export const RefreshStateItem = compose(
    withPermission({isVisible: permission}),
    withVisibility(),
    withProps(() => ({
        Icon: IconRefresh,
        children: __('Refresh State'),
    })),
    withSelectionHandler(),
)(MenuItem)

export default compose(
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: refreshState,
        }, dispatch)
    ),
    withSelectionFeature(REFRESH_STATE),
)(RefreshStateItem)