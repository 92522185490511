import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'

const defaultState = {
    rows: [],
    isLoading: false,
}

export default handleActions({
    [fetch]() {
        return {
            ...defaultState,
            isLoading: true,
        }
    },

    [receive](state, {payload, error}) {
        if (error) {
            return {
                ...state,
                error: payload,
                isLoading: false,
            }
        }

        return {
            ...state,
            ...payload,
            isLoading: false,
        }
    },
}, defaultState)