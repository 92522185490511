import * as api from 'api/users'

import {editUser} from 'modules/forms/handlers'
import {snackShow} from 'modules/snacks'
import {all, call, put, select, takeEvery} from 'redux-saga/effects'
import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery([actions.setEnabled], watchSetEnable),
        takeEvery([editUser.SUCCESS], watchUserEdit),
    ])
}

function* watchSetEnable({payload: {ids, isEnabled}}) {
    const byIds = yield select(state => state.users.store.byIds)

    const toUpdate = ids.filter(
        id => byIds[id] && byIds[id].isEnabled !== isEnabled,
    )

    if (toUpdate.length === 0) {
        return
    }

    yield put(actions.update(
        toUpdate.map(id => ({
            id,
            isEnabled,
            isOnline: false,
        })),
    ))

    try {
        const endpoint = isEnabled ? api.enable : api.suspend
        yield call(endpoint, toUpdate)
    } catch ({message}) {
        yield put(actions.update(
            toUpdate.map(id => ({
                id,
                isEnabled: !isEnabled,
                isOnline: byIds[id].isOnline,
            })),
        ))
        yield put(snackShow(message))
    }
}

function* watchUserEdit({payload}) {
    yield put(actions.update(payload.result))
}