import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import DropDown, {ALIGN_TOP, DropDownContent} from 'ipmp-react-ui/DropDown'
import Definition from 'ipmp-react-ui/Definition'
import Spinner from 'ipmp-react-ui/Spinner'

import Empty from 'ui/Empty'
import stopPropagation from 'utils/stopPropagation'

export default class Packages extends PureComponent {
    static propTypes = {
        packages: PropTypes.arrayOf(PropTypes.shape({
            packageName: PropTypes.string.isRequired,
        })).isRequired,
        children: PropTypes.element.isRequired,
        isLoading: PropTypes.bool,
    }

    renderPackages = () => {
        const {packages} = this.props

        if ((!packages) || (!packages.length)) {
            return <Empty />
        }

        return (
            packages.map(({packageName}) => (
                <Definition key={packageName} title={packageName} />
            ))
        )
    }

    render() {
        const {children, isLoading} = this.props

        return (
            <div className="upgradePackages-wrapper" onClick={stopPropagation}>
                <DropDown
                    className="upgradePackages"
                    trigger={children}
                    align={ALIGN_TOP}
                >
                    <DropDownContent>
                        {isLoading && <Spinner />}
                        {!isLoading && this.renderPackages()}
                    </DropDownContent>
                </DropDown>
            </div>
        )
    }
}