import {selectPerPage} from 'modules/settings/selectors'
import {all} from 'redux-saga/effects'

import * as actions from './actions'
import {update, purge} from 'modules/basicConfiguration/store/actions'
import * as api from 'api/basicConfiguration'

import listSaga from 'modules/higherOrder/createListSaga'

const selector = (state) => ({
    ...state.basicConfiguration.list,
    perPage: selectPerPage(state, 'basicConfiguration'),
    listRoute: 'basicConfigurations',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
    ])
}
