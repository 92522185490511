import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import processType from 'constants/processType'

import getBatchStatusTitle, {list} from 'constants/batchesStatuses'

import {fetch, addFilters, removeFilters, clearFilters, fetchSuggests, setQuery} from 'modules/batches/list/actions'

import withProps from 'containers/withProps'

import Search from 'components/Search/Search'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import DateFilter from 'components/Search/Filters/DateFilter'

import __ from 'utils/i18n'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'


export default compose(
    withProps(() => ({
        filters: [
            new TranslateSuggestFilter('type', __('Type'), processType),
            new ValuesFilter('status', __('Status'),
                list.reduce((acc, value) => {
                    acc[value] = getBatchStatusTitle(value)
                    return acc
                }, {})
            ),
            new DateFilter('started', __('Started at')),
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('group', __('Group')),
            new DateFilter('finished', __('Finished at')),
        ],
    })),
    connect(
        ({batches}) => ({
            selected: batches.list.filters,
            suggests: batches.list.suggests,
            query: batches.list.query,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
            onQuery: setQuery,
        }, dispatch),
    ),
)(Search)