import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {withRouterPanelId} from 'containers/withPanel'

import {
    fetch, reset, startPoll,
    stopPoll
} from 'modules/panels/remoteInspections/actions'
import {
    markAsViewed,
    sendEmail
} from 'permissions/panel/remoteInspections/actions'

import {initiateNow} from 'modules/remoteInspections/store/actions'
import {showScheduleRri} from 'modules/modals/actions'
import moment from 'moment'
import {YEAR} from 'ipmp-react-ui/Calendar'
import {
    markAsViewed as markAsViewedAction,
    sendEmail as sendEmailAction
} from 'modules/remoteInspections/results/actions'
import {withPermission} from './withPermission'
import {selectPanelRemoteInspection} from 'modules/panels/remoteInspections/selectors'

export default function withPanelRemoteInspection() {
    return compose(
        withPermission({
            permissions: {
                markAsViewed,
                sendEmail,
            },
        }),
        withRouterPanelId(),
        connect(
            selectPanelRemoteInspection,
            (dispatch, {panelId}) => bindActionCreators({
                reset: () => reset(
                    {date: moment(), view: YEAR},
                    parseInt(panelId)
                ),
                fetch: (...args) => fetch(...args, parseInt(panelId)),
                startPoll: () => startPoll(
                    {date: moment(), view: YEAR},
                    parseInt(panelId)
                ),
                stopPoll: () => stopPoll(),
                initiateNow: () => initiateNow([panelId]),
                schedule: () => showScheduleRri([panelId]),
                markAsViewed: markAsViewedAction,
                sendEmail: sendEmailAction,
            }, dispatch),
        )
    )
}