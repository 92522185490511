import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {
    setConfigurationAppState,
    setUserAppState,
} from 'modules/panels/one/actions'

import {withPermission} from 'containers/withPermission'
import {ALIGN_BOTTOM, ALIGN_RIGHT, ALIGN_LEFT} from 'ipmp-react-ui/DropDown'
import apps from 'permissions/panels/apps'
import isRtl from 'ipmp-react-ui/_utils/isRtl'

import AppIcon from 'ipmp-react-ui/icons/app.svg'

import ApplicationInteractiveDropdown from 'components/DropDown/ApplicationInteractiveDropdown'
import withProps from 'containers/withProps'
import {withFeatureRejection} from 'containers/withFeature'
import {selectApplicationFeature} from 'modules/panels/store/selectors'

class ApplicationCell extends Component {
    static propTypes = {
        setUserAppState: PropTypes.func,
        setConfigurationAppState: PropTypes.func,
        row: PropTypes.object,
        isPermitted: PropTypes.bool,
    }

    handleUserAppChange = (e) => {
        const {setUserAppState, row} = this.props
        setUserAppState(row, e.target.checked)
    }

    handleInstallerAppChange = (e) => {
        const {setConfigurationAppState, row} = this.props
        setConfigurationAppState(row, e.target.checked)
    }

    renderCell() {
        const {row} = this.props

        return (
            <span className="applications">
                <AppIcon className="applications-icon"/>

                <span className={classNames('application', {'application--enabled': row.userApp})}> U </span>
                <span className={classNames('application', {'application--enabled': row.configuratorApp})}> I </span>
            </span>
        )
    }

    render() {
        const {row, isPermitted} = this.props

        const trigger = this.renderCell()

        if (!isPermitted) {
            return trigger
        }

        return (
            <ApplicationInteractiveDropdown
                panel={row}
                trigger={trigger}
                align={ALIGN_BOTTOM | (isRtl() ? ALIGN_LEFT : ALIGN_RIGHT)}
            />
        )
    }
}

export default compose(
    withPermission(apps),
    connect(null, dispatcher => bindActionCreators({
        setUserAppState,
        setConfigurationAppState,
    }, dispatcher)),
    withProps(({row}) => ({panelId: row.id})),
    withFeatureRejection(selectApplicationFeature)
)(ApplicationCell)