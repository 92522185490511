import createPermission, {any} from 'permissions/createPermission'

import ssh from './plink/ssh'
import log from './plink/log'
import reboot from './plink/reboot'

export default createPermission(
    any(
        log,
        ssh,
        reboot,
    ),
)