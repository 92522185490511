import createPermission, {any} from 'permissions/createPermission'
import selection from './selection'

export const create = createPermission('reports.create')

export default createPermission(
    any(
        selection,
        create,
    ),
)