import StringValue from 'components/Configuration/Row/StringValue'
import {__} from 'utils/i18n'

const regexp = /^([a-f]|\d)*$/i

export default class AccountValue extends StringValue {

    getMaxLength() {
        return super.getMaxLength() || 10
    }

    validate(value) {
        if (value && !regexp.test(value)) {
            return __('Should contains only hexadecimal digits')
        }

        return super.validate(value)
    }

}