import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'

import {
    setLoading,
    setError,
    clear,
    update
} from './actions'


const defaultState = {
    isLoading: false,
    error: false,
    byId: {},
}

export default handleActions({
    [clear]() {
        return defaultState
    },

    [setLoading](state, {payload}) {
        const {isLoading} = payload

        return {
            ...state,
            isLoading,
        }
    },

    [setError](state, {payload}) {
        const {isError, message} = payload

        return {
            ...state,
            error: isError ? {message} : false,
        }
    },


    [update](state, {payload}) {
        const {profiles} = payload

        return {
            ...state,
            byId: {
                ...state.byId,
                ...keyBy(profiles, 'id'),
            },
        }
    },
}, defaultState)