import {post, get} from 'api/http'
import {mapReportExpected} from './reports.map'

export function receive(panelId, from, to) {
    return get('/unit_report_results/getall', {
        unt_id: panelId,
        from,
        to,
    })
}

export function review(reportResultId) {
    return post('/unit_report_results/review', {
        urr_id: reportResultId,
    })
}

export function receiveExpected(panelId, from, to) {
    return get('/unit_report_results/getallexpected', {
        unt_id: panelId,
        from,
        to,
    }).then(data => data.map(mapReportExpected))
}
