import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import navigation from 'permissions/group/navigation'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'
import {__} from 'utils/i18n'

class GroupBarNav extends Component {
    static propTypes = {
        group: PropTypes.shape({
            id: PropTypes.number,
        }),
    }

    render() {
        const {group: {id}} = this.props

        return (
            <NavTabs>
                <NavTabLink exact to={path('system.group.general', {id})}>
                    {__('General')}
                </NavTabLink>

                <NavTabLink exact to={path('system.group.stations', {id})}>
                    {__('CS Communicating')}
                </NavTabLink>
            </NavTabs>
        )
    }
}

export default withRouter(
    compose(
        withPermission({isVisible: navigation}),
        withVisibility(),
    )(GroupBarNav),
)
