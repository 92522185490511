import {compose} from 'redux'

import {remove} from 'permissions/roles/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withSelection from 'containers/withSelection'
import withRoles from 'containers/withRoles'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import IconRemove from 'ipmp-react-ui/icons/remove.svg'

export default compose(
    withPermission({isVisible: remove}),
    withSelection(),
    withRoles(),
    withProps(({showRemoveRole}) => ({
        Icon: IconRemove,
        onClick: showRemoveRole,
        title: __('Remove roles'),
        message: __(''),
        positive: __('Remove'),
        label: __('Remove'),
    })),
    withSelectionHandler(),
)(Button)