import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/centralStations/list'

import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/centralStations/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import __ from 'utils/i18n'


export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('name', __('Name')),
            new SuggestFilter('protocol', __('Protocol')),
            new SuggestFilter('host', __('Host')),
            new SuggestFilter('port', __('Port')),
            new SuggestFilter('device', __('Device')),
        ],
    })),
    connect(
        ({centralStations: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onQuery: setQuery,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)
