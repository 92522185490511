import {__} from 'utils/i18n'
import pickBy from 'lodash/pickBy'

const tabs = {
    general: {
        label: () => __('General'),
        path: 'system.settings.general',
        exact: true,
    },
    receiver: {
        label: () => __('Receiver'),
        path: 'system.settings.receiver',
    },
    resolve: {
        label: () => __('Resolve'),
        path: 'system.settings.resolve',
    },
    interactive: {
        label: () => __('Interactive'),
        path: 'system.settings.interactive',
    },
    brokers: {
        label: () => __('Message Brokers'),
        path: 'system.settings.brokers',
    },
}

const allMenuItems = {
    general: true,
    receiver: true,
    resolve: true,
    interactive: true,
    brokers: true,
}

export function getMenuItems() {
    return allMenuItems
}

export function getItemsByKeys(keys) {
    return pickBy(tabs, (tab, key) => keys.indexOf(key) >= 0)
}