import {all} from 'redux-saga/effects'

import store from './store/saga'
import links from './links/saga'
import list from './list/saga'
import one from './one/saga'
import select from './select/saga'

export default function*() {
    yield all([
        store(),
        links(),
        list(),
        one(),
        select(),
    ])
}