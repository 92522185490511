import {
    createAction
} from 'redux-actions'

export const fetch = createAction(
    'PANELS/REMOTE_INSPECTIONS/FETCH',
    null,
    (_, panelId) => ({panelId})
)
export const reset = createAction(
    'PANELS/REMOTE_INSPECTIONS/RESET',
    null,
    (_, panelId) => ({panelId})
)
export const update = createAction(
    'PANELS/REMOTE_INSPECTIONS/UPDATE',
    null,
    (_, panelId, count) => ({panelId, count})
)
export const startPoll = createAction(
    'PANELS/REMOTE_INSPECTIONS/START_POLL',
    null,
    (_, panelId) => ({panelId})
)
export const stopPoll = createAction('PANELS/REMOTE_INSPECTIONS/STOP_POLL')