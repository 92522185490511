export default function notify(data, onClick) {
    const {title, closeTimeout, ...options} = data

    const n = new Notification(title, options)

    if (onClick) {
        n.onclick = e => onClick(e, n)
    }

    if (closeTimeout) {
        setTimeout(closeTimeout, ()=>{
            n.close()
        })
    }

    return n
}