import React, {Fragment} from 'react'
import {Route} from 'react-router'

class ChangeEmiter extends React.Component {

    componentDidMount() {
        this.props.emit(this.props.path)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false
    }

    render() {
        return null
    }
}

export function withLocationChange(onChange) {
    return Component => props => (
        <Fragment>
            <Component {...props}/>

            <Route>
                {
                    (match) => <ChangeEmiter key={match.location.pathname} emit={() => onChange(match, props)}/>
                }
            </Route>
        </Fragment>
    )
}