import {all, put, takeEvery} from 'redux-saga/effects'

import * as processTypes from 'constants/processTypes'
import * as api from 'api/pgm'
import * as actions from './actions'

import generateProcess from 'modules/processes/manager/generateProcess'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'

export default function* () {

    yield all([
        takeEvery(actions.disablePGM, watchDisablePGM),
        takeEvery(actions.startSavePGM, watchStartSavePGM),

        takeEveryProcessComplete([
            processTypes.PROCESS_TYPE_EDITPGM,
        ], watchProcessComplete),
    ])
}

function* watchStartSavePGM({payload}) {
    const {panelId, id, type, options} = payload
    const {batchId} = yield generateBatchForOneProcess(
        processTypes.PROCESS_TYPE_EDITPGM,
        panelId
    )
    const {execute} = yield generateProcess(
        processTypes.PROCESS_TYPE_EDITPGM,
        panelId
    )

    try {
        yield execute(
            api.editPGM,
            panelId,
            id,
            type,
            options,
            batchId
        )
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchDisablePGM({payload: {panelId, id}}) {
    const {batchId} = yield generateBatchForOneProcess(
        processTypes.PROCESS_TYPE_DISABLEPGM,
        panelId
    )
    const {execute} = yield generateProcess(
        processTypes.PROCESS_TYPE_DISABLEPGM,
        panelId
    )

    try {
        yield execute(api.disablePGM, panelId, id, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchProcessComplete(process) {
    if (process.isFailed) {
        yield put(snackShow(process.message))
    }
}