import React, {PureComponent} from 'react'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import Textarea from 'ipmp-react-ui/Textarea'
import ModalCardForm from 'ui/ModalCardForm'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchLanguages} from 'modules/languages/actions'
import _get from 'lodash-es/get'
import {set} from 'immutable-modify'

const LanguageSelect = withSelectLoader(
    fetchLanguages,
    store => store.languages.isLoading,
    store => Object.values(store.languages.byIds).map(({id, name}) => ({value: id, label: name})),
)(Select)

export default class GroupFieldset extends PureComponent {

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.data = props.data
    }

    rules = {
        name: {
            presence: true,
            length: {
                maximum: 24,
                tooLong: 'needs to have less then %{count} characters',
            },
        },

        description: {
            length: {
                maximum: 1024,
            },
        },

        pmasterGprsSupervision: {
            presence: (value, attributes) => attributes.pmasterGprsIsEnabled !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        pmasterGprsTimeout: {
            presence: (value, attributes) => attributes.pmasterGprsIsEnabled !== null,
            numericality: (value, {pmasterGprsSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(pmasterGprsSupervision),
            }),
        },

        pmasterBbaTimeout: {
            presence: true,
            numericality: (value, {pmasterBbaSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(pmasterBbaSupervision),
            }),
        },

        pmasterBbaSupervision: {
            presence: true,
            numericality: {
                onlyInteger: true,
            },
        },

        quazarGprsSupervision: {
            presence: (value, attributes) => attributes.quazarGprsIsEnabled !== null || attributes.quazarBbaIsEnabled !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        quazarBbaTimeout: {
            presence: (value, attributes) => attributes.quazarBbaIsEnabled !== null,
            numericality: (value, {quazarGprsSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(quazarGprsSupervision) ,
            }),
        },

        quazarGprsTimeout: {
            presence: (value, attributes) => attributes.quazarGprsIsEnabled !== null,
            numericality: (value, {quazarGprsSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(quazarGprsSupervision),
            }),
        },

        quazar53BbaSupervision: {
            presence: (value, attributes) => attributes.quazar53BbaIsEnabled !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        quazar53BbaTimeout: {
            presence: (value, attributes) => attributes.quazar53BbaIsEnabled !== null,
            numericality: (value, {quazar53BbaSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(quazar53BbaSupervision),
            }),
        },

        quazar53GprsSupervision: {
            presence: (value, attributes) => attributes.quazar53GprsIsEnabled !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        quazar53GprsTimeout: {
            presence: (value, attributes) => attributes.quazar53GprsIsEnabled !== null,
            numericality: (value, {quazar53GprsSupervision}) => ({
                onlyInteger: true,
                greaterThan: parseInt(quazar53GprsSupervision),
            }),
        },

        serverMessagingLanguageId: {
            presence: true,
        },
    }

    handleFieldChanged = (path, value) => {
        this.setState(set(this.state, `data.${path}`, value))
    }

    handleQuazarSupervison = (value) => {
        value = Number(value)
        let state = set(this.state, 'data.communications.quazar.bba.supervision', value)
        state = set(state, 'data.communications.quazar.gprs.supervision', value)
        this.setState(state)
    }

    handleSubmit(data) {
        this.props.handle(this.state.data)
    }

    render() {
        const {...props} = this.props
        const {data} = this.state
        return (
            <ModalCardForm
                wide
                confirmOnDismiss
                onSubmit={this.handleSubmit.bind(this)}
                rules={this.rules}
                {...props}
            >

                <Input
                    autoFocus
                    name="name"
                    maxLength="24"
                    defaultValue={data.name}
                    label={__('Name')}
                    onChange={(e) => { this.handleFieldChanged('name', e.target.value) }}/>

                <Textarea
                    name="description"
                    defaultValue={data.description}
                    label={__('Description')}
                    onChange={(e) => { this.handleFieldChanged('description', e.target.value) }}/>


                <h3 className="title title--cols">{__('PowerMaster/PowerMax')}</h3>

                <div className="cols cols--stretch">
                {/* commented by PMN-6748
                    <div className="col">
                        <Togglebox
                            name="pscConnection"
                            label={__('PSC connection enabled')}
                            labelOff={__('PSC connection disabled')}
                            defaultChecked={_get(data, 'pscConnection', false)}
                            onChange={(e) => { this.handleFieldChanged('pscConnection', e.target.checked == 1) }}
                        />
                    </div>
                */}
                    <div className="col">
                        <Select
                            name="upgradeMethod"
                            label={__('Power Master upgrade method')}
                            defaultValue={data.upgradeMethod}
                            defaultLabel={data.upgradeMethod == 1 ? 'GRPS' : 'Broadband'}
                            onChange={(e, value) => { this.handleFieldChanged('upgradeMethod', value) }}
                        >
                            <Option value="gprs" label={__('GPRS')} />
                            <Option value="bba" label={__('Broadband')} />
                        </Select>
                    </div>
                </div>

                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Cellular')}>
                        <Togglebox
                            name="pmasterGprsIsEnabled"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={_get(data, 'communications.pmaster.gprs.isEnabled', false)}
                            onChange={(e) => { this.handleFieldChanged('communications.pmaster.gprs.isEnabled', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="pmasterGprsSupervision"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={_get(data, 'communications.pmaster.gprs.supervision', false)}
                                disabled={!_get(data, 'communications.pmaster.gprs.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.pmaster.gprs.supervision', Number(e.target.value)) }}
                            />

                            <Input
                                name="pmasterGprsTimeout"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.pmaster.gprs.timeout', false)}
                                disabled={!_get(data, 'communications.pmaster.gprs.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.pmaster.gprs.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col" title={__('Broadband')}>
                        <Togglebox
                            name="BBAEncryption"
                            label={__('Encryption enabled')}
                            labelOff={__('Encryption disabled')}
                            defaultChecked={_get(data, 'BBAEncryption', false)}
                            onChange={(e) => { this.handleFieldChanged('BBAEncryption', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="pmasterBbaSupervision"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={_get(data, 'communications.pmaster.bba.supervision', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.pmaster.bba.supervision', Number(e.target.value)) }}
                            />

                            <Input
                                name="pmasterBbaTimeout"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.pmaster.bba.timeout', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.pmaster.bba.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>
                </div>

                <h3 className="title title--cols">{__('PowerSeries NEO/Pro, Quazar <5.3')}</h3>

                <div className="cols cols--stretch">
                    <FormSection className="col">
                        <Togglebox
                            name="quazarGprsIsEnabled"
                            label={__('Cellular Supervision enabled')}
                            labelOff={__('Cellular Supervision disabled')}
                            defaultChecked={_get(data, 'communications.quazar.gprs.isEnabled', false)}
                            onChange={(e) => { this.handleFieldChanged('communications.quazar.gprs.isEnabled', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="quazarGprsSupervision"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar.bba.supervision', false)}
                                disabled={!(_get(data, 'communications.quazar.bba.isEnabled', false) || _get(data, 'communications.quazar.gprs.isEnabled', false))}
                                onChange={(e) => { this.handleQuazarSupervison(e.target.value) }}
                            />

                            <Input
                                name="quazarGprsTimeout"
                                label={__('Cellular Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar.gprs.timeout', false)}
                                disabled={!_get(data, 'communications.quazar.gprs.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar.gprs.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col">
                        <Togglebox
                            name="quazarBbaIsEnabled"
                            label={__('Broadband Supervision enabled')}
                            labelOff={__('Broadband Supervision disabled')}
                            defaultChecked={_get(data, 'communications.quazar.bba.isEnabled', false)}
                            onChange={(e) => { this.handleFieldChanged('communications.quazar.bba.isEnabled', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="quazarBbaTimeout"
                                label={__('Broadband Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar.bba.timeout', false)}
                                disabled={!(_get(data, 'communications.quazar.gprs.isEnabled', false) || _get(data, 'communications.quazar.bba.isEnabled', false))}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar.bba.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>
                </div>
                <div className="title title--cols">
                    {__('PowerSeries NEO, Quazar 5.3 and higher')}
                </div>
                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Cellular')}>
                        <Togglebox
                            name="quazar53GprsIsEnabled"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={_get(data, 'communications.quazar53.gprs.isEnabled', false)}
                            onChange={(e) => { this.handleFieldChanged('communications.quazar53.gprs.isEnabled', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="quazar53GprsSupervision"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar53.gprs.supervision', false)}
                                disabled={!_get(data, 'communications.quazar53.gprs.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar53.gprs.supervision', Number(e.target.value)) }}
                            />

                            <Input
                                name="quazar53GprsTimeout"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar53.gprs.timeout', false)}
                                disabled={!_get(data, 'communications.quazar53.gprs.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar53.gprs.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col" title={__('Broadband')}>
                        <Togglebox
                            name="quazar53BbaIsEnabled"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={_get(data, 'communications.quazar53.bba.isEnabled', false)}
                            onChange={(e) => { this.handleFieldChanged('communications.quazar53.bba.isEnabled', e.target.checked == 1) }}
                        />

                        <FormRow>
                            <Input
                                name="quazar53BbaSupervision"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar53.bba.supervision', false)}
                                disabled={!_get(data, 'communications.quazar53.bba.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar53.bba.supervision', Number(e.target.value)) }}
                            />

                            <Input
                                name="quazar53BbaTimeout"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={_get(data, 'communications.quazar53.bba.timeout', false)}
                                disabled={!_get(data, 'communications.quazar53.bba.isEnabled', false)}
                                onChange={(e) => { this.handleFieldChanged('communications.quazar53.bba.timeout', Number(e.target.value)) }}
                            />
                        </FormRow>
                    </FormSection>
                </div>
                <div className="title title--cols">
                    {__('General')}
                </div>
                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Interactive')}>
                        <Checkbox
                            name="localWakeUp"
                            label={__('Local wake up')}
                            defaultChecked={data.localWakeUp}
                            onChange={(e) => { this.handleFieldChanged('localWakeUp', e.target.checked == 1) }}
                            />
                        <LanguageSelect
                            name="serverMessagingLanguageId"
                            label={__('Server Messaging Language')}
                            defaultValue={data.serverMessagingLanguageId}
                            defaultLabel={data.serverMessagingLanguage}
                            onChange={(e, value) => { this.handleFieldChanged('serverMessagingLanguageId', Number(value)) }}
                        />
                    </FormSection>

                    <FormSection className="col" title={__('Other')}>
                        <Checkbox
                            name="timeSynchronization"
                            label={__('Panel\'s time synchronization')}
                            defaultChecked={data.timeSynchronization}
                            onChange={(e) => { this.handleFieldChanged('timeSynchronization', e.target.checked == 1) }}
                        />
                        <Checkbox
                            name="allowUpgradeOverGprsDSCPanels"
                            label={__('Allow upgrade over GPRS for DSC panel')}
                            defaultChecked={data.allowUpgradeOverGprsDSCPanels}
                            onChange={(e) => (
                                this.handleFieldChanged(
                                    'allowUpgradeOverGprsDSCPanels',
                                    e.target.checked == 1
                                )
                            )}
                        />
                    </FormSection>
                </div>
            </ModalCardForm>
        )
    }

}