import {compose} from 'redux'
import page from 'permissions/remoteInspections/page'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'
import withProps from 'containers/withProps'

import RemoteInspectionContent from './RemoteInspectionContent'
import RemoteInspectionSearch from './RemoteInspectionSearch'
import RemoteInspectionBar from './RemoteInspectionBar'

import Page from 'ipmp-react-ui/Page'

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withProps({
        Top: RemoteInspectionSearch,
        Bar: RemoteInspectionBar,
        Content: RemoteInspectionContent,
    }),
)(Page)