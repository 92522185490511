import themes, {DEFAULT_THEME} from 'constants/themes'
import {setLastKnownTheme} from 'storage/lastKnownTheme'

export default function applyTheme(theme) {
    theme = theme || DEFAULT_THEME
    setLastKnownTheme(theme)
    Object.values(themes).forEach(
        ({classList}) => document.body.classList.remove(...classList),
    )
    document.body.classList.add(...themes[theme].classList)
}