const COUNTRIES = {
    dz: {en: 'Algeria', ar: ' الجزائر'},
    bh: {en: 'Bahrain', ar: ' البحرين'},
    eg: {en: 'Egypt', ar: ' مصر'},
    iq: {en: 'Iraq', ar: ' العراق'},
    jo: {en: 'Jordan', ar: ' الأردن'},
    kw: {en: 'Kuwait', ar: ' الكويت'},
    lb: {en: 'Lebanon', ar: ' لبنان'},
    ly: {en: 'Libyan Arab Jamahiriya', ar: ' ليبيا'},
    ma: {en: 'Morocco', ar: ' المغرب'},
    om: {en: 'Oman', ar: ' عمان'},
    qa: {en: 'Qatar', ar: ' قطر'},
    sa: {en: 'Saudi Arabia', ar: ' السعودية'},
    sd: {en: 'Sudan', ar: ' السودان'},
    sy: {en: 'Syrian Arab Republic', ar: ' سوريا'},
    tn: {en: 'Tunisia', ar: ' تونس'},
    ae: {en: 'United Arab Emirates', ar: ' الإمارات العربية المتحدة'},
    ye: {en: 'Yemen', ar: ' اليمن'},
    am: {en: 'Armenia', hy: 'Հայաստան'},
    by: {en: 'Belarus', be: 'Беларусь'},
    ba: {en: 'Bosnia and Herzegovina', bs: 'Bosna i Hercegovina'},
    bg: {en: 'Bulgaria', bg: 'България'},
    es: {en: 'Spain', ca: 'Espanya', es: 'España'},
    hr: {en: 'Croatia', hr: 'Hrvatska'},
    cz: {en: 'Czech Republic', cs: 'Česko'},
    dk: {en: 'Denmark', da: 'Danmark'},
    be: {en: 'Belgium', nl: 'België', fr: 'Belgique', de: 'Belgien'},
    nl: {en: 'Netherlands', nl: 'Nederland'},
    as: {en: 'American Samoa'},
    au: {en: 'Australia'},
    bz: {en: 'Belize'},
    bw: {en: 'Botswana'},
    ca: {en: 'Canada', fr: 'Canada'},
    gu: {en: 'Guam'},
    hk: {en: 'Hong Kong'},
    in: {en: 'India'},
    ie: {en: 'Ireland'},
    jm: {en: 'Jamaica'},
    mt: {en: 'Malta'},
    mh: {en: 'Marshall Islands'},
    mu: {en: 'Mauritius'},
    na: {en: 'Namibia'},
    nz: {en: 'New Zealand'},
    mp: {en: 'Northern Mariana Islands'},
    pk: {en: 'Pakistan'},
    ph: {en: 'Philippines'},
    sg: {en: 'Singapore'},
    za: {en: 'South Africa'},
    tt: {en: 'Trinidad and Tobago'},
    um: {en: 'United States Minor Outlying Islands'},
    vi: {en: 'Virgin Islands, U.S.'},
    gb: {en: 'United Kingdom'},
    us: {en: 'United States of America', es: 'Estados Unidos'},
    zw: {en: 'Zimbabwe'},
    ee: {en: 'Estonia', et: 'Eesti'},
    fi: {en: 'Finland', fi: 'Suomi', sv: 'Finland'},
    bj: {en: 'Benin', fr: 'Bénin'},
    bf: {en: 'Burkina Faso', fr: 'Burkina Faso'},
    bi: {en: 'Burundi', fr: 'Burundi'},
    cm: {en: 'Cameroon', fr: 'Cameroun'},
    cf: {en: 'Central African Republic', fr: 'Centrafricaine, République'},
    td: {en: 'Chad', fr: 'Tchad'},
    km: {en: 'Comoros', fr: 'Comores'},
    cg: {en: 'Congo', fr: 'Congo, République populaire'},
    cd: {en: 'Congo, the Democratic Republic of the', fr: 'Congo, République démocratique'},
    ci: {en: 'Cote D\'Ivoire', fr: 'Côte-d\'Ivoire'},
    dj: {en: 'Djibouti', fr: 'Djibouti'},
    gq: {en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial'},
    fr: {en: 'France', fr: 'France'},
    ga: {en: 'Gabon', fr: 'Gabon'},
    gp: {en: 'Guadeloupe', fr: 'Guadeloupe'},
    gn: {en: 'Guinea', fr: 'Guinée'},
    lu: {en: 'Luxembourg', fr: 'Luxembourg, Grand-Duché', de: 'Luxemburg'},
    mg: {en: 'Madagascar', fr: 'Madagascar'},
    ml: {en: 'Mali', fr: 'Mali'},
    mq: {en: 'Martinique', fr: 'Martinique'},
    mc: {en: 'Monaco', fr: 'Monaco'},
    ne: {en: 'Niger', fr: 'Niger'},
    rw: {en: 'Rwanda', fr: 'Rwanda'},
    re: {en: 'Reunion', fr: 'Réunion'},
    bl: {en: 'Saint Barthélemy', fr: 'Saint-Barthélemy'},
    mf: {en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)'},
    sn: {en: 'Senegal', fr: 'Sénégal'},
    ch: {en: 'Switzerland', fr: 'Suisse', de: 'Schweiz', it: 'Svizzera'},
    tg: {en: 'Togo', fr: 'Togo'},
    ge: {en: 'Georgia', ka: 'საქართველო'},
    at: {en: 'Austria', de: 'Österreich'},
    de: {en: 'Germany', de: 'Deutschland'},
    li: {en: 'Liechtenstein', de: 'Liechtenstein'},
    cy: {en: 'Cyprus', el: 'Κύπρος'},
    gr: {en: 'Greece', el: 'Ελλάδα'},
    il: {en: 'Israel', he: 'ישראל'},
    hu: {en: 'Hungary', hu: 'Magyarország'},
    id: {en: 'Indonesia', id: 'Indonesia'},
    it: {en: 'Italy', it: 'Italia'},
    jp: {en: 'Japan', ja: '日本'},
    kr: {en: 'South Korea', ko: '대한민국'},
    lv: {en: 'Latvia', lv: 'Latvija'},
    lt: {en: 'Lithuania', lt: 'Lietuva'},
    mk: {en: 'Macedonia, the Former Yugoslav Republic of', mk: 'Македонија'},
    no: {en: 'Norway', nb: 'Norge', nn: 'Noreg'},
    af: {en: 'Afghanistan', fa: 'افغانستان'},
    ir: {en: 'Iran, Islamic Republic of', fa: 'ایران'},
    pl: {en: 'Poland', pl: 'Polska'},
    br: {en: 'Brazil', pt: 'Brasil'},
    gw: {en: 'Guinea-Bissau', pt: 'Guiné Bissau'},
    mz: {en: 'Mozambique', pt: 'Moçambique'},
    pt: {en: 'Portugal', pt: 'Portugal'},
    md: {en: 'Moldova, Republic of', ro: 'Republica Moldova', ru: 'Молдавия'},
    ro: {en: 'Romania', ro: 'România'},
    ru: {en: 'Russian Federation', ru: 'Россия'},
    ua: {en: 'Ukraine', ru: 'Украина', uk: 'Україна'},
    sk: {en: 'Slovakia', sk: 'Slovensko'},
    si: {en: 'Slovenia', sl: 'Slovenija'},
    ar: {en: 'Argentina', es: 'Argentina'},
    bo: {en: 'Bolivia', es: 'Bolivia'},
    cl: {en: 'Chile', es: 'Chile'},
    co: {en: 'Colombia', es: 'Colombia'},
    cr: {en: 'Costa Rica', es: 'Costa Rica'},
    do: {en: 'Dominican Republic', es: 'República Dominicana'},
    ec: {en: 'Ecuador', es: 'Ecuador'},
    sv: {en: 'El Salvador', es: 'El Salvador'},
    gt: {en: 'Guatemala', es: 'Guatemala'},
    hn: {en: 'Honduras', es: 'Honduras'},
    mx: {en: 'Mexico', es: 'México'},
    ni: {en: 'Nicaragua', es: 'Nicaragua'},
    pa: {en: 'Panama', es: 'Panamá'},
    py: {en: 'Paraguay', es: 'Paraguay'},
    pe: {en: 'Peru', es: 'Perú'},
    pr: {en: 'Puerto Rico', es: 'Puerto Rico'},
    uy: {en: 'Uruguay', es: 'Uruguay'},
    ve: {en: 'Venezuela', es: 'Venezuela'},
    se: {en: 'Sweden', sv: 'Sverige'},
    tr: {en: 'Turkey', tr: 'Türkiye'},
    ax: {en: 'Åland Islands'},
    al: {en: 'Albania'},
    ad: {en: 'Andorra'},
    ao: {en: 'Angola'},
    ai: {en: 'Anguilla'},
    aq: {en: 'Antarctica'},
    ag: {en: 'Antigua and Barbuda'},
    aw: {en: 'Aruba'},
    az: {en: 'Azerbaijan'},
    bs: {en: 'Bahamas'},
    bd: {en: 'Bangladesh'},
    bb: {en: 'Barbados'},
    bm: {en: 'Bermuda'},
    bt: {en: 'Bhutan'},
    bq: {en: 'Bonaire, Sint Eustatius and Saba'},
    bv: {en: 'Bouvet Island'},
    io: {en: 'British Indian Ocean Territory'},
    bn: {en: 'Brunei Darussalam'},
    cv: {en: 'Cape Verde'},
    kh: {en: 'Cambodia'},
    ky: {en: 'Cayman Islands'},
    cn: {en: 'China'},
    cx: {en: 'Christmas Island'},
    cc: {en: 'Cocos (Keeling) Islands'},
    ck: {en: 'Cook Islands'},
    cu: {en: 'Cuba'},
    cw: {en: 'Curaçao'},
    dm: {en: 'Dominica'},
    er: {en: 'Eritrea'},
    et: {en: 'Ethiopia'},
    fk: {en: 'Falkland Islands (Malvinas)'},
    fo: {en: 'Faroe Islands'},
    fj: {en: 'Fiji'},
    gf: {en: 'French Guiana'},
    pf: {en: 'French Polynesia'},
    tf: {en: 'French Southern Territories'},
    gm: {en: 'Gambia'},
    gh: {en: 'Ghana'},
    gi: {en: 'Gibraltar'},
    gl: {en: 'Greenland'},
    gd: {en: 'Grenada'},
    gg: {en: 'Guernsey'},
    gy: {en: 'Guyana'},
    ht: {en: 'Haiti'},
    hm: {en: 'Heard Island and Mcdonald Islands'},
    va: {en: 'Holy See (Vatican City State)'},
    is: {en: 'Iceland'},
    im: {en: 'Isle of Man'},
    je: {en: 'Jersey'},
    kz: {en: 'Kazakhstan'},
    ke: {en: 'Kenya'},
    ki: {en: 'Kiribati'},
    kp: {en: 'North Korea'},
    kg: {en: 'Kyrgyzstan'},
    la: {en: 'Lao People\'s Democratic Republic'},
    ls: {en: 'Lesotho'},
    lr: {en: 'Liberia'},
    mo: {en: 'Macao'},
    mw: {en: 'Malawi'},
    my: {en: 'Malaysia'},
    mv: {en: 'Maldives'},
    mr: {en: 'Mauritania'},
    yt: {en: 'Mayotte'},
    fm: {en: 'Micronesia, Federated States of'},
    mn: {en: 'Mongolia'},
    me: {en: 'Montenegro'},
    ms: {en: 'Montserrat'},
    mm: {en: 'Myanmar'},
    nr: {en: 'Nauru'},
    np: {en: 'Nepal'},
    nc: {en: 'New Caledonia'},
    ng: {en: 'Nigeria'},
    nu: {en: 'Niue'},
    nf: {en: 'Norfolk Island'},
    pw: {en: 'Palau'},
    ps: {en: 'Palestinian Territory, Occupied'},
    pg: {en: 'Papua New Guinea'},
    pn: {en: 'Pitcairn'},
    sh: {en: 'Saint Helena'},
    kn: {en: 'Saint Kitts and Nevis'},
    lc: {en: 'Saint Lucia'},
    pm: {en: 'Saint Pierre and Miquelon'},
    vc: {en: 'Saint Vincent and the Grenadines'},
    ws: {en: 'Samoa'},
    sm: {en: 'San Marino'},
    st: {en: 'Sao Tome and Principe'},
    rs: {en: 'Serbia'},
    sc: {en: 'Seychelles'},
    sl: {en: 'Sierra Leone'},
    sx: {en: 'Sint Maarten (Dutch part)'},
    sb: {en: 'Solomon Islands'},
    so: {en: 'Somalia'},
    gs: {en: 'South Georgia and the South Sandwich Islands'},
    ss: {en: 'South Sudan'},
    lk: {en: 'Sri Lanka'},
    sr: {en: 'Suriname'},
    sj: {en: 'Svalbard and Jan Mayen'},
    sz: {en: 'Swaziland'},
    tw: {en: 'Taiwan'},
    tj: {en: 'Tajikistan'},
    tz: {en: 'Tanzania, United Republic of'},
    th: {en: 'Thailand'},
    tl: {en: 'Timor-Leste'},
    tk: {en: 'Tokelau'},
    to: {en: 'Tonga'},
    tm: {en: 'Turkmenistan'},
    tc: {en: 'Turks and Caicos Islands'},
    tv: {en: 'Tuvalu'},
    ug: {en: 'Uganda'},
    uz: {en: 'Uzbekistan'},
    vu: {en: 'Vanuatu'},
    vn: {en: 'Viet Nam'},
    vg: {en: 'Virgin Islands, British'},
    wf: {en: 'Wallis and Futuna'},
    eh: {en: 'Western Sahara'},
    zm: {en: 'Zambia'},
}

export function getCountryName(code, language) {
    code = code.toLowerCase()
    language = language.toLowerCase()

    if (!COUNTRIES.hasOwnProperty(code)) {
        return code
    }

    const translations = COUNTRIES[code]

    if (translations.hasOwnProperty(language)) {
        return translations[language]
    }

    if (translations.hasOwnProperty('en')) {
        return translations['en']
    }

    return Object.values(translations).pop()
}