import get from 'lodash-es/get'
import {compose} from 'redux'

import {STATUS_SUSPENDED} from 'constants/interactiveUserStatus'
import {suspend} from 'permissions/interactiveUsers/actions'

import withProps from 'containers/withProps'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withSelection, {withSelectionHandler} from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: suspend}),
    withVisibility(),
    withSelection(),
    withInteractiveUsers(),
    withProps(({enable}) => ({
        label: __('Enable'),
        onClick: selection => enable(selection),
    })),
    withSelectionHandler(
        (id, {interactiveUsers}) => get(interactiveUsers, [
            'store',
            'byIds',
            id,
            'status',
        ]) === STATUS_SUSPENDED,
    ),
)(Button)