import dataMapper from 'utils/dataMapper'

export const PGMListMapper = (device) => device && ({
    deviceId: device.device_id,
    deviceType: device.device_type,
    pgms: device.pgms.map(dataMapper(PGMMapper)),
})

export const PGMMapper = (pgm) => pgm && ({
    id: parseInt(pgm.id),
    zoneNumber: parseInt(pgm.number),
    port: parseInt(pgm.port),
    enabled: pgm.enabled,
    type: pgm.type,
    options: dataMapper(PGMOptionsMapper)(pgm.options),
})


export const PGMOptionsMapper = (options) => {
    const option = name => (options.hasOwnProperty(name) ? {[name]: options[name]} : {})
    return ({
        ...option('ACCESS_CODE_REQUIRED'),
        ...option('AC_TROUBLE'),
        ...option('AUDIBLE_EXIT_FAULT'),
        ...option('AUTO_ARM_PRE_ALERT'),
        ...option('BATTERY_ABSENT'),
        ...option('BATTERY_LOW'),
        ...option('BURGLARY_ALARM'),
        ...option('BURGLARY_VERIFIED'),
        ...option('BUS_VOLTAGE'),
        ...option('COMMUNICATIONS'),
        ...option('CO_ALARM'),
        ...option('DC_TROUBLE'),
        ...option('DEVICE_FAULT'),
        ...option('DEVICE_LOW_BATTERY'),
        ...option('DEVICE_TAMPER'),
        ...option('DOOR_CHIME'),
        ...option('DURESS_ALARM_149'),
        ...option('DURESS_ALARM_156'),
        ...option('EMERGENCY_ALARM'),
        ...option('ENTRY_DELAY'),
        ...option('EXIT_DELAY'),
        ...option('FIRE_ALARM'),
        ...option('FIRE_SUPERVISORY'),
        ...option('FIRE_TROUBLE'),
        ...option('FOLLOW_ALARM'),
        ...option('HOLDUP'),
        ...option('HOLD_UP_VERIFIED'),
        ...option('KEYPAD_BUZZER_ZONE'),
        ...option('LATCHING'),
        ...option('LOSS_OF_CLOCK'),
        ...option('MEDICAL_ALARM_149'),
        ...option('MEDICAL_ALARM_156'),
        ...option('MODULE_SUPERVISORY'),
        ...option('MODULE_TAMPER'),
        ...option('NOT_NETWORKED'),
        ...option('OPEN_AFTER_ALARM'),
        ...option('OPEN_CLOSE'),
        ...option('PANIC_ALARM'),
        ...option('PARTITIONS'),
        ...option('PGM_TIMER'),
        ...option('PGM_TIMER_VALUE'),
        ...option('PRIORITY_EVENT'),
        ...option('PROX_TAG_USED_NUMBER'),
        ...option('RF_DELINQUENCY'),
        ...option('SERVICE_REQUIRED'),
        ...option('SUPERVISORY'),
        ...option('SYSTEM_MODULE_TAMPERS'),
        ...option('TIMED_OUTPUT'),
        ...option('TIMED_OUTPUT_LATCHED_OUTPUT'),
        ...option('TRUE_OUTPUT_INVERTED'),
        ...option('TRUE_OUTPUT_LATCHED_OUTPUT'),
        ...option('ZONE_AUTO_BYPASS'),
        ...option('ZONE_FOLLOW_NUMBER'),
        ...option('ZONE_TAMPERS'),
        ...option('ZONE_TERMINALS_1_8'),
        ...option('ZONE_TERMINALS_9_16'),
        ...option('ZONE_TERMINALS_17_24'),
        ...option('ZONE_TERMINALS_25_32'),
        ...option('ZONE_TERMINALS_33_40'),
        ...option('ZONE_TERMINALS_41_48'),
        ...option('ZONE_TERMINALS_49_56'),
        ...option('ZONE_TERMINALS_57_64'),
        ...option('ZONE_TERMINALS_65_72'),
        ...option('ZONE_TERMINALS_73_80'),
        ...option('ZONE_TERMINALS_81_88'),
        ...option('ZONE_TERMINALS_89_96'),
        ...option('ZONE_TERMINALS_97_104'),
        ...option('ZONE_TERMINALS_105_112'),
        ...option('ZONE_TERMINALS_113_120'),
        ...option('ZONE_TERMINALS_121_128'),
    })
}

export const mapPGM = dataMapper(PGMListMapper)

const sortPGMTypes = (a, b) => {
    if (a.label === b.label) {
        return 0
    }
    if (a.label > b.label) {
        return 1
    } else {
        return -1
    }
}

export const PGMTypesMapper = (response) => ({
    types: response.types && response.types
        .sort(sortPGMTypes)
        .map(dataMapper(PGMTypeMapper)),
    options: response.options && response.options
        .sort(sortPGMTypes)
        .map(dataMapper(PGMOptionMapper)),
})

export const PGMTypeMapper = (type) => ({
    type: type.type,
    label: type.label,
    description: type.description,
    restrictions: type.restrictions ? dataMapper(PGMTypeRestrictionsMapper)(type.restrictions) : null,
})

export const PGMTypeRestrictionsMapper = (restrictions) => ({
    deviceTypes: restrictions.device_types,
    numbers: restrictions.numbers,
})

export const PGMOptionMapper = (option) => ({
    key: option.key,
    label: option.label,
    description: option.description,
    type: option.type,
    units: option.units,
    restrictions: option.restrictions ? dataMapper(PGMOptionRestrictionsMapper)(option.restrictions) : null,
})

export const PGMOptionRestrictionsMapper = (restrictions) => ({
    deviceTypes: restrictions.device_types,
    pgmTypes: restrictions.pgm_types,
})

export const mapPGMTypes = dataMapper(PGMTypesMapper)

export const mapProcessToken = dataMapper(data => ({
    id: data.process_token,
}))
