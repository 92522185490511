import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import NotViewedIcon from 'ipmp-react-ui/icons/viewed.svg'
import ViewedIcon from 'ipmp-react-ui/icons/viewed-checked.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {humanTime} from 'ipmp-react-ui/humanTime'

export default class ViewedRRI extends Component {

    static propTypes = {
        markAsViewed: PropTypes.func.isRequired,
        id: PropTypes.number,
        result: PropTypes.shape({
            isReviewed: PropTypes.bool,
            reviewedDate: PropTypes.any,
            reviewedBy: PropTypes.string,
        }),
    }

    markAsViewed = e => {
        const {result: {isReviewed}, id, markAsViewed} = this.props
        e.stopPropagation()
        !isReviewed && markAsViewed([id])
    }

    render() {
        const {result} = this.props

        if (!result) {
            return null
        }

        if (result.isReviewed) {
            const tooltip = (
                <Fragment>
                    {humanTime(result.reviewedDate)}
                    <div className="empty">
                        {result.reviewedBy}
                    </div>
                </Fragment>
            )

            return (
                <Tooltip tooltip={tooltip} className="rri-action">
                    <ViewedIcon className="rri-action-icon rri-action-icon--viewed"/>
                </Tooltip>
            )
        }

        return (
            <a onClick={this.markAsViewed} className="rri-action">
                <NotViewedIcon className="rri-action-icon rri-action-icon--notViewed"/>
            </a>
        )
    }
}