import createPermission, {any} from 'permissions/createPermission'

import list from './list'
import bar from './bar'

export default createPermission(
    any(
        list,
        bar
    )
)