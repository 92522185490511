import React, {PureComponent} from 'react'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import PanelFieldSet, {validation as panelValidation} from './Fieldset/Panel'
import CustomerFieldSet, {
    validation as customerValidation
} from './Fieldset/Customer'
import withForm from 'containers/withForm'
import {addPanel} from 'modules/forms/handlers'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import panelVendorType, {
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
} from 'constants/panelVendorType'

const panelVendors = [
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
]

class AddPanel extends PureComponent {

    state = {
        vendor: VENDOR_NEO,
    }

    changePanelType = (e, value) => {
        this.setState({vendor: value})
    }

    render() {
        const {handle, ...props} = this.props
        const {vendor} = this.state
        const header = __('Add %s Panel', panelVendorType(vendor))
        const rules = {
            ...customerValidation,
            ...panelValidation[vendor],
        }

        return (
            <ModalCardForm
                wide
                confirmOnDismiss
                onSubmit={handle}
                header={header}
                rules={rules}
                {...props}
            >
                <div className="cols cols--stretch">
                    <div className="col">
                        <h3 className="form-section-header">
                            {__('Panel Info')}
                        </h3>

                        <Select
                            label={__('Panel type')}
                            value={vendor}
                            name="vendor"
                            onChange={this.changePanelType}
                        >
                            {panelVendors.map(vendor => (
                                <Option key={vendor} value={vendor} label={panelVendorType(vendor)}/>
                            ))}
                        </Select>

                        <PanelFieldSet isNew={true}/>
                    </div>

                    <div className="col">
                        <h3 className="form-section-header">{__('Customer Info')}</h3>
                        <CustomerFieldSet isNew={true}/>
                    </div>
                </div>
            </ModalCardForm>
        )
    }
}

export default withForm(addPanel)(AddPanel)
