export default function configurationFindNode(item, key) {
    for (let node of item.val) {
        if (node.key === key) {
            return node
        }

        if (node.type === 'menu') {
            const result = configurationFindNode(node, key)

            if (result != null) {
                return result
            }
        }
    }

    return null
}