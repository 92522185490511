import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import ModalCardForm from 'ui/ModalCardForm'
import UserFieldSet from './Fieldset/User'

export default class UserForm extends PureComponent {
    render() {
        const {handle, header, rules, ...props} = this.props
        return (
            <ModalCardForm
                confirmOnDismiss
                onSubmit={handle}
                header={header}
                rules={rules}
                {...props}
            >
                <UserFieldSet {...props}/>
            </ModalCardForm>
        )
    }
}

UserForm.propTypes = {
    handle: PropTypes.func,
    header: PropTypes.string,
    rules: PropTypes.object,
}
