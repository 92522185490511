import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import actions from 'permissions/installers/actions'
import {accept, reject} from 'modules/installers/store/actions'

import {withPermission} from 'containers/withPermission'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'

export class InstallerStatusChanger extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
        accept: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isPermitted: false,
    }

    getLabel() {
        const {row: {status}} = this.props

        switch (status) {
            case 'pending':
                return __('Pending')
            case 'rejected':
                return __('Rejected')
            case 'accepted':
                return __('Accepted')
            default:
                return __('Unknown')
        }
    }

    getClassName() {
        const {row: {status}} = this.props

        switch (status) {
            case 'pending':
                return 'warn'
            case 'rejected':
                return 'danger'
        }
    }

    isIndeterminated() {
        const {row: {status}} = this.props

        return status === 'pending'
    }

    isChecked() {
        const {row: {status}} = this.props

        return status === 'accepted'
    }

    onChange = () => {
        const {accept, reject, row: {id, status}} = this.props

        switch (status) {
            case 'pending':
            case 'rejected':
                return accept(id)
            case 'accepted':
            default:
                return reject(id)
        }
    }

    isDisabled() {
        const {isPermitted} = this.props
        return !isPermitted
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <Checkbox
                    checked={this.isChecked()}
                    disabled={this.isDisabled()}
                    indeterminate={this.isIndeterminated()}
                    label={this.getLabel()}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({isPermitted: actions}),
    connect(
        () => ({}),
        dispatch => bindActionCreators({accept, reject}, dispatch),
    ),
)(InstallerStatusChanger)