import {getVideo, processor} from 'api/events'
import {requestVideo} from 'api/vod'
import toIds from 'utils/toIds'
import {snackShow} from 'modules/snacks'
import {update} from '../store/actions'
import {__} from 'utils/i18n'

const mark = (ids, isViewed) => update(ids.map(id => ({id, isViewed})))

export function recordVideo(eventId, unitId, unitZoneId) {
    return dispatch => {
        requestVideo(unitId, unitZoneId)
            .then(() => {
                dispatch(fetchVideo(eventId))
            })
            .catch(({message}) => {
                dispatch(snackShow(message))
            })
    }
}

export function fetchVideo(id) {
    return dispatch => {
        getVideo(id).then(video => {
            dispatch(update({id, video}))
        })
    }
}

export function markAsViewed(rows) {
    return dispatch => {
        const ids = toIds(rows)

        if (ids.length == 0) {
            return
        }

        dispatch(mark(ids, true))

        processor(ids)
            .catch(() => {
                dispatch(snackShow(__('Communication Error')))
                dispatch(mark(ids, false))
            })
    }
}