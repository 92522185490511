import {handleActions} from 'redux-actions'
import {
    fetch,
    receive,
    more,
    update,
} from './actions'

const defaultState = {
    perPage: 30,
    rows: [],
    isLoading: true,
    hasMore: false,
}

export default handleActions({
    [fetch](state, {payload: {panelId, type}}) {
        return {
            [panelId]: {
                ...defaultState,
                type,
            },
        }
    },

    [more](state, {payload: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                hasMore: {
                    isLoading: true,
                },
            },
        }
    },

    [receive](state, {payload, error, meta: {panelId}}) {
        const old = state[panelId]

        if (error) {
            return {
                ...state,
                [panelId]: {
                    ...old,
                    isLoading: false,
                    error: payload,
                },
            }
        }

        const rows = old.rows.concat(payload.rows)

        return {
            ...state,
            [panelId]: {
                ...old,
                isLoading: false,
                hasMore: rows.length < payload.count,
                rows,
                process: payload.process,
            },
        }
    },

    [update](state, {payload, meta: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                ...payload,
            },
        }
    },
}, {})