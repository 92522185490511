import {takeEvery, put, take, fork, cancel} from 'redux-saga/effects'
import {show, hide} from 'modules/modals/actions'
import {receive} from 'modules/forms/actions'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield takeEvery(show, watchModal)
}

function* watchResult() {
    while (true) {
        const {payload: {success, errors, error}} = yield take(receive)

        if (success) {
            yield put(hide())
            return
        } else if (!errors || Object.keys(errors).length === 0) {
            yield put(snackShow(error))
        }
    }
}

function* watchModal() {
    const watchResultTask = yield fork(watchResult)
    yield take(hide)
    yield cancel(watchResultTask)
}