import {connect} from 'react-redux'
import {compose} from 'redux'

import {status} from 'permissions/panel/actions'
import {stateTitle} from 'constants/state'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import PanelStatusLine from 'components/PanelStatusLine'
import {troubleTitle} from 'constants/troubleType'
import {alarmTitle} from 'constants/alarmType'
import {SEVERITY_ALARM, SEVERITY_TROUBLE} from 'constants/severityType'

import {selectWarningsByPanelId} from 'modules/devices/list/selectors'
import {selectPartitionStatesById} from 'modules/panels/state/selectors'

function formatStateLines(states) {
    if (states.length) {
        const filtered = states
            .map(({state}) => state)
            .filter((state, index, self) => self.indexOf(state) === index)

        if (filtered.length === 1) {
            return filtered.map(stateTitle)
        } else {
            return states.map(({partition, state}) => `${partition}: ${stateTitle(state)}`)
        }
    }

    return []
}

function formatWarningLines(warnings) {
    return warnings.map(warning => {
        switch (warning.severity) {
            case SEVERITY_ALARM:
                return alarmTitle(warning.type)
            case SEVERITY_TROUBLE:
            default:
                return troubleTitle(warning.type)
        }
    })
}

export default compose(
    withPermission({isVisible: status}),
    withVisibility(),
    connect(
        (state, {panel: {id}}) => {
            const states = selectPartitionStatesById(state, id)
            const warningLines = formatWarningLines(selectWarningsByPanelId(state, id))

            return {
                lines: [
                    ...warningLines,
                    ...formatStateLines(states),
                ],
                hasTroubles: !!warningLines.length,
            }
        },
    ),
)(PanelStatusLine)