import React, {Component} from 'react'
import {compose} from 'redux'

import {pushBasic} from 'permissions/panels/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import PushBasicConfigurationButton from './GridBar/PushBasicConfigurationButton'
import PushBasicConfigurationPager from './GridBar/PushBasicConfigurationPager'
import PushBasicConfigurationDropDown from './GridBar/PushBasicConfigurationDropDown'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'

class FirmwareUpgradeGridBar extends Component {
    render() {
        const basicConfigId = parseInt(this.props.match.params.id)

        return (
            <Bar>
                <PushBasicConfigurationDropDown/>

                <Buttons>
                    <PushBasicConfigurationButton basicConfigId={basicConfigId}/>
                </Buttons>

                <BarSpace/>

                <PushBasicConfigurationPager/>
            </Bar>
        )
    }
}

export default compose(
    withPermission({isVisible: pushBasic}),
    withVisibility(),
)(FirmwareUpgradeGridBar)