import React from 'react'
import ButtonComponent from 'ipmp-react-ui/Button'

import withEvents from 'containers/withEvents'
import __ from 'utils/i18n'

const LoadNewEventsButton = ({newerCount, fetchNewer}) => (
    !!newerCount &&
    <ButtonComponent
        onClick={fetchNewer}>
        {__('Load %d new events', newerCount)}
    </ButtonComponent>
)

export default withEvents()(LoadNewEventsButton)