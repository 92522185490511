import {takeEvery, call, put, select} from 'redux-saga/effects'
import {fetch, receive, setLoading} from './actions'
import {fetchUserCountries} from 'api/user'

export default function*() {
    yield takeEvery(fetch, watchFetch)
}

function* watchFetch() {
    const countryList = yield select(state => state.countries.byIds)

    if (Object.keys(countryList).length === 0) {
        try {
            const countries = yield call(fetchUserCountries)
            yield put(receive(countries))
        } catch (error) {
            yield put(receive(error))
        }
    } else {
        yield put(setLoading(false))
    }
}
