import React from 'react'
import {__, __n} from 'utils/i18n'
import {humanTime} from 'ipmp-react-ui/humanTime'
import Definition from 'ipmp-react-ui/Definition'

const NextRICell = ({next, repetition}) => {
    const d = humanTime(next)
    let detail = d && d.time
    if (next && repetition) {
        detail += ' | ' + __n('Every %d month', 'Every %d months', repetition)
    }

    return (
        <div className="rri-nearest">
            <div>
                {__('Next RI:')}

                <span className="rri-next-date">
                    {next
                        ? <Definition title={d.date} detail={detail}/>
                        : __('Not scheduled')}
                </span>
            </div>
        </div>
    )
}

export default NextRICell
