export default class Filter {

    hasSuggests = false

    name

    title

    constructor(name, title) {
        this.name = name
        this.title = title
    }

    createItem(value) {
        return {
            $: this.digest(value),
            value,
            name: this.name,
            label: this.getItemLabel(value),
        }
    }

    digest(value) {
        return this.name + '$' + value
    }

    getItemLabel(value) {
        return value
    }

    createItemByPrefix(prefix) {
        return null
    }
}


