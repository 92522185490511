import {withRouter} from 'react-router'
import {compose} from 'redux'

import withProps from 'containers/withProps'

export default function(props: (params: Object) => Object) {
    return compose(
        withRouter,
        withProps(({match: {params}}) => props(params)),
    )
}