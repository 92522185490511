import {all, put, call, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/system/settings'
import * as handlers from 'modules/forms/handlers'

import {snackShow} from 'modules/snacks'

import {fetchInfo as fetchLicense} from 'api/license'

export default function*() {
    yield all([
        yield takeEvery(actions.fetchCellularConnected, watchFetchCellularConnected),
        yield takeEvery(handlers.setSystemSettings.CellularConnected.SUCCESS, watchSetCellularConnected),

        yield takeEvery(actions.fetchBroadbandConnected, watchFetchBroadbandConnected),
        yield takeEvery(handlers.setSystemSettings.BroadbandConnected.SUCCESS, watchSetBroadbandConnected),

        yield takeEvery(actions.fetchCommonConnected, watchFetchCommonConnected),
        yield takeEvery(handlers.setSystemSettings.CommonConnected.SUCCESS, watchSetCommonConnected),

        yield takeEvery(actions.fetchSupervision, watchFetchSupervision),
        yield takeEvery(handlers.setSystemSettings.Supervision.SUCCESS, watchSetSupervision),

        yield takeEvery(actions.fetchRemoteInspection, watchFetchRemoteInspection),
        yield takeEvery(handlers.setSystemSettings.RemoteInspection.SUCCESS, watchSetRemoteInspection),

        yield takeEvery(actions.fetchInteractiveSession, watchFetchInteractiveSession),
        yield takeEvery(handlers.setSystemSettings.InteractiveSession.SUCCESS, watchSetInteractiveSession),

        yield takeEvery(actions.fetchUserNotifications, watchFetchUserNotifications),
        yield takeEvery(handlers.setSystemSettings.UserNotification.SUCCESS, watchSetUserNotifications),

        yield takeEvery(actions.fetchAdvertisement, watchFetchAdvertisement),
        yield takeEvery(handlers.setSystemSettings.Advertisement.SUCCESS, watchSetAdvertisement),

        yield takeEvery(actions.fetchMessageBrokers, watchFetchMessageBrokers),
        yield takeEvery(actions.removeMessageBroker, watchRemoveMessageBroker),

        yield takeEvery(handlers.setSystemSettings.MessageBroker.SUCCESS, watchSaveMessageBroker),

        yield takeEvery(actions.fetchSerialPorts, watchFetchSerialPorts),

        yield takeEvery(actions.fetchLicense, watchFetchLicense),

    ])
}

export function* watchFetchCellularConnected() {
    try {
        const settings = yield call(api.getCellularConnected)

        yield put(actions.updateCellularConnected(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetCellularConnected({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateCellularConnected(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchBroadbandConnected() {
    try {
        const settings = yield call(api.getBroadbandConnected)

        yield put(actions.updateBroadbandConnected(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetBroadbandConnected({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateBroadbandConnected(settings))
        console.log(payload)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchCommonConnected() {
    try {
        const settings = yield call(api.getCommonConnected)

        yield put(actions.updateCommonConnected(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetCommonConnected({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateCommonConnected(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchSupervision() {
    try {
        const settings = yield call(api.getSupervision)

        yield put(actions.updateSupervision(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetSupervision({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateSupervision(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchRemoteInspection() {
    try {
        const settings = yield call(api.getRemoteInspection)

        yield put(actions.updateRemoteInspection(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetRemoteInspection({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateRemoteInspection(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchInteractiveSession() {
    try {
        const settings = yield call(api.getInteractiveSession)

        yield put(actions.updateInteractiveSession(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetInteractiveSession({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateInteractiveSession(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchUserNotifications() {
    try {
        const settings = yield call(api.getUserNotifications)

        yield put(actions.updateUserNotifications(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetUserNotifications({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateUserNotifications(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchAdvertisement() {
    try {
        const settings = yield call(api.getAdvertisement)

        yield put(actions.updateAdvertisement(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSetAdvertisement({payload}) {
    try {
        const settings = payload && payload.result

        yield put(actions.updateAdvertisement(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchMessageBrokers() {
    try {
        const brokers = yield call(api.fetchMessageBrokers)

        yield put(actions.updateMessageBrokers(brokers))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchSaveMessageBroker({payload}) {
    try {
        const broker = payload && payload.result

        yield put(actions.saveMessageBrokerInStore(broker))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchRemoveMessageBroker({payload}) {
    try {
        const brokers = yield call(api.removeMessageBroker, payload)

        yield put(actions.updateMessageBrokers(brokers))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchFetchSerialPorts() {
    try {
        const ports = yield call(api.fetchSerialPorts)

        yield put(actions.updateSerialPorts(ports))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}


function* watchFetchLicense() {
    try {
        const info = yield fetchLicense()
        yield put(actions.updateLicense(info))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}