import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {cleanKey, cleanLog, enable, send} from 'modules/panels/keypad/actions'
import {selectKeypadFeature} from 'modules/features/store/selectors'

export default function withKeypad() {
    return compose(
        connect(selectKeypadFeature),
        connect(
            (state, {panelId}) => {
                const panel = state.panels.store.byIds[panelId] || {}
                const keypad = state.panels.keypad[panelId] || {}

                return {
                    panel,
                    keypad,
                    softwareVersion: panel.info.panelSwVersionNumber,
                }
            },
            (dispatch, {panelId}) => bindActionCreators({
                enable: () => enable(panelId),
                send: (...arg) => send(...arg, panelId),
                cleanLog: () => cleanLog(panelId),
                cleanKey: () => cleanKey(panelId),
            }, dispatch),
        )
    )
}