/***
 *     █████╗ ████████╗████████╗███████╗███╗   ██╗████████╗██╗ ██████╗ ███╗   ██╗
 *    ██╔══██╗╚══██╔══╝╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝██║██╔═══██╗████╗  ██║
 *    ███████║   ██║      ██║   █████╗  ██╔██╗ ██║   ██║   ██║██║   ██║██╔██╗ ██║
 *    ██╔══██║   ██║      ██║   ██╔══╝  ██║╚██╗██║   ██║   ██║██║   ██║██║╚██╗██║
 *    ██║  ██║   ██║      ██║   ███████╗██║ ╚████║   ██║   ██║╚██████╔╝██║ ╚████║
 *    ╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
 *
 *    WITH GREAT PERFORMANCE COMES GREAT RESPONSIBILITY
 *
 *    THAT CODE AND ALL THOSE MAGIC NUMBERS ARE BASED ON **STYLESHEETS** AND HOW IT WILL LOOK **AFTER** RENDER.
 *
 *    Code tells what components should be rendered, so we don't know any measurements beforehand.
 *    Thus, that file DUPLICATE stylesheet. Not so much, but works really well.
 *
 *    If you don't like magic numbers you can refactor constants into calculated values using
 *    `getComputedStyle` OR `document.styleSheets` or use your favorite cannon.
 *    But please don't go mad.
 *
 *    I don't do it because, in my opinion, code won't become less fragile, but have much more complexity.
 */

const HEIGHT_ROW = 60
const HEIGHT_SECTION_MARGIN = 32
const HEIGHT_SECTION_TITLE = 34
const HEIGHT_TITLE = 40
const HEIGHT_LIST_TITLE_MARGIN = 48
const HEIGHT_FIRST_TITLE_MARGIN = 16

export const HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST = 172
export const HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST = 60

function measureSection({val, key}, startOffset) {
    let lastRow = false
    let offset = startOffset

    const sectionReducer = (nodes, keys, acc = []) => nodes.reduce(
        (acc, node) => {
            const key = [...keys, node.key]

            if (node.type === 'menu') {
                if (lastRow) {
                    offset += HEIGHT_LIST_TITLE_MARGIN
                    lastRow = false
                }

                if (acc.length === 0) {
                    offset += HEIGHT_FIRST_TITLE_MARGIN
                }

                acc.push({
                    key,
                    level: key.length - 1,
                    offset,
                    bottom: offset + HEIGHT_TITLE,
                    node,
                })

                offset += HEIGHT_TITLE
                return sectionReducer(node.val, key, acc)
            }

            acc.push({
                key,
                offset,
                bottom: offset + HEIGHT_ROW,
                node,
            })

            lastRow = true
            offset += HEIGHT_ROW

            return acc
        }, acc)

    const items = sectionReducer(val, [key])

    return {
        items,
        height: offset - startOffset,
    }
}

export default function measure(sections) {
    let offset = 0

    return sections.map((node, index) => {
        if (index > 0) {
            offset += HEIGHT_SECTION_MARGIN
        }

        const sectionOffset = offset

        if (node.name) {
            offset += HEIGHT_SECTION_TITLE
        }

        const {height, items} = measureSection(node, offset)

        offset += height

        return {
            key: [node.key],
            node,
            offset: sectionOffset,
            bottom: offset + height,
            height,
            items,
        }
    })
}