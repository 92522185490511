import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IconReady from 'ipmp-react-ui/icons/flag.svg'
import IconWait from 'ipmp-react-ui/icons/hourglass-empty.svg'
import IconNotReady from 'ipmp-react-ui/icons/exclamation.svg'
import Circle from 'ipmp-react-ui/Circle'

export default class PartitionReadyStateIcon extends Component {

    static propTypes = {
        state: PropTypes.shape({
            ready: PropTypes.bool,
            wait: PropTypes.bool,
        }),
    }

    renderIcon = ({className, ...props}) => {
        const {ready, wait} = this.props.state

        if (wait) {
            return <IconWait className={className} {...props}/>
        }

        if (ready) {
            return <IconReady className={classnames(className, 'success')} {...props}/>
        }

        return <IconNotReady className={classnames(className, 'danger')} {...props}/>
    }

    render() {
        return (
            <Circle className="panelState-readyState" Icon={this.renderIcon}/>
        )
    }
}
