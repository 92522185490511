import {compose} from 'redux'

import withProps from 'containers/withProps'

import UsersGridBar from './UsersGridBar'
import UsersTable from './UsersTable'
import UsersSearch from './UsersSearch'

import Page from 'ipmp-react-ui/Page'

export default compose(
    withProps({
        Top: UsersSearch,
        Bar: UsersGridBar,
        Content: UsersTable,
    }),
)(Page)