import createPermission, {any} from 'permissions/createPermission'
import {configurator, user} from 'permissions/panels/apps'

export const status = createPermission('unit.info')
export const refresh = createPermission('unit.state.get')
export const remove = createPermission('units.remove')
export const edit = createPermission('units.edit')
export const activate = createPermission('unit.activate')
export const encryption = createPermission('unit.encryption')

export const actions = createPermission(
    any(
        edit,
        remove,
        configurator,
        user,
    ),
)