import React, {Component} from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

export default class Quazar extends Component {
    static propTypes = {
        settings: PropTypes.objectOf(PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })),
    }

    render() {
        const {settings: {bba, gprs}} = this.props

        const supervision = bba.isEnabled || gprs.isEnabled

        return (
            <List className="card-content" twoCols>
                {supervision &&
                <ListHintItem name={__('Supervision period')}
                              text={`${bba.supervision} seconds`}/>}

                {supervision &&
                <ListHintItem name={__('Broadband Offline Timer')}
                              text={__n('%d second', '%d seconds', bba.timeout)}/>}

                {supervision &&
                <ListHintItem name={__('Cellular Offline Timer')}
                              text={__n('%d second', '%d seconds', gprs.timeout)}/>}

                {!supervision && <ListHintItem name={__('Supervision')} text={__('Disabled')}/>}
            </List>
        )
    }
}