import {all, takeEvery, put, call} from 'redux-saga/effects'

import {fetch, update} from './actions'

import * as api from 'api/servers'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(fetch, watchFetch)
    ])
}

function* watchFetch() {
    try {
        const data = yield call(api.fetch)
        yield put(update(data))
    } catch (error) {
        put(snackShow(error.message))
    }
}