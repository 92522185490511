import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'

import RemoteInspection from './RemoteInspection'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class Resolve extends Component {
    render() {
        return (
            <ScrollView>
                <Card className="card">
                    <RemoteInspection/>
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {remoteInspection}}}) => ({
            isLoading: remoteInspection.isLoading,
        }),
        dispatch => bindActionCreators({
            remoteInspection: actions.fetchRemoteInspection,
        }, dispatch),
    ),
    withLoader(({remoteInspection}) => {remoteInspection()}),
)(Resolve)