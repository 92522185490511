import {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import get from 'lodash/get'
import isNull from 'lodash-es/isNull'

import beep from 'assets/keypad-sounds/beep.wav'
import beep1 from 'assets/keypad-sounds/beep1.wav'
import beep2 from 'assets/keypad-sounds/beep2.wav'
import beep3 from 'assets/keypad-sounds/beep3.wav'
import beep4 from 'assets/keypad-sounds/beep4.wav'
import beep5 from 'assets/keypad-sounds/beep5.wav'
import beep6 from 'assets/keypad-sounds/beep6.wav'
import beep7 from 'assets/keypad-sounds/beep7.wav'
import beep8 from 'assets/keypad-sounds/beep8.wav'
import beep9 from 'assets/keypad-sounds/beep9.wav'
import beep10 from 'assets/keypad-sounds/beep10.wav'
import beep11 from 'assets/keypad-sounds/beep11.wav'
import beep12 from 'assets/keypad-sounds/beep12.wav'
import siren from 'assets/keypad-sounds/siren.wav'
import squack from 'assets/keypad-sounds/squack.wav'
import beepbase from 'assets/keypad-sounds/beepbase.wav'
import tone from 'assets/keypad-sounds/tone.wav'
import bingBing from 'assets/keypad-sounds/bingbing.wav'
import dingDong from 'assets/keypad-sounds/dingdong.wav'
import alarmTone from 'assets/alerts/alert.mp3'
import {
    MESSAGE_TYPE_BEEP,
    MESSAGE_TYPE_SIREN,
    MESSAGE_TYPE_SQUACK,
    MESSAGE_TYPE_NEO_SOUND,
    neoSoundParams,
    NEO_SOUND_STOP_BUZZER,
    SOUND_TYPE_BASE_BEEP,
    SOUND_TYPE_TONE,
    SOUND_TYPE_BINGBING,
    SOUND_TYPE_DINGDONG,
    SOUND_TYPE_ALARM_TONE,
    NEO_SOUND_STEADY_TONE,
} from 'constants/virtualKeypad/sounds'
import {err} from 'utils/log'
import getAudioContext from 'utils/getAudioContext'
import getSoundBuffer from 'api/soundBuffer'
import {warn} from '../../../../utils/log'

class Sounds extends Component {
    static propTypes = {
        sound: PropTypes.shape({
            type: PropTypes.string,
            data: PropTypes.number,
            id: PropTypes.string,
            duration: PropTypes.number,
        }),
        mute: PropTypes.bool.isRequired,
        panelId: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props)
        /**
         * According with best practises
         * to prevent memory leak on async data load
         * https://github.com/material-components/material-components-web-react/issues/434#issuecomment-449561024
         *
         */
        this._isMounted = false
        this.neoSource = {
            sources: [],
            intervalId: null,
        }
        this.REPEAT_SOUND_DELAY = 50
        this.state = {}

        this.audioContext = getAudioContext()
        this.gainNode = this.audioContext.createGain()
        this.gainNode.connect(this.audioContext.destination)
        this.gainNode.gain.value = this.props.mute ? 0 : this.gainNode.gain.defaultValue
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.mute !== this.props.mute) {
            return true
        }

        if (this.props.sound && nextProps.sound) {
            return nextProps.sound.id !== this.props.sound.id
        }

        return true
    }

    async loadSounds() {
        const beepBuffer = await getSoundBuffer(beep)
        const beep1Buffer = await getSoundBuffer(beep1)
        const beep2Buffer = await getSoundBuffer(beep2)
        const beep3Buffer = await getSoundBuffer(beep3)
        const beep4Buffer = await getSoundBuffer(beep4)
        const beep5Buffer = await getSoundBuffer(beep5)
        const beep6Buffer = await getSoundBuffer(beep6)
        const beep7Buffer = await getSoundBuffer(beep7)
        const beep8Buffer = await getSoundBuffer(beep8)
        const beep9Buffer = await getSoundBuffer(beep9)
        const beep10Buffer = await getSoundBuffer(beep10)
        const beep11Buffer = await getSoundBuffer(beep11)
        const beep12Buffer = await getSoundBuffer(beep12)
        const sirenBuffer = await getSoundBuffer(siren)
        const squackBuffer = await getSoundBuffer(squack)
        const beepBaseBuffer = await getSoundBuffer(beepbase)
        const toneBuffer = await getSoundBuffer(tone)
        const bingbingBuffer = await getSoundBuffer(bingBing)
        const dingDongBuffer = await getSoundBuffer(dingDong)
        const alarmToneBuffer = await getSoundBuffer(alarmTone)

        if (this._isMounted) {
            this.setState({
                beepBuffer,
                beep1Buffer,
                beep2Buffer,
                beep3Buffer,
                beep4Buffer,
                beep5Buffer,
                beep6Buffer,
                beep7Buffer,
                beep8Buffer,
                beep9Buffer,
                beep10Buffer,
                beep11Buffer,
                beep12Buffer,
                sirenBuffer,
                squackBuffer,
                beepBaseBuffer,
                toneBuffer,
                bingbingBuffer,
                dingDongBuffer,
                alarmToneBuffer,
            })
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.loadSounds()

        this.playSound()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sound && this.props.sound
            && (prevProps.sound.id != this.props.sound.id)) {
            this.playSound()
        }

        this.checkMute()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    playSound() {
        const {sound} = this.props

        if (!sound) {
            return
        }

        try {
            switch (sound.type) {
                case MESSAGE_TYPE_BEEP:
                    this.playBeep()
                    break
                case MESSAGE_TYPE_SIREN:
                    this.playSiren()
                    break
                case MESSAGE_TYPE_SQUACK:
                    this.playSquack()
                    break
                case MESSAGE_TYPE_NEO_SOUND:
                    this.resolveNeoSounds()
                    break
            }
        } catch (e) {
            err(`Somthing went wrong: ${e.message}`)
        }
    }

    checkMute = () => {
        this.gainNode.gain.value = this.props.mute ? 0 : this.gainNode.gain.defaultValue
    }

    playBeep = () => {
        const {sound: {value}} = this.props
        this.play(this.state[`beep${value}Buffer`])
    }

    playSiren = () => {
        const {sound: {value}} = this.props
        let sirenSource

        if (value == 0 && sirenSource) {
            try {
                sirenSource.stop()
            } catch (error) {
                err(`Stop error: ${error.message}`)
            }
        } else {
            sirenSource = this.play(this.state.sirenBuffer)
        }
    }

    playSquack = () => {
        const {sound: {value}} = this.props
        let squackSource

        if (value == 0 && squackSource) {
            try {
                squackSource.stop()
            } catch (error) {
                err(`Stop error: ${error.message}`)
            }
        } else {
            squackSource = this.play(this.state.squackBuffer)
        }
    }

    play = (buffer: AudioBuffer, time = 0) => {
        const source = this.audioContext.createBufferSource()
        source.buffer = buffer
        source.connect(this.gainNode)
        source.start(time)

        return source
    }

    resolveNeoSounds = () => {
        const {sound: {value, duration}} = this.props

        // Stop the previous playback if a new sound has arrived.
        // Usualy after not endless sounds arrive NEO_SOUND_STOP_BUZZER
        this.stopAllNeoSounds()
        clearInterval(this.neoSource.intervalId)

        if (value === NEO_SOUND_STOP_BUZZER) {
            return
        }

        const sound = this.neoSound

        if (sound.repeatInterval) {
            this.neoSource.intervalId = setInterval(() => {
                this.playNeoSounds(sound)
            }, sound.repeatInterval)

            // According documentation(IT Protocol V2.00 R2.18 [Third Party Licensed])
            // duration: 0 - endless playback
            if (duration) {
                setTimeout(() => {
                    clearInterval(this.neoSource.intervalId)
                }, duration)
            }
        } else {
            this.playNeoSounds(sound)
        }
    }

    stopAllNeoSounds = () => {
        this.neoSource.sources.forEach(source => {
            try {
                source.stop()
            } catch (error) {
                err(`Stop error: ${error.message}`)
            }
        })
    }

    get neoSound() {
        const {sound: {value}} = this.props

        if (neoSoundParams.hasOwnProperty(value)) {
            return neoSoundParams[value]
        } else {
            err(`There are no neo sound type: ${value}`)
            return neoSoundParams[NEO_SOUND_STEADY_TONE]
        }
    }

    playNeoSounds = (sound) => {
        this.neoSource.sources = []

        sound.sounds.forEach(soundElement => {
            if (soundElement.frequency > 1) {
                this.repeatNeoSound(soundElement)
            } else {
                this.startNeoSound(soundElement)
            }
        })
    }

    repeatNeoSound = (sound) => {
        this.startNeoSound(sound)

        // delayed playback starts after two repetitions.
        for (let index = 1; index < sound.frequency; index++) {
            const source = this.getSourceByType(sound.type)

            if (!isNull(source.buffer)) {
                const nextStart = (source.buffer.duration * 1000 + this.REPEAT_SOUND_DELAY)

                setTimeout(() => {
                    source.start()
                    this.neoSource.sources.push(source)
                }, index * nextStart)
            } else {
                warn(`Sound ${sound.type} did not load`)
            }
        }
    }

    startNeoSound = (sound) => {
        const source = this.getSourceByType(sound.type)
        this.neoSource.sources.push(source)

        // imitate sound with duration
        if (sound.duration) {
            source.loop = true

            setTimeout(() => {
                source.loop = false
            }, sound.duration)
        }

        source.start()
    }

    getSourceByType = (type) => {
        let source = this.audioContext.createBufferSource()

        switch (type) {
            case SOUND_TYPE_BASE_BEEP:
                source.buffer = this.state.beepBaseBuffer
                break
            case SOUND_TYPE_TONE:
                source.buffer = this.state.toneBuffer
                break
            case SOUND_TYPE_BINGBING:
                source.buffer = this.state.bingbingBuffer
                break
            case SOUND_TYPE_DINGDONG:
                source.buffer = this.state.dingDongBuffer
                break
            case SOUND_TYPE_ALARM_TONE:
                source.buffer = this.state.alarmToneBuffer
                break
        }

        source.connect(this.gainNode)
        return source
    }

    render() {
        return null
    }
}

export default connect(
    ({panels}, {panelId}) => {
        const sound = get(panels, ['keypad', panelId, 'sound'], null)

        return {sound}
    },
)(Sounds)
