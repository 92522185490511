import createPermission, {any} from 'permissions/createPermission'

export const accept = createPermission('system.installers.accept')
export const reject = createPermission('system.installers.accept')

export default createPermission(
    any(
        accept,
        reject,
    ),
)