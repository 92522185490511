import {get, post} from 'api/http'
import mapper from 'utils/dataMapper'
import {keysMap} from './panels.map'
import generateFilterString from './base/generateFilterString'

const mapPackage = mapper(data => ({
    id: parseInt(data.uep_id),
    type: data.uep_type,
    name: data.uep_name,
    version: data.uep_version,
    description: data.uep_description,
}))

const map = mapper(data => ({
    id: parseInt(data.utd_id),
    type: data.utd_rest_name,
    name: data.utd_name,
    groupAbbr: data.utd_group_abbr,
    upgradePackages: data.upgrade_packages && data.upgrade_packages.map(mapPackage) || [],
}))

export function listAppliance() {
    return get('/firmware/listappliance').then(appliances => appliances.map(map))
}

export function upgrade(panelIds, applianceId, upgradePackageId, timeout, failOnArmedState, batchId) {
    return post('firmware/upgrade', {form: {
        unt_id: panelIds,
        utd_id: parseInt(applianceId),
        uep_id: parseInt(upgradePackageId),
        timeout: parseInt(timeout),
        fail_on_armed_state: failOnArmedState,
        batch_id: batchId,
    }})
}

export function retry(ids, batchId) {
    return post('/firmware/retry', {prs_id: ids, batch_id: batchId})
}

export function upgradeByFilters(
    {
        filters, batchId, applianceId,
        upgradePackageId, timeout, failOnArmedState,
        panelIds,
    },
) {
    filters = generateFilterString(filters, keysMap)
    const data = {
        filters,
        batch_id: batchId,
        utd_id: parseInt(applianceId),
        uep_id: parseInt(upgradePackageId),
        timeout: parseInt(timeout),
        fail_on_armed_state: failOnArmedState,
    }

    if (Array.isArray(panelIds) && panelIds.length > 0) {
        data.unt_id = panelIds
    }

    return post('firmware/upgradefilter', {form: data})
}