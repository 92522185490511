import createPermission, {any} from 'permissions/createPermission'

import list from './list'

export const remove = createPermission('system.users.remove')
export const suspend = createPermission('system.users.suspend')
export const enable = createPermission('system.users.enable')

export default createPermission(
    list,
    any(
        remove,
        suspend,
        enable
    ),
)