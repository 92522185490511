import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'

import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import generateFilterString from 'api/base/generateFilterString'
import parseDate from 'api/base/parseDate'

import dataMapper from 'utils/dataMapper'

export const mapProcess = dataMapper(data => ({
    id: parseInt(data.prs_id),
    panelId: parseInt(data.unt_id),
    user: data._usr_name,
    userId: data.usr_id ? parseInt(data.usr_id) : null,
    serial: data._unt_serial,
    status: data.prs_status,
    type: data.prs_type,
    started: parseDate(data.prs_created_timestamp),
    finished: parseDate(data.prs_finished_timestamp),
    percentage: parseInt(data.prs_percentage) || 0,
    error: data.prs_error,
    errorMessage: data.prs_error_message,
    details: data.prs_details,
    description: data.prs_description,
    isStoppable: data.prs_status == 'start',
    isRunning: data.prs_status == 'start' || data.prs_status == 'handled',
    isSuccessful: data.prs_status == 'succeeded',
    isFailed: data.prs_status == 'failed',
}))

export const mapProcessBatch = ({processes, lastError}) => ({
    processes: processes.map(mapProcess),
    lastError,
})

export const keysMap = {
    id: 'prs_id',
    panelId: 'unt_id',
    user: '_usr_name',
    serial: '_unt_serial',
    status: 'prs_status',
    type: 'prs_type',
    error: 'prs_error',
    started: 'prs_created_timestamp',
    finished: 'prs_finished_timestamp',
    percentage: 'prs_percentage',
    errorMessage: 'prs_error_message',
    details: 'prs_details',
    description: 'prs_description',
    date: 'date',
    time: 'time',
    timestamp: 'timestamp',
    isTimer: 'is_timer',
    batchId: 'batch_id',
}

export function fetch({start = 0, perPage: count = 15, filters, query, sort, desc}) {
    filters = generateFilterString(filters, keysMap)

    if (sort) {
        sort = (desc ? '-' : '') + (keysMap[sort] || sort)
    }

    return get('/process/getallprocesses', {start, count, filter: filters, sort, query})
        .then(createRowsResponseParser(mapProcess))
}

export function status(ids) {
    return get('/process/status', {'prs_id[]': ids})
        .then(processes => processes.map(mapProcess))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/process/suggestprocesses', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function stop(id) {
    return post('/process/stop', {[keysMap.id]: id})
}