import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    remove,
    setStart,
} from 'modules/centralStations/list/actions'
import {showCentralStationCreateModal} from 'modules/modals/actions'

export default function withCentralStations() {
    return compose(
        withPerPage('centralStations'),
        connect(
            ({centralStations: {list: {page, isLoading, start, perPage, total}, store: {byId}}}) => ({
                isLoading, start, total,
                rows: page.map(id => byId[id]),
            }),
            (dispatch => bindActionCreators({
                init,
                reset,
                remove,
                onPageChange: setStart,
                showCentralStationCreateModal,
            }, dispatch)),
        ),
    )
}
