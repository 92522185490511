import React from 'react'

import withProps from 'containers/withProps'

import PartitionStateButtons from 'components/Panel/PartitionStateButtons'
import PartitionStateDefinition from 'components/Panel/PartitionStateDefinition'
import PartitionReadyStateIcon from 'components/Panel/PartitionReadyStateIcon'

import Table from 'ipmp-react-ui/Table'

import PartitionStateButton from './Buttons/PartitionStateButton'

const columns = [
    {
        tooltip: false,
        width: 44,
        fixed: true,
        render: (partition, {partitionStates}) => (
            <PartitionReadyStateIcon state={partitionStates[partition.id]}/>
        ),
    },
    {
        tooltip: false,
        render: (partition, {sendState, partitionStates, isSetAllowed}) => {
            const state = partitionStates[partition.id] || {}

            return (
                <div className="panelState-partition">
                    <PartitionStateDefinition
                        partition={partition}
                        state={state}
                    />

                    <PartitionStateButtons
                        state={state}
                        partition={partition}
                        sendState={sendState}
                        disabled={!isSetAllowed}
                    >
                        {PartitionStateButton}
                    </PartitionStateButtons>
                </div>
            )
        },
    },
]

export default withProps({
    columns,
})(Table)