import {__} from 'utils/i18n'
import {warn} from 'utils/log'

// @also see Ipmp_Helper_Unit_FamilyBuilder

export const VENDOR_NEO = 'NEO'
export const VENDOR_POWER_MASTER = 'POWER_MASTER'
export const VENDOR_FIBRO_TRANSMITTER = 'FIBRO_TRANSMITTER'
export const VENDOR_DUAL_PATH = 'DUAL_PATH'

export default function panelVendorType(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return __('Neo')

        case VENDOR_POWER_MASTER:
            return __('PowerMaster')

        case VENDOR_FIBRO_TRANSMITTER:
            return __('Fibro Transmitter')

        case VENDOR_DUAL_PATH:
            return __('Dual Path')
    }

    warn(`Unknown vendor ${vendor}`)

    return vendor
}
