import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withProps from 'containers/withProps'
import Page from 'ipmp-react-ui/Page'
import withLifeCycle from 'containers/withLifeCycle'
import {startPoll, stopPoll} from 'modules/centralStations/list/actions'

import CentralStationsTable from './CentralStationsTable'
import CentralStationsGridBar from './CentralStationsGridBar'
import CentralStationsSearch from './CentralStationsSearch'

export default compose(withProps({
        Top: CentralStationsSearch,
        Bar: CentralStationsGridBar,
        Content: CentralStationsTable,
    }),
    connect(
        null,
        dispatch => bindActionCreators({
            startPoll,
            stopPoll,
        }, dispatch),
    ),
    withLifeCycle({
        onMount: ({startPoll}) => {
            startPoll()
        },
        onUnmount: ({stopPoll}) => {
            stopPoll()
        },
    }),
)(Page)