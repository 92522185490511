import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import actions from 'permissions/interactiveUsers/actions'
import {enable, suspend} from 'modules/interactiveUsers/store/actions'

import {withPermission} from 'containers/withPermission'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'

import {STATUS_SUSPENDED, STATUS_VERIFIED, STATUS_REGISTERED} from 'constants/interactiveUserStatus'

export class InteractiveUserStatusChanger extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
        enable: PropTypes.func.isRequired,
        suspend: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isPermitted: false,
    }

    getLabel() {
        const {row: {status}} = this.props

        switch (status) {
            case STATUS_REGISTERED:
                return __('Not verified')
            case STATUS_VERIFIED:
                return __('Active')
            case STATUS_SUSPENDED:
                return __('Suspended')
            default:
                return __('Unknown')
        }
    }

    getClassName() {
        const {row: {status}} = this.props

        switch (status) {
            case STATUS_REGISTERED:
                return 'warn'
            case STATUS_SUSPENDED:
                return 'danger'
        }
    }

    isIndeterminated() {
        const {row: {status}} = this.props

        return status === STATUS_REGISTERED
    }

    isChecked() {
        const {row: {status}} = this.props

        return status === STATUS_VERIFIED
    }

    onChange = () => {
        const {enable, suspend, row: {id, status}} = this.props

        switch (status) {
            case STATUS_SUSPENDED:
                return enable(id)
            case STATUS_VERIFIED:
                return suspend(id)
        }
    }

    isDisabled() {
        const {isPermitted, row} = this.props
        return !isPermitted || row.status === 'registered'
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <Checkbox
                    checked={this.isChecked()}
                    disabled={this.isDisabled()}
                    indeterminate={this.isIndeterminated()}
                    label={this.getLabel()}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({isPermitted: actions}),
    connect(
        () => ({}),
        dispatch => bindActionCreators({enable, suspend}, dispatch),
    ),
)(InteractiveUserStatusChanger)