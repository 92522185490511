import {createSelector} from 'reselect'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import keyBy from 'lodash-es/keyBy'
import flatten from 'lodash-es/flatten'
import uniq from 'lodash-es/uniq'

import {PARTITION_ALL} from 'constants/partitions'

export const selectPartitionStatesById = createSelector(
    (state, panelId) => get(state, ['panels', 'state', 'byIds', panelId], {}),
    (data) => {
        const states = get(data, 'partitionStates', [])

        return Object
            .values(states)
            .reduce((states, {id, name}) => {
                const {name: label, isActive} = get(data, ['partitions', id], {
                    name: `P${id}`,
                    isActive: false,
                })

                if (isActive) {
                    states.push({
                        partition: label,
                        state: name,
                    })
                }

                return states
            }, [])
    },
)

export const selectActivePartitions = createSelector(
    (state, panelId) => get(state, ['panels', 'state', 'byIds', panelId, 'partitions'], {}),
    state => {
        const partitions = sortBy(
            Object.values(state).filter(({isActive}) => isActive),
            partition => partition.id,
        )

        const all = partitions.find(partition => partition.id === PARTITION_ALL)

        if (all && partitions.length === 2) {
            return partitions.filter(partition => partition !== all)
        }

        return partitions
    },
)

export const selectActivePartitionsState = createSelector(
    selectActivePartitions,
    (state, panelId) => get(state, ['panels', 'state', 'byIds', panelId, 'partitionStates'], {}),
    (state, panelId) => get(state, ['panels', 'state', 'byIds', panelId, 'stateSets'], {}),
    (partitions, partitionStates, stateSets) => {
        const states = keyBy(
            partitions.map(partition => {
                const state = partitionStates[partition.id] || {}
                const stateSet = stateSets[partition.stateSet]

                if (!state) {
                    return {
                        id: partition.id,
                        stateSet,
                        transitions: null,
                    }
                }

                const currentState = stateSet[state.name] || {}
                const transitions = currentState.transitions || null
                const wait = !!(state.substate || (currentState && !currentState.visible))

                return {
                    id: partition.id,
                    ...state,
                    wait,
                    stateSet,
                    transitions,
                }
            }),
            'id',
        )

        if (states.hasOwnProperty(PARTITION_ALL)) {
            if (states[PARTITION_ALL].transitions === null) {
                const allTransitions = flatten(
                    Object.values(states).map(state => state.transitions),
                )

                states[PARTITION_ALL].transitions = uniq(allTransitions).filter(t => t)
            }

            if (!partitionStates.hasOwnProperty(PARTITION_ALL)) {
                states[PARTITION_ALL].ready = Object.values(states).every(
                    state => state.wait || state.ready
                )

                states[PARTITION_ALL].wait = Object.values(states).every(
                    state => state.wait
                )
            }
        }

        return states
    },
)