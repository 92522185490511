import {all} from 'redux-saga/effects'

import applianceSelection from './applianceSelection/saga'
import list from './list/saga'

export default function*() {
    yield all([
        applianceSelection(),
        list(),
    ])
}