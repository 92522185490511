import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'

import {
    observe,
    receive,
    restore,
    track,
    untrack,
} from './actions'

const defaultState = {
    byKeys: {},
    ids: {},
    track: [],
}

export default handleActions({
    [observe](state, {payload: processes}) {
        if (!processes || !processes.length) {
            return state
        }

        const ids = processes.filter(process => process.id)
            .reduce(
                (acc, {id, key}) => ({
                    ...acc,
                    [id]: key,
                }),
                state.ids,
            )

        return {
            ...state,
            byKeys: {
                ...state.byKeys,
                ...keyBy(processes, 'key'),
            },
            ids,
        }
    },

    [receive](state, {payload}) {
        const byKeys = {
            ...state.byKeys,
            ...keyBy(payload, 'key'),
        }

        return {
            ...state,
            byKeys,
        }
    },

    [restore](state, {payload: newState}) {
        return newState || state
    },

    [track](state, {payload}) {
        return {
            ...state,
            track: [...state.track, ...payload],
        }
    },

    [untrack](state, {payload}) {
        return {
            ...state,
            track: state.track.filter(
                (id) => !payload.includes(id)
            ),
        }
    },
}, defaultState)
