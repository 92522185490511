import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import __ from 'utils/i18n'
import {configurator} from 'permissions/panels/apps'
import withPanel from 'containers/withPanel'

import {setConfigurationAppState} from 'modules/panels/one/actions'
import {withFeatureRejection} from 'containers/withFeature'
import {selectAppConfigurator} from 'modules/features/store/selectors'
import Void from 'components/Void'
import withProps from 'containers/withProps'

class ToggleInstallerAppItem extends Component {

    static propTypes = {
        panel: PropTypes.shape({
            configuratorApp: PropTypes.bool,
        }),
    }

    handleInstallerAppChange = (e) => {
        const {setConfigurationAppState, panel} = this.props
        setConfigurationAppState(panel, e.target.checked)
    }

    render() {
        const {panel} = this.props

        return (
            <div className="menu-item">
                <AppToggle
                    permission={configurator}
                    label={__('Installer App')}
                    onChange={this.handleInstallerAppChange}
                    checked={panel.configuratorApp || false}
                />
            </div>
        )
    }
}

export default compose(
    withPanel(),
    connect(
        null,
        (dispatcher) => bindActionCreators({
            setConfigurationAppState,
        }, dispatcher),
    ),
    withProps(({panel}) => ({panelId: panel.id})),
    withFeatureRejection(selectAppConfigurator, Void),
)(ToggleInstallerAppItem)