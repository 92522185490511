import createRowsResponseParser from 'api/base/createRowsResponseParser'
import parseDate from 'api/base/parseDate'
import {get, post} from 'api/http'

import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'

import dataMapper from 'utils/dataMapper'

const mapInstaller = dataMapper(installer => ({
    id: parseInt(installer.id),
    email: installer.email,
    name: installer.name,
    status: installer.status,
    telephone: installer.telephone,
    createdAt: parseDate(installer.created_at),
}))

const keysMap = {
    id: 'id',
    name: 'name',
    email: 'email',
    status: 'status',
}

export function fetch({start = 0, perPage: count = 10, filters, query: search}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/installer/list', {start, count, search, filter})
        .then(createRowsResponseParser(mapInstaller))
}

export function accept(installerId) {
    return post('/installer/accept', {installer_id: installerId}).then(mapInstaller)
}

export function reject(installerId) {
    return post('/installer/reject', {installer_id: installerId}).then(mapInstaller)
}


// TODO: checkout usage this method
export function suggest(fields, prefix = '', start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/installer/suggest', {suggest, start, count})
        .then(data => fields.reduce(
            (acc, key) => {
                const responseKey = keysMap[key] || key

                if (data.hasOwnProperty(responseKey)) {
                    acc[key] = data[responseKey].rows.map(({suggest}) => suggest)
                } else {
                    acc[key] = []
                }

                return acc
            },
            {},
        ))
}