import {all} from 'redux-saga/effects'

import list from './list/saga'
import alarms from './alarms/saga'
import bySerialList from './bySerialList/saga'

export default function*() {
    yield all([
        list(),
        alarms(),
        bySerialList(),
    ])
}