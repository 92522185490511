import React, {Component} from 'react'
import {compose} from 'redux'

import InstallersPager from './GridBar/InstallersPager'
import InstallersAcceptButton from './GridBar/InstallersAcceptButton'
import InstallersRejectButton from './GridBar/InstallersRejectButton'
import InstallersSelectionDropDown from './GridBar/InstallersSelectionDropDown'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

export class InstallersBar extends Component {
    render() {
        return (
            <Bar>
                <InstallersSelectionDropDown/>
                <InstallersAcceptButton/>
                <InstallersRejectButton/>

                <BarSpace/>

                <InstallersPager/>
            </Bar>
        )
    }
}

export default compose()(InstallersBar)