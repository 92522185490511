import {compose} from 'redux'

import OfflineFaultyPanelsChart from 'components/Dashboard/OfflineFaultyPanelsChart'
import {withOnline} from 'containers/withDashboard'
import {withPermission, withRejection} from 'containers/withPermission'
import {onlineFaulty} from 'permissions/dashboard/charts'

export default compose(
    withPermission({isAllowed: onlineFaulty}),
    withRejection(),
    withOnline()
)(OfflineFaultyPanelsChart)