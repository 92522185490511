import Filter from 'components/Search/Filters/Filter'
import startsWith from 'lodash-es/startsWith'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Menu from 'ipmp-react-ui/Menu'
import {MenuItem} from 'ipmp-react-ui/Menu'
import {__} from 'utils/i18n'

export default class FiltersMenu extends Component {

    static propTypes = {
        filters: PropTypes.arrayOf(
            PropTypes.instanceOf(Filter),
        ).isRequired,
        prefix: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        onSearch: PropTypes.func,
    }

    componentDidUpdate(prevProps, prevState) {
        const {prefix} = this.props

        if (prefix !== prevProps.prefix) {
            this.forceUpdate(() => this.menu && this.menu.select(0))
        }
    }

    handleSelect(e, filter) {
        e.stopPropagation()
        this.props.onSelect(filter)
    }

    handleSearch = (e) => {
        e.stopPropagation()
        this.props.onSearch()
    }

    handleRef = menu => this.menu = menu

    render() {
        const {filters, prefix, onSearch} = this.props

        const items = filters
            .filter(({title}) => startsWith(title.toLowerCase().trim(), prefix.toLowerCase().trim()))
            .map(filter => (
                <MenuItem onClick={e => this.handleSelect(e, filter)} key={filter.name}>
                    {filter.title}
                </MenuItem>
            ))

        if (prefix && onSearch) {
            items.push(
                <MenuItem onClick={this.handleSearch} key="search">
                    {__('Search for «%s»', prefix)}
                </MenuItem>,
            )
        }

        if (items.length === 0) {
            return null
        }

        return (
            <Menu scope="input" ref={this.handleRef}>
                {items}
            </Menu>
        )
    }

}