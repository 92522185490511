import Promise from 'promise-polyfill'
import mapKeys from 'lodash-es/mapKeys'
import mapValues from 'lodash-es/mapValues'

import {BAD_REQUEST_PARAMS} from 'constants/errorType'

import objectFlip from 'utils/objectFlip'

export default function mapValidationErrors(error, keysMap) {
    if (error.isServerError && error.type === BAD_REQUEST_PARAMS && error.details) {
        const errorFieldsMap = objectFlip(keysMap || {})

        error.errors = mapValues(
            mapKeys(
                error.details,
                (error, fieldName) => errorFieldsMap[fieldName] || fieldName,
            ),
            fieldErrors => {
                if (Array.isArray(fieldErrors)) {
                    return fieldErrors
                }

                if (fieldErrors instanceof Object) {
                    return Object.values(fieldErrors)
                }

                return fieldErrors
            },
        )
    }

    return Promise.reject(error)
}