export const PROCESS_TYPE_FEATURES = 'Features'
export const PROCESS_TYPE_PLINKSOFTWAREUPGRADE = 'PLinkSoftwareUpgrade'
export const PROCESS_TYPE_PMAXANONYMOUS = 'Anonymous'
export const PROCESS_TYPE_PMAXCANCELSOAKZONE = 'CancelSoakZone'
export const PROCESS_TYPE_PMAXCLEARBYPASSZONE = 'ClearByPassZone'
export const PROCESS_TYPE_PMAXCONFIGBACKUP = 'ConfigBackup'
export const PROCESS_TYPE_PMAXCONFIGBASICUPLOAD = 'ConfigBasicUpload'
export const PROCESS_TYPE_PMAXCONFIGDOWNLOAD = 'ConfigDownload'
export const PROCESS_TYPE_PMAXCONFIGUPLOAD = 'ConfigUpload'
export const PROCESS_TYPE_PMAXFILMONDEMAND = 'FilmOnDemand'
export const PROCESS_TYPE_PMAXLOGLEGACY = 'LogLegacy'
export const PROCESS_TYPE_PMAXLOGSTANDARD = 'LogStandard'
export const PROCESS_TYPE_NEOLIVELOG = 'NeoLiveLog'
export const PROCESS_TYPE_PMAXPYTHONUPGRADE = 'PythonUpgrade'
export const PROCESS_TYPE_PMAXSETBYPASSZONE = 'SetByPassZone'
export const PROCESS_TYPE_PMAXSETCUSTOMZONES = 'SetCustomZones'
export const PROCESS_TYPE_PMAXSETSOAKZONE = 'SetSoakZone'
export const PROCESS_TYPE_PMAXSOFTWAREUPGRADE = 'SoftwareUpgrade'
export const PROCESS_TYPE_PMAXSTATEGET = 'StateGet'
export const PROCESS_TYPE_PMAXSTATESET = 'StateSet'
export const PROCESS_TYPE_PMAXZONEADD = 'ZoneAdd'
export const PROCESS_TYPE_PMAXZONEREMOVE = 'ZoneRemove'
export const PROCESS_TYPE_PMAXACTIVATESIREN = 'ActivateSiren'
export const PROCESS_TYPE_PMAXZONERSSI = 'ZoneRssi'
export const PROCESS_TYPE_REFRESH_GSM = 'GsmSignalLevel'
export const PROCESS_TYPE_PMAXZONEWALKTEST = 'ZoneWalkTest'
export const PROCESS_TYPE_RESETPASSWORD = 'ResetPassword'
export const PROCESS_TYPE_REMOTEINSPECTION = 'RemoteInspection'
export const PROCESS_TYPE_SOFTWAREREPOSYNC = 'SoftwareRepoSync'
export const PROCESS_TYPE_DISABLEX10 = 'DisableX10'
export const PROCESS_TYPE_ENABLEX10 = 'EnableX10'
export const PROCESS_TYPE_DIMMERX10 = 'DimmerX10'
export const PROCESS_TYPE_BRIGHTERX10 = 'BrighterX10'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0 = 'SetPrivateReportsEmail0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1 = 'SetPrivateReportsEmail1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2 = 'SetPrivateReportsEmail2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3 = 'SetPrivateReportsEmail3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0 = 'SetPrivateReportsPhone0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1 = 'SetPrivateReportsPhone1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2 = 'SetPrivateReportsPhone2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3 = 'SetPrivateReportsPhone3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0 = 'SetPrivateReportsEmailFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1 = 'SetPrivateReportsEmailFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2 = 'SetPrivateReportsEmailFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3 = 'SetPrivateReportsEmailFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0 = 'SetPrivateReportsSmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1 = 'SetPrivateReportsSmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2 = 'SetPrivateReportsSmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3 = 'SetPrivateReportsSmsFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0 = 'SetPrivateReportsMmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1 = 'SetPrivateReportsMmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2 = 'SetPrivateReportsMmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3 = 'SetPrivateReportsMmsFilter3'
export const PROCESS_TYPE_REPORT = 'Report'
export const PROCESS_TYPE_PMAXSETVODMODE = 'SetVODMode'
export const PROCESS_TYPE_PMAXRESETRECEIVERIP = 'ResetReceiverIp'
export const PROCESS_TYPE_PMAXASSIGNPARTITIONS = 'AssignPartitions'
export const PROCESS_TYPE_PMAXDISABLESIREN = 'DisableSiren'
export const PROCESS_TYPE_NEOACTIVATION = 'NeoActivation'
export const PROCESS_TYPE_FIRMWARE_UPGRADE = 'FirmwareUpgrade'
export const PROCESS_TYPE_NEO_UPGRADE = 'SoftwareUpgrade'
export const PROCESS_TYPE_SET_LABEL = 'SetLabel'
export const PROCESS_TYPE_EDITPGM = 'EditPGM'
export const PROCESS_TYPE_DISABLEPGM = 'DisablePGM'
export const PROCESS_TYPE_PANEL_DISCOVERY = 'PanelDiscovery'