import {all} from 'redux-saga/effects'

import item from './item/saga'
import list from './list/saga'
import types from './types/saga'

export default function* () {
    yield all([
        item(),
        list(),
        types(),
    ])
}
