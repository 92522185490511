import {createAction} from 'redux-actions'
import uniqid from 'uniqid'

const toArray = data => Array.isArray(data) ? data : [data]

export const update = createAction('PANELS/REMARKS/UPDATE', toArray, (_, panelId) => ({panelId}))
export const fetch = createAction('PANELS/REMARKS/FETCH')
export const add = createAction('PANELS/REMARKS/ADD', null, (_, panelId) => ({panelId}))
export const remove = createAction('PANELS/REMARKS/REMOVE', null, (_, panelId) => ({panelId}))

export function create(text, panelId) {
    return (dispatch, getState) => {
        const user = getState().auth.sign.user

        dispatch(add({
            key: uniqid(),
            isNew: true,
            time: new Date,
            text,
            user,
        }, panelId))
    }
}