import {handleActions} from 'redux-actions'

import {
    fetch,
    reset,
    receive,
    fetchOlder,
    fetchNewer,
    setNewerCount,
    setHasOlder,
} from './actions'

const defaultState = {
    error: null,
    rows: [],
    isLoading: true,
    isOlderLoading: false,
    isNewerLoading: false,
    panelId: null,
    hasOlder: false,
    newerCount: 0,
}

export default handleActions({
    [reset]: () => defaultState,

    [fetch]: (state, {payload}) => ({
        ...defaultState,
        panelId: payload.panelId,
    }),

    [fetchOlder]: state => ({
        ...state,
        isOlderLoading: true,
    }),

    [fetchNewer]: state => ({
        ...state,
        isNewerLoading: true,
    }),

    [setNewerCount]: (state, {payload}) => ({
        ...state,
        newerCount: payload.newerCount,
    }),

    [setHasOlder]: (state, {payload}) => ({
        ...state,
        hasOlder: payload.hasOlder,
    }),

    [receive]: (state, {error, payload}) => {
        if (error) {
            return {
                ...state,
                error: payload,
                isLoading: false,
                isPristine: false,
            }
        }

        const rows = payload.ids
            .map(Number)
            .concat(state.rows)
            .filter((item, pos, array) => array.indexOf(item) === pos)
            .sort((a, b) => b - a)

        return {
            ...state,
            error: null,
            rows,
            isLoading: false,
            isOlderLoading: false,
            isNewerLoading: false,
        }
    },
}, defaultState)
