import {get} from 'api/http'

import {
    mapConnectedPanels,
    mapEventsRate,
    mapOnline,
    mapAllProcesses,
} from 'api/dashboard.map'

export function getConnectedPanels() {
    return get('/statistics_connected/getdata')
        .then(response => response.map(mapConnectedPanels))
}

export function getEventsRate() {
    return get('/statistics_eventsrate/getdata')
        .then(response => response.map(mapEventsRate))
}

export function getOnline() {
    return get('/statistics_online/getdata')
        .then(response => response.map(mapOnline))
}

export function getAllProcesses() {
    return get('/statistics_process/all')
        .then(response => response.map(mapAllProcesses))
}
