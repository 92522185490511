import {handleActions} from 'redux-actions'
import IdSet, {IdSetSubstractive} from './store'
import {select, selectAll, deselect, deselectAll, reverse} from './actions'

export default handleActions({
    [select](state, {payload}) {
        if (!payload) {
            return state
        }

        return state.add(payload)
    },

    [reverse](state, {payload: {idSet, total}}) {
        switch (true) {
            case idSet instanceof IdSetSubstractive:
                return new IdSet(idSet.set)
            case idSet instanceof IdSet:
            default:
                return new IdSetSubstractive(total, idSet.set)
        }

    },

    [selectAll](state, {payload: total}) {
        return new IdSetSubstractive(total)
    },

    [deselect](state, {payload}) {
        // It means that we deselect all elements on page
        // If state was `IdSetSubstractive` we must return to additive logic
        if (payload.length === state.count()) {
            return new IdSet
        }

        if (!payload) {
            return state
        }

        return state.remove(payload)
    },

    [deselectAll]() {
        return new IdSet
    },
}, new IdSet)


