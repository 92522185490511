import {all} from 'redux-saga/effects'
import list from './list/saga'
import one from './one/saga'
import store from './store/saga'
import licensing from './licensing/saga'

export default function*() {
    yield all([
        list(),
        one(),
        store(),
        licensing(),
    ])
}