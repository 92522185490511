import createPermission, {any} from 'permissions/createPermission'

import list from './list'
import {markAsViewed} from './actions'

export default createPermission(
    list,
    any(
        markAsViewed
    )
)