import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

export default class ProcessCounters extends PureComponent {
    static propTypes = {
        stats: PropTypes.shape({
            succeeded: PropTypes.number.isRequired,
            handled: PropTypes.number.isRequired,
            failed: PropTypes.number.isRequired,
            start: PropTypes.number.isRequired,
            notStarted: PropTypes.number.isRequired,
            canceled: PropTypes.number.isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            totalCounter: this.getTotalCounter(props.stats),
        }
    }

    getTotalCounter = (stats) => {
        return Object.values(stats).reduce((acc, count) => {
            return acc + count
        }, 0)
    }

    renderCounter(count, className) {
        const {totalCounter} = this.state

        count = parseInt(count)
        if ((count <= 0) || isNaN(count) || totalCounter === 0) {
            return null
        }
        const width = (count / totalCounter) * 100
        const style = {width: `${width}%`}

        return (
            <div className={'process-counter process-counter--' + className} style={style}>
                <span className="process-counter-label">
                    {count}
                </span>
            </div>
        )
    }

    render() {
        const {stats} = this.props
        return (
            <div className="process-counters">
                {this.renderCounter(stats.succeeded, 'succeeded')}
                {this.renderCounter(stats.handled, 'handled')}
                {this.renderCounter(stats.failedToStart, 'failed')}
                {this.renderCounter(stats.failed, 'failed')}
                {this.renderCounter(stats.start, 'start')}
                {this.renderCounter(stats.canceled, 'failed')}
                {this.renderCounter(stats.notStarted || 0, 'start')}
            </div>
        )
    }
}
