import React from 'react'
import {compose} from 'redux'

import selection from 'permissions/remoteInspections/selection'

import {withPermission} from 'containers/withPermission'
import withSelection from 'containers/withSelection'
import withProps from 'containers/withProps'
import withRemoteInspections from 'containers/withRemoteInspections'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import {TableGrouped} from 'ipmp-react-ui/Table'
import ResultsCell from 'components/Cell/RemoteInspections/ResultsCell'
import NextRICell from 'components/Cell/RemoteInspections/NextRICell'
import PanelCell from 'components/TableCells/PanelCell'
import ActionsCell from './Cells/ActionsCell'

import {__} from 'utils/i18n'
import {humanDate} from 'ipmp-react-ui/humanTime'

const columns = [
    {
        tooltip: false,
        render: ({id, serial, account, latest, result, progress, next, repetition, isSupported}) => {
            const results = latest ? latest.result : []

            return (
                <div>
                    <PanelCell {...{id, serial, account}} to="remoteInspections"/>
                    {isSupported && <ResultsCell {...{results, progress, result}} />}
                    {isSupported && <NextRICell {...{next, repetition, isSupported}} />}
                    {!isSupported && <div>{__('Remote inspection is not supported')}</div>}
                </div>
            )
        },
    },
    {
        tooltip: false,
        width: 150,
        fixed: true,
        render: ActionsCell,
    },
]

export default compose(
    withPermission({hasSelection: selection}),
    withRemoteInspections(),
    withRemoteInspectionsResult(),
    withProps(({select, inspection, selection}) => ({
        onRowClick: (remoteInspections, index) => select(index),
        active: inspection,
        columns,
        emptyMessage: __('No Panels'),
        selectionBar: false,
        separatorCallback(row) {
            let group = humanDate(row.last)
            return group || __('Remote Inspections never initiated')
        },
    })),
    withSelection(),
)(TableGrouped)
