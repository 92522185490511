export const MESSAGE_TYPE_BEEP = 'beep'
export const MESSAGE_TYPE_SIREN = 'siren'
export const MESSAGE_TYPE_SQUACK = 'squack'
export const MESSAGE_TYPE_NEO_SOUND = 'neo_sound'

export const SOUND_TYPE_BASE_BEEP = 'basebeep'
export const SOUND_TYPE_TONE = 'tone'
export const SOUND_TYPE_BINGBING = 'bingbing'
export const SOUND_TYPE_DINGDONG = 'dingdong'
export const SOUND_TYPE_ALARM_TONE = 'alarmtone'

export const NEO_SOUND_STOP_BUZZER = 0
export const NEO_SOUND_TROUBLE = 1
export const NEO_SOUND_STEADY_TONE = 2
export const NEO_SOUND_KEYPAD_RING_BACK = 3
export const NEO_SOUND_WALK_TEST = 4
export const NEO_SOUND_ERROR_SHORT = 5
export const NEO_SOUND_ERROR = 6
export const NEO_SOUND_ERROR_LONG = 7
export const NEO_SOUND_ERROR_DOUBLE = 8
export const NEO_SOUND_SINGLE_ACK = 9
export const NEO_SOUND_SHORT_ACK = 10
export const NEO_SOUND_ACK = 11
export const NEO_SOUND_LONG_ACK = 12
export const NEO_SOUND_DOUBLE_ACK = 13
export const NEO_SOUND_ENTRY_DELAY_NORMAL = 14
export const NEO_SOUND_ENTRY_DELAY_ALARM = 15
export const NEO_SOUND_ENTRY_DELAY_WARNING = 16
export const NEO_SOUND_ENTRY_DELAY_ALARM_MEMORY = 17
export const NEO_SOUND_EXIT_DELAY_NORMAL = 18
export const NEO_SOUND_EXIT_DELAY_WARNING = 19
export const NEO_SOUND_PENDANT_TEST = 20
export const NEO_SOUND_BURGLARY_ALARM = 21
export const NEO_SOUND_SCHEDULED_AUTO_ARMING_PRE_ALERT = 22
export const NEO_SOUND_NO_ACTIVITY_AUTO_ARMING_PRE_ALERT = 23
export const NEO_SOUND_BEEP_KEYPAD_VIA_DLS = 24
export const NEO_SOUND_CHIME_PATTERN_0 = 25
export const NEO_SOUND_CHIME_PATTERN_1 = 26
export const NEO_SOUND_CHIME_PATTERN_2 = 27
export const NEO_SOUND_CHIME_PATTERN_3 = 28
export const NEO_SOUND_URGENT_NOTIFICATION = 29
export const NEO_SOUND_URGENT_NOTIFICATION_WITH_TROUBLE = 30
export const NEO_SOUND_EXIT_DELAY_NORMAL_WITH_ALARM = 31
export const NEO_SOUND_EXIT_DELAY_WARNING_WITH_ALARM = 32
export const NEO_SOUND_EXIT_DELAY_STAY_ARM = 33
export const NEO_SOUND_EXIT_DELAY_STAY_ALARM = 34
export const NEO_SOUND_EXIT_DELAY_STAY_PRE_ALERT = 35
export const NEO_SOUND_CORBUS_FAULT = 36

export const neoSoundParams = {
    [NEO_SOUND_TROUBLE]: {
        repeatInterval: 10000,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 2,
            duration: null,
        }],
    },
    [NEO_SOUND_STEADY_TONE]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_KEYPAD_RING_BACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 8,
            duration: null,
        }],
    },
    [NEO_SOUND_WALK_TEST]: {
        repeatInterval: 10000,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 5,
            duration: null,
        }],
    },
    [NEO_SOUND_ERROR_SHORT]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 1000,
        }],
    },
    [NEO_SOUND_ERROR]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 2000,
        }],
    },
    [NEO_SOUND_ERROR_LONG]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 3000,
        }],
    },
    [NEO_SOUND_ERROR_DOUBLE]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 4000,
        }],
    },
    [NEO_SOUND_SINGLE_ACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_SHORT_ACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 2,
            duration: null,
        }],
    },
    [NEO_SOUND_ACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_LONG_ACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 4,
            duration: null,
        }],
    },
    [NEO_SOUND_DOUBLE_ACK]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 6,
            duration: null,
        }],
    },
    [NEO_SOUND_ENTRY_DELAY_NORMAL]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_ENTRY_DELAY_ALARM]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_ENTRY_DELAY_WARNING]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_ENTRY_DELAY_ALARM_MEMORY]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_NORMAL]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_WARNING]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_PENDANT_TEST]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 1000,
        }],
    },
    [NEO_SOUND_BURGLARY_ALARM]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_SCHEDULED_AUTO_ARMING_PRE_ALERT]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_NO_ACTIVITY_AUTO_ARMING_PRE_ALERT]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_BEEP_KEYPAD_VIA_DLS]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 6,
            duration: null,
        }],
    },
    [NEO_SOUND_CHIME_PATTERN_0]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 6,
            duration: null,
        }],
    },
    [NEO_SOUND_CHIME_PATTERN_1]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_BINGBING,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_CHIME_PATTERN_2]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_DINGDONG,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_CHIME_PATTERN_3]: {
        repeatInterval: null,
        sounds: [{
            type: SOUND_TYPE_ALARM_TONE,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_URGENT_NOTIFICATION]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_URGENT_NOTIFICATION_WITH_TROUBLE]: {
        repeatInterval: 10000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 2,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_NORMAL_WITH_ALARM]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_WARNING_WITH_ALARM]: {
        repeatInterval: 1000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 3,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_STAY_ARM]: {
        repeatInterval: 3000,
        sounds: [{
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_STAY_ALARM]: {
        repeatInterval: 3000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_EXIT_DELAY_STAY_PRE_ALERT]: {
        repeatInterval: 3000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: null,
        }, {
            type: SOUND_TYPE_BASE_BEEP,
            frequency: 1,
            duration: null,
        }],
    },
    [NEO_SOUND_CORBUS_FAULT]: {
        repeatInterval: 2000,
        sounds: [{
            type: SOUND_TYPE_TONE,
            frequency: 1,
            duration: 1000,
        }],
    },
}

export default function isSound(type) {
    switch (type) {
        case MESSAGE_TYPE_BEEP:
        case MESSAGE_TYPE_SIREN:
        case MESSAGE_TYPE_SQUACK:
        case MESSAGE_TYPE_NEO_SOUND:
            return true
        default:
            return false
    }
}