import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import ModalCardForm from 'ui/ModalCardForm'
import withModalLoader from 'containers/withModalLoader'
import withForm from 'containers/withForm'
import {__} from 'utils/i18n'
import {editCentralStation} from 'modules/forms/handlers'
import {fetch as fetchSerialPorts} from 'modules/centralStations/serialPorts/actions'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'

import CentralStationFormFields, {rules} from './CentralStationFormFields'

export class EditCentralStation extends PureComponent {

    static propTypes = {
        centralStationId: PropTypes.number,
        hide: PropTypes.func.isRequired,
        handle: PropTypes.any,
        protocols: PropTypes.any,
        serialPorts: PropTypes.array,
        data: PropTypes.object,
        isLoading: PropTypes.bool,
    }

    handle = (data) => {
        const {handle, data: oldData, centralStationId} = this.props

        data = {
            ...oldData,
            ...data,
        }

        if (!data.isHeartBeat) {
            data.heartBeat = 0
        }

        handle(data, centralStationId)
    }

    getSerialPortsWithCurrent() {
        const {data, serialPorts} = this.props

        if (data.connectionType !== 'serial') {
            return serialPorts
        }

        return [
            {
                id: data.serialPortId,
                name: data.device,
            },
            ...serialPorts
        ]
    }

    render() {
        const {data, hide, isLoading, protocols} = this.props

        return (
            <ModalCardForm
                confirmOnDismiss
                rules={rules}
                header={__('Edit Central Station')}
                isLoading={isLoading}
                hide={hide}
                onSubmit={this.handle}
            >
                <CentralStationFormFields
                    data={data}
                    serialPorts={this.getSerialPortsWithCurrent()}
                    protocols={protocols}
                />
            </ModalCardForm>
        )
    }

}

export default compose(
    connect(
        (state, {centralStationId}) => {
            const {serialPorts, protocols, store} = state.centralStations
            const data = store.byId[centralStationId]

            return {
                serialPorts: serialPorts.data,
                protocols: protocols.data,
                isLoading: protocols.isLoading || serialPorts.isLoading,
                data,
            }
        },
        (dispatch) => bindActionCreators({
            fetchSerialPorts,
            fetchProtocols,
        }, dispatch)
    ),
    withModalLoader(({fetchSerialPorts, fetchProtocols}) => {
        fetchSerialPorts()
        fetchProtocols()
    }),
    withForm(editCentralStation),
)(EditCentralStation)