import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import {Line} from 'react-chartjs-2'

import {__} from 'utils/i18n'
import momentPropType from 'utils/momentPropType'

import {BRIGHTNESS, TEMPERATURE} from 'constants/meteoTypes'

export default class Chart extends Component {

    _yDefaultThreshold = {
        [BRIGHTNESS]: 1300,
        [TEMPERATURE]: 40,
    }

    static propTypes = {
        type: PropTypes.oneOf([TEMPERATURE, BRIGHTNESS]),
        range: PropTypes.shape({
            from: momentPropType,
            to: momentPropType,
        }).isRequired,

        data: PropTypes.shape({
            min: PropTypes.array,
            max: PropTypes.array,
            avg: PropTypes.array,
            exact: PropTypes.array,
            yThreshold: PropTypes.number,
        }).isRequired,

        label: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            yThreshold: get(props, 'data.yThreshold', this.yDefaultThreshold),
        }
    }

    get yDefaultThreshold() {
        const {type} = this.props
        return this._yDefaultThreshold[type]
            || this._yDefaultThreshold[TEMPERATURE]
    }

    getDataset() {
        const {label, data: {min, max, avg, exact}} = this.props

        const styles = {
            min: {
                label: __('Minimum'),
                fill: '+2',
                pointRadius: 1,
                pointHitRadius: 2,
                data: min || [],
            },
            avg: {
                label: __('Average'),
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: avg || [],
            },
            max: {
                label: __('Maximum'),
                pointRadius: 1,
                pointHitRadius: 2,
                fill: false,
                data: max || [],
            },
            exact: {
                label,
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: exact || [],
            },
        }

        if (exact) {
            return {
                datasets: [styles.exact],
            }
        }

        return {
            datasets: [styles.min, styles.avg, styles.max],
        }
    }

    defs = {
        animation: false,
        legend: false,
    }

    titleCallback = (item) => {
        const {xLabel} = Array.isArray(item) ? item[0] : item

        return xLabel.replace('GMT+00:00', 'GMT')
            .replace(/(.*)(GMT[+-]?)0?(\d+)(:[1-9]\d)?.*/, '$1$2$3$4')
    }

    tooltipFormat = 'llll [GMT]Z'
    displayFormats = {
        hour: 'LT',
    }

    get temperatureOptions() {
        const {range: {from, to}, label} = this.props
        const {yThreshold} = this.state

        return {
                ...this.defs,
                tooltips: {
                    callbacks: {
                        title: this.titleCallback,
                        label: (item) => {
                            const {yLabel} = Array.isArray(item) ? item[0] : item

                            return `${Number(yLabel).toFixed(1)} ` + label
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                suggestedMax: yThreshold,
                                suggestedMin: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            distribution: 'linear',
                            time: {
                                min: from,
                                max: to,
                                tooltipFormat: this.tooltipFormat,
                                displayFormats: this.displayFormats,
                            },
                        },
                    ],
                },
            }
    }

    get brightnessOptions() {
        const {range: {from, to}, label} = this.props
        const {yThreshold} = this.state

        return {
            ...this.defs,
            tooltips: {
                callbacks: {
                    title: this.titleCallback,
                    label: (item) => {
                        const {yLabel} = Array.isArray(item) ? item[0] : item

                        return `${Number(yLabel).toFixed(1)}  ` + label
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            suggestedMax: yThreshold,
                            suggestedMin: 0,
                        },
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            min: from,
                            max: to,
                            tooltipFormat: this.tooltipFormat,
                            displayFormats: this.displayFormats,
                        },
                    },
                ],
            },
        }
    }

    getOptions() {
        const {type} = this.props

        switch (type) {
            case BRIGHTNESS:
                return this.brightnessOptions
            case TEMPERATURE:
                return this.temperatureOptions
        }
    }

    render() {
        const data = this.getDataset()
        const options = this.getOptions()

        return <Line data={data} options={options}/>
    }
}