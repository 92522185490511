import createPermission, {any} from 'permissions/createPermission'
import list, {add} from './remarks'

export const info = createPermission('unit.info')

export default createPermission(
    any(
        info,
        list,
        add,
    ),
)
