import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {fetch, addFilters, removeFilters, clearFilters, fetchSuggests, setQuery} from 'modules/runners/list/actions'

import withProps from 'containers/withProps'

import Search from 'components/Search/Search'
import DateFilter from 'components/Search/Filters/DateFilter'

import __ from 'utils/i18n'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'

import getRunnerStatusTitle, {list} from 'constants/runnerStatuses'

export default compose(
    withProps(() => ({
        filters: [
            new ValuesFilter('status', __('Status'),
                list.reduce((acc, value) => {
                    acc[value] = getRunnerStatusTitle(value)
                    return acc
                }, {})
            ),
            new DateFilter('started', __('Process Started')),
            new SuggestFilter('serial', __('Panel`s Serial')),
            new SuggestFilter('group', __('Panel`s Group')),
            new DateFilter('changed', __('State Changed')),
        ],
    })),
    connect(
        ({runners}) => ({
            selected: runners.list.filters,
            suggests: runners.list.suggests,
            query: runners.list.query,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
            onQuery: setQuery,
        }, dispatch),
    ),
)(Search)