import {handleActions} from 'redux-actions'

import {
    clear,
    update,
    receive,
} from './actions'

const defaultState = {}

export default handleActions({
    [clear]() {
        return defaultState
    },

    [update](state, {payload: {groupId, centralStationId, links}}) {
        return {
            ...state,
            [groupId]: {
                ...state[groupId],
                [centralStationId]: links,
            },
        }
    },

    [receive](state, {payload}) {
        const {groupId, links} = payload

        const data = links
            .reduce(
                (acc, {centralStationId, profileId}) => {
                    const profiles = acc[centralStationId] || []

                    if (!profiles.includes(profileId)) {
                        profiles.push(profileId)
                    }

                    return {
                        ...acc,
                        [centralStationId]: profiles,
                    }
                },
                {}
            )

        return {
            ...state,
            [groupId]: data,
        }
    },
}, defaultState)