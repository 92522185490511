import {TIMEOUT_DISABLED, TIMEOUTS_AUTOLOGOUT} from 'configs/timeouts'
import {getCountryName} from 'constants/ISO3166-1'
import themes, {DEFAULT_THEME} from 'constants/themes'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import {getNativeLanguageName} from 'constants/ISO639-1'

import {
    setLanguage,
    setAutologout,
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
    setTheme,
    setTemperature,
} from 'modules/settings/actions'
import {fetch} from 'modules/locales/actions'

import withModalLoader from 'containers/withModalLoader'

import ModalCardForm from 'ui/ModalCardForm'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {humanDuration} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'
import {getTemperatureUnits} from 'utils/temperature'

class EditSettings extends Component {

    static propTypes = {
        setLanguage: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        timeout: PropTypes.number,
        locale: PropTypes.string,
        isAlarmsEnabled: PropTypes.bool,
        isAlarmSoundEnabled: PropTypes.bool,
        setAlarmsEnabled: PropTypes.func.isRequired,
        setAlarmsSoundEnabled: PropTypes.func.isRequired,
        setAlarmsNotificationsEnabled: PropTypes.func.isRequired,
        setTheme: PropTypes.func.isRequired,
        setTemperature: PropTypes.func.isRequired,
    }

    state = {}

    handleEnableSupervisionChange = (e) => {
        const {setAlarmsEnabled} = this.props
        setAlarmsEnabled(e.target.checked)
    }

    handleEnableSoundChange = (e) => {
        const {setAlarmsSoundEnabled} = this.props
        setAlarmsSoundEnabled(e.target.checked)
    }

    handleEnableNotificationsChange = (e) => {
        const {setAlarmsNotificationsEnabled} = this.props
        setAlarmsNotificationsEnabled(e.target.checked)
    }

    handleChangeLocale = (e, value) => {
        const {setLanguage} = this.props
        setLanguage(value)
    }

    handleChangeAutologout = (e, value) => {
        const {setAutologout} = this.props
        setAutologout(value)
    }

    handleChangeTheme = (e, value) => {
        const {setTheme} = this.props
        setTheme(value)
    }

    handleChangeTemperature = (e, value) => {
        const {setTemperature} = this.props
        setTemperature(value)
    }

    handleClose = () => {
        const {hide} = this.props

        hide()
    }

    renderLocaleOptions() {
        const {locales} = this.props

        const languages = locales
            .map(locale => locale.substr(0, 2))
            .filter((locale, index, array) => array.indexOf(locale) !== index)

        return locales.map(locale => {
            const [langCode, countryCode] = locale.split('-')
            const showCountry = languages.includes(langCode)

            let label = getNativeLanguageName(langCode)

            if (showCountry && countryCode) {
                const country = getCountryName(countryCode, langCode)
                label += ' - ' + (country || countryCode)
            }

            return (
                <Option
                    key={locale}
                    label={label}
                    value={locale}/>
            )
        })
    }

    renderTemperatureOptions() {
        return getTemperatureUnits().map(({key, name}) => {
            return (
                <Option
                    key={key}
                    label={name}
                    value={key}/>
            )
        })
    }

    render() {
        const {
            locale,
            autologout,
            isAlarmsEnabled,
            isAlarmSoundEnabled,
            isAlarmNotificationsEnabled,
            theme,
            temperature,
        } = this.props

        return (
            <ModalCardForm
                hide={this.handleClose}
                onSubmit={this.handleClose}
                showDismiss={false}
                submitLabel={__('Close')}
                header={__('Edit Settings')}
            >

                <Select
                    label={__('Language')}
                    onChange={this.handleChangeLocale}
                    defaultValue={locale}>
                    {this.renderLocaleOptions()}
                </Select>

                <Select
                    label={__('Temperature units')}
                    onChange={this.handleChangeTemperature}
                    defaultValue={temperature}>
                    {this.renderTemperatureOptions()}
                </Select>

                <Select
                    label={__('Theme')}
                    onChange={this.handleChangeTheme}
                    defaultValue={theme || DEFAULT_THEME}>
                    {Object.keys(themes).map(
                        (theme) => (
                            <Option label={themes[theme].title} key={theme} value={theme}/>
                        ),
                    )}
                </Select>

                <Select
                    label={__('Auto logout timeout')}
                    onChange={this.handleChangeAutologout}
                    defaultValue={autologout || TIMEOUTS_AUTOLOGOUT[0]}>
                    {TIMEOUTS_AUTOLOGOUT.map(
                        value => value > TIMEOUT_DISABLED
                                 ? (<Option key={value} value={value} label={humanDuration(value, 'minutes')}/>)
                                 : (<Option key={value} value={value} label={__('Disabled')}/>),
                    )}
                </Select>

                <h3 className="form-section-header">{__('Alarms supervision')}</h3>

                <Togglebox
                    label={__('Enable alarms supervision')}
                    onChange={this.handleEnableSupervisionChange}
                    checked={isAlarmsEnabled}
                />

                <br/>
                <p className={isAlarmsEnabled ? '' : 'empty'}>{__('When alarm occurred:')}</p>

                <Togglebox
                    label={__('Show notification')}
                    onChange={this.handleEnableNotificationsChange}
                    checked={isAlarmNotificationsEnabled}
                    disabled={!isAlarmsEnabled}
                />

                <Togglebox
                    label={__('Play sound')}
                    onChange={this.handleEnableSoundChange}
                    checked={isAlarmSoundEnabled}
                    disabled={!isAlarmsEnabled}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({locales, settings}) => {
            const {isLoading, list, error, locale} = locales
            const {autologout, alarms, theme, temperature} = settings

            return {
                autologout,
                isLoading,
                error,
                locales: list,
                locale,
                theme,
                temperature,
                isAlarmsEnabled: alarms.isEnabled,
                isAlarmSoundEnabled: alarms.isSoundEnabled,
                isAlarmNotificationsEnabled: alarms.isNotificationsEnabled,
            }
        },
        (dispatch) => bindActionCreators({
            setLanguage,
            setAutologout,
            fetchLocalesList: fetch,
            setAlarmsEnabled,
            setAlarmsSoundEnabled,
            setAlarmsNotificationsEnabled,
            setTemperature,
            setTheme,
        }, dispatch),
    ),
    withModalLoader(({fetchLocalesList}) => fetchLocalesList()),
)(EditSettings)
