import {compose} from 'redux'

import withSelection from 'containers/withSelection'
import withRunners from 'containers/withRunners'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withSelection(),
    withRunners(),
)(SelectionDropDown)