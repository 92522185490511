import {createAction} from 'redux-actions'

export const fetchCellularConnected = createAction('SYSTEM_SETTINGS/CELLULAR_CONNECTED/FETCH')
export const updateCellularConnected = createAction('SYSTEM_SETTINGS/CELLULAR_CONNECTED/UPDATE')
export const setCellularConnected = createAction('SYSTEM_SETTINGS/CELLULAR_CONNECTED/SET')

export const fetchBroadbandConnected = createAction('SYSTEM_SETTINGS/BROADBAND_CONNECTED/FETCH')
export const updateBroadbandConnected = createAction('SYSTEM_SETTINGS/BROADBAND_CONNECTED/UPDATE')
export const setBroadbandConnected = createAction('SYSTEM_SETTINGS/BROADBAND_CONNECTED/SET')

export const fetchCommonConnected = createAction('SYSTEM_SETTINGS/COMMON_CONNECTED/FETCH')
export const updateCommonConnected = createAction('SYSTEM_SETTINGS/COMMON_CONNECTED/UPDATE')
export const setCommonConnected = createAction('SYSTEM_SETTINGS/COMMON_CONNECTED/SET')

export const fetchSupervision = createAction('SYSTEM_SETTINGS/SUPERVISION/FETCH')
export const updateSupervision = createAction('SYSTEM_SETTINGS/SUPERVISION/UPDATE')
export const setSupervision = createAction('SYSTEM_SETTINGS/SUPERVISION/SET')

export const fetchRemoteInspection = createAction('SYSTEM_SETTINGS/REMOTE_INSPECTION/FETCH')
export const updateRemoteInspection = createAction('SYSTEM_SETTINGS/REMOTE_INSPECTION/UPDATE')
export const setRemoteInspection = createAction('SYSTEM_SETTINGS/REMOTE_INSPECTION/SET')

export const fetchInteractiveSession = createAction('SYSTEM_SETTINGS/INTERACTIVE_SESSION/FETCH')
export const updateInteractiveSession = createAction('SYSTEM_SETTINGS/INTERACTIVE_SESSION/UPDATE')
export const setInteractiveSession = createAction('SYSTEM_SETTINGS/INTERACTIVE_SESSION/SET')

export const fetchUserNotifications = createAction('SYSTEM_SETTINGS/USER_NOTIFICATION/FETCH')
export const updateUserNotifications = createAction('SYSTEM_SETTINGS/USER_NOTIFICATION/UPDATE')
export const setUserNotifications = createAction('SYSTEM_SETTINGS/USER_NOTIFICATION/SET')

export const fetchAdvertisement = createAction('SYSTEM_SETTINGS/ADVERTISEMENT/FETCH')
export const updateAdvertisement = createAction('SYSTEM_SETTINGS/ADVERTISEMENT/UPDATE')
export const setAdvertisement = createAction('SYSTEM_SETTINGS/ADVERTISEMENT/SET')

export const fetchMessageBrokers = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/FETCH')
export const updateMessageBrokers = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/UPDATE')
export const addMessageBroker = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/ADD')
export const addMessageBrokerToStore = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/ADD_TO_STORE')
export const removeMessageBroker = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/REMOVE')
export const removeMessageBrokerFromStore = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/REMOVE_FROM_STORE')
export const saveMessageBroker = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/SAVE')
export const saveMessageBrokerInStore = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/SAVE_IN_STORE')

export const fetchSerialPorts = createAction('SYSTEM_SETTINGS/SERIAL_PORTS/FETCH')
export const updateSerialPorts = createAction('SYSTEM_SETTINGS/SERIAL_PORTS/UPDATE')

export const fetchLicense = createAction('LICENSE/FETCH_INFO')
export const updateLicense = createAction('LICENSE/UPDATE_INFO')