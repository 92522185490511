import {takeEvery, all, put, select} from 'redux-saga/effects'

import {
    update
} from './actions'
import {fetch} from 'modules/centralStations/list/actions'
import {editCentralStation, addCentralStation} from 'modules/forms/handlers'

export default function*() {
    yield all([
        takeEvery(editCentralStation.SUCCESS, watchEditCentralStation),
        takeEvery(addCentralStation.SUCCESS, watchAddCentralStation),
    ])
}

function* watchEditCentralStation({meta}) {
    const {id, data} = meta
    yield put(update({id, ...data}))
}

function* watchAddCentralStation({payload}) {
    const newCentralStation = {...payload.result}
    const {protocolId, serialPortId} = payload.result

    const {serialPorts, protocols} = yield select(state => state.centralStations)
    newCentralStation.protocol = find(protocols.data, {id: protocolId}).name

    if (serialPortId) {
        newCentralStation.device = find(serialPorts, {id: serialPortId}) || null
    }

    yield put(update(newCentralStation))
    yield put(fetch())
}