import {put, select} from 'redux-saga/effects'
import uniqid from 'uniqid'

import * as retrievers from './retrievers'

import * as actions from './actions'
import {snackShow} from 'modules/snacks'
import {__} from 'utils/i18n'
import timezones from 'utils/timezones'

import mergeStubsAndProcesses from 'modules/processes/manager/helpers/mergeStubsAndProcesses'
import makeNotStartedProcess from 'modules/processes/manager/helpers/makeNotStartedProcess'

const makeProcessStub = (type, panelId, store) => ({
    key: uniqid(),
    webname: store && store[panelId] && store[panelId].webname,
    panelId,
    type,
    status: 'start',
    started: timezones.server(),
    percentage: 0,
})

export const makeProcessStubs = (type, panelIds, store) => panelIds.map(
    panelId => makeProcessStub(type, panelId, store),
)

function createExecutor(type, dataRetriever, processStubs, resultParser = ({processes}) => processes) {
    return function* executor(fn, ...arg) {
        try {
            const data = yield dataRetriever(fn, ...arg)
            const processes = mergeStubsAndProcesses(processStubs, resultParser(data))

            yield put(actions.observe(processes))

            const failed = processes
                .filter(process => !process.id)
                .map(({panelId}) => panelId)

            if (failed.length > 0) {
                yield put(snackShow(__('1 process failed to start', '%d processes failed to start', failed.length)))
            }

            return failed
        } catch (error) {
            yield put(actions.observe(
                processStubs.map(makeNotStartedProcess),
            ))

            throw error
        }
    }
}

export default function* generateProcess(type, panelId) {
    const store = yield select(state => state.panels.store.byIds)
    const process = makeProcessStub(type, panelId, store)

    yield put(actions.observe(process))

    return {
        process: process.key,
        execute: createExecutor(type, retrievers.simple, [process], process => [process]),
    }
}
