import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {snackShow} from 'modules/snacks'

export default function withSnacks() {
    return connect(
        null,
        dispatch => bindActionCreators({snackShow}, dispatch)
    )
}