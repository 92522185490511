import __ from 'utils/i18n'
import {warn} from 'utils/log'

export const BRIGHTNESS = 'brightness'
export const TEMPERATURE = 'temperature'

export default function getTitle(type) {
    switch (type) {
        case TEMPERATURE:
            return __('Temperature')
        case BRIGHTNESS:
            return __('Brightness')
        default:
            warn(`Unknown metric type: ${type}`)
            return __('Unknown metric')
    }
}