import {put} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import {fetchWithoutLoader} from 'modules/features/store/actions'

export default function* watchPanelDiscoveryComplete({panelId}) {
    try {
        yield put(fetchWithoutLoader(panelId))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}