import FirmwareTab from 'pages/Panel/Devices/Sidebar/Tabs/FirmwareTab'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'

import Tabs from 'ipmp-react-ui/Tabs'

import configuration from 'permissions/panel/configuration/page'

import panelSectionName from 'utils/configuration/panelSectionName'

import ConfigurationTab from './Tabs/ConfigurationTab'

import {withPermission} from 'containers/withPermission'

import {__} from 'utils/i18n'

import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'

export class PanelSidebar extends Component {

    get hasConfiguration() {
        return this.props.isConfigurationAllowed
    }

    get hasFirmware() {
        return this.props.firmware && this.props.firmware.hasOwnProperty('packages')
    }

    render() {
        const {
            panel,
            firmware,
        } = this.props

        const configurationSectionName = panelSectionName(panel)

        return (
            <Tabs>

                {this.hasConfiguration && configurationSectionName && <ConfigurationTab
                    name={__('Configuration')}
                    sectionName={configurationSectionName}
                    panelId={panel.id}/>
                }

                {this.hasFirmware && <FirmwareTab
                    name={__('Firmware')}
                    panelId={panel.id}
                    {...firmware}
                />}

            </Tabs>
        )
    }
}

export default compose(
    withPermission({
        isConfigurationAllowed: configuration,
    }),
    connect(
        (state, {panel}) => selectPanelFirmwareByDeviceType(
            state,
            {
                panelId: panel.id,
                device: {deviceType: DEVICE_TYPE_CONTROL_PANEL},
            },
        ),
    ),
)(PanelSidebar)
