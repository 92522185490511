import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'

export default class BitsetViewer extends PureComponent {

    static propTypes = {
        item: PropTypes.object.isRequired,
    }

    titleFormat(title, key) {
        return title.replace('%d', key)
    }

    render() {
        const {item: {label, offset, val}} = this.props

        if (val.includes('1') === false) {
            return <div className="empty">{__('Not set')}</div>
        }

        const multiValue = val.split('')
            .map((element, key) => ({
                value: key,
                selected: element == 1,
            }))
            .filter(element => (element.selected))
            .map((element, key) => (
                Number(element.value)
            )).reduce((grouped, val) => {
                if (grouped.length === 0) {
                    grouped.push([val])
                } else {
                    let lastVal = grouped[grouped.length - 1][grouped[grouped.length - 1].length - 1]
                    if (val - lastVal === 1) {
                        grouped[grouped.length - 1].push(val)
                    } else {
                        grouped.push([val])
                    }
                }
                return grouped
            }, []).reduce((result, group) => {
                if (group.length == 1) {
                    result.push(this.titleFormat(label, group.pop().toString()))
                } else {
                    result.push(this.titleFormat(label, (offset + group[0]) + '-' + (offset + group[group.length - 1])))
                }
                return result
            }, []).join(', ')
        return (
            <div className="configuration-bitset-viewer">
                {multiValue}
            </div>
        )
    }
}