import SearchBar from 'components/Search/SearchBar'
import {CrossServerSearchContext} from 'pages/Panels/CrossServerSearch/CrossServerSearch'
import React from 'react'
import {__} from 'utils/i18n'

export default function CrossServerSearchBar() {
    return (
        <CrossServerSearchContext.Consumer>
            {({search}) => (
                <SearchBar onChange={search} placeholder={__('Enter account')}/>
            )}
        </CrossServerSearchContext.Consumer>
    )
}
