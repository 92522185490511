import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS} from 'constants/moduleTypes'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import EditPanelItem from './ActionsItems/EditPanelItem'
import RemovePanelItem from './ActionsItems/RemovePanelItem'

import {actions} from 'permissions/panel/actions'
import {__} from 'utils/i18n'

import ToggleUserAppItem from './ActionsItems/ToggleUserAppItem'
import ToggleInstallerAppItem from './ActionsItems/ToggleInstallerAppItem'
import ToggleEncryptionItem from './ActionsItems/ToggleEncryptionItem'
import TriggerDiscoveryItem from './ActionsItems/TriggerDiscoveryItem'

class ActionsDropDown extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            id: PropTypes.number,
            connected: PropTypes.bool,
        }),
    }

    render() {
        const {panel: {connected}} = this.props
        return (
            <DropDownButton label={__('Actions')}>
                <Menu>
                    <ToggleUserAppItem/>
                    <ToggleInstallerAppItem/>
                    <MenuDelimiter/>
                    <TriggerDiscoveryItem/>
                    <EditPanelItem/>
                    <RemovePanelItem/>
                    <MenuDelimiter/>
                    <ToggleEncryptionItem connected={connected} type={MODULE_TYPE_BBA}/>
                    <ToggleEncryptionItem connected={connected} type={MODULE_TYPE_GPRS}/>
                </Menu>
            </DropDownButton>
        )
    }
}

export default compose(
    withPermission({isVisible: actions}),
    withVisibility(),
    withPanel(),
)(ActionsDropDown)
