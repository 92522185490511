import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/reports/list'

import {showSaveSearchModal} from 'modules/modals/actions'
import {fetch, setQuery, addFilters, removeFilters, clearFilters, fetchSuggests} from 'modules/reports/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import DateFilter from 'components/Search/Filters/DateFilter'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new ValuesFilter('isActive', __('Is Active'), {
                'yes': __('Yes'),
                'no': __('No'),
            }),
            new DateFilter('created', __('Created')),
            new DateFilter('finishedAt', __('Last report')),
            new DateFilter('next', __('Next report'), 'future'),
        ],
    })),
    connect(
        ({reports: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: filters => showSaveSearchModal('reports', filters),
        }, dispatch),
    ),
)(Search)