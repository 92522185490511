import {handleActions} from 'redux-actions'

import {
    fetchError,
    fetchOneData,
    receiveOneData
} from './actions'

const defaultState = {
    isLoading: false,
    error: null,
    centralStationData: null,
}
export default handleActions({

    [fetchOneData]: (state, {payload}) => ({
        isLoading: true,
    }),

    [fetchError]: (state, {payload}) => ({
        ...state,
        error: payload,
        isLoading: false,
    }),

    [receiveOneData]: (state, {payload}) => ({
        ...state,
        centralStationData: payload.data,
        isLoading: false,
    }),

}, defaultState)