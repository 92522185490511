import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import measure, {HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST} from './measure'

import ConfigurationRow from 'components/Configuration/ConfigurationRow'

export default class ConfigurationList extends PureComponent {

    static propTypes = {
        bindMoveTo: PropTypes.func,
        onChange: PropTypes.func,
        onMove: PropTypes.func,
        isChecked: PropTypes.func,
        changes: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        ),
        sections: PropTypes.array,
        backup: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        ),
        heightOffsetBeforeConfiguration: PropTypes.number.isRequired,
    }

    static defaultProps = {
        heightOffsetBeforeConfiguration: HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST,
    }

    state = {
        from: 0,
        // height: window.innerHeight,
        height: window.innerHeight - this.props.heightOffsetBeforeConfiguration,
        measures: measure(this.props.sections),
        sections: this.props.sections,
    }

    // for invalidation
    lastVisibleElement

    lastVisibleSection

    currentHeadlineElement

    constructor(props, context) {
        super(props, context)
        this.length = props.sections.length
        props.bindMoveTo && props.bindMoveTo(this.moveTo)

        window.addEventListener('resize', this.handleResize)
    }

    handleScroll = e => {
        if (e.target !== e.currentTarget) {
            return
        }

        const from = e.target.scrollTop

        const visibleSection = this.state.measures.filter(
            ({offset}) => offset <= from,
        ).slice(-1).pop()

        if (!visibleSection) {
            return
        }

        const lastVisibleElement = visibleSection.items.filter(
            ({bottom}) => bottom <= from,
        ).slice(-1).pop()

        if (this.lastVisibleElement !== lastVisibleElement || this.lastVisibleSection !== visibleSection) {
            // first visible element is changed
            this.lastVisibleElement = lastVisibleElement
            this.lastVisibleSection = visibleSection
            this.setState({from})
        }

        if (this.props.onMove) {
            const lastPassedHead = visibleSection.items.filter(
                ({offset, node}) => node.type === 'menu' && offset <= from,
            ).slice(-1).pop()

            const currentHeadlineElement = lastPassedHead ? lastPassedHead.key : visibleSection.key

            if (this.currentHeadlineElement !== currentHeadlineElement) {
                this.currentHeadlineElement = currentHeadlineElement
                this.props.onMove(currentHeadlineElement.join('$$'))
            }
        }
    }

    handleResize = () => this.setState({height: window.innerHeight})

    handleRef = element => this.element = element

    moveTo = (key) => {
        if (!this.element) {
            return
        }

        const sectionKey = key.split('$$').shift()
        const section = this.state.measures.find(({node}) => sectionKey === node.key)

        if (!section) {
            return
        }

        const item = section.node.key === key
            ? section
            : section.items.find(node => node.key.join('$$') === key)

        if (item) {
            this.element.scrollTop = item.offset
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.sections !== state.sections) {
            return {
                sections: props.sections,
                measures: measure(props.sections),
            }
        }

        return {
            sections: props.sections,
        }
    }

    renderRow(item) {
        const {
            exporting,
            isEditable,
            isChecked,
            isShowExportCheckbox,
            changes,
            backup,
            isChanged,
            onChange,
            onSetExport,
            onUndo,
            hasUndo,
        } = this.props

        const hasChangedValue = changes && changes.hasOwnProperty(item.key)
        const hasBackupValue = backup && backup.hasOwnProperty(item.key)
        const isExported = exporting && exporting.hasOwnProperty(item.key)

        return (
            <ConfigurationRow {...{
                key: item.key,
                item,
                isEditable,
                isExported,
                isShowExportCheckbox,
                isChanged: isChanged ? isChanged(item) : hasChangedValue,
                isChecked: isChecked ? isChecked(item) : false,
                hasUndo,
                changed: hasChangedValue ? changes[item.key] : undefined,
                backup: hasBackupValue ? backup[item.key] : undefined,
                onChange,
                onSetExport,
                onUndo,
            }}/>
        )
    }

    renderList = (items, from, to) => {
        const visible = items.filter(
            ({bottom, offset}) => to > offset && from < bottom,
        )

        if (visible.length === 0) {
            return null
        }

        const rows = visible.map(
            ({key, level, node}) => {
                if (node.type === 'menu') {
                    return (
                        <h3 key={key} className={'configuration-title configuration-title--' + level}>
                            {node.name}
                        </h3>
                    )
                }

                return this.renderRow(node)
            },
        )

        if (visible[0] !== items[0]) {
            let visibleOffset = visible[0].offset - items[0].offset

            if (items[0].node.type === 'menu') {
                // workaround
                // offset shows visible offset, but we want to preserve vertical space, so we add that margin
                visibleOffset += 16
            }

            rows.unshift(
                <div key="stub" className="configuration-stub" style={{height: visibleOffset + 'px'}}/>,
            )
        }

        return rows
    }

    renderSections() {
        const {from, height} = this.state
        const to = from + height

        return this.state.measures.map(({node, offset, items, height}) => {
            const sectionVisible = (offset + height) > from && offset < to

            return (
                <section className="configuration-section" key={node.key}>
                    {node.name && <div className="configuration-section-title">{node.name}</div>}

                    <div className="card" style={{height}}>
                        {sectionVisible && this.renderList(
                            items,
                            from,
                            to,
                            offset,
                        )}
                    </div>
                </section>
            )
        })
    }

    render() {
        return (
            <div className="configuration-content" ref={this.handleRef} onScroll={this.handleScroll}>
                {this.renderSections()}
            </div>
        )
    }
}
