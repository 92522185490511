import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import Logo from 'ui/Logo'
import LoginForm from 'components/Auth/LoginForm'
import ForgotPasswordForm from 'components/Auth/ForgotPasswordForm'
import {FORGOT_PASSWORD_SCREEN, LOGIN_SCREEN} from 'constants/loginScreen'

const screen = {
    [FORGOT_PASSWORD_SCREEN]: <ForgotPasswordForm/>,
    [LOGIN_SCREEN]: <LoginForm/>,
}

class Login extends Component {

    static propTypes = {
        loginScreenName: PropTypes.string,
    }

    render() {
        const {loginScreenName, children} = this.props

        return (
            <div className="login">
                <div className="login-logo">
                    <Logo/>
                </div>

                {screen[loginScreenName]}

                {children}

                <div className="login-version">
                    {IPMP_VERSION}
                </div>
            </div>
        )
    }
}

export default connect(
    ({auth: {sign}}) => ({
        ...sign,
    }),
)(Login)