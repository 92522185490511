import React from 'react'
import {compose} from 'redux'
import {Route, Switch, withRouter} from 'react-router-dom'

import map from 'lodash-es/map'

import withProps from 'containers/withProps'

import Brokers from './Brokers/Brokers'
import General from './General/General'
import Receiver from './Receiver/Receiver'
import Resolve from './Resolve/Resolve'
import Interactive from './Interactive/Interactive'

import path from 'utils/path'

const componentsMap = {
    brokers: Brokers,
    general: General,
    receiver: Receiver,
    resolve: Resolve,
    interactive: Interactive,
}

export default compose(
    withRouter,
    withProps(({routes}) => {
        const children = map(routes, ({path: pathName, exact}, route) => (
            <Route exact={exact} path={path(pathName)} component={componentsMap[route]} key={route}/>
        ))

        return {
            children,
        }
    }),
)(Switch)