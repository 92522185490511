import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
    bindActionCreators,
    compose,
} from 'redux'

import {
    connect,
} from 'react-redux'

import {
    fetch as fetchContentOptions,
} from 'modules/reports/contentOptions/actions'

import {
    fetch as fetchPmaxIndexes,
} from 'modules/reports/pmaxIndexes/actions'

import {fetch as fetchTransportEmails} from 'modules/reports/transportEmails/actions'

import {
    createReport,
} from 'modules/forms/handlers'

import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {FormSection} from 'ipmp-react-ui/Form'
import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import Content from './Create/Content'
import Schedule from './Create/Schedule'
import Recurrence from './Create/Recurrence'
import RangeOfRecurrence from './Create/RangeOfRecurrence'

import rules from './Create/utils/rules'
import prepareParams from './Create/utils/prepareParams'

import withModalLoader from 'containers/withModalLoader'
import withForm from 'containers/withForm'

import {__, __n} from 'utils/i18n'

class CreateReport extends Component {
    static propTypes = {
        reportTypes: PropTypes.object,
        contentOptions: PropTypes.object,
        pmaxIndexes: PropTypes.object,
        transportEmails: PropTypes.array,
        handle: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
        panelIds: PropTypes.array,
    }

    reportTypes = {
        onetime: __('One time'),
        daily: __('Daily'),
        weekly: __('Weekly'),
        monthly: __('Monthly'),
        // yearly: __('Yearly'),
    }

    state = {
        reportType: 'onetime',
    }

    changeReportType = (e, reportType) => {
        return this.setState({
            reportType,
        })
    }

    onSubmit = (formData) => {
        const {panelIds, onSuccess} = this.props

        const params = prepareParams({
            ...formData,
            panelIds,
        })

        return this.props.handle(params).then(() => onSuccess && onSuccess())
    }

    header() {
        const {panelIds} = this.props

        if (Array.isArray(panelIds)) {
            return __n('Create report for %s panel', 'Create report for %s panels', panelIds.length, [panelIds.length])
        }

        return __('Create Report for all panels')
    }

    render() {
        const {reportType} = this.state
        const {contentOptions, pmaxIndexes, transportEmails, panelIds, ...props} = this.props

        return (
            <ModalCardForm
                confirmOnDismiss
                wide={2}
                rules={rules}
                header={this.header()}
                onSubmit={this.onSubmit}
                {...props}
            >
                <Input
                    autoFocus
                    label={__('Report Name')}
                    name="name"
                />

                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Report Options')}>
                        <Select {...{
                            onChange: this.changeReportType,
                            value: reportType,
                            label: __('Report Type'),
                            name: 'schedule.pattern',
                        }}>
                            {
                                Object.keys(this.reportTypes).map((key) => (
                                    <Option {...{key, value: key, label: this.reportTypes[key]}} />
                                ))
                            }
                        </Select>

                        <Recurrence {...{reportType}}/>

                        {reportType !== 'onetime' && <RangeOfRecurrence/>}

                        <Schedule/>

                    </FormSection>

                    <FormSection className="col" title={__('Content Options')}>
                        <Content {...{contentOptions, pmaxIndexes, transportEmails, panelIds}}/>
                    </FormSection>
                </div>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({reports, auth: {sign: {user: {email}}}}) => ({
            isLoading: reports.contentOptions.isLoading || reports.pmaxIndexes.isLoading || reports.transportEmails.isLoading,
            contentOptions: reports.contentOptions.options,
            pmaxIndexes: reports.pmaxIndexes.indexes,
            transportEmails: reports.transportEmails.emails.includes(email)
                             ? reports.transportEmails.emails
                             : [email, ...reports.transportEmails.emails],
        }),

        dispatch => bindActionCreators({
            fetchContentOptions,
            fetchPmaxIndexes,
            fetchTransportEmails,
        }, dispatch),
    ),

    withModalLoader(({fetchContentOptions, fetchPmaxIndexes, fetchTransportEmails}) => {
        fetchPmaxIndexes()
        fetchContentOptions()
        fetchTransportEmails()
    }),

    withForm(createReport),
)(CreateReport)