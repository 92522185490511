import React, {PureComponent} from 'react'
import Definition from 'ipmp-react-ui/Definition'

export default class Soak extends PureComponent {

    renderRow({name, number}, index) {
        const zoneName = `${name} ${number}`

        return (
            <div key={index} className="rri-result-details-line">
                <Definition title={zoneName}/>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.props.rows.map(this.renderRow)}
            </div>
        )
    }
}
