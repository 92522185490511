import {handleActions} from 'redux-actions'

const generalDefaultState = {
    error: null,
    start: 0,
    page: [],
    sort: null,
    query: null,
    filters: [],
    total: null,
    isLoading: true,
    suggests: {},
}

const createReducers = defaultState => ({
    reset(state, {payload: {filters}}) {
        if (!filters) {
            return defaultState
        }

        return {
            ...defaultState,
            filters,
        }
    },

    setLoading(state, {payload}) {
        return {
            ...state,
            isLoading: payload == undefined ? true : !!payload,
        }
    },

    remove(state, {payload}) {
        return {
            ...state,
            oldPage: state.page,
            oldTotal: state.total,
            total: state.total && (state.total - payload.length),
            page: state.page.filter(id => !payload.includes(id)),
        }
    },

    setQuery(state, {payload}) {
        return {
            ...state,
            query: payload,
            start: 0,
        }
    },

    revertRemove(state) {
        if (!state.oldPage) {
            // page already changed
            return state
        }

        return {
            ...state,
            total: state.oldTotal,
            oldPage: null,
            oldTotal: null,
            page: state.oldPage,
        }
    },

    receive(state, {payload, error}) {
        if (error) {
            return {
                ...state,
                error: payload,
                oldPage: null,
                isLoading: false,
            }
        }

        return {
            ...state,
            ...payload,
            isLoading: false,
            oldPage: null,
            error: null,
        }
    },

    fetch(state) {
        return {
            ...state,
            isLoading: true,
        }
    },

    setStart(state, {payload: start}) {
        return {
            ...state,
            start,
            isLoading: true,
        }
    },

    setFilters(state, {payload}) {
        return {
            ...state,
            total: null,
            start: 0,
            filters: payload,
        }
    },

    clearFilters(state) {
        return {
            ...state,
            total: null,
            query: null,
            start: 0,
            filters: [],
        }
    },

    addFilters(state, {payload}) {
        const keys = payload.map(item => item.$)

        const filters = state.filters
            .filter(({$}) => !keys.includes($))
            .concat(payload)

        return {
            ...state,
            start: 0,
            filters,
        }
    },

    removeFilters(state, {payload}) {
        return {
            ...state,
            start: 0,
            filters: state.filters.filter(({$}) => !payload.includes($)),
        }
    },

    fetchSuggests(state, {payload: {fields, prefix}}) {
        return {
            ...state,
            suggests: fields.reduce(
                (acc, key) => {
                    return {
                        ...acc,
                        [key]: {
                            ...acc[key],
                            prefix,
                            isLoading: true,
                        },
                    }
                },
                state.suggests,
            ),
        }
    },

    receiveSuggests(state, {payload: {results, prefix}}) {
        return {
            ...state,
            suggests: Object.keys(results).reduce(
                (acc, key) => {
                    const {count, values} = results[key]

                    acc[key] = {
                        ...acc[key],
                        prefix,
                        isLoading: false,
                        values,
                        count,
                        hasMore: values.length < count,
                    }

                    if (!prefix) {
                        acc[key].total = count
                        acc[key].head = values
                        acc[key].isFull = values.length >= count
                    }

                    return acc
                },
                {...state.suggests},
            ),
        }
    },
})

export default function createListReducer(actions: Object, actionHandlers = {}, defaultState = {}) {
    defaultState = {
        ...generalDefaultState,
        ...defaultState,
    }

    const reducers = createReducers(defaultState)

    const handlers = Object.keys(actions).reduce(
        (acc, name) => {
            const action = actions[name]

            if (!reducers[name]) {
                return acc
            }

            return {
                ...acc,
                [action]: reducers[name],
            }
        }
        , {})

    const reducer = handleActions(handlers, defaultState)

    return (state, action) => {
        state = reducer(state, action)

        if (actionHandlers[action.type]) {
            state = actionHandlers[action.type](state, action)
        }

        return state
    }
}