import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
} from 'modules/batches/list/actions'
import {selectBatchesListRows} from 'modules/batches/list/selectors'

export default function withBatches() {
    return compose(
        withPerPage('batches'),
        connect(
            (state) => {
                const {isLoading, error, total, start} = state.batches.list

                return {
                    rows: selectBatchesListRows(state),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            (dispatch) => bindActionCreators({
                init,
                reset,
                setStart,
                fetch,
                onPageChange: setStart,
                startPoll,
                stopPoll,
            }, dispatch),
        ),
    )
}