import {handleActions} from 'redux-actions'

import {setLocale, fetch, receive} from './actions'

const defaultState = {
    list: [],
    error: null,
    isLoading: true,
}

export default handleActions({
    [fetch](state) {
        return {
            ...state,
            isLoading: true,
        }
    },

    [setLocale](state, {payload: locale}) {
        return {
            ...state,
            locale,
        }
    },

    [receive](state, {payload, error}) {
        if (error) {
            return {
                ...state,
                error: payload,
            }
        }

        return {
            ...state,
            isLoading: false,
            list: payload,
        }
    },
}, defaultState)