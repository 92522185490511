import {deviceTypesSectionName} from './deviceSectionName'

const regexps = Object.values(deviceTypesSectionName)
    .map(
        val => new RegExp(val.replace('%d', '\\d')),
    )

function _isChangesInsideSubtree(item, changes) {
    return item.val.some((node) => {
        if (node.type === 'menu') {
            return _isChangesInsideSubtree(node, changes)
        }

        return changes.hasOwnProperty(node.key)
    })
}

function _getChangedSections(acc, item, changes) {
    for (let node of item.val) {
        if (node.type !== 'menu') {
            continue
        }

        regexps.forEach(regexp => {
            const match = regexp.exec(node.key)

            if (!match) {
                return
            }

            if (_isChangesInsideSubtree(node, changes)) {
                acc[node.key] = true
            }
        })

        _getChangedSections(acc, node, changes)
    }

    return acc
}

export default function getChangedDeviceSections(item, changes) {
    if (!changes || !Object.keys(changes).length) {
        return {}
    }

    return _getChangedSections({}, item, changes)
}