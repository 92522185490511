import {createSelector} from 'reselect'


export const selectApplianceTypeAndPackageName = createSelector(
    (state, {match: {params: {applianceId}}}) => (
        state.firmware.store.appliances.byIds[applianceId]
    ),
    (state, {match: {params: {upgradePackageId}}}) => (
        state.firmware.store.upgradePackages.byIds[upgradePackageId]

    ),
    (appliance, upgradePackage) => {
        const isAvailableApplianceType = appliance
            && appliance.hasOwnProperty('type')
        const applianceType = isAvailableApplianceType
            ? appliance['type']
            : null
        const isAvailablePackageName = upgradePackage
            && upgradePackage.hasOwnProperty('name')
        const packageName = isAvailablePackageName
            ? upgradePackage.name
            : null

        return {
            applianceType,
            packageName,
        }
    },
)