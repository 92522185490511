import {err} from 'utils/log'

export default function createPurgeStoreHandler(
    purgeAction
) {
    return {
        [purgeAction](state, {payload: ids}) {
            const byIds = {...state.byIds}

            try {
                Object.values(byIds)
                    .forEach(({id}) => {
                        if (!ids.includes(id)) {
                            delete byIds[id]
                        }
                    })
            } catch (error) {
                err(`Somthing went wrong in purge store for action ${purgeAction.toString()}: ${error.message} ${error.stack}`)
                console.trace()
            }

            return {
                ...state,
                byIds,
            }
        },
    }
}