import {compose} from 'redux'
import get from 'lodash-es/get'

import {retry} from 'permissions/firmware/upgradeStatus'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withUpgradeStatusRunners from 'containers/withUpgradeStatusRunners'
import {withSelectionHandler} from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: retry,
    }),
    withVisibility(),
    withUpgradeStatusRunners(),
    withProps(({retry}) => ({
        label: __('Retry'),
        onClick: retry,
    })),
    withSelectionHandler((runnerId, {runners}) => (
        get(runners, ['store', 'byIds', runnerId, 'isFailed'], false)
    )),
)(Button)
