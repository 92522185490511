import {
    createAction
} from 'redux-actions'

import {
    fetchReportPmaxIndexes
} from 'api/reports'

export const setLoading = createAction('REPORTS/PMAX_INDEXES/SET_LOADING', (isLoading = true) => ({isLoading}))
export const persist = createAction('REPORTS/PMAX_INDEXES/PERSIST', (data) => ({data}))

export function fetch() {
    return dispatch => {
        dispatch(setLoading())

        return fetchReportPmaxIndexes().then(({rows}) => {
            dispatch(persist(rows))
            dispatch(setLoading(false))
        })
    }
}