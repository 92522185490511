import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import IconEdit from 'ipmp-react-ui/icons/edit.svg'

import Button from 'ipmp-react-ui/Button'
import {showEditUserModal} from 'modules/modals/actions'

export default connect(
    () => ({
        flat: true,
        small: true,
        primary: true,
        Icon: IconEdit,
    }),
    (dispatch, {row: {id}}) => bindActionCreators({
        onClick: () => showEditUserModal(id),
    }, dispatch),
)(Button)