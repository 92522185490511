import {withVisibility} from 'containers/withVisibility'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    setConfigurationAppState,
    setUserAppState,
} from 'modules/panels/one/actions'

import {withPermission, withPermissionProp} from 'containers/withPermission'

import withProps from 'containers/withProps'
import apps, {user, configurator} from 'permissions/panels/apps'

import Togglebox from 'ipmp-react-ui/ToggleBox'
import DropDown, {
    ALIGN_BOTTOM, DropDownContent,
} from 'ipmp-react-ui/DropDown'
import Button from 'ipmp-react-ui/Button'
import AppIcon from 'ipmp-react-ui/icons/app.svg'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectUserAppFeature, selectInstallerAppFeature} from 'modules/panels/store/selectors'

export const AppToggle = compose(
    withPermissionProp(),
    withProps(
        ({isPermitted, disabled}) => ({
            disabled: disabled ? disabled : !isPermitted,
        }),

        ({isPermitted, permission, ...rest}, props) => ({
            ...rest,
            ...props,
        }),
    ),
)(Togglebox)

const AppToggleUserApp = compose(
    withFeatureRejection(selectUserAppFeature),
    withProps(() => ({
        label: __('User App'),
    }))
)(AppToggle)

const AppToggleInstallerApp = compose(
    withFeatureRejection(selectInstallerAppFeature),
    withProps(() => ({
        label: __('Installer App'),
    }))
)(AppToggle)

class ApplicationInteractiveDropdown extends Component {
    static propTypes = {
        panel: PropTypes.object.isRequired,
        trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    }

    static defaultProps = {
        trigger: ({active}) => <Button active={active}><AppIcon className="applications-icon"/>{__('Interactive')}
        </Button>,
        align: ALIGN_BOTTOM,
    }

    handleUserAppChange = (e) => {
        const {setUserAppState, panel} = this.props
        setUserAppState(panel, e.target.checked)
    }

    handleInstallerAppChange = (e) => {
        const {setConfigurationAppState, panel} = this.props
        setConfigurationAppState(panel, e.target.checked)
    }

    render() {
        const {panel, trigger, align} = this.props

        return (
            <DropDown trigger={trigger} align={align}>
                <DropDownContent>
                    <AppToggleUserApp
                        panelId={panel.id}
                        permission={user}
                        onChange={this.handleUserAppChange}
                        checked={panel.userApp}
                    />

                    <AppToggleInstallerApp
                        panelId={panel.id}
                        permission={configurator}
                        onChange={this.handleInstallerAppChange}
                        checked={panel.configuratorApp}
                    />
                </DropDownContent>
            </DropDown>
        )
    }
}

export default compose(
    withPermission({isVisible: apps}),
    withVisibility(),
    connect(
        null,
        (dispatcher) => bindActionCreators({
            setUserAppState,
            setConfigurationAppState,
        }, dispatcher),
    ),
)(ApplicationInteractiveDropdown)