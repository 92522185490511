import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {review, stop} from 'modules/reports/store/actions'
import {showCreateReportModal} from 'modules/modals/actions'
import {init, setStart, remove, reset, startPoll, stopPoll} from 'modules/reports/list/actions'
import {selectReportsListRows} from 'modules/reports/list/selectors'

export default function withReports() {
    return compose(
        withPerPage('reports'),
        connect(
            state => {
                const {isLoading, error, start, total} = state.reports.list

                return {
                    isLoading,
                    error,
                    start,
                    total,
                    rows: selectReportsListRows(state),
                }
            },
            dispatch => bindActionCreators({
                init,
                onPageChange: setStart,
                remove,
                reset,
                stop,
                review,
                showCreateReportModal,
                startPoll, stopPoll,
            }, dispatch),
        ),
    )
}