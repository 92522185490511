import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
} from 'modules/processes/list/actions'
import {stop} from 'modules/processes/store/actions'
import {selectProcessesListRows} from 'modules/processes/list/selectors'

export default function withProcesses() {
    return compose(
        withPerPage('processes'),
        connect(
            (state) => {
                const {isLoading, error, total, start} = state.processes.list

                return {
                    rows: selectProcessesListRows(state),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            (dispatch) => bindActionCreators({
                init,
                reset,
                setStart,
                fetch,
                onPageChange: setStart,
                stop,
                startPoll,
                stopPoll,
            }, dispatch),
        ),
    )
}