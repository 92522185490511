import {compose} from 'redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import {reassignService} from 'modules/forms/handlers'
import MaintainForm from './MaintainForm'

import {__n} from 'utils/i18n'

export default compose(
    withForm(reassignService),
    withProps(({panelIds}) => ({
        header: __n('Reassign %s panel for service', 'Reassign %s panels for service', panelIds.length),
    })),
)(MaintainForm)