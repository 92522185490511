import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import SelectBroker from './SelectBroker'
import {setSystemSettings} from 'modules/forms/handlers'


class CellularConnectedSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            isAutoEnrollmentEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
            retriesMax: PropTypes.number,
            retryInterval: PropTypes.number,
            wakeupOnUserDiscovery: PropTypes.bool,
        }),
    }

    get rules() {
        return {
            messageBroker: {},
            retriesMax: {
                presence: {
                    message: __('You should define Number of Retries'),
                },
                numericality: {
                    onlyInteger: true,
                },
            },
            retryInterval: {
                presence: {
                    message: __('You should define Retries Interval'),
                },
                numericality: {
                    onlyInteger: true,
                },
            },
        }
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Cellular Connected Settings')}
                confirmOnDismiss
                {...props}
            >
                <SelectBroker
                    label={__('Message Broker for WakeUp')}
                    name="messageBroker"
                    defaultValue={data.messageBroker}
                />

                <Input
                    label={__('Number of Retries')}
                    name="retriesMax"
                    defaultValue={data.retriesMax}
                />

                <Input
                    label={__('Retries Interval [seconds]')}
                    name="retryInterval"
                    defaultValue={data.retryInterval}
                />

                <Checkbox
                    label={__('Enable Auto-Enroll')}
                    name="isAutoEnrollmentEnabled"
                    defaultChecked={data.isAutoEnrollmentEnabled}
                />

                <Checkbox
                    label={__('WakeUp panel on user initiated discovery')}
                    name="wakeupOnUserDiscovery"
                    defaultChecked={data.wakeupOnUserDiscovery}
                />
            </ModalCardForm>
        )
    }
}


export default compose(
    withForm(setSystemSettings.CellularConnected)
)(CellularConnectedSettings)
