import React, {Component, Fragment} from 'react'
import ProptTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'
import Input from 'ipmp-react-ui/Input'
import MultiSelect from 'ipmp-react-ui/MultiSelect'
import {Option} from 'ipmp-react-ui/Select'

import {__} from 'utils/i18n'
import {FormSection} from 'ipmp-react-ui/Form'

export default class extends Component {
    static propTypes = {
        transportEmails: ProptTypes.array.isRequired,
    }

    state = {
        isSendEmail: false,
        isSendFtp: false,
    }

    toggleSendEmail = () => {
        return this.setState(({isSendEmail}) => ({
            isSendEmail: !isSendEmail,
        }))
    }

    toggleSendFtp = () => {
        return this.setState(({isSendFtp}) => ({
            isSendFtp: !isSendFtp,
        }))
    }

    render() {
        const {isSendEmail, isSendFtp} = this.state
        const {transportEmails} = this.props

        return (
            <div>
                <FormSection>
                    <Checkbox {...{
                        onChange: this.toggleSendEmail,
                        checked: isSendEmail,
                        name: 'sendTo',
                        value: 'email',
                    }}>
                        {__('Send email')}
                    </Checkbox>

                    {isSendEmail &&
                    <MultiSelect maxSelectOptions={10}
                                 maxOptionsToShow={9}
                                 isCreatable {...{label: __('Destination Email'), name: 'destinationEmails'}}>
                        {transportEmails && transportEmails.map(
                            (email, i) => <Option key={i} label={email} value={email}/>,
                        )}
                    </MultiSelect>}
                </FormSection>

                <FormSection>
                    <Checkbox {...{onChange: this.toggleSendFtp, checked: isSendFtp, name: 'sendTo', value: 'ftp'}}>
                        {__('Send to FTP Server')}
                    </Checkbox>

                    {isSendFtp && <Fragment>
                        <Input {...{label: __('Destination FTP server address'), name: 'destinationFtp'}} />
                        <small>{__('Example')}: ftp://user:password@host:port/path</small>
                    </Fragment>}

                </FormSection>
            </div>
        )
    }
}