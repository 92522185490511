import {createSelector} from 'reselect'

export const selectWidgetBatches = createSelector(
    state => state.batches.store.byIds,
    state => state.batches.widget.ids,
    state => state.batches.widget.tempIds,
    (byIds, widgetIds, tempIds) => tempIds.concat(widgetIds).map(id => byIds[id]),
)

export const selectBatchIsTemporary = createSelector(
    state => state.batches.widget.tempIds,
    (_, batchId) => batchId,
    (tempIds, batchId) => tempIds.includes(batchId),
)
