import {select, put} from 'redux-saga/effects'
import isEqual from 'lodash-es/isEqual'

import * as actions from './actions'

/**
 * use that coroutine to emit updating rows only if something really changes
 * best for poller updates
 *
 * @param rows
 */
export function* updateRows(rows) {
    const byIds = yield select(state => state.events.store.byIds)

    const toUpdate = rows.filter(
        row => {
            return !isEqual(row, byIds[row.id])
        },
    )

    if (toUpdate.length) {
        yield put(actions.update(toUpdate))
    }
}