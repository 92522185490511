import {compose} from 'redux'
import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/events/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import EventPager from './GridBar/EventPager'
import MarkAsViewedButton from './GridBar/MarkAsViewedButton'
import EventDropDown from './GridBar/EventDropDown'
import StreamNewEventsButton from './GridBar/StreamNewEventsButton'
import LoadNewEventsButton from './GridBar/LoadNewEventsButton'

const EventsGridBar = () => {
    return (
        <Bar>
            <EventDropDown />
            <MarkAsViewedButton />
            <BarSpace/>
            <LoadNewEventsButton />
            <BarSpace/>
            <EventPager />
            <StreamNewEventsButton />
        </Bar>
    )
}

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(EventsGridBar)