import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import IconTimer from 'ipmp-react-ui/icons/timer.svg'
import IconTimerOff from 'ipmp-react-ui/icons/timer-off.svg'

export default class ScheduleOrCancelRRI extends PureComponent {

    static propTypes = {
        panelId: PropTypes.number.isRequired,
        showScheduleRri: PropTypes.func.isRequired,
        next: PropTypes.object,
    }

    handleClick = (e) => {
        e.stopPropagation()
        const {panelId, showScheduleRri} = this.props
        showScheduleRri([panelId])
    }

    render() {
        const {next} = this.props
        const Icon = (next === null) ? IconTimerOff : IconTimer

        return (
            <a className="rri-action" onClick={this.handleClick}>
                <Icon className="rri-action-icon"/>
            </a>
        )
    }
}