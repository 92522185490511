import {alarmIcon, alarmTitle} from 'constants/alarmType'
import {troubleIcon, troubleTitle} from 'constants/troubleType'
import {__} from 'utils/i18n'

export const SEVERITY_ALARM = 'ALARM'
export const SEVERITY_ALERT = 'ALERT'
export const SEVERITY_TROUBLE = 'TROUBLE'

export function severityIcon({severity, type, zoneType}) {
    if (severity === SEVERITY_ALARM) {
        return alarmIcon(type, zoneType)
    }

    return troubleIcon(type, severity !== SEVERITY_TROUBLE)
}

export function severityTitle({severity, type, zoneType, inMemory}) {
    const appendix = inMemory ? ` / ${__('in memory')}` : ''

    if (severity === SEVERITY_ALARM) {
        return alarmTitle(type, zoneType) + appendix
    }

    return troubleTitle(type, severity !== SEVERITY_TROUBLE) + appendix
}