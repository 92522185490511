import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter as Router} from 'react-router-redux'
import {connect} from 'react-redux'

import SnackBarComponent from 'ipmp-react-ui/SnackBar'

import {selectWidgetBatches} from 'modules/batches/widget/selectors'
import {hide} from 'modules/snacks'
import {
    untrack,
    untrackFinished,
} from 'modules/batches/widget/actions'
import {
    showEditProfileInfoModal,
    showChangePasswordModal,
    showEditSettingsModal,
    showAboutModal,
} from 'modules/modals/actions'
import {logout} from 'modules/auth/sign/actions'
import Login from 'pages/App/Login'
import path from 'utils/path'
import withLoader from 'containers/withLoader'
import pages from 'pages/routes'

import UserInfoComponent from 'components/UserInfo'
import ProcessBatches from 'components/Processes/ProcessBatches'
import NavMain from 'components/Nav/NavMain'
import ModalsContainer from 'modals/ModalsContainer'
import Logo from 'ui/Logo'
import BatchInfo from 'pages/App/BatchInfo'

const SnackBar = connect(
    ({snacks}) => ({snacks}),
    dispatch => bindActionCreators({
        hide,
        dispatch,
    }, dispatch)
)(SnackBarComponent)

const UserInfo = connect(
    (({auth: {sign: {user}}}) => ({user})),
    dispatch => bindActionCreators({
        showEditProfileInfoModal,
        showChangePasswordModal,
        showEditSettingsModal,
        logout: () => logout(),
    }, dispatch),
)(UserInfoComponent)

const Processes = compose(
    connect(
        state => ({
            batches: selectWidgetBatches(state),
        }),
        dispatcher => bindActionCreators({
            untrack,
            untrackFinished,
        }, dispatcher)
    ),
)(ProcessBatches)

class Layout extends PureComponent {
    static propTypes = {
        showAboutModal: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired,
        isLogged: PropTypes.bool.isRequired,
        history: PropTypes.object,
    }

    state = {
        isOpenedProcessBatchInfo: false,
        batchId: null,
    }

    appElement = null
    navElement = null
    processElement = null
    batchInfoRefHandler = null

    openProcessBatchInfo = (batchId) => this.setState({
        isOpenedProcessBatchInfo: true,
        batchId,
    })

    closeProcessBatchInfo = () => this.setState({
        isOpenedProcessBatchInfo: false,
        batchId: null,
    })

    handleAppRef = (appElement) => {
        this.appElement = appElement

        if (this.appElement) {
            this.appElement.addEventListener('click', this.handleAppClick, true)
        }
    }

    navRefHandler = (navElement) => this.navElement = navElement

    processRefHandler = (processElement) => this.processElement = processElement

    batchInfoRefHandler = (batchInfoElement) => this.batchInfoElement = batchInfoElement

    componentWillUnmount() {
        if (this.appElement) {
            this.appElement.removeEventListener('click', this.handleAppClick, true)
        }
    }

    handleAppClick = (event) => {
        if (!this.navElement || !this.processElement) {
            return
        }

        const isClickOnBatchInfo = this.batchInfoElement
            && (
                this.batchInfoElement.contains(event.target)
                || (this.batchInfoElement === event.target)
            )
        const isClickOnNavigation = this.navElement.contains(event.target)
        const isClickOnMyProcesses = this.processElement.contains(event.target)

        switch (true) {
            case isClickOnBatchInfo:
                break
            case isClickOnNavigation:
                this.closeProcessBatchInfo()
                break
            case isClickOnMyProcesses:
                break
            case this.state.isOpenedProcessBatchInfo:
                event.stopPropagation()
                this.closeProcessBatchInfo()
                break
            default:
                this.closeProcessBatchInfo()
                break
        }
    }

    render() {
        const {showAboutModal, locale, isLogged, history} = this.props
        const {isOpenedProcessBatchInfo, batchId} = this.state

        if (!isLogged) {
            return <Login><SnackBar/></Login>
        }

        return (
            <Router history={history} key={locale}>
                <div className="app" ref={this.handleAppRef}>
                    <UserInfo/>

                    <aside className="app-aside">
                        <div className="app-aside-logo" onClick={showAboutModal}>
                            <Logo multiline/>
                        </div>

                        <div className="app-aside-content">
                            <NavMain navRefHandler={this.navRefHandler} />
                            <Processes
                                batchId={batchId}
                                processRefHandler={this.processRefHandler}
                                showInfo={this.openProcessBatchInfo}
                                hide={this.closeProcessBatchInfo}
                            />
                        </div>
                    </aside>

                    {isOpenedProcessBatchInfo &&
                        <BatchInfo batchInfoRefHandler={this.batchInfoRefHandler}
                            hide={this.closeProcessBatchInfo}
                            batchId={batchId}
                        />
                    }

                    <Switch>
                        <Route exact path={path('/')} render={() => (
                            <Redirect to={path('panels', {})}/>
                        )}/>
                        {pages.map((page, i) => <Route key={i} {...page}/>)}
                    </Switch>

                    <SnackBar/>

                    <ModalsContainer/>
                </div>
            </Router>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}, locales}) => ({
            locale: locales.locale,
            isLogged: !!sign.user,
            isLoading: !sign.checked,
        }),

        dispatch => bindActionCreators({showAboutModal}, dispatch),
    ),
    withLoader(),
)(Layout)
