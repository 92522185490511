import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS} from 'constants/moduleTypes'
import {handleActions} from 'redux-actions'
import get from 'lodash-es/get'

import {
    update,
    clear,
    setRefreshing,
    setActivationStatus,
    setActivationFailed,
    setActivationSuccess,
    setEncryptionState,
    purge
} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [update](state, {payload}) {
        const byIds = payload.reduce((acc, row) => {
            const old = acc[row.id]

            const panel = {
                ...old,
                ...row,
                info: {
                    ...get(old, 'info', {}),
                    ...get(row, 'info', {}),
                },
            }

            return {
                ...acc,
                [row.id]: panel,
            }
        }, state.byIds || {})

        return {
            ...state,
            byIds,
        }
    },

    [setActivationStatus](state, {payload}) {
        const {panelId, isActivating} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isFailed: false,
                    isActivating: isActivating,
                },
            },
        }
    },

    [setActivationSuccess](state, {payload}) {
        const {panelId} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isActivated: true,
                    isActivating: false,
                },
            },
        }
    },

    [setActivationFailed](state, {payload}) {
        const {panelId, error} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isFailed: true,
                    isActivating: false,
                    activationError: error,
                },
            },
        }
    },

    [setRefreshing](state, {payload: {isRefreshing}}) {
        return {
            ...state,
            isRefreshing,
        }
    },

    [clear]() {
        return defaultState
    },

    [setEncryptionState](state, {payload}) {
        const {id: panelId, channel, encrypted} = payload

        const set = (module, type) => type === channel ? {...module, encrypted} : module

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    modules: {
                        bba: set(state.byIds[panelId].modules.bba, MODULE_TYPE_BBA),
                        gprs: set(state.byIds[panelId].modules.gprs, MODULE_TYPE_GPRS),
                    },
                },
            },
        }
    },
    ...createPurgeStoreHandler(purge),
}, defaultState)