import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import IconDropDown from 'ipmp-react-ui/icons/drop-down.svg'
import SlideDown from 'ipmp-react-ui/SlideDown'

import {
    activeFaultsTest,
    bypassedTest,
    frequentlyUsedTest,
    reportedFaultsTest,
    totalSystemUsageTest,
    soakTest,
    clockTest,
} from 'api/remoteInspectionResult'


import FrequentlyUsed from './Detailed/FrequentlyUsed'
import TimeFaults from './Detailed/TimeFaults'
import Bypassed from './Detailed/Bypassed'
import Soak from './Detailed/Soak'

const map = {
    [activeFaultsTest]: TimeFaults,
    [frequentlyUsedTest]: FrequentlyUsed,
    [reportedFaultsTest]: TimeFaults,
    [bypassedTest]: Bypassed,
    [totalSystemUsageTest]: TimeFaults,
    [soakTest]: Soak,
}

const resultFromInfoKeys = [
    clockTest,
]

export default class extends Component {

    static propTypes = {
        test: PropTypes.string,
        state: PropTypes.string,
        range: PropTypes.string,
        result: PropTypes.string,
    }

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state = {active: props.state === 'fail'}
    }

    toggle = () => {
        this.setState(({active}) => ({active: !active}))
    }

    renderHeader() {
        const {test, state, range} = this.props

        const className = classes('rri-result-title', {
            'rri-result-title--passed': state === 'pass',
            'rri-result-title--failed': state === 'fail',
            'rri-result-title--unavailable': state === 'not_available',
        })

        return (
            <div className="rri-result-header" onClick={this.toggle}>
                <div className="rri-result-header-toggle">
                    <IconDropDown className={classes('rri-result-header-toggle-icon', {
                        'rri-result-header-toggle-icon--active': this.state.active,
                    })}/>
                </div>

                <h3 {...{className}}>{test}</h3>
                {range && <span className="rri-result-range" children={range}/>}
            </div>
        )
    }

    renderTestResultList() {
        const {detailed, testTypeId} = this.props

        if (!map[testTypeId]) {
            return null
        }

        const Formatted = map[testTypeId]
        return Formatted ? <Formatted rows={detailed}/> : null
    }

    renderResult() {
        const {detailed} = this.props

        return (
            <div className="rri-result-content">
                {detailed && detailed.length ? this.renderTestResultList() : this.getResultValue()}
            </div>
        )
    }

    getResultValue() {
        const {testTypeId, result, info} = this.props

        return (
            <div>
                {resultFromInfoKeys.includes(testTypeId) && info ? info : result}
            </div>
        )
    }

    render() {
        const {active} = this.state

        return (
            <div className="rri-result">
                {this.renderHeader()}

                <SlideDown defaultStateAnimated={false}>
                    {active && this.renderResult()}
                </SlideDown>
            </div>
        )
    }
}