import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showFirmwareUpgradeModal} from 'modules/modals/actions'
import {upgrade as upgradePermission} from 'permissions/panel/firmware/actions'

import Button from 'ipmp-react-ui/Button'

import {withRouterPanelId} from 'containers/withPanel'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: upgradePermission,
    }),
    withVisibility(),
    withRouterPanelId(),
    connect(
        null,
        (dispatch, {panelId, packages}) => bindActionCreators({
            onClick: () => showFirmwareUpgradeModal({panelId, packages}),
        }, dispatch),
    ),
    withProps(({packages}) => ({
        children: __('Upgrade'),
        disabled: !packages.length,
    })),
)(Button)