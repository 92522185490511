import dataMapper from 'utils/dataMapper'
import parseDate from './base/parseDate'
import {RUNNERS_STARTED_STATUS, RUNNERS_HANDLED_STATUS, RUNNERS_NOT_STARTED_STATUS, RUNNERS_SUCCEEDED_STATUS, RUNNERS_FAILED_STATUS, RUNNERS_FAILED_TO_START_STATUS, isStoppable} from 'constants/runnerStatuses'

export const keysMap = {
    started: 'runner_created_timestamp',
    serial: '_unt_serial',
    status: 'runner_status',
    group: '_utg_name',
    changed: 'prs_changed_timestamp',
}

export const mapRunners = dataMapper(data => ({
    batchId: data.batch_id,
    changed: parseDate(data.prs_changed_timestamp),
    processStarted: parseDate(data.prs_created_timestamp),
    description: data.prs_description,
    details: data.prs_details,
    error: data.prs_error,
    errorMessage: data.runner_error,
    finished: parseDate(data.prs_finished_timestamp),
    processId: parseInt(data.prs_id),
    percentage: parseInt(data.prs_percentage),
    reconsiderationCondition: data.prs_reconsideration_condition,
    processStatus: data.prs_status,
    processType: data.prs_type,
    started: parseDate(data.runner_created_timestamp),
    id: parseInt(data.runner_id),
    status: data.runner_status,
    type: data.runner_type,
    params: data.runner_params,
    panelId: parseInt(data.unt_id),
    serial: data._unt_serial,
    userId: parseInt(data.usr_id),
    isAvailable: data.__prs_is_available,
    groupe: data._utg_name,
    isNotRunned: data.runner_status === RUNNERS_NOT_STARTED_STATUS,
    isStoppable: isStoppable(data.runner_status),
    isRunning: data.runner_status === RUNNERS_STARTED_STATUS
        || data.runner_status === RUNNERS_HANDLED_STATUS,
    isSuccessful: data.runner_status === RUNNERS_SUCCEEDED_STATUS,
    isFailed: (data.runner_status === RUNNERS_FAILED_STATUS) || (data.runner_status === RUNNERS_FAILED_TO_START_STATUS),
}))