import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {general} from 'permissions/group/navigation'

import {ScrollView} from 'ipmp-react-ui/Layout'
import List, {ListHintItem} from 'ipmp-react-ui/List'

import PowerMaster from './Communications/PowerMaster'
import Quazar from './Communications/Quazar'
import Quazar53 from './Communications/Quazar53'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'

import {__} from 'utils/i18n'

export class General extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
    }


    renderPowerMaster() {
        const {group} = this.props
        if (group.communications.pmaster) {
            return (
                <div>
                    <h1 className="card-header">
                        {__('PowerMaster/PowerMax')}
                    </h1>
                    <PowerMaster settings={group.communications.pmaster}
                                 isEncryptionEnabled={group.BBAEncryption}
                                 pscConnection={group.pscConnection}
                    />
                </div>
            )
        }
        return null
    }

    renderQuazar() {
        const {group} = this.props
        if (group.communications.quazar) {
            return (
                <div>
                    <h3 className="card-header">
                        {__('PowerSeries NEO/Pro, Quazar <5.3')}
                    </h3>
                    <Quazar settings={group.communications.quazar}/>
                </div>
            )
        }
        return null
    }

    renderQuazar53() {
        const {group} = this.props
        if (group.communications.quazar) {
            return (
                <div>
                    <h3 className="card-header">
                        {__('PowerSeries NEO, Quazar 5.3 and higher')}
                    </h3>
                    <Quazar53 settings={group.communications.quazar53}/>
                </div>
            )
        }
        return null
    }

    render() {
        const {group} = this.props

        const empty = (
            <span className="empty">
                {__('not set')}
            </span>
        )
        return (
            <div className="groupInfo">
                <div className="card">
                    <ScrollView>
                        <List className="card-content" twoCols>
                            <ListHintItem name={__('Group Name')} text={group.name}/>
                            <ListHintItem name={__('Description')} text={group.description || empty}/>


                            <ListHintItem name={__('Server Messaging Language')}
                                          text={group.serverMessagingLanguage}/>
                            <ListHintItem name={__('Local Wake up')}
                                          text={group.localWakeUp ? __('Enabled') : __('Disabled')}/>

                            <ListHintItem name={__('Video Format')}
                                          text={group.videoFormatName}/>


                            <ListHintItem name={__('Upgrade Method')}
                                          text={group.upgradeMethod === 'gprs' ? __('GPRS') : __('Broadband')}/>
                            <ListHintItem name={__('Time Synchronization')}
                                          text={group.timeSynchronization ? __('Enabled') : __('Disabled')}/>
                        </List>
                        {this.renderPowerMaster()}
                        {this.renderQuazar()}
                        {this.renderQuazar53()}
                    </ScrollView>
                </div>
            </div>
        )
    }
}

export default compose(
    withPermission({isAllowed: general}),
    withRejection(),
)(General)