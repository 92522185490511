import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import PanelLink from 'components/PanelLink'
import stopPropagation from 'utils/stopPropagation'

export default class PanelCell extends PureComponent {

    static propTypes = {
        id: PropTypes.number,
        serial: PropTypes.string,
        account: PropTypes.string,
        className: PropTypes.string,
        to: PropTypes.string,
    }

    static defaultProps = {
        to: 'devices',
    }

    render() {
        const {id, serial, account, to, className, ...other} = this.props

        const props = {
            ...other,
            title: serial,
            detail: account,
        }

        return (
            <PanelLink className={className} to={to} id={id} onClick={stopPropagation}>
                <Definition {...props}/>
            </PanelLink>
        )
    }
}