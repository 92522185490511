import React, {PureComponent} from 'react'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import {editRemoteInspectionValues} from 'modules/forms/handlers'
import {fetch} from 'modules/remoteInspections/values/actions'
import Select, {Option} from 'ipmp-react-ui/Select'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withModalLoader from 'containers/withModalLoader'

const allowedValues = Object.freeze({
    reportedFaultsOption: ['10', '20', '30'],
    systemStateOption: ['40', '60', '120'],
    frequentlyUsedZonesOption: ['1', '2', '5'],
    treatAdjustedDateOption: ['15', '30', '45'],
})

const rules = {
    reportedFaultsOption: {
        presence: true,
        inclusion: allowedValues.reportedFaultsOption,
    },
    systemStateOption: {
        presence: true,
        inclusion: allowedValues.systemStateOption,
    },
    frequentlyUsedZonesOption: {
        presence: true,
        inclusion: allowedValues.frequentlyUsedZonesOption,
    },
    treatAdjustedDateOption: {
        presence: true,
        inclusion: allowedValues.treatAdjustedDateOption,
    },
}

class RemoteInspectionValues extends PureComponent {

    state = {}

    static propTypes = {
        data: PropTypes.shape({
            reportedFaultsOption: PropTypes.string.isRequired,
            systemStateOption: PropTypes.string.isRequired,
            frequentlyUsedZonesOption: PropTypes.string.isRequired,
            treatAdjustedDateOption: PropTypes.string.isRequired,
        }),
        editable: PropTypes.bool,
        isLoading: PropTypes.bool,
        handle: PropTypes.func,
    }

    render() {
        const {handle, data, editable, ...props} = this.props

        return (
            <ModalCardForm
                wide
                editable={editable}
                onSubmit={handle}
                header={__('Remote Inspection Values')}
                rules={rules}
                {...props}
            >
                <Select
                    disabled={!editable}
                    name="reportedFaultsOption"
                    label={__('Check for reported faults in last')}
                    defaultValue={data.reportedFaultsOption}
                >
                    {allowedValues.reportedFaultsOption.map((value, key) =>
                        <Option key={key} value={value} label={__('%d faulted events', value)}/>,
                    )}
                </Select>

                <Select
                    disabled={!editable}
                    name="systemStateOption"
                    label={__('Total System States in last')}
                    defaultValue={data.systemStateOption}
                >
                    {allowedValues.systemStateOption.map((value, key) =>
                        <Option key={key} value={value} label={__('%d reports', value)}/>,
                    )}
                </Select>

                <Select
                    disabled={!editable}
                    name="frequentlyUsedZonesOption"
                    label={__('Check frequently used zones not used over:')}
                    defaultValue={data.frequentlyUsedZonesOption}
                >
                    {allowedValues.frequentlyUsedZonesOption.map((value, key) =>
                        <Option key={key} value={value} label={__('%d week', value)}/>,
                    )}
                </Select>

                <Select
                    disabled={!editable}
                    name="treatAdjustedDateOption"
                    label={__('Treat Adjusted Date / time as failure if adjustment is over')}
                    defaultValue={data.treatAdjustedDateOption}
                >
                    {allowedValues.treatAdjustedDateOption.map((value, key) =>
                        <Option key={key} value={value} label={__('%d minutes', value)}/>,
                    )}
                </Select>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({remoteInspections}) => remoteInspections.values,
        (dispatch) => bindActionCreators({
            fetch,
        }, dispatch),
    ),
    withModalLoader(({fetch}) => fetch()),
    withForm(editRemoteInspectionValues),
)(RemoteInspectionValues)

