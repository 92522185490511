import {compose} from 'redux'

import list from 'permissions/remoteInspections/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspections from 'containers/withRemoteInspections'

import Pager from 'ipmp-react-ui/Pager'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withRemoteInspections(),
)(Pager)