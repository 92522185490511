export function getDependencyKeys(item, acc = {}) {
    if (!item || !item.val) {
        return {}
    }

    item.val.forEach((node) => {
        if (node.type === 'menu') {
            getDependencyKeys(node, acc)
        }

        if (node.dependency) {
            node.dependency.forEach(
                ({key}) => acc[key] = true,
            )
        }
    })

    return acc
}

function _finder(item, dependencyValues, result = []) {
    if (!item || !item.val) {
        return result
    }

    item.val.forEach((node) => {
        if (node.type === 'menu') {
            _finder(node, dependencyValues, result)
        }

        if (!node.dependency) {
            return
        }

        const isResolved = node.dependency.some(
            ({key, val}) => dependencyValues[key] === val,
        )

        if (!isResolved) {
            result.push(node.key)
        }
    })

    return result
}

export default function findNotResolvedKeys(configuration, dependencyValues) {
    return _finder(configuration, dependencyValues)
}
