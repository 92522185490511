import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {fetch} from 'modules/devices/meteo/actions'
import {selectDeviceBrightness, selectDeviceTemperature} from 'modules/devices/meteo/selectors'
import {TEMPERATURE} from 'constants/meteoTypes'

export default function withDeviceMeteo() {
    return connect(
        (state, {type, panelId, deviceId, range: {from, to}}) => {
            const props = {
                panelId,
                deviceId,
                from,
                to,
            }

            return type === TEMPERATURE
                ? selectDeviceTemperature(state, props)
                : selectDeviceBrightness(state, props)
        },
        (dispatch, {type, panelId, deviceId}) => bindActionCreators({
            fetch: () => fetch(panelId, deviceId, type),
        }, dispatch),
    )
}