import createPermission, {any} from 'permissions/createPermission'

import list from './list'

export const remove = createPermission('system.centralStations.remove')

export default createPermission(
    list,
    any(
        remove,
    ),
)