import BoardFilter from 'components/Search/Board/BoardFilter'
import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/Search/Filters/Filter'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import {Option} from 'ipmp-react-ui/Select'
import {__} from 'utils/i18n'

export default class BoardSuggestFilter extends BoardFilter {

    static propTypes = {
        filter: PropTypes.instanceOf(Filter),
        selected: PropTypes.array.isRequired,
        onSuggest: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
        suggest: PropTypes.shape({
            isLoading: PropTypes.bool,
            count: PropTypes.number,
            total: PropTypes.number,
            values: PropTypes.array,
            head: PropTypes.array,
        }),
    }

    state = {}

    getFastItems(fastItemsCount) {
        const {filter, suggest} = this.props
        return filter.getItems(suggest && suggest.head && suggest.head.slice(0, fastItemsCount))
    }

    handleType = (e) => {
        const {onSuggest, filter} = this.props
        const {value} = e.target
        value && onSuggest(filter.name, value)
        this.setState({prefix: value})
    }

    renderAutocomplete(fastItemsCount) {
        const {filter, suggest} = this.props
        const {prefix} = this.state

        if (!suggest || suggest.total <= fastItemsCount) {
            return null
        }

        const values = prefix
            ? suggest.values
            : suggest.head.slice(fastItemsCount)

        return (
            <Autocomplete
                value={prefix}
                isLoading={suggest.isLoading}
                hasMore={prefix ? suggest.hasMore : !suggest.isFull}
                placeholder={__('More value')}
                onType={suggest.isFull ? null : this.handleType}
                onSet={this.handleSet}
            >
                {filter.getItems(values).map(
                    (item) => <Option key={item.$} label={item.label} value={item}/>,
                )}
            </Autocomplete>
        )
    }
}