import {
    handleActions,
} from 'redux-actions'

import {
    update,
} from './actions'

export default handleActions({
    [update](state, {payload: permissions}) {
        return permissions
    },
}, {})
