import React, {Component} from 'react'

import UpgradeStatusPager from './Bar/UpgradeStatusPager'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

export default class extends Component {
    render() {
        return (
            <Bar orientation={RIGHT}>
                <UpgradeStatusPager/>
            </Bar>
        )
    }
}