import {
    handleActions
} from 'redux-actions'

import keyBy from 'lodash-es/keyBy'

import {
    setLoading,
    persist
} from './actions'

export default handleActions({
    [setLoading](state, {payload}) {
        const {isLoading} = payload

        return {
            ...state,
            isLoading,
        }
    },

    [persist](state, {payload}) {
        const {data} = payload

        return {
            ...state,
            indexes: keyBy(data, 'id'),
        }
    },
}, {
    isLoading: false,
    indexes: {},
})