import {createSelector} from 'reselect'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import {err} from 'utils/log'

export const selectOnlineIsLoading = state => state.dashboard.online.isLoading
export const selectOnlineData = state => state.dashboard.online.data
export const selectOnlineError = state => state.dashboard.online.error

export const selectOnline = createSelector(
    [
        selectOnlineIsLoading,
        selectOnlineData,
        selectOnlineError,
    ],
    (isLoading, data, error) => {
        const from = data.length
            ? maxBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date
        const to = data.length
            ? minBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date

        const {faultyData, offlaneData} = data.reduce((acc, dataItem, i) => {
            let faultyDataY
            let offlaneDataY

            if (dataItem.total == 0) {
                err(`Bad data for 'online' data: ${i} number`)
                faultyDataY = 1
                offlaneDataY = 1
            } else {
                faultyDataY = dataItem.faulty / dataItem.total
                offlaneDataY = (dataItem.total - dataItem.online) / dataItem.total
            }

            return {
                faultyData: [...acc.faultyData, {
                    x: new Date(dataItem.time),
                    y: faultyDataY.toFixed(2),
                }],
                offlaneData: [...acc.offlaneData, {
                    x: new Date(dataItem.time),
                    y: offlaneDataY.toFixed(2),
                }],
            }
        }, {faultyData: [], offlaneData: []})

        return {isLoading, from, to, faultyData, offlaneData, error}
    },
)
