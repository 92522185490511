export default function generateSuggestString(obj, keysmap = {}) {
    if (!obj) {
        return
    }

    let keys

    if (Array.isArray(obj)) {
        keys = obj
        obj = {}
    } else {
        keys = Object.keys(obj)
    }

    return keys
        .map(key => `:${keysmap[key] || key}:${obj[key] || ''}`)
        .join(';')
}