import {
    createAction
} from 'redux-actions'

export const clear = createAction('REMOTE_INSPECTIONS/STORE/CLEAR')
export const update = createAction('REMOTE_INSPECTIONS/STORE/UPDATE', rows => Array.isArray(rows) ? rows : [rows])
export const initiateNow = createAction('REMOTE_INSPECTIONS/STORE/INITIATE_NOW', (ids) => Array.isArray(ids) ? ids : [ids])
export const cancel = createAction('REMOTE_INSPECTIONS/STORE/CANCEL', (ids) => Array.isArray(ids) ? ids : [ids])
export const purge = createAction('REMOTE_INSPECTIONS/STORE/PURGE')

// Workaround for use runners
export const initiateNowBatch = createAction('REMOTE_INSPECTIONS/STORE/INITIATE_NOW_BATCH', (ids) => Array.isArray(ids) ? ids : [ids])
