import get from 'lodash-es/get'
import maxBy from 'lodash-es/maxBy'
import moment from 'moment/moment'
import {createSelector} from 'reselect'
import {TEMPERATURE, BRIGHTNESS} from 'constants/meteoTypes'

import {temperatureConverter, temperatureKeyToLabel} from 'utils/temperature'

const POINTS_ON_CHART = 30
const MAX_POINTS_FOR_EXEC = POINTS_ON_CHART * 3
const Y_TRESHOLD_GAP = 10

function filterData(data, type, from, to) {
    return data
        .map(({timestamp, [type]: value}) => ({
            time: moment.unix(timestamp),
            value,
        }))
        .filter(({time}) => time.isBetween(from, to, null, '[]'))
}

function groupDataset(dataset, from, to) {
    const fromTimestamp = from.unix()
    const toTimestamp = to.unix()

    const step = Math.round((toTimestamp - fromTimestamp) / POINTS_ON_CHART)

    return dataset.reduce((carrier, {time, value}) => {
        const intervalIdx = Math.floor((time.unix() - fromTimestamp) / step)

        const {min, max, avg, cnt} = get(carrier, intervalIdx, {
            min: value,
            max: value,
            avg: value,
            cnt: 0,
        })

        carrier[intervalIdx] = {
            time: fromTimestamp + intervalIdx * step,
            min: Math.min(min, value),
            max: Math.max(max, value),
            avg: (avg * cnt + value) / (cnt + 1),
            cnt: cnt + 1,
        }

        return carrier
    }, new Array(POINTS_ON_CHART))
}

const defaultState = {
    isLoading: true,
    error: null,
    data: [],
}

const prepare = (data, type, from, to, isDynamicYTreshold, temperatureUnit) => {
    data = filterData(data, type, from, to)
    let result = {}

    if (!data.length) {
        return result
    }

    if (type == TEMPERATURE) {
        data = data.map(point => {
            point.value = temperatureConverter(point.value, temperatureUnit)
            return point
        })
    }

    if (data.length <= MAX_POINTS_FOR_EXEC) {
        result.exact = data.map(({time: t, value: y}) => ({t, y}))

        if (isDynamicYTreshold) {
            result.yThreshold = get(maxBy(result.exact, 'y'), 'y', 0) + Y_TRESHOLD_GAP
        }

        return result
    }

    result = groupDataset(data, from, to).reduce(
        (result, {min, max, avg, time}) => {
            const x = moment.unix(time)

            result.min.push({
                x, y: min ? min.toFixed(1) : null,
            })
            result.max.push({
                x, y: max ? max.toFixed(1) : null,
            })
            result.avg.push({
                x, y: avg ? avg.toFixed(1) : null,
            })

            return result
        },
        {
            min: [],
            max: [],
            avg: [],
        },
    )
    if (isDynamicYTreshold) {
        result.yThreshold = maxBy(result.max, 'y') + Y_TRESHOLD_GAP
    }

    return result
}

const prepareLabel = (type, temperatureUnit) => {
    if (type == BRIGHTNESS) {
        return 'lux'
    }
    return temperatureKeyToLabel(temperatureUnit)
}

const createDeviceMeteoSelector = (type, isDynamicYTreshold) => createSelector(
    (state, {panelId, deviceId}) => get(state.devices.meteo, `${panelId}.${deviceId}.${type}`, defaultState),
    (_, props) => props.from,
    (_, props) => props.to,
    (state) => state.settings.temperature,
    ({data, ...state}, from, to, temperatureUnit) => {
        return {
            ...state,
            label: prepareLabel(type, temperatureUnit),
            data: prepare(data, type, from, to, isDynamicYTreshold, temperatureUnit),
        }
    },
)

export const selectDeviceTemperature = createDeviceMeteoSelector(TEMPERATURE)
export const selectDeviceBrightness = createDeviceMeteoSelector(BRIGHTNESS, true)