import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'

import Supervision from './Supervision'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class Receiver extends Component {
    render() {
        return (
            <ScrollView>
                <Card className="card">
                    <Supervision/>
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {supervision}}}) => ({
            isLoading: supervision.isLoading,
        }),
        dispatch => bindActionCreators({
            supervision: actions.fetchSupervision,
        }, dispatch),
    ),
    withLoader(({supervision}) => {
        supervision()
    }),
)(Receiver)