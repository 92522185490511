import {snackShow} from 'modules/snacks'
import {all, takeEvery, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/pgm'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
    ])
}

function* watchFetch({payload: panelId}) {
    try {
        const data = yield call(api.getPGMTypes, panelId)

        yield put(actions.receive(data))
    } catch (error) {
        yield put(actions.receive(error))
        yield put(snackShow(error.message))
    }
}