import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {fetch, setLoading} from './actions'

const state = {
    isLoading: false,
}

export default handleActions({
    [fetch]: (state) => set(state, 'isLoading', true),

    [setLoading]: (state, {payload: {isLoading}}) => set(state, 'isLoading', isLoading),
}, state)