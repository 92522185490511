import {takeEvery, put, call} from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'api/roles'
import {update} from '../store/actions'
import toIds from 'utils/toIds'

export default function* () {
    yield takeEvery(actions.fetch, watchFetch)
}

function* watchFetch() {
    try {
        const {rows} = yield call(api.assignableRoles)

        yield put(update(rows))
        yield put(actions.receive(toIds(rows)))
    } catch (error) {
        yield put(actions.receive(error))
    }
}