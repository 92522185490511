import SuggestFilter from 'components/Search/Filters/SuggestFilter'

/**
 * @deprecated
 *
 * Workaround for another type of suggesting api in ActionLog
 * Add's key to filter, that will be sended to endpoint
 */
export class MapSuggestFilter extends SuggestFilter {

    createItem(value) {
        return {
            $: this.digest(value.key),
            key: value.key,
            value: value.value,
            name: this.name,
            label: value.value,
        }
    }

}