import React from 'react'

import * as keys from 'utils/keypad/keys'

import Tooltip from 'ipmp-react-ui/Tooltip'
import IconKeypadLock from 'ipmp-react-ui/icons/keypad/keypad-lock.svg'
import IconArrowUp from 'ipmp-react-ui/icons/arrow-up.svg'
import IconArrowPrev from 'ipmp-react-ui/icons/arrow-prev.svg'
import IconArrowNext from 'ipmp-react-ui/icons/arrow-next.svg'
import IconDisarm from 'ipmp-react-ui/icons/keypad/keypad-unlock.svg'
import IconHome from 'ipmp-react-ui/icons/keypad/keypad-home.svg'
import IconEmergency from 'ipmp-react-ui/icons/keypad/keypad-emergency.svg'
import IconPanic from 'ipmp-react-ui/icons/keypad/keypad-alarm.svg'
import IconFire from 'ipmp-react-ui/icons/worries/fire.svg'
import IconStay from 'ipmp-react-ui/icons/keypad/keypad-stay.svg'
import IconAway from 'ipmp-react-ui/icons/keypad/keypad-away.svg'
import IconChime from 'ipmp-react-ui/icons/alert-off.svg'
import IconReset from 'ipmp-react-ui/icons/refresh.svg'
import IconExit from 'ipmp-react-ui/icons/logout.svg'

const commonOrderedDigits = [
    {keyCode: keys.KEY_1, digit: 1},
    {keyCode: keys.KEY_2, digit: 2},
    {keyCode: keys.KEY_3, digit: 3},
    {keyCode: keys.KEY_4, digit: 4},
    {keyCode: keys.KEY_5, digit: 5},
    {keyCode: keys.KEY_6, digit: 6},
    {keyCode: keys.KEY_7, digit: 7},
    {keyCode: keys.KEY_8, digit: 8},
    {keyCode: keys.KEY_9, digit: 9},
    {keyCode: keys.KEY_STAR, digit: '*'},
    {keyCode: keys.KEY_0, digit: '0'},
    {keyCode: keys.KEY_HASH, digit: '#'}
]

export const pmaxDigits = [
    {keyCode: keys.KEY_OFF, digit: <Tooltip tooltip="Clear">{'OFF'}</Tooltip>},
    {keyCode: keys.KEY_UP, digit: <Tooltip tooltip="Level Up"><IconArrowUp/></Tooltip>},
    {keyCode: keys.KEY_ESC, digit: <Tooltip tooltip="Exit Menu">{'ESC'}</Tooltip>},
    {keyCode: keys.KEY_LEFT, digit: <IconArrowPrev/>},
    {keyCode: keys.KEY_OK, digit: 'OK'},
    {keyCode: keys.KEY_RIGHT, digit: <IconArrowNext/>},
    ...commonOrderedDigits,
    {keyCode: keys.KEY_DISARM, digit: <IconDisarm />},
    {keyCode: keys.KEY_ARM_HOME, digit: <IconHome />},
    {keyCode: keys.KEY_ARM_AWAY, digit: <IconKeypadLock />},
    {keyCode: keys.KEY_EMERGENCY, digit: <Tooltip tooltip="Emergency"><IconEmergency /></Tooltip>},
    {keyCode: keys.KEY_PANIC, digit: <Tooltip tooltip="Panic"><IconPanic /></Tooltip>},
    {keyCode: keys.KEY_FIRE, digit: <Tooltip tooltip="Fire Alarm"><IconFire /></Tooltip>},
]

export const neoDigits = [
    {keyCode: keys.KEY_LEFT, digit: <IconArrowPrev/>},
    {keyCode: keys.KEY_RIGHT, digit: <IconArrowNext/>},
    ...commonOrderedDigits,
    {keyCode: keys.KEY_EMERGENCY, digit: <Tooltip tooltip="Emergency"><IconEmergency /></Tooltip>},
    {keyCode: keys.KEY_PANIC, digit: <Tooltip tooltip="Panic"><IconPanic /></Tooltip>},
    {keyCode: keys.KEY_FIRE, digit: <Tooltip tooltip="Fire Alarm"><IconFire /></Tooltip>},

    {keyCode: keys.KEY_STAY, digit: <Tooltip tooltip="Stay"><IconStay /></Tooltip>},
    {keyCode: keys.KEY_AWAY, digit: <Tooltip tooltip="Away"><IconAway /></Tooltip>},
    {keyCode: keys.KEY_CHIME, digit: <Tooltip tooltip="Chime"><IconChime /></Tooltip>},
    {keyCode: keys.KEY_RESET, digit: <Tooltip tooltip="Reset"><IconReset /></Tooltip>},
    {keyCode: keys.KEY_EXIT, digit: <Tooltip tooltip="Exit"><IconExit /></Tooltip>}
]