import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditCellularConnectedButton from './Buttons/EditCellularConnectedButton'

import {__} from 'utils/i18n'

class CellularConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            isAutoEnrollmentEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
            retriesMax: PropTypes.number,
            retryInterval: PropTypes.number,
            wakeupOnUserDiscovery: PropTypes.bool,
        }),
        messageBroker: PropTypes.object,
    }

    render() {
        const {data, messageBroker} = this.props
        return (
            <div>
                <h1 className="card-header">
                    {__('Cellular Connected')}
                    <EditCellularConnectedButton/>
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(__('Enable Auto-Enroll'), data.isAutoEnrollmentEnabled)}
                    {this.renderListItem(__('Message Broker for WakeUp'), messageBroker && messageBroker.name)}
                    {this.renderListItem(__('Number of Retries'), data.retriesMax)}
                    {this.renderListItem(__('Retries Interval [seconds]'), data.retryInterval)}
                    {this.renderListItem(__('WakeUp panel on user initiated discovery'), data.wakeupOnUserDiscovery)}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {cellularConnected, messageBrokers}}}) => {
            return {
                messageBroker: messageBrokers.data.find((broker) => broker.id == cellularConnected.data.messageBroker),
                ...cellularConnected,
            }
        }
    ),
)(CellularConnected)