import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import __ from 'utils/i18n'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {login, setLoginScreenForm} from 'modules/auth/sign/actions'
import {FORGOT_PASSWORD_SCREEN} from 'constants/loginScreen'

const rules = {
    email: {
        presence: true,
        email: true,
    },
    password: {
        presence: true,
        length: {minimum: 6},
    },
}

class LoginForm extends Component {

    static propTypes = {
        login: PropTypes.func.isRequired,
        setLoginScreenForm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    handleSubmit = ({email, password}) => {
        this.props.login(email, password)
    }

    handleForgot = () => {
        this.props.setLoginScreenForm(FORGOT_PASSWORD_SCREEN)
    }

    render() {
        const {isLoading, error} = this.props

        return (
            <Form
                className="card login-form"
                isLoading={isLoading}
                onSubmit={this.handleSubmit}
                errors={error && error.errors}
                rules={rules}>

                <CardHeader>{__('Login')}</CardHeader>

                <CardContent dir="ltr">
                    <Input
                        name="email"
                        label={__('Email')}
                        autocomplete={'off'}
                        />

                    <Input
                        name="password"
                        type="password"
                        label={__('Password')}
                        autocomplete={'off'}
                        />
                </CardContent>

                <CardActions justify>
                    <a className="link" onClick={this.handleForgot}>{__('Forgot password?')}</a>
                    <Button primary disabled={isLoading} type="submit">{__('Login')}</Button>
                </CardActions>
            </Form>
        )
    }
}

export default connect(
    ({auth: {sign}}) =>({...sign}),
    (dispatch) => bindActionCreators({
        login,
        setLoginScreenForm,
    }, dispatch)
)(LoginForm)