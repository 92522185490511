import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import location from 'constants/locations'

import Input from 'ipmp-react-ui/Input'

export default class LocationRow extends PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        characters: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        isChanged: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        maxLength: PropTypes.number,
    }

    state = {
        value: this.props.value,
    }

    static getDerivedStateFromProps({value}, prevState) {
        return {value}
    }

    handleKeyPress = e => {
        const {characters} = this.props
        const char = String.fromCharCode(e.charCode)

        if (characters.indexOf(char) < 0) {
            e.preventDefault()
        }
    }

    handleChange = (e) => {
        const {id, onChange, characters} = this.props
        const value = e.target.value.split('').filter(s => characters.indexOf(s) >= 0).join('')

        this.setState({
            value,
        })
        onChange(id, value)
    }

    render() {
        const {name, id, isDisabled, isReadOnly, isChanged, maxLength} = this.props
        const {value} = this.state

        return (
            <div className={classes('form-row', 'locations-row', {
                'form-row--changed': isChanged,
            })}>
                <div className="form-row-label">
                    {location(id, name)}
                </div>

                <div className="form-row-content">
                    <Input
                        name={name}
                        value={value}
                        disabled={isDisabled || isReadOnly}
                        maxLength={maxLength}
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        )
    }

}