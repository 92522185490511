import {handleActions} from 'redux-actions'

import {receive, untrack, trackTemp} from './actions'

const defaultState = {
    ids: [],
    tempIds: [],
}

export default handleActions({
    [receive](state, {payload: ids}) {
        const tempIds = state.tempIds.filter(id => (!ids.includes(id)))
        return {
            tempIds: tempIds,
            ids,
        }
    },

    [untrack](state, {payload: batchId}) {
        const ids = state.ids.filter(id => id !== batchId)

        return {
            ...state,
            ids,
        }
    },

    [trackTemp](state, {payload: batchId}) {
        return {
            ...state,
            tempIds: [batchId, ...state.tempIds],
        }
    },

}, defaultState)
