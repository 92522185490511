import {
    PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
    PROCESS_TYPE_PMAXCONFIGUPLOAD,
    PROCESS_TYPE_PMAXZONEADD, PROCESS_TYPE_PMAXZONEREMOVE,
} from 'constants/processTypes'
import {selectReadyChanges} from 'modules/panels/configuration/selectors'
import generateBatch, {generateBatchForPanel} from 'modules/batches/manager/generateBatch'
import {snackShow} from 'modules/snacks'
import {select, all, call, takeEvery, put} from 'redux-saga/effects'
import {createBasicConfiguration} from 'modules/forms/handlers'
import {__n} from 'utils/i18n'

import * as actions from './actions'
import * as api from 'api/panel/configuration'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.markAsBackup, watchMarkAsBackup),
        takeEvery(actions.fetchOne, watchFetchOne),
        takeEvery(actions.refresh, watchRefresh),
        takeEvery(actions.upload, watchUpload),
        takeEvery(createBasicConfiguration.SUCCESS, watchMakeBasicComplete),
        takeEvery(actions.refreshRunner, watchRefreshRunner),
    ])
}

function* watchFetch({payload: panelId}) {
    try {
        const {rows, runner} = yield call(api.list, panelId)

        const current = rows.find(row => row.current) || {}
        const {currentConfigId} = yield select(state => state.panels.configuration[panelId] || {})

        if (current.id >= 0 && current.id !== currentConfigId) {
            const configuration = yield call(api.one, panelId, current.id)
            yield put(actions.receiveCurrent(configuration, panelId))
        }

        yield put(actions.receive({
            runner,
            rows,
            currentConfigId: current.id,
        }, panelId))
    } catch (error) {
        yield put(actions.receive(error, panelId))
    }
}

function* watchFetchOne({payload: {panelId, configId}}) {
    try {
        const configuration = yield call(api.one, panelId, configId)
        yield put(actions.receiveOne(configuration, panelId, configId))
    } catch (error) {
        yield put(actions.receiveOne(error, panelId, configId))
    }
}

function* watchMarkAsBackup({payload: {id, panelId}}) {
    try {
        yield call(api.backup, panelId, id)
    } catch (error) {
        yield put(snackShow(error))
        yield put(actions.revertBackup(panelId))
    }
}

function* watchRefresh({payload: panelIds}) {
    if (panelIds.length === 1) {
        yield refreshPanel(panelIds.pop())
    } else {
        yield refreshPanels(panelIds)
    }
}

function* refreshPanel(panelId) {
    const {batchId} = generateBatchForPanel(
        PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
        panelId
    )

    try {
        const runner = yield call(api.refresh, panelId, batchId)

        yield put(actions.update({runner}, panelId))
    } catch (error) {
        yield put(actions.update({runner: null}, panelId))
        yield put(snackShow(error.message))
    }
}

function* refreshPanels(panelIds) {
    const {batchId} = yield generateBatch(PROCESS_TYPE_PMAXCONFIGDOWNLOAD, panelIds)
    try {
        yield call(api.refreshAll, panelIds, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchUpload({payload: {panelId}}) {
    const {changes, configuration, errors} = yield select(state => state.panels.configuration[panelId] || {})
    const readyChanges = yield select(selectReadyChanges, {panelId})
    const errorsCount = errors ? Object.keys(errors).length : 0

    if (errorsCount > 0) {
        const message = __n('You have %d error in configuration', 'You have %d errors in configuration', errorsCount)
        yield put(snackShow(message))
        return
    }

    const {batchId} = yield generateBatchForPanel(
        PROCESS_TYPE_PMAXCONFIGUPLOAD,
        panelId
    )

    try {
        const runner = yield call(
            api.upload,
            panelId,
            readyChanges,
            configuration && configuration.version,
            batchId
        )

        yield put(actions.update({
            runner,
            changes: {},
        }, panelId))
    } catch (error) {
        yield put(actions.update({process: null, changes}, panelId))
        yield put(snackShow(error.message))
    }
}

function* watchMakeBasicComplete({meta}) {
    const {panelId} = meta
    yield put(actions.dismissChanges(panelId))
}

function* watchRefreshRunner({payload: {runner}}) {
    const oneOfType = [PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
        PROCESS_TYPE_PMAXCONFIGUPLOAD,
        PROCESS_TYPE_PMAXZONEADD,
        PROCESS_TYPE_PMAXZONEREMOVE].includes(runner.type)

    if (runner.isSuccessful && oneOfType) {
        yield put(actions.outdated(runner.panelId))
    }
}