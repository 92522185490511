import parseDate from 'api/base/parseDate'
import sortBy from 'lodash-es/sortBy'
import dataMapper from 'utils/dataMapper'

export const
    reportedFaultsTest = 'REPORTED_FAULTS',
    totalSystemUsageTest = 'TOTAL_SYSTEM_USAGE_TEST',
    activeFaultsTest = 'ACTIVE_FAULTS',
    bypassedTest = 'BYPASSED',
    frequentlyUsedTest = 'FREQUENTLY_USED',
    clockTest = 'CLOCK',
    gprsTest = 'GPRS',
    broadbandTest = 'BROADBAND',
    soakTest = 'SOAK'

const testTypeMap = {
    1: reportedFaultsTest,
    2: totalSystemUsageTest,
    3: activeFaultsTest,
    4: bypassedTest,
    5: frequentlyUsedTest,
    6: clockTest,
    8: gprsTest,
    9: broadbandTest,
    10: soakTest,
}

const mapRemoteInspectionResultTestType = (id) => {
    id = parseInt(id)

    if (testTypeMap[id]) {
        return testTypeMap[id]
    }

    return null
}

const order = ['not_available', 'fail']

const mapRemoteInspectionResultTest = ({id, range, result, description, value, state, detailed, testId, info}) => ({
    id: parseInt(id),
    testTypeId: mapRemoteInspectionResultTestType(testId),
    range,
    result,
    state,
    detailed,
    description: (description === '---' ? null : description),
    test: value,
    info,
})

export const mapRemoteInspectionResult = dataMapper((result) => ({
    id: parseInt(result.resultId),
    panelId: parseInt(result.unitId),
    created: parseDate(result.dt),
    isEmailSent: parseInt(result.email) === 1,
    isReviewed: result.reviewed,
    reviewedDate: parseDate(result.reviewed_date),
    reviewedBy: result.reviewed_by,
    fails: parseInt(result.fails),
    result: sortBy(
        result.result.map(mapRemoteInspectionResultTest),
        ({state}) => -order.indexOf(state),
    ),
}))