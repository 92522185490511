import createPermission, {any} from 'permissions/createPermission'

export const connectedPanels = createPermission('system.statistics.connectedPanels')
export const eventsRate = createPermission('system.statistics.eventsRate')
export const faulty = createPermission('system.statistics.faulty')
export const online = createPermission('system.statistics.online')
export const allProcesses = createPermission('system.statistics.process.all')

export const onlineFaulty = createPermission(
    any(
        faulty,
        online,
    ),
)