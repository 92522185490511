import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Pie} from 'react-chartjs-2'

import {getLabelBackgroundColor, getLabelHoverBackgroundColor} from 'constants/statistic'
import __ from 'utils/i18n'
import {getProcessStatusTitle} from 'constants/processStatuses'

export default class AllProcesses extends Component {
    static propTypes = {
        dataset: PropTypes.array,
        labels: PropTypes.array,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    get backgroundColor() {
        const {labels} = this.props

        return labels.map(getLabelBackgroundColor)
    }

    get hoverBackgroundColor() {
        const {labels} = this.props

        return labels.map(getLabelHoverBackgroundColor)
    }

    get data() {
        const {dataset, labels} = this.props

        return {
            labels: labels.map(getProcessStatusTitle),
            datasets: [{
                data: dataset,
                backgroundColor: this.backgroundColor,
                hoverBackgroundColor: this.hoverBackgroundColor,
            }],
        }
    }

    get options() {
        return {
            animation: false,
            maintainAspectRatio: false,
        }
    }

    render() {
        return (
            <div className="card dashboard-secondary">
                <h3 className="dashboard-chartTitle">{__('All processes')}</h3>
                <Pie data={this.data}
                    options={this.options}
                    height={300} />
            </div>
        )
    }
}