import {takeEvery, all, put, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/reports'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.review, watchReview),
        takeEvery(actions.stop, watchStop),
    ])
}

function* watchStop({payload: {ids}}) {
    try {
        yield call(api.stop, ids)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(
            ids.map(id => ({id, isActive: true})),
        ))
    }
}

function* watchReview({payload: {id}}) {
    try {
        yield call(api.review, id)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update({id, isReviewed: true}))
    }
}