import keyBy from 'lodash-es/keyBy'
import {handleActions} from 'redux-actions'

import {update} from './actions'
import {merge} from 'immutable-modify'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [update](state, {payload: batches}) {
        return merge(state, 'byIds', keyBy(batches, 'id'))
    },
}, defaultState)
