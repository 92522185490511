import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {selectRunnersGridBar} from 'modules/runners/list/selectors'

export default function withRunnerGridBar() {
    return compose(
        withRouter,
        connect(
            selectRunnersGridBar
        )
    )
}