import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'
import Portal from 'ipmp-react-ui/Portal'
import CardMessage from 'ipmp-react-ui/CardMessage'

import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'

export default class ModalCardForm extends Component {
    static propTypes = {
        wide: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.oneOf([2, 3]),
        ]),
        className: PropTypes.string,
        header: PropTypes.node,
        isLoading: PropTypes.bool,
        editable: PropTypes.bool,
        showDismiss: PropTypes.bool,
        submitLabel: PropTypes.string,
        dissmissLabel: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        confirmOnDismiss: PropTypes.bool,
        hide: PropTypes.func,
    }

    static defaultProps = {
        editable: true,
        showDismiss: true,
        confirmOnDismiss: false,
    }

    state = {
        changed: false,
        showConfirmation: false,
    }

    dismiss = (e) => {
        e && e.preventDefault()

        if (this.isNeedConfirmation()) {
            this.setState({showConfirmation: true})
        } else {
            this.props.hide && this.props.hide(e)
        }
    }

    onChange = (...args) => {
        this.setState({
            changed: true,
        })

        this.props.onChange && this.props.onChange(...args)
    }

    isNeedConfirmation() {
        const {confirmOnDismiss} = this.props
        return confirmOnDismiss && this.state.changed
    }

    agree = () => this.props.hide && this.props.hide()

    cancel = () => {
        this.setState({showConfirmation: false})
    }

    render() {
        const {
            header,
            hide,
            className,
            children,
            submitLabel,
            dissmissLabel,
            showDismiss,
            wide,
            editable,
            ...props
        } = this.props

        const {showConfirmation} = this.state

        delete props.confirmOnDismiss

        return (
            <Modal onClose={this.dismiss}>
                <Form {...props}
                    onChange={this.onChange}
                    className={classes('card', className, {
                        'card--wide': wide === true,
                        'card--wide-2': wide === 2,
                        'card--wide-3': wide === 3,
                    })}
                >
                    {hide && <CardClose onClick={this.dismiss}/>}
                    {header && <CardHeader>{header}</CardHeader>}

                    <CardContent>
                        {children}
                    </CardContent>

                    <CardActions>
                        {hide && showDismiss &&
                        <Button borderless shortcut="esc" onClick={this.dismiss}>
                            {dissmissLabel || __('Dismiss')}
                        </Button>}

                        {editable &&
                        <Button primary disabled={props.isLoading} type="submit">
                            {submitLabel || __('Save')}
                        </Button>}
                    </CardActions>
                </Form>

                {showConfirmation &&
                <Portal onClick={stopPropagation} key="portal">
                    <Modal onClose={this.dismiss}>
                        <CardMessage
                            className="card--narrow"
                            title={__('Closing form')}
                            message={__('All data will be lost. Do you wan\'t to proceed?')}
                            positive={__('Confirm')}
                            negative={__('Cancel')}
                            onPositive={this.agree}
                            onNegative={this.cancel}
                            onClose={this.cancel}
                        />
                    </Modal>
                </Portal>}
            </Modal>
        )
    }
}