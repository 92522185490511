import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import page from 'permissions/panel/log/page'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

import Layout, {SideBar} from 'ipmp-react-ui/Layout'
import Log from './Log'
import {withPermission, withRejection} from 'containers/withPermission'

export class Logs extends Component {

    state = {
        mode: 'standard',
    }

    handleShowStandard = () => this.setState({mode: 'standard'})

    handleShowLegacy = () => this.setState({mode: 'legacy'})

    render() {
        const {panel} = this.props
        const {mode} = this.state

        if (panel.isNeo) {
            return <Log key={mode} mode={mode} panel={panel}/>
        }

        return (
            <Layout>
                <Log key={mode} mode={mode} panel={panel}/>

                <SideBar className="sidebar--bookmark">
                    <Button toggler onClick={this.handleShowStandard} active={mode === 'standard'}>
                        {__('Standard Log')}
                    </Button>

                    <Button toggler onClick={this.handleShowLegacy} active={mode === 'legacy'}>
                        {__('Legacy Log')}
                    </Button>
                </SideBar>
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    connect(
        (state, {match}) => ({
            panel: state.panels.store.byIds[match.params.id],
        }),
    ),
)(Logs)