import Form from 'ipmp-react-ui/Form'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import Bar from 'ipmp-react-ui/Bar'
import Select, {Option} from 'ipmp-react-ui/Select'
import Button from 'ipmp-react-ui/Button'
import Chips, {Chip} from 'ipmp-react-ui/Chips'

import {__} from 'utils/i18n'
import {deviceSubtype} from 'constants/deviceSubtype'

class Firmware extends Component {
    static propTypes = {
        number: PropTypes.number,
        type: PropTypes.string,
        currentVersion: PropTypes.string,
        packages: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
                version: PropTypes.string,
            }),
        ),
        selectedPackageName: PropTypes.string,
        handler: PropTypes.func,
        isLoading: PropTypes.bool,
        error: PropTypes.string,
        runner: PropTypes.object.isRequired,
    }

    state = {
        selectedPackage: null,
    }

    onChange = (e, value) => {
        const {type, number} = this.props
        let selectedPackage = null

        if (value !== '') {
            selectedPackage = {type, number, packageName: value}
        }

        this.setState({selectedPackage})
    }

    onSubmit = () => {
        const {handler} = this.props
        const {selectedPackage} = this.state

        handler(selectedPackage)
    }

    get isPackageAvailable() {
        return this.props.packages.length > 0
    }

    renderPackageSelector() {
        const {packages} = this.props

        if (!this.isPackageAvailable) {
            return <div className="empty">{__('No packages available')}</div>
        }

        return (
            <Select
                optional={__('Do not upgrade')}
                onChange={this.onChange}
            >
                {packages.map(({name}) => <Option key={name} label={name}/>)}
            </Select>
        )
    }

    getRules = () => {

    }

    renderTip = () => {
        let {devices, number} = this.props
        const {selectedPackage} = this.state

        devices = devices.filter((device) => device.zone !== number)

        if (!selectedPackage || !this.isPackageAvailable
            || devices.length === 0) {
            return null
        }

        return (
            <div className="block">
                <h4 className="title">{__('These devices also will be upgraded')}</h4>
                <Chips>
                    {devices.map((device) => (
                        <Chip light>
                            {`${deviceSubtype(device.subtype, device.type)} ${device.zone}`}
                        </Chip>
                    ))}
                </Chips>
            </div>
        )
    }

    render() {
        const {currentVersion, isLoading, error} = this.props
        const {selectedPackage} = this.state

        return (
            <Form isLoading={isLoading}
                  className="firmware-form blocks"
                  onSubmit={this.onSubmit}
                  rules={this.getRules()}
                  errors={error && error.errors}
            >
                <div className="block">
                    <h4 className="title">
                        <Definition
                            className="device-firmware-label"
                            title={currentVersion}
                            detail={__('Current version')}
                        />
                    </h4>

                    {this.renderPackageSelector()}
                    <Bar className="form-field" childrenRight>
                        <Button className="device-firmware-submit"
                            primary
                            disabled={!selectedPackage}
                            type="submit"
                        >
                            {__('Upgrade')}
                        </Button>
                    </Bar>
                </div>
                {this.renderTip()}
            </Form>
        )
    }
}

export default Firmware