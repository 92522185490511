import BasicConfigurationPage from './BasicConfigurationPage'
import path from 'utils/path'

export default class BasicConfigurationsRoute {

    exact = true

    path = path('system.basicConfiguration.edit')

    component = BasicConfigurationPage

}