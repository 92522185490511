import {compose,bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {refreshConfiguration} from 'modules/runners/store/actions'

import {DownloadConfigurationItem} from 'pages/Panels/GridBar/DropDowns/Items/DownloadConfigurationItem.js'
import {withSelectionFeature} from 'containers/withFeature'
import {CONFIGURATION_DOWNLOAD} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'

export default compose(
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: refreshConfiguration,
        }, dispatch)
    ),
    withSelectionFeature(
        CONFIGURATION_DOWNLOAD,
        selectSelectedRunnersFeature
    ),
)(DownloadConfigurationItem)