import {compose} from 'redux'

import {initiate} from 'permissions/panel/remoteInspections/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'

import RunIcon from 'ipmp-react-ui/icons/run.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: initiate}),
    withVisibility(),
    withPanelRemoteInspection(),
    withProps(({initiateNow, remoteInspection}) => ({
        Icon: RunIcon,
        title: __('Initiate inspection'),
        label: __('Start Inspection'),
        onClick: initiateNow,
        message: __('Do you want to initiate remote inspection now?'),
        positive: __('Begin Now'),
        disabled: remoteInspection && remoteInspection.progress !== null,
    })),
    withConfirmation(),
)(Button)