import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'

import {showEditPanelCustomerInfoModal, showEditPanelInfoModal} from 'modules/modals/actions'

export default function withPanelInfo() {
    return compose(
        withRouter,
        connect(
            ({panels}, {match}) => {
                const id = parseInt(match.params.id)
                const panel = panels.store.byIds[id]

                return {
                    panel,
                }
            },
            (dispatch, {match}) => {
                const id = parseInt(match.params.id)

                return bindActionCreators({
                    editPanelInfo: () => showEditPanelInfoModal(id),
                    editCustomerInfo: () => showEditPanelCustomerInfoModal(id),
                }, dispatch)
            },
        ),
    )
}