import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {MarkForServiceItem} from 'pages/Panels/GridBar/DropDowns/Items/MarkForServiceItem.js'

import {showMarkForServiceModal} from 'modules/runners/store/actions'

export default connect(
    null,
    dispatch => bindActionCreators({
        onClick: showMarkForServiceModal,
    }, dispatch),
)(MarkForServiceItem)