import createPermission, {any} from 'permissions/createPermission'

import disable from './log/disable'
import enable from './log/enable'
import send from './log/send'

export default createPermission(
    any(
        enable,
        disable,
        send,
    ),
)