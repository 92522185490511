import {compose} from 'redux'

import {refresh} from 'permissions/panel/actions'

import withTrackProcess from 'containers/withTrackProcess'
import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import RefreshButton from 'ipmp-react-ui/RefreshButton'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRefreshState} from 'modules/features/store/selectors'
import Void from 'components/Void'

export default compose(
    withPermission({isVisible: refresh}),
    withVisibility(),
    withPanel(),
    withTrackProcess(({panel}) => panel.refreshProcess),
    withProps(({process, refreshState}) => ({
        onClick: refreshState,
        isRefreshing: !!process,
    })),
    withRouterPanelId(),
    withFeatureRejection(selectRefreshState, Void),
)(RefreshButton)
