import React, {Component} from 'react'
import classes from 'classnames'
import ProgressBar from 'ipmp-react-ui/ProgressBar'
import {__} from 'utils/i18n'
import {humanDiff} from 'ipmp-react-ui/humanTime'
import moment from 'moment'
export default class PanelStatusLine extends Component {

    state = {index: 0}

    constructor(props, context) {
        super(props, context)

        if (props.lines && props.lines.length > 0) {
            this.state.line = props.lines[0]
            this.run()
        }
    }

    componentWillUnmount() {
        this.stop()
    }

    static getDerivedStateFromProps({lines}, prevState) {
        if (!prevState.line) {
            return {
                line: lines[0],
            }
        }

        return null
    }

    componentDidUpdate(prevProps, prevState) {
        const {lines} = this.props

        if (lines && lines.length > 0) {
            this.run()
        } else {
            this.stop()
        }
    }

    run() {
        if (!this.iId) {
            this.iId = setInterval(this.nextLine, 1000)
        }
    }

    stop() {
        if (this.iId) {
            clearInterval(this.iId)
            this.iId = null
        }
    }

    nextLine = () => {
        const {lines} = this.props

        this.setState(({line, index}) => {
            index = (index + 1) % lines.length
            line = lines[index] || line
            return {line, index}
        })
    }

    renderDiscoveryProgressText = () => {
        const {panel: {discoveryStatus: {
            completedStages,
            totalStages,
        }}} = this.props

        if (totalStages === 0) {
            return null
        }

        const progressText = `${completedStages} / ${totalStages}`

        return (
            <span className="panelStatusLine-discovery-progresstext">
                {progressText}
            </span>
        )
    }

    renderDiscoveryDuration = () => {
        let {panel: {discoveryStatus: {retriggeredAt}}} = this.props

        if (!retriggeredAt) {
            return ''
        }

        retriggeredAt = retriggeredAt
            ? moment.unix(retriggeredAt).utc()
            : null

        return (
            <span className="panelStatusLine-discovery-duration">
                {humanDiff(retriggeredAt)}
            </span>
        )
    }

    renderDiscoveryProgressBar() {
        const {panel: {discoveryStatus}} = this.props
        const percents = Math.round(100 * discoveryStatus.completedStages / discoveryStatus.totalStages)
        const indeterminate = isNaN(percents)

        return (
            <div className={classes('panelStatusLine')}>
                 <div className="panelStatusLine-discovery">{__('Discovery in progress...')}</div>
                 <div>
                    <ProgressBar value={percents}
                        className="panelStatusLine-discovery-progressbar"
                        inline={true}
                        indeterminate={indeterminate}
                    />
                    {this.renderDiscoveryProgressText()}
                    &nbsp;
                    {this.renderDiscoveryDuration()}
                 </div>
            </div>
        )
    }

    renderDefault() {
        const {hasTroubles} = this.props
        return (
            <div className={classes('panelStatusLine', {'panelStatusLine--hasTroubles': hasTroubles})}>
                {this.state.line}
                <div className="panelStatusLine-message">{__('This information can be outdated!')}</div>
            </div>
        )
    }

    render() {
        const {panel, lines} = this.props

        if (panel.discoveryStatus && panel.discoveryStatus.completed !== true) {
            return this.renderDiscoveryProgressBar()
        }

        if (!lines || !lines.some(line => !!line)) {
            return null
        }

        return this.renderDefault()
    }

}