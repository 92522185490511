import * as actions from './actions'
import createListReducer from 'modules/higherOrder/createListReducer'

const handlers = {
    [actions.receive](state, {error, payload: {page}}) {
        if (error) {
            return state
        }

        return {
            ...state,
            maxId: Math.max(state.maxId, ...page),
            overbound: {},
        }
    },

    [actions.setMaxId](state, {payload: {maxId}}) {
        return {
            ...state,
            maxId,
        }
    },

    [actions.setFirstPageStreamable](state, {payload: {isFirstPageStreamable}}) {
        return {
            ...state,
            isFirstPageStreamable,
            isNewerLoading: false,
        }
    },

    [actions.setNewerCount](state, {payload: {count}}) {
        return {
            ...state,
            newerCount: count,
        }
    },

    [actions.fetchNewer](state) {
        return {
            ...state,
            start: 0,
            maxId: null,
            isLoading: true,
            newerCount: 0,
        }
    },

    [actions.receiveOverbound](state, {payload}) {
        return {
            ...state,
            overbound: {
                ...state.overbound,
                ...payload,
            },
        }
    },

    [actions.nextOverbound](state) {
        if (!state.overbound.next) {
            return state
        }

        return {
            ...state,
            start: state.start + state.perPage,
            page: state.overbound.next,
            overbound: {
                prev: state.page,
            },
        }
    },

    [actions.prevOverbound](state) {
        if (!state.overbound.prev) {
            return state
        }

        return {
            ...state,
            start: state.start - state.perPage,
            page: state.overbound.prev,
            overbound: {
                next: state.page,
            },
        }
    },
}

export default createListReducer(actions, handlers, {
    sort: ['-isViewed', '-id'],
    maxId: null,
    newerCount: 0,
    isFirstPageStreamable: false,
    overbound: {},
})