import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import group from 'permissions/group/page'

import {
    withPermission,
} from 'containers/withPermission'

import path from 'utils/path'

class GroupCell extends PureComponent {
    static propTypes = {
        isPermitted: PropTypes.bool,
        row: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }

    renderLink() {
        const {row: {id, name}} = this.props

        return (
            <Link to={path('system.group', {id})}>
                {name}
            </Link>
        )
    }

    renderStatic() {
        const {row: {name}} = this.props

        return (
            <span>
                {name}
            </span>
        )
    }

    render() {
        const {isPermitted} = this.props

        return isPermitted ? this.renderLink() : this.renderStatic()
    }
}

export default withPermission(group)(GroupCell)