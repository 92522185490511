import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Textarea from 'ipmp-react-ui/Textarea'
import Select, {Option} from 'ipmp-react-ui/Select'

import SelectPort from './SelectPort'

const MESSAGE_BROKER_TYPE_HTTP = 'HTTP Gateway'
const MESSAGE_BROKER_TYPE_SERIAL_PORT = 'Serial Port'

class MessageBrokerSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            sender: PropTypes.string,
            login: PropTypes.string,
            password: PropTypes.string,
            host: PropTypes.string,
            port: PropTypes.number,
            useTLS: PropTypes.bool,
            messageTemplate: PropTypes.string,
            serial: PropTypes.number,
        }),
    }

    state = {
        messageBrokerType: MESSAGE_BROKER_TYPE_HTTP,
        port: (this.props.data && this.props.data.port) || (this.props.data && this.props.data.useTLS) ? 443 : 80,
        tls: false,
    }

    handle = (data) => {
        const {data: original = {}, handle} = this.props

        data.id = original.id
        if (this.isHttp) {
            data.serial = 0
        } else {
            data.messageTemplate = ''
            data.port = 0
            data.host = ''
        }

        handle(data)
    }

    static getDerivedStateFromProps(props, state) {
        const {data} = props
        const {serial, messageTemplate} = data || {}

        if (messageTemplate) {
            return {...state, messageBrokerType: MESSAGE_BROKER_TYPE_HTTP}
        }
        if (serial) {
            return {...state, messageBrokerType: MESSAGE_BROKER_TYPE_SERIAL_PORT}
        }

        return {...state}
    }

    get rules() {
        const {isHttp} = this

        return {
            name: {
                presence: {
                    message: __('You should define message broker name'),
                },
            },
            port: {
                presence: isHttp && {
                    message: __('You should define http gateway port'),
                },
                numericality: {
                    onlyInteger: true,
                },
            },
            host: {
                presence: isHttp && {
                    message: __('You should define http gateway host'),
                },
            },
            messageTemplate: {
                presence: isHttp && {
                    message: __('You should define message template'),
                },
            },
            serial: {
                presence: !isHttp && {
                    message: __('You should define serial port'),
                },
            },
        }
    }

    get isHttp() {
        return (this.state && this.state.messageBrokerType) === MESSAGE_BROKER_TYPE_HTTP
    }

    changeType = (e, value) => {
        this.setState(
            {
                messageBrokerType: value,
            }
        )
    }

    changePort = (e) => {
        this.setState({port: e.target.value})
    }

    changeTLS = (e) => {
        const {state} = this
        const tls = e.target.checked

        if (state && tls && state.port === 80) {
            this.setState({port: 443})
        }
        if (state && !tls && state.port === 443) {
            this.setState({port: 80})
        }

        this.setState({tls})
    }

    render() {
        const {data = {}, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={data.id ? __('Edit Message Broker Settings') : __('Add Message Broker Settings')}
                confirmOnDismiss
                {...props}
            >

                <Input
                    label={__('Name')}
                    name="name"
                    defaultValue={data.name}
                    readOnly={!!data.id}
                />

                <Select
                    label={__('Type')}
                    onChange={this.changeType}
                    defaultValue={this.state && this.state.messageBrokerType}
                >
                    <Option label={__('HTTP Gateway')} value={MESSAGE_BROKER_TYPE_HTTP}/>
                    <Option label={__('Serial Port')} value={MESSAGE_BROKER_TYPE_SERIAL_PORT}/>
                </Select>

                <Input
                    label={__('Description')}
                    name="description"
                    defaultValue={data.description}
                />

                {this.isHttp && <Input
                    label={__('Sender (${ORIGINATOR})')}
                    name="sender"
                    defaultValue={data.sender}
                />}

                {this.isHttp && <Input
                    label={__('Login (${USER})')}
                    name="login"
                    defaultValue={data.login}
                />}

                {this.isHttp && <Input
                    label={__('Password (${PASSWORD})')}
                    name="password"
                    type="password"
                    defaultValue={data.password}
                />}

                {this.isHttp && <Input
                    label={__('Host (${HOST})')}
                    name="host"
                    defaultValue={data.host}
                />}

                {this.isHttp && <Input
                    label={__('Port (${PORT})')}
                    name="port"
                    value={this.state.port}
                    onChange={this.changePort}
                />}

                {this.isHttp && <Textarea
                    label={__('Template of GET/POST request to send sms')}
                    name="messageTemplate"
                    defaultValue={data.messageTemplate}
                />}

                {this.isHttp && <Checkbox
                    label={__('Use TLS')}
                    name="useTLS"
                    defaultChecked={data.useTLS}
                    onChange={this.changeTLS}
                />}

                {!this.isHttp && <SelectPort
                    label={__('Serial Port')}
                    name="serial"
                    defaultValue={data.serial}
                />}

            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(setSystemSettings.MessageBroker)
)(MessageBrokerSettings)