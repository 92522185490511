import {
    handleActions
} from 'redux-actions'

import {
    update,
    clear,
    purge
} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const state = {
    byIds: [],
}

export default handleActions({
    [update](state, {payload}) {
        const byIds = payload.reduce((acc, row) => {
            const old = acc[row.id]

            return {
                ...acc,
                [row.id]: {
                    ...old,
                    ...row,
                },
            }
        }, state.byIds || {})

        return {
            ...state,
            byIds,
        }
    },

    [clear]() {
        return state
    },
    ...createPurgeStoreHandler(purge),
}, state)