import {compose} from 'redux'

import {remove} from 'permissions/groups/actions'

import withProps from 'containers/withProps'
import withGroups from 'containers/withGroups'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import withSelection, {withSelectionHandler} from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'
import IconRemove from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withSelection(),
    withGroups(),
    withProps(
        ({remove}) => ({
            onClick: remove,
            Icon: IconRemove,
            title: __('Removing groups'),
            message: __('Do you really want to remove selected groups?'),
            children: __('Remove'),
        }),
    ),
    withSelectionHandler(),
    withConfirmation(),
)(Button)