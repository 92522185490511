import Page from 'ipmp-react-ui/Page'

import withProps from 'containers/withProps'

import ReportsTable from './ReportsTable'
import ReportsGridBar from './ReportsGridBar'
import ReportsSearch from './ReportsSearch'

export default withProps({
    Top: ReportsSearch,
    Bar: ReportsGridBar,
    Content: ReportsTable,
})(Page)