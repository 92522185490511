import React, {Component} from 'react'
import IconBookmarkOutline from 'icons/bookmark-outline.svg'
import IconCheck from 'icons/bookmark.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {__} from 'utils/i18n'

export default class BackupCell extends Component {
    handleClick = (e) => {
        e.stopPropagation()

        const {allowBackup, row, markAsBackup} = this.props

        if (allowBackup && !row.backup) {
            markAsBackup(row)
        }
    }

    renderContent() {
        const {row, allowBackup} = this.props

        if (row.backup) {
            return (
                <Tooltip tooltip={__('Not in rotation')}>
                    <IconCheck className="configurations-icon configurations-icon--active"/>
                </Tooltip>
            )
        }

        if (!allowBackup) {
            return (
                <IconBookmarkOutline className="configurations-icon"/>
            )
        }

        return (
            <Tooltip tooltip={__('Exclude from rotation')}>
                <IconBookmarkOutline className="configurations-icon"/>
            </Tooltip>
        )
    }

    render() {
        return (
            <div onClick={this.handleClick}>
                {this.renderContent()}
            </div>
        )
    }
}