import {all} from 'redux-saga/effects'

import list from './list/saga'
import store from './store/saga'
import upgradeStatusRunnersList from './upgradeStatusRunnersList/saga'

export default function* () {
    yield all([
        list(),
        store(),
        upgradeStatusRunnersList()
    ])
}
