import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import keyBy from 'lodash-es/keyBy'

import * as actions from './actions'

const defaultState = {}

export default handleActions({
    [actions.fetch](state, {payload: panelId}) {
        return set(state, [panelId, 'isLoading'], true)
    },

    [actions.startPoll](state, {payload: panelId}) {
        return set(state, [panelId, 'isLoading'], true)
    },

    [actions.stopPoll](state, {payload: panelId}) {
        return set(state, [panelId, 'isLoading'], false)
    },

    [actions.receive](state, {payload, error, meta: {panelId}}) {
        if (error) {
            return set(state, panelId, store => ({
                ...store,
                isLoading: false,
                error: payload,
            }))
        }

        return set(state, panelId, store => ({
            ...store,
            isLoading: false,
            error: null,
            byIds: keyBy(payload, 'id'),
        }))
    },
}, defaultState)

