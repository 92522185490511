import {compose} from 'redux'

import {remove} from 'permissions/reports/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withReports from 'containers/withReports'
import withProps from 'containers/withProps'

import RemoveIcon from 'ipmp-react-ui/icons/remove.svg'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withReports(),
    withProps(({remove}) => ({
        onClick: remove,
        Icon: RemoveIcon,
        label: __('Remove'),
        title: __('Remove reports'),
        message: __('Do you want to remove selected reports?'),
    })),
    withSelectionHandler(),
    withConfirmation(),
)(Button)