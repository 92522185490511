import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
} from 'modules/upgradeStatus/upgradeStatusRunnersList/actions'
import {retry} from 'modules/upgradeStatus/store/actions'
import {selectUpgradeStatusRunnersListRows} from 'modules/upgradeStatus/upgradeStatusRunnersList/selectors'

export default function withUpgradeStatusRunners() {
    return compose(
        withPerPage('upgradeStatusRunners'),
        connect(
            (state) => {
                const {
                    isLoading,
                    error,
                    total,
                    start,
                } = state.upgradeStatus.upgradeStatusRunnersList

                return {
                    rows: selectUpgradeStatusRunnersListRows(state),
                    isLoading,
                    error,
                    total,
                    start,
                    stoppingIds: state.runners.store.stoppingIds,
                }
            },
            (dispatch) => bindActionCreators({
                init,
                reset,
                setStart,
                fetch,
                onPageChange: setStart,
                startPoll,
                stopPoll,
                retry,
            }, dispatch),
        ),
    )
}