import React from 'react'
import {compose} from 'redux'

import Charts from 'components/Dashboard/Charts'

import EventsRateChart from './Charts/EventsRateChart'
import OfflineFaultyPanelsChart from './Charts/OfflineFaultyPanelsChart'
import AllProcesses from './Charts/AllProcesses'
import ConnectedPanels from './Charts/ConnectedPanels'

const ChartsComponent = () => (
    <Charts>
        <EventsRateChart/>
        <OfflineFaultyPanelsChart/>
        <AllProcesses/>
        <ConnectedPanels/>
    </Charts>
)

export default compose()(ChartsComponent)