import React, {Component} from 'react'
import {compose} from 'redux'

import {edit} from 'permissions/basicConfiguration/actions'

import withBasicConfiguration from 'containers/withBasicConfiguration'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import IconGoBack from 'ipmp-react-ui/icons/go-back.svg'
import IconGoBackRtl from 'ipmp-react-ui/icons/go-back-rtl.svg'
import Definition from 'ipmp-react-ui/Definition'
import path from 'utils/path'
import SaveBasicConfiguration from './GridBar/SaveBasicConfiguration'
import isRtl from 'ipmp-react-ui/_utils/isRtl'

class BasicConfigurationGridBar extends Component {

    handleGoBack = () => {
        const {history} = this.props
        history.push(path('system.basicConfigurations', {}))
    }

    render() {
        const {name, vendor, changes} = this.props
        const isChanged = changes && Object.keys(changes).length > 0

        const IconBack = isRtl()
            ? IconGoBackRtl
            : IconGoBack

        return (
            <Bar>
                <Button onClick={this.handleGoBack} className="btn--goBack"><IconBack/></Button>

                <Definition
                    multiLine
                    className="title title--large"
                    title={name}
                    detail={vendor}/>

                <BarSpace/>

                <SaveBasicConfiguration disabled={!isChanged}/>
            </Bar>
        )
    }
}

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    withBasicConfiguration(),
)(BasicConfigurationGridBar)