import StringValue from 'components/Configuration/Row/StringValue'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'

export default class IntValue extends StringValue {

    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    getMaxLength() {
        return 20 + 1 // 64 bit number and one minus symbol
    }

    validate(value) {
        if (value === null || value === '' || value === undefined) {
            return __('Required')
        }

        const {item} = this.props

        const intVal = parseInt(value, 10)

        if (intVal.toString() !== value.toString()) {
            return __('Not an integer number')
        }

        if (item.hasOwnProperty('max') && intVal > item.max) {
            return __('Should be less than or equal to %s', item.max)
        }

        if (item.hasOwnProperty('min') && intVal < item.min) {
            return __('Should be greater than or equal to %s', item.min)
        }
    }
}