export const TIMEOUT_DISABLED = -1
export const TIMEOUT_AUTOLOGOUT_DEFAULT = TIMEOUT_DISABLED

export const TIMEOUTS_AUTOLOGOUT = [
    TIMEOUT_DISABLED,
    10,
    30,
    60,
    4 * 60,
]
