import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import IconKey from 'ipmp-react-ui/icons/key.svg'

import {showEditRolePermission} from 'modules/modals/actions'
import Button from 'ipmp-react-ui/Button'

export default connect(
    () => ({
        flat: true,
        small: true,
        Icon: IconKey,
    }),
    (dispatch, {row: {id}}) => bindActionCreators({
        onClick: () => showEditRolePermission(id),
    }, dispatch),
)(Button)