import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'
import Empty from 'ui/Empty'
import withProps from 'containers/withProps'

const columns = [
    {
        render: ({serial, account}) => (
            <Definition title={serial} detail={account} />
        ),
    },
    {
        render: ({group}) => group || <Empty/>,
    },
    {
        render: ({model}) => model || <Empty/>,
    },
]

export default compose(
    withProps({
        columns,
    })
)(Table)
