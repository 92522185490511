import mapper from 'utils/dataMapper'
import {getVendor, isNeoSerial} from 'utils/panelType'
import {mapProcess} from 'api/processes'

export const map = mapper(data => ({
    id: parseInt(data.unt_id),
    groupId: parseInt(data.utg_id),
    userId: parseInt(data.usr_id),
    serial: data.unt_serial,
    isNeo: isNeoSerial(data.unt_serial),
    vendor: getVendor({family: data.family}),
    account: data.unt_account,
    group: data._utg_name,
    simNumber: data.unt_sim_number,
    contact: {
        name: data.unt_contact_name,
        email: data.unt_contact_email,
        phone: data.unt_contact_phone,
        address: data.unt_contact_address,
        remark: data.unt_remark,
    },
    model: data.unt_model,
    family: data.family,
    info: {
        ip: data.unt_ip_addr,
        model: data.unt_model,
    },
    isActivated: data.unt_activated === '1',
    configuratorApp: data.unt_installer_remote_access === 'allow',
    userApp: data.unt_remote_access === 'allow',
}))

export const mapDiscoveryStatus = mapper(data => ({
    completedStages: parseInt(data.completed_stages),
    totalStages: parseInt(data.total_stages),
    completed: data.completed,
    retriggeredAt: data.retriggered_at,
}))

export const mapOnline = mapper(data => ({
    id: parseInt(data.unt_id),
    connected: data.connected,
    online: data.online,
    refreshProcess: data.refreshProcess ? mapProcess(data.refreshProcess) : null,
    isActivated: data.activated,
    discoveryStatus: mapDiscoveryStatus(data.discoveryStatus),
}))

export const keysMap = {
    id: 'unt_id',
    serial: 'unt_serial',
    name: 'unt_contact_name',
    email: 'unt_contact_email',
    phone: 'unt_contact_phone',
    address: 'unt_contact_address',
    remark: 'unt_remark',
    groupId: 'utg_id',
    account: 'unt_account',
    simNumber: 'unt_sim_number',
}
export const mapModules = ({type, ...data}) => {
    if (typeof type === 'undefined') {
        return data
    }

    return {
        ...data,
        _unt_module_gprs: type.includes('gprs') ? 'offline' : 'none',
        _unt_module_bb: type.includes('bba') ? 'offline' : 'none',
    }
}

//eslint-disable-next-line camelcase
export const mapUsers = mapper(({max_users_count, user_names}) => ({
    maxUsersCount: max_users_count,
    userNames: user_names,
}))

export const mapIsPanelActivated = mapper(data => ({
    isActivating: Boolean(data.isPanelActivating),
}))