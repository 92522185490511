import {connect} from 'react-redux'
import {compose,bindActionCreators} from 'redux'

import {refreshState} from 'modules/runners/store/actions'
import {RefreshStateItem} from 'pages/Panels/GridBar/DropDowns/Items/RefreshStateItem'
import {withSelectionFeature} from 'containers/withFeature'
import {REFRESH_STATE} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'

export default compose(
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: refreshState,
        }, dispatch),
    ),
    withSelectionFeature(
        REFRESH_STATE,
        selectSelectedRunnersFeature
    ),
)(RefreshStateItem)