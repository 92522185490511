import createRowsResponseParser from 'api/base/createRowsResponseParser'
import moment from 'moment/moment'
import Promise from 'promise-polyfill'

import parseDate from 'api/base/parseDate'
import dataMapper from 'utils/dataMapper'
import {get} from 'api/http'
import getProperty from 'lodash-es/get'

const keysMap = {
    users: 'usr_id',
    statuses: 'anl_status',
    activities: 'anl_activity',
    datetime: 'anl_time',
}

export const mapActionLog = dataMapper((actionLog) => ({
    id: parseInt(actionLog.id),
    userId: parseInt(actionLog.usr_id),
    time: parseDate(actionLog.anl_time),
    method: actionLog.anl_method,
    activity: actionLog.anl_activity,
    description: actionLog.anl_description || '',
    url: actionLog.anl_url,
    params: actionLog.anl_params,
    data: actionLog.anl_data,
    ip: actionLog.anl_ip,
    status: actionLog.anl_status,
    message: actionLog.anl_message,
    userName: actionLog.usr_name,
}))

/** implements another filter logic, cuz action log api not work like other lists */
function generateActionLogFilters(filters) {
    return filters.reduce(
        (acc, item) => {
            const name = item.name
            let value = item.hasOwnProperty('key') ? item.key : item.value

            const matches = /{([-+]?\d+)?(day|month|year)(?::([-+]?\d+)(day|month|year))?}/.exec(value)

            if (matches) {
                const from = moment().add(matches[1], matches[2])
                const to = moment().add(matches[3], matches[4])

                value = {
                    from: moment.min(from, to),
                    to: moment.max(from, to),
                }
            }

            if (moment.isMoment(value)) {
                value = {
                    from: value,
                    to: value,
                }
            }

            if (value && (value.from || value.to)) {
                const fromKey = `filter[${keysMap[name] || name}][from]`
                const toKey = `filter[${keysMap[name] || name}][to]`

                return {
                    ...acc,
                    [fromKey]: moment(value.from).format('YYYY-MM-DD 00:00:00'),
                    [toKey]: moment(value.to).format('YYYY-MM-DD 23:59:59'),
                }
            }

            const key = `filter[${keysMap[name] || name}][]`

            return {
                ...acc,
                [key]: [
                    ...(acc[key] || []),
                    value,
                ],
            }
        },
        {},
    )
}

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateActionLogFilters(filters)

    return get('/actionlog/list', {start, count, ...filter, search: query})
        .then(createRowsResponseParser(mapActionLog))
}

function mapRowsToValues(data) {
    const values = getProperty(data, 'rows')
    delete data.rows
    return {...data, values}
}

function suggestsUsers() {
    return get('actionlog/users')
        .then(createRowsResponseParser(data => ({
            key: data.usr_id,
            value: data.usr_name,
        })))
        .then(mapRowsToValues)
}

function suggestsStatuses() {
    return get('actionlog/statuses')
        .then(createRowsResponseParser())
        .then(mapRowsToValues)
}

function suggestsActivities() {
    return get('actionlog/activities')
        .then(createRowsResponseParser())
        .then(mapRowsToValues)
}

export function suggest() {
    return Promise.all([
        suggestsUsers(),
        suggestsStatuses(),
        suggestsActivities(),
    ]).then(data => ({
        users: data[0],
        statuses: data[1],
        activities: data[2],
    }))
}