import {PARTITION_ALL} from 'constants/partitions'
import {handleActions} from 'redux-actions'

import * as actions from './actions'
import keyBy from 'lodash-es/keyBy'
import {set} from 'immutable-modify'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [actions.receive](state, {error, payload, meta: {panelId}}) {
        if (error) {
            return set(
                state,
                ['byIds', panelId],
                data => ({
                    ...data,
                    error: payload,
                }),
            )
        }

        const {partitionStates, partitions, stateSets} = payload

        return set(
            state,
            ['byIds', panelId],
            data => ({
                ...data,
                error: null,
                hasPartitions: partitions.find(partition => partition.id !== PARTITION_ALL),
                partitionStates: keyBy(partitionStates, 'id'),
                partitions: keyBy(partitions, 'id'),
                stateSets: Object.keys(stateSets).reduce((acc, key) => {
                    acc[key] = keyBy(stateSets[key], 'name')

                    return acc
                }, {}),
            }),
        )
    },

    [actions.sendState](state, action) {
        const {panelId, partition, modifier} = action.payload
        const armingState = action.payload.state

        return set(
            state,
            ['byIds', panelId, 'sentState', partition],
            {state: armingState, modifier},
        )
    },

    [actions.renamePartition](state, {payload: {panelId, partitionId, name}}) {
        return set(
            state,
            ['byIds', panelId, 'partitions', partitionId, 'name'],
            name
        )
    },
}, defaultState)