import {__} from 'utils/i18n'
import {warn} from 'utils/log'

const EVENTS = 'Events'
const FAULTS = 'Faults'
const PROCESSES = 'Processes'
const RI_TESTS = 'RI Tests'

export default function getContentName(type) {
    switch (type) {
        case EVENTS: return __('Events')
        case FAULTS: return __('Faults')
        case PROCESSES: return __('Processes')
        case RI_TESTS: return __('RI Tests')
    }

    warn(`Unknown report type ${type}`)

    return type
}