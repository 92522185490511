import createPermission, {any} from 'permissions/createPermission'

import list from './list'
import selection from './selection'

export default createPermission(
    any(
        list,
        selection,
    ),
)