import {withPermission} from 'containers/withPermission'
import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {exports, request} from 'permissions/panel/devices/video/actions'

import withLoader from 'containers/withLoader'
import {withDeviceVideoOnDemand} from 'containers/withDevices'

import Video from 'components/Video'
import {Tab} from 'ipmp-react-ui/Tabs'

import {__} from 'utils/i18n'

class VideoOnDemandTab extends Component {
    static propTypes = {
        isRequestAllowed: PropTypes.bool,
        isExportAllowed: PropTypes.bool,
        panelId: PropTypes.number,
        device: PropTypes.shape({
            id: PropTypes.number,
            zone: PropTypes.number,
        }),
        onStartRecord: PropTypes.func,
        fetchVideo: PropTypes.func,
        video: PropTypes.shape({
            frames: PropTypes.arrayOf(PropTypes.string),
            video: PropTypes.objectOf(PropTypes.string),
            time: PropTypes.string,
            isClosed: PropTypes.bool,
        }),
        process: PropTypes.shape({
            status: PropTypes.string,
            isFailed: PropTypes.bool,
            isSuccessful: PropTypes.bool,
            isRunning: PropTypes.bool,
        }),
    }

    getLocation() {
        const {device} = this.props

        return device.traits.location && device.traits.location.name
    }

    render() {
        const {onStartRecord, video: data, process, isRequestAllowed, isExportAllowed, device: {traits: {vod}}} = this.props
        const {frames, video, time} = data || {}
        return (
            <Tab name={__('Video On Demand')}>
                <Video
                    title={this.getLocation()}
                    frames={frames}
                    video={video}
                    time={time}
                    process={process}
                    onStartRecord={onStartRecord}
                    isRequestAllowed={isRequestAllowed}
                    isExportAllowed={isExportAllowed}
                    trait={vod}
                />
            </Tab>
        )
    }
}


export default compose(
    withPermission({
        isRequestAllowed: request,
        isExportAllowed: exports,
    }),
    withDeviceVideoOnDemand(),
    withLoader(
        ({fetch}) => fetch(),
        ({erase}) => erase(),
    ),
)(VideoOnDemandTab)