import {warn, err} from 'utils/log'

let dataMapper, Proxy

if (typeof window !== 'undefined') {
    Proxy = window.Proxy
}

if (PRODUCTION || !Proxy) {
    dataMapper = map => map
} else {
    dataMapper = map => (data, ...args) => {
        let fields = Object.keys(data)

        const proxy = new Proxy(data, {
            get(target, prop) {
                if (typeof target[prop] !== 'function') {
                    if (!target.hasOwnProperty(prop)) {
                        err(`Trying to read non-existing field '${prop}' of response`)
                    } else {
                        fields = fields.filter(key => key != prop)
                    }
                }

                return target[prop]
            },
        })

        const result = map(proxy, ...args)

        if (fields.length > 0) {
            warn(`Unread fields from response: '${fields.join('\', \'')}'`)
        }

        return result
    }
}

export default dataMapper