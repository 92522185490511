import {call} from 'redux-saga/effects'
import chunk from 'lodash-es/chunk'

const CHUNK_PROCESS_STATUS = 300
import * as api from 'api/processes'

/**
 * Prevent `431 Request Header Fields Too Large` status
 *
 * @export
 * @param {Array} toRefresh
 * @returns {Array}
 */
export default function getChunkToRefresh(toRefresh: Array) : Array {
    return chunk(toRefresh, CHUNK_PROCESS_STATUS)
        .reduce((calls, toRefresh) => {
            calls.push(call(api.status, toRefresh))
            return calls
        }, [])
}