import React, {PureComponent} from 'react'
import {__, __n} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import {changeGroup} from 'modules/forms/handlers'
import withForm from 'containers/withForm'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchGroups} from 'modules/groups/select/actions'

const GroupSelect = withSelectLoader(
    fetchGroups,
    state => state.groups.select.isLoading,
    state => Object.values(state.groups.store.byIds).map(
        ({id, name}) => ({label: name, value: id}),
    ),
    10
)(Autocomplete)

class ChangePanelGroup extends PureComponent {

    rules = {
        groupId: {
            presence: true,
        },
    }

    handle = ({groupId}) => {
        const {panelIds, handle} = this.props
        handle(panelIds, groupId)
    }

    render() {
        const {panelIds, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__n('Assign %d panel to group', 'Assign %s panels to group', panelIds.length, [panelIds.length])}
                {...props}
            >
                <GroupSelect showTypeForMore
                    label={__('Group')}
                    name="groupId"/>
            </ModalCardForm>
        )
    }
}

export default withForm(changeGroup)(ChangePanelGroup)
