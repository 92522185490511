import {handleActions} from 'redux-actions'
import mapValues from 'lodash-es/mapValues'

import {
    toggleWalktest,
    receiveWalktest,
    startWalktest,
    stopWalktest,
} from './actions'

const defaultState = {
    isActive: false,
    isRunning: false,
    status: {},
}

export default handleActions({
    [toggleWalktest](state, {payload: {panelId}}) {
        const old = state[panelId] || defaultState

        return {
            ...state,
            [panelId]: {
                ...old,
                isActive: !old.isActive,
            },
        }
    },

    [startWalktest](state, {payload: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                isRunning: true,
            },
        }
    },

    [startWalktest](state, {payload: {panelId}}) {
        const old = state[panelId] || {}
        const status = old.status ? mapValues(old.status, () => 'pending') : null

        return {
            ...state,
            [panelId]: {
                ...old,
                isRunning: true,
                process: {},
                status,
            },
        }
    },

    [stopWalktest](state, {payload: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                isStopping: true,
            },
        }
    },

    [receiveWalktest](state, {error, payload, meta: {panelId}}) {
        const old = state[panelId] || {}

        if (error) {
            return {
                ...state,
                [panelId]: {
                    ...old,
                    error: payload,
                },
            }
        }

        const {process, zones} = payload
        const isRunning = process && process.status !== 'succeeded' && process.status !== 'failed'
        const falseStatus = isRunning ? 'pending' : 'fail'

        return {
            ...state,
            [panelId]: {
                ...old,
                isStopping: old.isStopping && isRunning,
                isRunning,
                process,
                status: mapValues(zones, value => value ? 'ok' : falseStatus),
            },
        }
    },
}, {})