import {showBlockedNotificationsHelpModal} from 'modules/modals/actions'
import {eventChannel, END} from 'redux-saga'
import {takeEvery, call, put, all, fork, take, select, cancel} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import Promise from 'promise-polyfill'

import SuperVisionWorker from './supervision.worker.js'

import {mapEvent, fetch} from 'api/events'

import * as eventActions from './actions'
import * as commands from './commands'

import {setAlarmsEnabled, setAlarmsNotificationsEnabled} from 'modules/settings/actions'
import {loggedIn, loggedOut} from 'modules/auth/sign/actions'
import {update as updatePermissions} from 'modules/auth/permissions/actions'
import {snackShow} from 'modules/snacks'

import path from 'utils/path'
import {__, __n} from 'utils/i18n'
import notify from 'utils/notification'

import alarmIcon from 'assets/alerts/alarm.png'
import alertIcon from 'assets/alerts/alert.png'
import alarmSound from 'assets/alerts/alert.mp3'

const alarmAudio = new Audio(alarmSound)

export default function* () {
    yield all([
        supervisor(),
        takeEvery(setAlarmsNotificationsEnabled, requestNotificationsPermissions),
    ])
}

export function* supervisor() {
    let task

    while (true) {
        const {isEnabled} = yield select(state => state.settings.alarms)
        const {list} = yield select(state => state.auth.permissions.events || {})
        const {user} = yield select(state => state.auth.sign)

        if (isEnabled && list && user) {
            if (!task) {
                task = yield fork(supervisionSaga)
            }
        } else {
            if (task) {
                yield cancel(task)
                task = null
            }
        }

        yield take([
            loggedIn,
            loggedOut,
            updatePermissions,
            setAlarmsEnabled,
        ])
    }
}

function* supervisionSaga() {
    console.log('supervision start')
    const worker = new SuperVisionWorker()
    worker.port.start()

    yield fork(requestNotificationsPermissions)

    const lastId = yield getLastEventId()
    const messageChannel = yield call(createMessageChannel, worker.port, lastId)
    const senderTask = yield takeEvery(eventActions.setLastEventId, watchLastEventId, worker.port)

    const onUnload = () => {
        worker.port.postMessage({
            command: commands.COMMAND_UNSUBSCRIBE,
        })
    }

    window.addEventListener('unload', onUnload)

    try {
        while (true) {
            const {command, count, rows, confirmationId} = yield take(messageChannel)

            switch (command) {
                case commands.COMMAND_UPDATE:
                    yield put(eventActions.setCount(count))
                    break

                case commands.COMMAND_ALERT:
                    yield worker.port.postMessage({
                        command: commands.COMMAND_CONFIRM,
                        confirmationId,
                    })

                    yield triggerAlert(rows)
                    break
            }
        }
    } finally {
        window.removeEventListener('unload', onUnload)
        messageChannel.close()
        yield cancel(senderTask)
        console.log('supervision stop')
    }
}

function watchLastEventId(port, {payload: {lastId}}) {
    port.postMessage({
        command: commands.COMMAND_RESET,
        currentAlertId: lastId,
    })
}

function* requestNotificationsPermissions() {
    const {isNotificationsEnabled} = yield select(state => state.settings.alarms)

    if (!isNotificationsEnabled) {
        return
    }

    try {
        yield call(() => new Promise((resolve, reject) => {
            Notification.requestPermission(permission => {
                if (permission === 'granted') {
                    resolve()
                } else {
                    reject()
                }
            })
        }))
    } catch (e) {
        yield put(setAlarmsNotificationsEnabled(false))
        yield put(snackShow(
            __('Notifications blocked by browser'),
            showBlockedNotificationsHelpModal(),
            __('Help'),
        ))
    }
}

function* triggerAlert(rows) {
    const {isSoundEnabled, isNotificationsEnabled} = yield select(state => state.settings.alarms)

    if (isSoundEnabled) {
        alarmAudio.play()
    }

    if (isNotificationsEnabled) {
        rows = rows.map(mapEvent)

        const alertCount = rows.filter(({severity}) => severity === 'alert').length
        const alarmCount = rows.filter(({severity}) => severity !== 'alert').length

        const notification = notify({
            title: getTitle(alertCount, alarmCount),
            body: rows.pop().description,
            icon: alarmCount ? alarmIcon : alertIcon,
        })

        const clickChanel = yield call(createClickChannel, notification)
        try {
            yield take(clickChanel)
            yield put(push(path('events', {})))
            window.focus()
            notification.close()
        } finally {
            clickChanel.close()
        }
    }
}

function* getLastEventId() {
    const {rows} = yield call(fetch, {
        start: 0,
        perPage: 1,
        sort: '-id',
    })

    if (rows.length) {
        return rows[0].id
    }

    return 0
}

function createMessageChannel(port, currentAlertId) {
    return eventChannel(emit => {
        port.onmessageerror = e => {
            console.warn('port alarm recieve error', e)
        }

        port.onmessage = ({data}) => {
            console.log(data)
            emit(data)
        }

        port.postMessage({
            command: commands.COMMAND_START,
            currentAlertId,
        })

        return () => {
            port.postMessage({
                command: commands.COMMAND_STOP,
            })

            port.close()
        }
    })
}

function createClickChannel(eventTarget) {
    return eventChannel(emit => {
        eventTarget.onclick = (event) => {
            event.preventDefault()
            emit(event)
        }

        eventTarget.onclose = () => {
            emit(END)
        }

        return () => {
            eventTarget.onclick = null
            eventTarget.onclose = null
        }
    })
}

function getTitle(alertCount, alarmCount) {
    if (alertCount + alarmCount === 1) {
        return alertCount ? __('ALERT') : __('ALARM')
    }

    return (alarmCount ? __n('%d ALARM', '%d ALARMS', alarmCount) + ' ' : '')
        + (alertCount ? __n('%d ALERT', '%d ALERTS', alertCount) : '')
}