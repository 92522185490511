import {createAction} from 'redux-actions'

import * as modals from './constants'

export const hide = createAction('MODAL/HIDE', () => null)
export const show = createAction('MODAL/SHOW', (type, args) => ({type, args}))

export function showAboutModal() {
    return show(modals.MODAL_ABOUT)
}

export function showAboutLicenseFeaturesModal() {
    return show(modals.MODAL_ABOUT_LICENSE_FEATURES)
}

export function showBlockedNotificationsHelpModal() {
    return show(modals.MODAL_BLOCKED_NOTIFICATIONS_HELP)
}

export function showConfirmationModal(action, messages) {
    action = Array.isArray(action) ? action : [action]
    return show(modals.MODAL_CONFIRMATION, {action, messages})
}

export function showAddPanelModal() {
    return show(modals.MODAL_ADD_PANEL)
}

export function showEditPanelInfoModal(panelId) {
    return show(modals.MODAL_EDIT_PANEL_INFO, {panelId})
}

export function showEditPanelCustomerInfoModal(panelId) {
    return show(modals.MODAL_EDIT_PANEL_CUSTOMER_INFO, {panelId})
}

export function showMarkForServiceModal(panelIds) {
    return show(modals.MODAL_MARK_FOR_SERVICE, {panelIds})
}

export function showReassignServiceModal(panelIds) {
    return show(modals.MODAL_REASSIGN_SERVICE, {panelIds})
}

export function showResolveFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_RESOLVE, {panelIds})
}

export function showSuspendFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_SUSPEND, {panelIds})
}

export function showResumeFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_RESUME, {panelIds})
}

export function showChangePanelGroupModal(panelIds) {
    return show(modals.MODAL_CHANGE_PANEL_GROUP, {panelIds})
}

export function showMakeBasicConfigurationModal(panelId) {
    return show(modals.MODAL_MAKE_BASIC_CONFIGURATION, {panelId})
}

export function showPushBasicConfigurationModal(panelIds) {
    return show(modals.MODAL_PUSH_BASIC_CONFIGURATION, {panelIds})
}

export function showActivatePanel(unitId) {
    return show(modals.MODAL_ACTIVATE_PANEL, {unitId})
}

export function showAddGroup() {
    return show(modals.MODAL_ADD_GROUP)
}

export function showEditGroup(groupId) {
    return show(modals.MODAL_EDIT_GROUP, {groupId})
}

export function showScheduleRri(panelIds) {
    if (!Array.isArray(panelIds)) {
        panelIds = [panelIds]
    }

    return show(modals.MODAL_SCHEDULE_RRI, {panelIds})
}

export function showCreateReportModal(panelIds, onSuccess) {
    return show(modals.MODAL_CREATE_REPORT, {panelIds, onSuccess})
}

export function showScheduleRriByCsvModal() {
    return show(modals.MODAL_SCHEDULE_RRI_CSV)
}

export function showEditCentralStationLinksModal(groupId, centralStationId) {
    return show(modals.MODAL_EDIT_CENTRAL_STATION_LINKS, {groupId, centralStationId})
}

export function showCentralStationCreateModal() {
    return show(modals.MODAL_ADD_CENTRAL_STATION)
}

export function showCentralStationEditModal(id) {
    return show(modals.MODAL_EDIT_CENTRAL_STATION, {centralStationId: parseInt(id)})
}

export function showAddUserModal() {
    return show(modals.MODAL_ADD_USER)
}

export function showEditUserModal(userId) {
    return show(modals.MODAL_EDIT_USER, {userId})
}

export function showRemoveUserModal(userIds) {
    return show(modals.MODAL_REMOVE_USER, {userIds})
}

export function showAddDeviceModal(panelId) {
    return show(modals.MODAL_ADD_DEVICE, {panelId})
}

export function showRemoteInspectionValuesModal({editable}) {
    return show(modals.MODAL_REMOTE_INSPECTION_VALUES, {editable})
}

export function showEditProfileInfoModal() {
    return show(modals.MODAL_EDIT_PROFILE_INFO)
}

export function showChangePasswordModal() {
    return show(modals.MODAL_CHANGE_PASSWORD)
}

export function showEditSettingsModal() {
    return show(modals.MODAL_EDIT_SETTINGS)
}

export function showRebootPlink(panelId) {
    return show(modals.MODAL_REBOOT_PLINK, {panelId})
}

export function showEnablePlinkLogging(panelId) {
    return show(modals.MODAL_ENABLE_PLINK_LOGGING, {panelId})
}

export function showDisablePlinkLogging(panelId) {
    return show(modals.MODAL_DISABLE_PLINK_LOGGING, {panelId})
}

export function showAddRole() {
    return show(modals.MODAL_ADD_ROLE)
}

export function showEditRole(roleId) {
    return show(modals.MODAL_EDIT_ROLE, {roleId})
}

export function showRemoveRole(roleIds) {
    return show(modals.MODAL_REMOVE_ROLE, {roleIds})
}

export function showEditRolePermission(roleId) {
    return show(modals.MODAL_EDIT_ROLE_PERMISSIONS, {roleId})
}

export function showSaveSearchModal(page, filter) {
    return show(modals.MODAL_SAVE_SEARCH_SCOPE, {page, filter})
}

export function showDeviceMeteoChart(panelId, deviceId, type) {
    return show(modals.MODAL_SHOW_DEVICE_METEO_CHART, {panelId, deviceId, type})
}

export function showRenameDeviceModal(panelId, device) {
    return show(modals.MODAL_RENAME_DEVICE, {panelId, device})
}

export function showRenamePartitionModal(panelId, partition) {
    return show(modals.MODAL_RENAME_PARTITION, {panelId, partition})
}

export function showBatchInfoModal(batchId) {
    return show(modals.MODAL_BATCH_INFO, {batchId})
}

export function showUnavailableFeatureModal({
    message,
    unavailablePanels,
    availableIds,
    action,
}) {
    return show(modals.MODAL_UNAVAILABLE_FEATURE, {
        message,
        unavailablePanels,
        availableIds,
        action,
    })
}

export function showFirmwareUpgradeModal(data) {
    return show(modals.MODAL_FIRMWARE_UPGRADE, {...data})
}

export function showTriggerDiscoveryModal(panelIds) {
    return show(modals.MODAL_TRIGGER_DISCOVERY, {panelIds})
}

export function showSystemSettingsCellularConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED, {...props})
}

export function showSystemSettingsBroadbandConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED, {...props})
}

export function showSystemSettingsCommonConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED, {...props})
}

export function showSystemSettingsSupervisionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_SUPERVISION, {...props})
}

export function showSystemSettingsRemoteInspectionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION, {...props})
}

export function showSystemSettingsInteractiveSessionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION, {...props})
}

export function showSystemSettingsUserNotificationsModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS, {...props})
}

export function showSystemSettingsAdvertisementModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_ADVERTISEMENT, {...props})
}

export function showSystemSettingsEditMessageBrokerModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER, {...props})
}

export function showSystemSettingsAddMessageBrokerModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER, {...props})
}
