import {compose} from 'redux'

import list from 'permissions/roles/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRoles from 'containers/withRoles'
import withProps from 'containers/withProps'

import Pager from 'ipmp-react-ui/Pager'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withRoles(),
    withProps(({isDataLoading})=>({disabled: isDataLoading})),
)(Pager)