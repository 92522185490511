import {all} from 'redux-saga/effects'

import result from './result/saga'
import expected from './expected/saga'

export default function* () {
    yield all([
        result(),
        expected(),
    ])
}