import createPermission, {any} from 'permissions/createPermission'

import {enroll, refreshRssi} from './actions'
import list from './list'

export default createPermission(
    any(
        list,
        enroll,
        refreshRssi,
    ),
)