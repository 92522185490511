import {compose} from 'redux'
import {connect} from 'react-redux'

import {withRouterPanelId} from './withPanel'
import {selectPrevNextDeviceId} from 'modules/devices/list/selectors'

export default function withDevicesNavigation() {
    return compose(
        withRouterPanelId(),
        connect(selectPrevNextDeviceId),
    )
}