import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import IconFile from 'ipmp-react-ui/icons/file.svg'

import {__} from 'utils/i18n'
import {createReport} from 'permissions/panels/selection'

export const CreateReportButton = compose(
    withProps(() => ({
        Icon: IconFile,
        children: __('Create Report'),
    })),
    withPermission({isVisible: createReport}),
    withVisibility(),
)(Button)

export default CreateReportButton