import {compose} from 'redux'

import {schedule} from 'permissions/panel/remoteInspections/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import withProps from 'containers/withProps'

import CalendarIcon from 'ipmp-react-ui/icons/calendar.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: schedule}),
    withVisibility(),
    withPanelRemoteInspection(),
    withProps(({schedule}) => ({
        onClick: schedule,
        Icon: CalendarIcon,
        label: __('Schedule Inspection'),
    })),
)(Button)