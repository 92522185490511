import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import Button from 'ipmp-react-ui/Button'

import {upgradeList} from 'permissions/firmware/upgrade'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'

import {__n} from 'utils/i18n'
import {selectFirmwareListTotal} from 'modules/firmware/list/selectors'
import withSelection from 'containers/withSelection'
import {showFirmwareUpgradeModal} from 'modules/modals/actions'
import {IdSetSubstractive} from 'modules/selection/store'

export default compose(
    withPermission({isVisible: upgradeList}),
    withSelection(),
    withVisibility(),
    withRouter,
    connect(
        selectFirmwareListTotal,
        (dispatch, {
            match: {params: {applianceId, upgradePackageId}},
            applianceType,
            selection,
        }) => bindActionCreators({
            onClick: () => showFirmwareUpgradeModal({
                selection,
                applianceId: parseInt(applianceId),
                upgradePackageId: parseInt(upgradePackageId),
                type: applianceType,
                upgradeAll: selection.isEmpty()
                    || selection instanceof IdSetSubstractive,
            }),
        }, dispatch),
    ),
    withProps(({total, selection}) => {
        const count = selection.count()
        total = selection.isEmpty()
            ? total
            : count

        return {
            children: __n(
                'Upgrade %d panel',
                'Upgrade all %d panels',
                total
            ),
        }
    }),
)(Button)