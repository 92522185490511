import React from 'react'
import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'
import {changePassword} from 'modules/forms/handlers'
import withForm from 'containers/withForm'
import {__} from 'utils/i18n'

const rules = {
    currentPassword: {
        presence: true,
        length: {minimum: 6},
    },
    newPassword: {
        presence: true,
        length: {minimum: 6},
    },
    newPasswordConfirmation: {
        presence: true,
        equality: {
            attribute: 'newPassword',
            message: () => __('Passwords do not match'),
            comparator: (v1, v2) => v1 === v2,
        },
    },
}

const ChangePassword = ({handle, ...props}) => (
    <ModalCardForm
        onSubmit={handle}
        header={__('Change Password')}
        rules={rules}
        {...props}
    >

        <Input
            label={__('Current Password')}
            name="currentPassword"
            type="password"/>

        <Input
            label={__('New Password')}
            name="newPassword"
            type="password"/>

        <Input
            label={__('Confirm Password')}
            name="newPasswordConfirmation"
            type="password"/>

    </ModalCardForm>
)

export default withForm(changePassword)(ChangePassword)