import {put, select} from 'redux-saga/effects'

import {update} from 'modules/batches/store/actions'
import {trackTemp} from 'modules/batches/widget/actions'
import makeBatch from 'modules/batches/manager/helpers/makeBatch'

import find from 'lodash/find'

export default function* generateBatch(type: String, panelIds: Array) {
    const userId = yield select(state => state.auth.sign.user.id)
    const state = yield select(state => state)

    const panelSerial = panelIds.length === 1
        ? getPanelSerial(panelIds[0], state)
        : null

    const batch = makeBatch({type, panelIds, panelSerial, userId})

    yield put(update([batch]))
    yield put(trackTemp(batch.id))

    return {
        batchId: batch.id,
    }
}

export function* generateBatchForPanel(
    type: String,
    panelId: Number,
    count: Number = 1,
) {
    return yield generateBatch(type, new Array(count).fill(panelId))
}

export function* generateBatchForOneProcess(type: String, panelId: Number) {
    const {batchId} = yield generateBatch(type, [panelId])
    return {batchId}
}

// TODO: refactor logic for find serial for generate batch
// maybe we should pass it in the arguments for generateBatch function
function getPanelSerial(panelId, state) {
    const panel = state.panels.store.byIds[panelId]

    // search in another places
    if (!panel) {
        // search in runners
        const runners = Object.values(state.runners.store.byIds)
        const runner = find(runners, {panelId})

        if (!runner) {
            return ''
        }

        return runner.serial
    }

    return panel.serial
}
