import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'
import {pushBasic} from 'permissions/panels/selection'

import {fetch, addFilters, removeFilters, clearFilters, fetchSuggests} from 'modules/basicConfiguration/push/actions'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import Button from 'ipmp-react-ui/Button'
import IconGoBack from 'ipmp-react-ui/icons/go-back.svg'
import IconGoBackRtl from 'ipmp-react-ui/icons/go-back-rtl.svg'
import isRtl from 'ipmp-react-ui/_utils/isRtl'

import path from 'utils/path'
import __ from 'utils/i18n'

const PushBasicConfigurationSearch = compose(
    withProps(() => ({
        filters: [
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('account', __('Account')),
            new SuggestFilter('group', __('Group')),
            new SuggestFilter('model', __('Model')),
        ],
    })),
    connect(
        ({basicConfiguration: {push}}, {match}) => ({
            key: match.params.scope,
            selected: push.filters,
            suggests: push.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)

class PushBasicConfigurationSearchBar extends Component {
    handleGoBack = () => {
        this.props.history.push(path('system.basicConfigurations', {}))
    }

    render() {
        const IconBack = isRtl()
            ? IconGoBackRtl
            : IconGoBack

        return (
            <header className="pushBasicConfiguration-header">
                <Button onClick={this.handleGoBack} className="btn--goBack pushBasicConfiguration-btn--goBack">
                    <IconBack/>
                </Button>

                <PushBasicConfigurationSearch className="pushBasicConfiguration-search" {...this.props} />
            </header>
        )
    }
}

export default compose(
    withPermission({isVisible: pushBasic}),
    withVisibility(),
)(PushBasicConfigurationSearchBar)