import createPermission, {any} from 'permissions/createPermission'

export const user = createPermission('units.apps.user')
export const configurator = createPermission('units.apps.installer')

export default createPermission(
    any(
        user,
        configurator,
    ),
)