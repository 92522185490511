import {snackShow} from 'modules/snacks'
import {all, call, put, takeEvery, select} from 'redux-saga/effects'
import get from 'lodash-es/get'

import * as actions from './actions'
import {fetchDeviceReferences as fetch} from 'api/devices'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
    ])
}

function* watchFetch({payload: {type}}) {
    try {
        const reference = yield select(state => state.devices.reference)
        const referenceByType = get(reference, type, {})

        if (!Object.values(referenceByType).length) {
            const references = yield call(fetch, type)
            yield put(actions.receive(type, references))
        }
    } catch (error) {
        yield put(snackShow(error.message))
    }
}