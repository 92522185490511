import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import {showActivatePanel} from 'modules/modals/actions'
import {activate} from 'permissions/panel/actions'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectActivation} from 'modules/features/store/selectors'
import Void from 'components/Void'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: activate}),
    connect(
        ({panels}, {panelId, isVisible}) => ({
            isVisible: isVisible && !panels.store.byIds[panelId].isActivating,
        }),
        (dispatch, {panelId}) => bindActionCreators({
            showActivatePanel: () => showActivatePanel(panelId),
        }, dispatch),
    ),
    withVisibility(),
    withFeatureRejection(selectActivation, Void),
    withProps(({showActivatePanel}) => ({
        label: __('Activate'),
        onClick: showActivatePanel,
    }))
)(Button)