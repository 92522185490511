import createPermission, {any} from 'permissions/createPermission'

import list from './list'

export const remove = createPermission('reports.remove')
export const stop = createPermission('reports.stop')

export default createPermission(
    list,
    any(
        remove,
        stop
    )
)