import {compose} from 'redux'

import {pushBasic} from 'permissions/panels/selection'

import {withSelectionHandler} from 'containers/withSelection'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withBasicConfigurations from 'containers/withBasicConfigurations'

import IconUpload from 'ipmp-react-ui/icons/upload.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {withSelectionFeature} from 'containers/withFeature'
import {CONFIGURATION_UPLOAD} from 'constants/features'

export const PushBasicConfigurationItem = compose(
    withPermission({isVisible: pushBasic}),
    withVisibility(),
    withSelectionHandler(),
    withBasicConfigurations(),
    withProps(() => ({
        message: __('Do you really want to push basic configurations for selected panels?'),
        title: __('Push basic configurations'),
        children: __('Push Basic Configuration'),
        Icon: IconUpload,
    })),
    withSelectionHandler(),
)(MenuItem)

export default compose(
    withBasicConfigurations(),
    withProps(({showPushBasicConfigurationModal: onClick}) => ({
        onClick,
    })),
    withSelectionFeature(CONFIGURATION_UPLOAD),
)(PushBasicConfigurationItem)
