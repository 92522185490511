import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withPerPage from './withPerPage'
import {fetch, init, reset, setStart} from 'modules/basicConfiguration/push/actions'
import {selectUnitListForPushBasicConfigurationRows} from 'modules/basicConfiguration/push/selectors'

const mapStateToProps = () =>
    state => {
        const {
            isLoading,
            error,
            total,
            start,
        } = state.basicConfiguration.push

        if (isLoading) {
            return {
                isLoading,
                error,
                total,
                start,
            }
        }

        const rows = selectUnitListForPushBasicConfigurationRows(state)
        const disabledRowsIds = rows.filter(({isSupported}) => !isSupported)
            .map(({id}) => id)

        return {
            rows,
            disabledRowsIds,
            isLoading,
            error,
            total,
            start,
        }
    }

const mapDispatchToProps = (dispatch, {basicConfigId}) =>
    bindActionCreators({
        init,
        fetch: () => fetch(basicConfigId),
        reset,
        onPageChange: setStart,
    }, dispatch)

export default function withPushBasicConfigurations() {
    return compose(
        withPerPage('pushBasicConfiguration'),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
    )
}
