import React from 'react'
import {compose} from 'redux'

import list from 'permissions/interactiveUsers/list'
import selection from 'permissions/interactiveUsers/selection'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import {withPermission, withRejection} from 'containers/withPermission'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import InteractiveUserStatusChanger from './TableCell/InteractiveUserStatusChanger'

import Table from 'ipmp-react-ui/Table'

import {__, __n} from 'utils/i18n'

const columns = [
    {
        name: () => __('Email'),
        render: ({email}) => <Definition title={email}/>,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt}/>,
    },
    {
        name: () => __('Panels'),
        render: ({panels}) => {
            if (panels === 0) {
                return <span className="empty">{__('No panels')}</span>
            }

            return <Definition title={panels} detail={__n('panel', 'panels', panels)}/>
        },
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InteractiveUserStatusChanger,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withInteractiveUsers(),
    withSelection(),
    withLoader(({init}) => init()),
    withProps(() => ({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No interactive users found'),
    })),
)(Table)