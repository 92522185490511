import {
    handleActions,
} from 'redux-actions'

import {
    clear,
    update,
    purge
} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

export default handleActions({
    [clear]() {
        return {}
    },

    [update](state, {payload}) {
        return payload.reduce((carrier, row) => ({
            ...carrier,
            [row.id]: {
                ...carrier[row.id],
                ...row,
            },
        }), state)
    },
    ...createPurgeStoreHandler(purge),
}, {})