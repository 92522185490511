import {warn} from 'utils/log'

import {PROCESS_SUCCEEDED_STATUS,PROCESS_FAILED_STATUS} from 'constants/processStatuses'

export function getLabelBackgroundColor(status) {
    switch (status) {
        case PROCESS_FAILED_STATUS: return 'rgba(202,35,57,0.65)'
        case PROCESS_SUCCEEDED_STATUS: return 'rgba(77, 186, 37, 0.65)'
        default:
            warn(`Unknown status ${status}`)
            return 'rgba(45,114,186,.65)'
    }
}

export function getLabelHoverBackgroundColor(status) {
    switch (status) {
        case PROCESS_FAILED_STATUS: return 'rgba(202,35,57,1)'
        case PROCESS_SUCCEEDED_STATUS: return 'rgba(77, 186, 37, 1)'
        default:
            warn(`Unknown status ${status}`)
            return 'rgba(45,114,186,1)'
    }
}