import {createAction} from 'redux-actions'

export const receive = createAction('PANELS/EVENTS/RECEIVE', ids => ({ids}))
export const reset = createAction('PANELS/EVENTS/RESET')
export const fetch = createAction('PANELS/EVENTS/FETCH', panelId => ({panelId}))
export const fetchOlder = createAction('PANELS/EVENTS/FETCH_OLDER')
export const fetchNewer = createAction('PANELS/EVENTS/FETCH_NEWER')
export const checkNewer = createAction('PANELS/EVENTS/CHECK_NEWER')
export const setNewerCount = createAction('PANELS/EVENTS/SET_NEWER_COUNT', newerCount => ({newerCount}))
export const setHasOlder = createAction('PANELS/EVENTS/SET_HAS_OLDER', hasOlder => ({hasOlder}))
