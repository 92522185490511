import {warn} from 'utils/log'
import {__} from 'utils/i18n'

export const DEVICE_TYPE_UNKNOWN = 'UNKNOWN'

export const DEVICE_TYPE_CONTROL_PANEL = 'CONTROL_PANEL'
export const DEVICE_TYPE_GSM = 'GSM'
export const DEVICE_TYPE_ZONE = 'ZONE'
export const DEVICE_TYPE_CAMERA = 'CAMERA'
export const DEVICE_TYPE_KEYFOB = 'KEYFOB'
export const DEVICE_TYPE_GUARD_KEY = 'GUARD_KEY'
export const DEVICE_TYPE_WIRELESS_COMMANDER = 'WIRELESS_COMMANDER'
export const DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD = '2_WAY_WIRELESS_KEYPAD'
export const DEVICE_TYPE_PENDANT = 'PENDANT'
export const DEVICE_TYPE_PROXY_TAG = 'PROXY_TAG'
export const DEVICE_TYPE_X10 = 'X10'
export const DEVICE_TYPE_REPEATER = 'REPEATER'
export const DEVICE_TYPE_EXPANDER_33 = 'EXPANDER_33'
export const DEVICE_TYPE_WL_SIREN = 'WL_SIREN'
export const DEVICE_TYPE_PGM = 'PGM'
export const DEVICE_TYPE_POWER_LINK = 'POWER_LINK'

export const DEVICE_TYPE_WIRED_KEYPAD = 'WIRED_KEYPAD'
export const DEVICE_TYPE_ZONE_EXPANDER = 'ZONE_EXPANDER_HSM2108'
export const DEVICE_TYPE_OUTPUT_EXPANDER = 'OUTPUT_EXPANDER_HSM2208'
export const DEVICE_TYPE_POWER_SUPPLY = 'POWER_SUPPLY_MODULE_HSM2300'
export const DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS = 'POWER_SUPPLY_WITH_OUTPUTS_HSM2204'
export const DEVICE_TYPE_PGH = 'PGH'
export const DEVICE_TYPE_COMMUNICATOR = 'COMMUNICATOR'
export const DEVICE_TYPE_AUDIO_VERIFICATION = 'AUDIO_VERIFICATION_HSM2955'

export const DEVICE_TYPE_POWER_SUPPLY_3A = 'POWER_SUPPLY_3A'
export const DEVICE_TYPE_IO_EXPANDER_HSM3408 = 'IO_EXPANDER_HSM3408'
export const DEVICE_TYPE_BUS_REPEATER_HSM3204CX = 'BUS_REPEATER_HSM3204CX'
export const DEVICE_TYPE_POWER_SUPPLY_MODULE_HSM2300 = 'POWER_SUPPLY_MODULE_HSM2300'
export const DEVICE_TYPE_DUALPATH_INPUT = 'DUAL_PATH_INPUT'
export const DEVICE_TYPE_DUALPATH_OUTPUT = 'DUAL_PATH_OUTPUT'

export default function deviceType(type) {
    switch (type) {
        case DEVICE_TYPE_UNKNOWN: return __('Unknown')
        case DEVICE_TYPE_CONTROL_PANEL: return __('Control Panel')
        case DEVICE_TYPE_ZONE: return __('Zone')
        case DEVICE_TYPE_CAMERA: return __('Camera')
        case DEVICE_TYPE_KEYFOB: return __('Keyfob')
        case DEVICE_TYPE_GUARD_KEY: return __('Guard key')
        case DEVICE_TYPE_WIRELESS_COMMANDER: return __('Wireless commander')
        case DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD: return __('Wireless keypad')
        case DEVICE_TYPE_PENDANT: return __('Pendant')
        case DEVICE_TYPE_PROXY_TAG: return __('Proxy tag')
        case DEVICE_TYPE_X10: return __('X10')
        case DEVICE_TYPE_REPEATER: return __('Repeater')
        case DEVICE_TYPE_EXPANDER_33: return __('Expander 33')
        case DEVICE_TYPE_WL_SIREN: return __('Wireless siren')
        case DEVICE_TYPE_PGM: return __('Pgm')
        case DEVICE_TYPE_WIRED_KEYPAD: return __('Wired Keypad')
        case DEVICE_TYPE_ZONE_EXPANDER: return __('Zone Expander')
        case DEVICE_TYPE_OUTPUT_EXPANDER: return __('Output Expander')
        case DEVICE_TYPE_POWER_SUPPLY: return __('Power Supply')
        case DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS: return __('Power Supply With Outputs')
        case DEVICE_TYPE_POWER_SUPPLY_3A: return __('Power Supply 3A')
        case DEVICE_TYPE_PGH: return __('PGH')
        case DEVICE_TYPE_COMMUNICATOR: return __('Communicator')
        case DEVICE_TYPE_AUDIO_VERIFICATION: return __('Audio Verification')
        case DEVICE_TYPE_POWER_LINK: return __('Power Link')
        case DEVICE_TYPE_GSM: return __('GSM Module')
        case DEVICE_TYPE_IO_EXPANDER_HSM3408: return __('8 I/O Module HSM3408')
        case DEVICE_TYPE_BUS_REPEATER_HSM3204CX: return __('Bus Repeater HSM3204CX')
        case DEVICE_TYPE_POWER_SUPPLY_MODULE_HSM2300: return __('Power Supply 3A HSM3350')
        case DEVICE_TYPE_DUALPATH_INPUT: return __('Input')
        case DEVICE_TYPE_DUALPATH_OUTPUT: return __('Output')
    }

    warn(`Unknown device type ${type}`)

    return type
}
