import {selectPerPage} from 'modules/settings/selectors'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {changePerPage} from 'modules/settings/actions'

export default function withPerPage(name) {
    return connect(
        (state) => ({
            perPage: selectPerPage(state, name),
        }),
        dispatch => bindActionCreators({
            onPerPageChange: (perPage) => changePerPage({[name]: perPage}),
        }, dispatch),
    )
}