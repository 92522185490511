import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showResolveFaultsModal} from 'modules/modals/actions'

import {withSelectionHandler} from 'containers/withSelection'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {resolveFaults} from 'permissions/panels/selection'

export default compose(
    withProps(() => ({
        children: __('Resolve faults'),
    })),
    withPermission({isVisible: resolveFaults}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: showResolveFaultsModal,
        }, dispatch),
    ),
    withSelectionHandler(),
)(MenuItem)
