import {handleActions} from 'redux-actions'

import {
    setPlinkError
} from './actions'

const defaultState = {
    error: null,
}

export default handleActions({
    [setPlinkError](state, {payload}) {
        return {
            ...state,
            error: payload,
        }
    },
}, defaultState)