import createPermission, {any} from 'permissions/createPermission'

export const refresh = createPermission('unit.logs.refresh')
export const download = createPermission('unit.logs.download')

export default createPermission(
    any(
        download,
        refresh
    )
)