import React, {PureComponent} from 'react'
import {InputComponent} from 'ipmp-react-ui/Input'
import {__} from 'utils/i18n'

export default class MultilineValue extends PureComponent {

    handleChange = (line, value) => {
        const values = this.getValues()
        values[line] = value
        this.props.onChange(values.join('\n'))
    }

    getValues() {
        return (this.props.value || '').split('\n')
    }

    render() {
        const {item} = this.props
        const values = this.getValues()

        return (
            <div className="cols">
                {Array(item.max_lines).fill().map(
                    (_, index) => (
                        <div className="col" key={index}>
                            <InputComponent
                                placeholder={__('Not set')}
                                value={values[index]}
                                maxLength={item.max_line_length}
                                onChange={e => this.handleChange(index, e.target.value)}
                            />
                        </div>
                    ),
                )}
            </div>
        )
    }

}