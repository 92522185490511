import {compose} from 'redux'

import {sendEmail} from 'permissions/remoteInspections/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspections from 'containers/withRemoteInspections'

import ResendEmail from 'components/Cell/RemoteInspections/Actions/ResendEmail'

export default compose(
    withPermission({isVisible: sendEmail}),
    withVisibility(),
    withRemoteInspections(),
)(ResendEmail)