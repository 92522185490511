import {selectEventsListRows, selectEventsOverBoundRows} from 'modules/events/list/selectors'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    fetch,
    fetchNewer,
    fetchOverbound,
    setStart,
    setFirstPageStreamable,
    startPoll,
    stopPoll, nextOverbound, prevOverbound,
} from 'modules/events/list/actions'
import {markAsViewed} from 'modules/events/one/actions'

export default function withEvents() {
    return compose(
        withPerPage('events'),
        connect(
            (state, {match}) => {
                const byIds = state.events.store.byIds
                const id = match && match.params && match.params.id

                const {
                    start,
                    total,
                    error,
                    isLoading,
                    newerCount,
                    isFirstPageStreamable,
                } = state.events.list

                return {
                    start,
                    total,
                    error,
                    newerCount,
                    isFirstPageStreamable,
                    isLoading,
                    rows: selectEventsListRows(state),
                    row: id ? byIds[id] || {} : {},
                    overbound: selectEventsOverBoundRows(state),
                }
            },
            dispatch => bindActionCreators({
                init,
                reset,
                fetch,
                fetchNewer,
                fetchOverbound,
                nextOverbound,
                prevOverbound,
                onPageChange: setStart,
                setFirstPageStreamable,
                markAsViewed,
                startPoll,
                stopPoll,
            }, dispatch),
        ),
    )
}