import mapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'

export const mapConnectedPanels = mapper(data => ({
    time: parseDate(data.time),
    connected: parseInt(data.connected),
}))

export const mapEventsRate = mapper(data => ({
    time: parseDate(data.time),
    alarms: parseInt(data.alarms),
    alerts: parseInt(data.alerts),
    other: parseInt(data.other),
    id: parseInt(data.id),
}))

export const mapOnline = mapper(data => ({
    time: parseDate(data.time),
    faulty: parseInt(data.faulty),
    online: parseInt(data.online),
    total: parseInt(data.total),
}))

export const mapAllProcesses = mapper(data => ({...data}))
