import createPermission, {any} from 'permissions/createPermission'

import selection from './selection'
import {create} from './actions'

export default createPermission(
    any(
        selection,
        create,
    ),
)