import {PureComponent} from 'react'
import React, {Fragment} from 'react'
import isEmpty from 'lodash-es/isEmpty'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Tabs, {Tab} from 'ipmp-react-ui/Tabs'
import CameraList from 'components/CameraList'
import {__} from 'utils/i18n'

const Layout = ({nav, tab}) => {
    return (
        <Fragment>
            <div className="sidebar-header">
                {nav}
            </div>

            <ScrollView className="sidebar-content">
                {tab}
            </ScrollView>
        </Fragment>
    )
}

export class VideoTabs extends PureComponent {

    get hasLive() {
        const {hasLive, video} = this.props

        return hasLive && !isEmpty(video.live)
    }

    render() {
        const {
            device, video, startRecordVideo,
            children, isRequestAllowed, isExportAllowed,
            fallbackTrait,
        } = this.props

        if (!this.hasLive) {
            return (
                <ScrollView className="sidebar-content">
                    <CameraList device={device}
                        cameras={video.preloaded}
                        isExportAllowed={isExportAllowed}
                    />
                    {children}
                </ScrollView>
            )
        }

        return (
            <Tabs Layout={Layout}>
                <Tab name={__('EVENT VIDEO')}>
                    <CameraList device={device} cameras={video.preloaded} isExportAllowed={isExportAllowed}/>
                    {children}
                </Tab>
                <Tab name={__('LIVE VIDEO')}>
                    <CameraList device={device} cameras={video.live} onStartRecord={startRecordVideo} isRequestAllowed={isRequestAllowed} isExportAllowed={isExportAllowed} fallbackTrait={fallbackTrait}/>
                    {children}
                </Tab>
            </Tabs>
        )
    }
}

export default VideoTabs