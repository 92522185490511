import React, {PureComponent} from 'react'
import {compose} from 'redux'

import keypad from 'permissions/panel/keypad/page'

import {withPermission, withRejection} from 'containers/withPermission'
import {withRouterPanelId} from 'containers/withPanel'
import withKeypad from 'containers/withKeypad'

import KeypadBar from './KeypadBar'
import KeypadContent from './KeypadContent'

import Layout from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'
import Error from 'ipmp-react-ui/Error'
import {__} from 'utils/i18n'

export class Keypad extends PureComponent {

    render() {
        const {
            panel,
            panelId,
            keypad,
            isAvailable,
            softwareVersion,
            enable,
            send,
            cleanLog,
            cleanKey,
        } = this.props

        if (!isAvailable) {
            const message = softwareVersion
                ? __('Panel\'s software version is %d', softwareVersion)
                : __('Can\'t determine panel\'s software version version due to unfinished discovery.')

            return (
                <Error
                    title={__('Virtual Keypad is not supported for panels with software version less than v.18')}
                    message={message}/>
            )
        }

        if (keypad.enabledBy) {
            return (
                <Error
                    message={__('Panel is in use by %s', keypad.enabledBy)}
                    title={__('Channel is busy')}>
                    <Button onClick={enable}>{__('Connect')}</Button>
                </Error>
            )
        }

        if (!keypad.enabled && !keypad.isConnecting) {
            return (
                <Error title={__('Keypad is not connected')}>
                    <Button onClick={enable}>{__('Connect now')}</Button>
                </Error>
            )
        }

        return (
            <Layout vertical className="keypad">
                <KeypadBar panelId={panelId}/>

                <KeypadContent
                    panelId={panel.id}
                    model={panel.model}
                    serial={panel.serial}
                    connected={panel.connected}
                    isNeo={panel.isNeo}
                    send={send}
                    cleanLog={cleanLog}
                    cleanKey={cleanKey}
                    {...keypad}
                />
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: keypad}),
    withRejection(),
    withRouterPanelId(),
    withKeypad(),
)(Keypad)