import {all, call, put, takeEvery} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import * as api from 'api/devices'
import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
    ])
}

function* watchFetch({payload: {panelId, deviceId, type}}) {
    try {
        yield put(actions.setLoading(panelId, deviceId, type))
        const data = yield call(api.fetchMeteo, panelId, deviceId, type)
        yield put(actions.receive(panelId, deviceId, type, data))
    } catch (error) {
        yield put(actions.setError(panelId, deviceId, type, error))
        yield put(snackShow(error.message))
    }
}