import isEqual from 'lodash-es/isEqual'

export const timeAndTrimFilter = message => message.trim().replace(/(.*)\d{2}:\d{2}(?!\d)(.*)/g, '$1TIME$2')

export default function logger(filter = timeAndTrimFilter) {
    let stack = [], expectedIndex = 0, groupFound = false

    return message => {
        const filteredMessage = filter(message)
        let messageIndex = stack.indexOf(filteredMessage)

        if (!groupFound && messageIndex !== -1) {
            stack = stack.splice(messageIndex)
            messageIndex = 0
            groupFound = true
        }

        if (!groupFound || messageIndex === -1 || messageIndex !== expectedIndex) {
            if (expectedIndex !== 0 || messageIndex === -1 && groupFound) {
                expectedIndex = 0
                stack = []
                groupFound = false
            }
            stack.push(filteredMessage)

            return true
        }

        if (++expectedIndex === stack.length) {
            expectedIndex = 0
        }
        return false
    }
}

export function ledLogger() {
    let lastLeds = {}

    return leds => {
        if (isEqual(lastLeds, leds)) {
            return false
        }
        lastLeds = leds
        return true
    }
}

export function* filter(source) {
    const log = logger()

    for (let message of source) {
        log(message) && (yield message)
    }
}

export const MESSAGE_TYPE_KEY = 'key'
export const MESSAGE_TYPE_TEXT = 'text1'
export const MESSAGE_TYPE_LEDS = 'leds'
export const MESSAGE_TYPE_POS = 'pos'
export const MESSAGE_TYPE_AUTORIZED = 'authorized'
export const MESSAGE_TYPE_ERROR = 'error'
export const LED_STATE_ON = 'LED_ON'
export const LED_STATE_OFF = 'LED_OFF'

export function textFormatResolve({type, text}) {
    switch (type) {
        case MESSAGE_TYPE_KEY:
            return `--> ${text}`
        case MESSAGE_TYPE_LEDS:
            return ledsToText(text)
        default:
            return text.trim().replace(/ /g, '\xa0')
    }
}

const ledsToText = data => 'leds: ' + Object.entries(data).reduce((acc, [led, state]) => {
    state = state === LED_STATE_ON ? 1 : 0

    acc.push(`${led}(${state})`)

    return acc
}, []).join(' ')