import React, {Component} from 'react'

import {
    NextButton,
    PreviousButton,
} from './Header/Navigate'
import ReviewButton from './Header/ReviewButton'
import SendEmailButton from './Header/SendEmailButton'
import InitiateButton from './Header/InitiateButton'

export default class RemoteInspectionsResultHeader extends Component {
    render() {
        return (
            <div className="rri-result-actions">
                <PreviousButton/>

                <ReviewButton/>
                <SendEmailButton/>
                <InitiateButton/>

                <NextButton/>
            </div>
        )
    }
}