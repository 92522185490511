import React from 'react'
import getHocName from 'utils/getHocName'

export default function withInterruption(condition: () => Boolean, Component) {
    return WrappedComponent => {

        const HOC = (props) => {
            return condition(props) ? <Component {...props} /> : <WrappedComponent {...props} />
        }

        HOC.displayName = getHocName('withInterruption', WrappedComponent)

        return HOC
    }
}