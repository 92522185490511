import {createSelector} from 'reselect'
import {get} from 'lodash-es'

export const selectProcessesByKeys = state => get(state, ['processes', 'manager', 'byKeys'], {})

export const selectProcessesAsArray = createSelector(
    selectProcessesByKeys,
    processes => Object.values(processes),
)

export const selectRunningProcessesByType = createSelector(
    selectProcessesAsArray,
    (state, types) => Array.isArray(types) ? types : [types],
    (processes, types) => processes.filter(process => types.includes(process.type) && process.isRunning),
)

export const createTrackedSelector = (key) => {
    return createSelector(
        (store) => store.processes.manager.byKeys[key],
        (store) => store.processes.store.byIds,
        (process, byIds) => process || byIds[key],
    )
}
