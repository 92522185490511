import {compose} from 'redux'

import {sendEmail} from 'permissions/remoteInspections/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export const SendEmailButton = compose(
    withPermission({isVisible: sendEmail}),
    withVisibility(),
)(Button)

export default compose(
    withRemoteInspectionsResult(),
    withProps(({result, sendEmail}) => ({
        borderless: true,
        onClick: sendEmail,
        disabled: !result,
        label: result && result.isEmailSent ? __('Resend email') : __('Send email'),
    })),
)(SendEmailButton)