import {createSelector} from 'reselect'
import {get} from 'lodash-es'

export const selectPGMListIsLoading = (state, {panelId}) => get(state, ['pgm', 'list', panelId, 'isLoading'], false)
export const selectPGMListError = (state, {panelId}) => get(state, ['pgm', 'list', panelId, 'error'], null)
export const selectPGMListData = (state, {panelId}) => get(state, ['pgm', 'list', panelId, 'byIds'], {})

export const selectPGMActiveList = createSelector(
    [
        selectPGMListData,
    ],
    (list) => list && Object.values(list).filter(({enabled}) => enabled),
)

export const selectPGMAvailableList = createSelector(
    [
        selectPGMListData,
    ],
    (list) => list && Object.values(list).filter(({enabled}) => !enabled),
)

export const selectPGMList = createSelector(
    [
        selectPGMListIsLoading,
        selectPGMListError,
        selectPGMActiveList,
    ],
    (isLoading, error, list) => ({isLoading, error, list}),
)

export const selectPGMParents = createSelector(
    [
        selectPGMListData,
    ],
    (list) => Object.values(list).reduce((acc, {deviceId, deviceType}) => {
        if (!acc.some(({deviceId: id}) => id === deviceId)) {
            acc.push({deviceId, deviceType})
        }
        return acc
    }, []),
)

export const selectPGMPortsByParents = createSelector(
    [
        selectPGMAvailableList,
    ],
    (list) => list ? Object.values(list).reduce((acc, {id, port, deviceId}) => {
        if (!acc.hasOwnProperty(deviceId)) {
            acc[deviceId] = []
        }
        if (!acc[deviceId].includes(port)) {
            acc[deviceId].push({id, port})
        }
        return acc
    }, {}) : {},
)

export const selectPGM = createSelector(
    [
        selectPGMListData,
        (state, {id}) => id,
    ],
    (list, id) => list && (list[id] || {}),
)
