import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'

const defaultState = {}

export default handleActions({
    [fetch](state, {payload}) {
        return {
            ...state,
            [payload]: null,
        }
    },

    [receive](state, {error, payload, meta: {roleId}}) {
        if (error) {
            return {
                ...state,
                [roleId]: {
                    error: payload,
                },
            }
        }

        return {
            ...state,
            [roleId]: {
                data: payload,
            },
        }
    },
}, defaultState)