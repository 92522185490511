import React, {PureComponent} from 'react'
import {compose} from 'redux'
import classnames from 'classnames'
import {stringify} from 'query-string'
import {Link} from 'react-router-dom'

import IconAlarm from 'ipmp-react-ui/icons/alarm.svg'
import IconAlert from 'ipmp-react-ui/icons/alert.svg'
import IconCheck from 'ipmp-react-ui/icons/check.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'

import {withFeatureRejection} from 'containers/withFeature'
import {selectEventFeature} from 'modules/panels/store/selectors'

class UnhandledEventsCell extends PureComponent {

    getPath(severity) {
        const {serial} = this.props

        return path('events', {}) + '?' + stringify({
            serial,
            severity,
        })
    }

    renderAlerts() {
        const {alerts} = this.props

        const tooltip = alerts
            ? __n('%d unhandled alert', '%d unhandled alerts', alerts)
            : __('All alerts handled')

        return (
            <Tooltip tooltip={tooltip} className={classnames('severity', {
                'severity--warning': alerts,
                'empty': !alerts,
            })}>
                <Link to={this.getPath('alert')}>
                    <IconAlert className="severity-icon"/>
                    {alerts}
                </Link>
            </Tooltip>
        )
    }

    renderAlarms() {
        const {alarms} = this.props

        const tooltip = alarms
            ? __n('%d unhandled alarm', '%d unhandled alarms', alarms)
            : __('All alarms handled')

        return (
            <Tooltip tooltip={tooltip} className={classnames('severity', {
                'severity--danger': alarms,
                'empty': !alarms,
            })}>
                <Link to={this.getPath('alarm')}>
                    <IconAlarm className="severity-icon"/>
                    {alarms}
                </Link>
            </Tooltip>
        )
    }

    render() {
        const {alerts, alarms} = this.props

        if (!alerts && !alarms) {
            return (
                <div className="severity-row">
                    <Tooltip tooltip={__('No unhandled alarms or alerts')}>
                        <IconCheck className="success"/>
                    </Tooltip>
                </div>
            )
        }

        return (
            <div className="severity-row">
                {this.renderAlarms()}
                {this.renderAlerts()}
            </div>
        )
    }
}

export default compose(
    withFeatureRejection(selectEventFeature)
)(UnhandledEventsCell)