import {getCurrentLocale} from 'utils/i18n'

export default function acceptLanguage(instance) {
    return instance.interceptors.request.use(
        config => {
            const locale = getCurrentLocale()

            if (locale) {
                config.headers = {
                    ...config.headers,
                    'Accept-Language': getAcceptLanguage(locale),
                }
            }

            return config
        },
    )
}

function getAcceptLanguage(forceLocale) {
    const languages = window.navigator.languages

    if (!languages) {
        return forceLocale
    }

    const navigatorLanguagesWithQuality = languages.reduce((acc, locale, i) => {
        if (forceLocale && forceLocale.toLowerCase() === locale.toLowerCase()) {
            return acc
        }

        const comma = i !== window.navigator.languages.length - 1 ? ',' : ''
        const quality = countQuality(window.navigator.languages.length, i + 1)

        return `${acc}${locale};q=${quality}${comma}`
    }, '')

    return `${forceLocale},${navigatorLanguagesWithQuality}`
}

function countQuality(length, index) {
    const divider = length < 10 ? 10 : 100

    return (divider - index) / divider
}