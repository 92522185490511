import {all, takeEvery, put} from 'redux-saga/effects'
import {observeBatch} from './actions'
import {update} from 'modules/batches/store/actions'

export default function* () {
    yield all([
        takeEvery(observeBatch, watchObserveBatch)
    ])
}

function* watchObserveBatch({payload: batch}) {
    yield put(update([batch]))
}