import React, {Component} from 'react'

import getHocName from 'utils/getHocName'

export function withVisibility() {
    return WrappedComponent => class extends Component {
        static displayName = getHocName('withVisibility', WrappedComponent)

        render() {
            const {isVisible, ...props} = this.props

            if (isVisible) {
                return <WrappedComponent {...props}/>
            }

            return null
        }
    }
}