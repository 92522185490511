import React, {Component} from 'react'
import PropTypes from 'prop-types'

import IconRepeat from 'ipmp-react-ui/icons/repeat.svg'
import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'
import {compose} from 'redux'

class InitRRI extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const {children, onClick} = this.props

        return (
            <a onClick={onClick} className="rri-action">
                <IconRepeat className="rri-action-icon"/>
                {children}
            </a>
        )
    }
}

export default compose(
    withProps(() => ({
        title: __('Initiate Inspection'),
        message: __('Do you want to initiate remote inspection now?'),
        positive: __('Begin now'),
    })),
    withConfirmation(({initiateNow, panelId}) => initiateNow([panelId])),
)(InitRRI)