import {snackShow} from 'modules/snacks'
import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/installers'
import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery(actions.accept, watchAccept),
        takeEvery(actions.reject, watchReject),
    ])
}

function* watchAccept({payload: installerIds}) {
    for (let idx in installerIds) {
        if (installerIds.hasOwnProperty(idx)) {
            try {
                const installer = yield call(api.accept, installerIds[idx])
                yield put(actions.update(installer))
            }
            catch (error) {
                yield put(snackShow(error.message))
                yield put(actions.revert(installerIds[idx]))
            }
        }
    }
}

function* watchReject({payload: installerIds}) {
    for (let idx in installerIds) {
        if (installerIds.hasOwnProperty(idx)) {
            try {
                const installer = yield call(api.reject, installerIds[idx])
                yield put(actions.update(installer))
            }
            catch (error) {
                yield put(snackShow(error.message))
                yield put(actions.revert(installerIds[idx]))
            }
        }
    }
}