import timezones from 'utils/timezones'
import uniqid from 'uniqid'

export default function makeBatch(
    {type, panelIds, panelSerial, userId}: {
        type: String,
        panelIds: Array,
        panelSerial: String,
        userId: Number
    }
) {
    return {
        id: uniqid(userId + 'p'),
        started: timezones.server().toDate(),
        finished: null,
        type: type,
        isObservable: true,
        processCount: panelIds.length,
        stats: {
            notStarted: panelIds.length,
            succeeded: 0,
            handled: 0,
            failed: 0,
            start: 0,
            canceled: 0,
            failedToStart: 0,
        },
        userId: null,
        panelSerial,
        panels: panelIds.length,
    }
}
