import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {init, fetch, reset, setStart} from 'modules/installers/list/actions'
import {accept, reject} from 'modules/installers/store/actions'

export default function withInstallers() {
    return compose(
        withPerPage('installers'),
        connect(
            ({installers}) => {
                const {isLoading, page, error, total, start} = installers.list

                return {
                    rows: page.map(id => installers.store.byIds[id]),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            dispatch => bindActionCreators({
                init,
                fetch,
                reset,
                accept,
                reject,
                onPageChange: setStart,
            }, dispatch),
        ),
    )
}