import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import GroupBarNav from './GroupBar/GroupBarNav'
import EditGroupButton from './GroupBar/EditGroupButton'

export default class GroupBar extends Component {
    static propTypes = {
        group: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    }

    render() {
        const {group} = this.props

        return (
            <Bar>
                <h1 className="title title--large">
                    {group.name}
                </h1>

                <GroupBarNav group={group}/>

                <BarSpace/>

                <EditGroupButton group={group}/>
            </Bar>
        )
    }
}