import {humanDuration} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const PROCESS_ALREADY_ENQUEUED = 'PROCESS_ALREADY_ENQUEUED'
export const PROCESS_FAILED_TO_ENQUEUE = 'PROCESS_FAILED_TO_ENQUEUE'
export const ENDPOINT_NOT_FOUND = 'ENDPOINT_NOT_FOUND'
export const SCOPE_NOT_FOUND = 'SCOPE_NOT_FOUND'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const AUTHENTICATION_BLOCKED = 'AUTHENTICATION_BLOCKED'
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED'
export const METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED'
export const DENIED = 'DENIED'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const LICENCE_LIMIT_REACHED = 'LICENCE_LIMIT_REACHED'
export const PARAMS_MISSING = 'PARAMS_MISSING'
export const BAD_REQUEST_PARAMS = 'BAD_REQUEST_PARAMS'
export const ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND'
export const SERVICE_ERROR = 'SERVICE_ERROR'
export const PANEL_IS_NOT_CONNECTED = 'PANEL_IS_NOT_CONNECTED'
export const PANEL_IS_CONNECTED = 'PANEL_IS_CONNECTED'
export const PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED'
export const PROCESS_NOT_FOUND = 'PROCESS_NOT_FOUND'
export const CUSTOM_ERROR = 'CUSTOM_ERROR'
export const INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE'
export const NOT_ALLOWED_FOR_CURRENT_USER = 'NOT_ALLOWED_FOR_CURRENT_USER'

export function messageByError(errorType, details = null) {
    switch (errorType) {
        case PROCESS_ALREADY_ENQUEUED: return __('Process already enqueued')
        case PROCESS_FAILED_TO_ENQUEUE: return __('Failed to enqueue process')
        case ENDPOINT_NOT_FOUND: return __('The requested page could not be found')
        case SCOPE_NOT_FOUND: return __('Search scope not exists')
        case AUTHENTICATION_FAILED: return __('Authentication failed')
        case AUTHENTICATION_BLOCKED: return __('Authentication blocked. Retry in %s', humanDuration(details.cooldown))
        case AUTHENTICATION_REQUIRED: return __('Authentication required')
        case METHOD_NOT_ALLOWED: return __('Method not allowed')
        case DENIED: return __('Access denied')
        case INTERNAL_SERVER_ERROR: return __('Internal Server Error')
        case LICENCE_LIMIT_REACHED: return __('Licence limit reached')
        case PARAMS_MISSING: return __('Params missing')
        case BAD_REQUEST_PARAMS: return __('Bad request params')
        case ENTITY_NOT_FOUND: return __('Entity not found')
        case SERVICE_ERROR: return __('Service Error')
        case PANEL_IS_NOT_CONNECTED: return __('Panel is not connected')
        case PANEL_IS_CONNECTED: return __('Panel is connected')
        case PROCESS_NOT_FOUND: return __('Process not found')
        case PARTIALLY_PROCESSED: return __('Partially processed')
        case INVALID_ACCESS_CODE: return __('Invalid access code')
        case NOT_ALLOWED_FOR_CURRENT_USER: return __('Not allowed for current user')
    }

    if (errorType === CUSTOM_ERROR) {
        return details.message
    }

    warn(`Unknown error type ${errorType}`)

    return __('Unknown server error: %s', errorType)
}