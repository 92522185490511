import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'


import BroadbandConnected from './BroadbandConnected'
import CellularConnected from './CellularConnected'
import CommonConnected from './CommonConnected'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class General extends Component {
    render() {
        return (
            <ScrollView>
                <Card>
                    <CellularConnected/>
                </Card>
                <Card>
                    <BroadbandConnected/>
                </Card>
                <Card>
                    <CommonConnected/>
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {cellularConnected, broadbandConnected, commonConnected, messageBrokers}}}) => ({
            isLoading: cellularConnected.isLoading || broadbandConnected.isLoading || commonConnected.isLoading || messageBrokers.isLoading,
        }),
        dispatch => bindActionCreators({
            cellular: actions.fetchCellularConnected,
            broadband: actions.fetchBroadbandConnected,
            common: actions.fetchCommonConnected,
            messageBrokers: actions.fetchMessageBrokers,
        }, dispatch),
    ),
    withLoader(({cellular, broadband, common, messageBrokers}) => {
        messageBrokers()
        cellular()
        broadband()
        common()
    }),
)(General)