import {compose} from 'redux'
import Chart from 'components/Devices/Chart'

import withDeviceMeteo from 'containers/withDeviceMeteo'
import withLoader from 'containers/withLoader'

export default compose(
    withDeviceMeteo(),
    withLoader(
        ({fetch}) => fetch(),
    ),
)(Chart)