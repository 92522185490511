// Configurations is very huge,
// so take performance into account
function fillHash(val, hash = {}) {
    val.forEach(item => {
        if (item.type === 'menu') {
            fillHash(item.val, hash)
        } else {
            hash[item.key] = item.val
        }
    })

    return hash
}

export default function configurationToHash({val}) {
    return fillHash(val)
}
