import {warn} from 'utils/log'
import {__} from 'utils/i18n'

export const FAULTY_PANELS = 'FAULTY_PANELS'
export const SUSPENDED_FAULTS = 'SUSPENDED_FAULTS'

export default function scopeName(scope) {
    switch (scope) {
        case FAULTY_PANELS: return __('Faulty Panels')
        case SUSPENDED_FAULTS: return __('Suspended Faults')
    }

    warn(`Unknown navigation scope ${scope}`)

    return scope
}