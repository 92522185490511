import {snackShow} from 'modules/snacks'
import {all, put, takeEvery, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/centralStations'

export default function* () {
    yield all([
        takeEvery(actions.fetchCanAdd, watchFetchAdd),
    ])
}

function* watchFetchAdd() {
    try {
        yield put(actions.setLoading(true))
        const data = yield call(api.canAdd)
        yield put(actions.receiveCanAdd(data))
    } catch (error) {
        yield put(actions.setLoading(false))
        yield put(snackShow(error.message))
    }
}

