import {compose} from 'redux'

import {SendEmailButton} from 'pages/RemoteInspection/Content/Result/Header/SendEmailButton'
import {withPanelRemoteInspectionsResult} from 'containers/withRemoteInspectionsResult'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

export default compose(
    withPanelRemoteInspectionsResult(),
    withProps(({remoteInspectionResult, sendEmail}) => ({
        borderless: true,
        onClick: sendEmail,
        disabled: !remoteInspectionResult,
        label: remoteInspectionResult && remoteInspectionResult.isEmailSent ? __('Resend email') : __('Send email'),
    })),
)(SendEmailButton)