import {handleActions} from 'redux-actions'

import {warn} from 'utils/log'
import {fetch, change, receive, update} from './actions'

const defaultState = {}

export default handleActions({
    [fetch](state, {payload}) {
        return {
            ...state,
            [payload]: {
                ...state[payload],
                isLoading: true,
            },
        }
    },

    [receive](state, {payload, error, meta: {panelId}}) {
        const old = state[panelId] || {}

        if (error) {
            return {
                ...state,
                [panelId]: {
                    ...old,
                    isLoading: false,
                    error: payload,
                },
            }
        }

        return {
            ...state,
            [panelId]: {
                ...old,
                ...payload,
                isLoading: false,
                error: null,
            },
        }
    },

    [update](state, {payload, meta: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                ...payload,
            },
        }
    },

    [change](state, {payload: {id, value}, meta: {panelId}}) {
        const old = state[panelId] || {}
        const values = {
            ...old.values,
            [id]: value,
        }

        const original = old.locations.find(loc => loc.id == id)

        if (!original) {
            warn(`Unknown location ${id} for panel ${panelId}`)
            return state
        }

        if (original.custom == value) {
            delete values[id]
        }

        return {
            ...state,
            [panelId]: {
                ...old,
                values,
            },
        }
    },
}, defaultState)