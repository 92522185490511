import {createAction} from 'redux-actions'

/**
 * @event PANELS/STATE/FETCH
 * @property {int} panelId id of the panel to fetch state of
 */
export const fetch = createAction('PANELS/STATE/FETCH', panelId => ({panelId}))
export const receive = createAction('PANELS/STATE/RECEIVE', null, (_, panelId) => ({panelId}))

export const renamePartition = createAction('PANELS/PARTITIONS/RENAME', (panelId, partitionId, name) => ({panelId, partitionId, name}))

/**
 * @event PANELS/STATE/SEND
 * @property {int} panelId id of the panel to change state for
 * @property {string} partition ID, e.g. P1, P2, P3,..
 * @property {string} state constant, e.g. AWAY, STAY, etc.
 * @property {string} modifier constant, .e.g. INSTANT, LATCHKEY, etc. may be null
 */
export const sendState = createAction('PANELS/STATE/SEND', (panelId, partition, state, modifier) => ({panelId, partition, state, modifier}))

export const startPoll = createAction('PANELS/STATE/START_POLL')
export const stopPoll = createAction('PANELS/STATE/STOP_POLL')