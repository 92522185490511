import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import BadgeSelect, {BadgeSelectOption} from 'ipmp-react-ui/BadgeSelect'

import {sprintf} from 'sprintf-js'

export default class BitsetValue extends PureComponent {

    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    handleChange = (selected) => {
        const {value} = this.props
        const newValue = selected.reduce((currentValue, {value}) => {
            return currentValue.substring(0, value) + '1' + currentValue.substring(value + 1)
        }, String('0').repeat(value.length))
        this.props.onChange(newValue)
    }

    titleFormat(title, key) {
        return sprintf(title, key)
    }

    render() {
        const {item: {label, offset}, value} = this.props
        const list = value.split('')

        const multiValue = list
            .map((element, key) => ({
                value: key,
                label: this.titleFormat(label, offset + key),
                selected: element == 1,
            }))
            .filter(element => (element.selected))

        return (
            <BadgeSelect defaultValues={multiValue} onChange={this.handleChange}>
                {
                    list.map((value, key) => (
                            <BadgeSelectOption key={key} label={this.titleFormat(label, offset + key)} value={key}/>
                        ),
                    )
                }
            </BadgeSelect>
        )
    }
}