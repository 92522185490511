import {takeEvery, call, put} from 'redux-saga/effects'
import {fetch, receive} from './actions'
import {fetchUserAssignableRoles} from 'api/user'
import {snackShow} from 'modules/snacks'

export default function*() {
    yield takeEvery(fetch, watchFetchRoles)
}

function* watchFetchRoles() {
    try {
        const assignableRoles = yield call(fetchUserAssignableRoles)
        yield put(receive(assignableRoles))
    } catch (error) {
        yield put(receive(error))
        yield put(snackShow(error.message))
    }
}
