import React, {Component} from 'react'

import BatchesPager from './GridBar/BatchesPager'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

export default class BatchesGridBar extends Component {
    render() {
        return (
            <Bar orientation={RIGHT}>
                <BatchesPager />
            </Bar>
        )
    }
}