import {withSearchScope} from 'containers/withSearchScopeLoader'
import React from 'react'
import {compose} from 'redux'

import list from 'permissions/events/list'
import selection from 'permissions/events/selection'

import withEvents from 'containers/withEvents'
import withSelection from 'containers/withSelection'
import {withPermission, withRejection} from 'containers/withPermission'

import EventsBySerialPage from './EventsBySerialPage'
import EventsListPage from './EventsListPage'

const EventsPage = (props) => {

    if (props.match.params.scope && props.match.params.serial && (!props.match.params.id)) {
        props.match.params = {
            ...props.match.params,
            id: props.match.params.serial,
            serial: props.match.params.scope,
            scope: undefined,
        }
    }

    if (props.match.params.id) {
        return <EventsBySerialPage {...props}/>
    }

    return <EventsListPage {...props}/>
}

export default compose(
    withPermission({isAllowed: list, hasSelection: selection}),
    withRejection(),
    withEvents(),
    withSelection(),
    withSearchScope(),
)(EventsPage)
