import createPermission, {any} from 'permissions/createPermission'

import {create} from './actions'
import selection from './selection'

export default createPermission(
    any(
        selection,
        create
    )
)