import {handleActions} from 'redux-actions'
import {show, hide} from 'modules/modals/actions'

const defaultState = {}

export default handleActions({
    [show](state, {payload}) {
        return {
            ...payload,
        }
    },

    [hide]() {
        return defaultState
    },
}, defaultState)