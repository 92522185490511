import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'

import Form from 'ipmp-react-ui/Form'
import Input from 'ipmp-react-ui/Input'
import Select, {Option} from 'ipmp-react-ui/Select'
import Checkbox from 'ipmp-react-ui/Checkbox'
import MultiSelect from 'ipmp-react-ui/MultiSelect'

import deviceType from 'constants/deviceType'
import {
    PGM_OPTION_TYPE_BOOLEAN,
    PGM_OPTION_TYPE_NUMBER,
    PGM_OPTION_TYPE_UNSIGNED_8,
    PGM_OPTION_TYPE_NUMBER_ARRAY,
    PGM_OPTION_TYPE_BOOLEAN_8,
} from 'constants/pgm'
import {PARTITION_ALL} from 'constants/partitions'

import {__} from 'utils/i18n'

import PGMFormFieldDescription from './PGMFormFieldDescription'

export default class PGMForm extends React.Component {
    static propTypes = {
        pgm: PropTypes.shape({
            id: PropTypes.number,
            deviceId: PropTypes.number,
            zoneNumber: PropTypes.number,
            port: PropTypes.number,
            enabled: PropTypes.bool,
            type: PropTypes.string,
            options: PropTypes.object,
        }),
        partitions: PropTypes.arrayOf(PropTypes.number),

        panelId: PropTypes.number,

        parents: PropTypes.arrayOf(PropTypes.shape({
            deviceId: PropTypes.number,
            deviceType: PropTypes.string,
        })),
        ports: PropTypes.objectOf(
            PropTypes.arrayOf(PropTypes.shape({
                port: PropTypes.number,
                id: PropTypes.number,
            })),
        ),

        types: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            label: PropTypes.string,
            description: PropTypes.string,
            restrictions: PropTypes.shape({
                deviceTypes: PropTypes.arrayOf(PropTypes.string),
                numbers: PropTypes.arrayOf(PropTypes.number),
            }),
        })),
        options: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string,
            units: PropTypes.string,
            restrictions: PropTypes.shape({
                deviceTypes: PropTypes.arrayOf(PropTypes.string),
                pgmTypes: PropTypes.arrayOf(PropTypes.string),
            }),
        })),

        onSubmit: PropTypes.func.isRequired,

        isLoading: PropTypes.bool,
        error: PropTypes.string,
    }

    static defaultProps = {
        isLoading: false,
    }

    state = {}

    renderParent = () => {
        const {parents, pgm: {deviceId: parentId}} = this.props

        return (
            <Select name="parentId"
                    label={__('Parent Id')}
                    value={parentId}
                    onChange={this.onChangeParentId}
            >
                {
                    parents && parents.map(({deviceId: id, deviceType: type}) => (
                        <Option label={deviceType(type)}
                                value={id}
                                key={id}
                        />
                    ))
                }
            </Select>
        )
    }

    onChangeParentId = (e, parentId) => {
        const {ports} = this.props

        if (ports.hasOwnProperty(parentId) && ports[parentId].length > 0) {
            const {port} = ports[parentId][0]
            this.setState({port})
        } else {
            this.setState({port: null})
        }
        this.setState({parentId})
    }

    renderPort = () => {
        const {ports, pgm: {deviceId: parentId, port}} = this.props

        return (
            <Select name="port"
                    label={__('Port')}
                    value={port}
                    onChange={(e, port) => this.setState({port: parseInt(port)})}
            >
                {
                    ports && ports.hasOwnProperty(parentId) && ports[parentId].map(({port}) => (
                        <Option label={port}
                                value={port}
                                key={port}
                        />
                    ))
                }
            </Select>
        )
    }

    get parentType() {
        const {parents, pgm: {deviceId: parentId}} = this.props

        if (!parents || !parentId) {
            return null
        }

        const {deviceType} = parents.find(({deviceId}) => deviceId === parentId) || {}

        return deviceType
    }

    get types() {
        const {types} = this.props

        if (!types) {
            return []
        }

        return types.filter(
            ({restrictions}) => !restrictions || restrictions.deviceTypes.includes(this.parentType),
        ) || []
    }

    renderType = () => {
        const {pgm: {type}} = this.props
        const value = this.state.type || type

        return (
            <Select name="type"
                    label={__('Type')}
                    value={value}
                    onChange={(e, type) => this.setState({type})}
            >
                {
                    this.types.map(({type, label}) => (
                        <Option label={label}
                                value={type}
                                key={type}
                        />
                    ))
                }
            </Select>
        )
    }

    get options() {
        const {options, pgm} = this.props

        if (!options) {
            return []
        }

        const type = this.state.type || pgm.type

        return options.filter(({restrictions}) => (
            !restrictions
            || (
                (!restrictions.deviceTypes || restrictions.deviceTypes.includes(this.parentType))
                && (!restrictions.pgmTypes || restrictions.pgmTypes.includes(type))
            )
        ))
    }

    getDefaultPartitionsValue = (value) => {
        const {partitions, partitionsList} = this.props

        value = value === null ? [] : value

        if (value.length === 0 && partitions) {
            value = Array.from(new Set(partitions.map(partition => partition === PARTITION_ALL ? 1 : partition)))
        }

        return value
            .filter(partitionId => partitionsList.map(partition => partition.value).includes(partitionId))
            .map(partitionId => partitionsList.find(partition => partition.value === partitionId))
    }

    getOptionValue = (key) => {
        const {pgm} = this.props
        const {options} = pgm || {}

        if (options && options.hasOwnProperty(key)) {
            return options[key]
        }
        return null
    }

    renderBooleanOptionField = ({key, label, description}) => {
        return (
            <Fragment key={key}>
                <Checkbox name={key}
                          label={label}
                          defaultChecked={!!this.getOptionValue(key)}
                />
                <PGMFormFieldDescription>
                    {description}
                </PGMFormFieldDescription>
            </Fragment>
        )
    }

    renderNumberField = ({key, label, units, description}) => {
        return (
            <Fragment key={key}>
                <Input name={key}
                       label={`${label} (${units})`}
                       defaultValue={this.getOptionValue(key)}
                       type="number"
                       min="0"
                />
                <PGMFormFieldDescription>
                    {description}
                </PGMFormFieldDescription>
            </Fragment>
        )
    }

    renderPartitions = ({label, key}) => {
        return (
            <MultiSelect label={label}
                         name={key}
                         key={key}
                         defaultValues={this.getDefaultPartitionsValue(this.getOptionValue(key))}
                         hasSelectAll
            >
                {
                    this.props.partitionsList && this.props.partitionsList.map((partition, key) => (
                        <Option key={partition.value || key} {...partition} />
                    ))
                }
            </MultiSelect>
        )
    }

    renderZones = ({label, key}) => {
        const options = []
        const range = key.match(/ZONE_TERMINALS_(\d+)_(\d+)/)

        for (let i = range[1]; i <= range[2]; i++) {
            options.push(
                <Option key={i}
                        value={i}
                        label={__(`Zone ${i}`)}
                />,
            )
        }

        const defaultValue = (this.getOptionValue(key) || [])
            .map((zone) => ({value: zone, label: __(`Zone ${zone}`)}))

        return (
            <MultiSelect label={label}
                         name={key}
                         key={key}
                         defaultValue={defaultValue}
                         hasSelectAll
            >
                {
                    options
                }
            </MultiSelect>
        )
    }

    renderBoolean8 = ({label, key}) => {
        const checkboxes = []
        const range = key.match(/ZONE_TERMINALS_(\d+)_(\d+)/)

        for (let i = range[1]; i <= range[2]; i++) {
            checkboxes.push(
                <Checkbox name={key + '[]'}
                          key={`${key}-${i}`}
                          label={__(`ZONE TERMINAL #${i}`)}
                          value={i}
                          defaultChecked={(this.getOptionValue(key) || []).includes(i)}
                />,
            )
        }

        return (
            <Fragment key={key}>
                <h3 className="pgm-form-field-title">
                    {label}
                </h3>
                {
                    checkboxes
                }
            </Fragment>
        )
    }

    renderOption = ({key, label, description, type, units}) => {
        switch (type) {
            case PGM_OPTION_TYPE_BOOLEAN:
                return this.renderBooleanOptionField({key, label, description})
            case PGM_OPTION_TYPE_NUMBER:
                return this.renderNumberField({key, label, units, description})
            case PGM_OPTION_TYPE_UNSIGNED_8:
            case PGM_OPTION_TYPE_NUMBER_ARRAY:
                switch (true) {
                    case key === 'PARTITIONS':
                        return this.renderPartitions({key, label})
                    case key.startsWith('ZONE_TERMINALS_'):
                        return this.renderZones({key, label})
                }
                break
            case PGM_OPTION_TYPE_BOOLEAN_8:
                return this.renderBoolean8({key, label})
        }
    }

    onClose = () => {
        const {onClose} = this.props

        return onClose ? onClose() : null
    }

    getPGMId = () => {
        const {pgm, ports} = this.props
        const {id} = pgm || {}

        if (id) {
            return id
        }

        const {pgm: {deviceId: parentId, port}} = this.props

        if (!parentId || !port || !ports || !ports.hasOwnProperty(parentId)) {
            return null
        }

        const available = ports[parentId].find(item => item.port === port) || {}

        if (available && available.hasOwnProperty('id')) {
            return available.id
        } else {
            return null
        }
    }

    getValidOptions = (options) => {
        return Object.keys(options).reduce((acc, key) => {
            const {type} = this.props.options.find(option => option.key === key) || {}

            switch (type) {
                case 'boolean':
                    return {...acc, [key]: !!options[key]}
                case 'number':
                    return {...acc, [key]: parseInt(options[key])}
                case 'boolean [8]':
                case 'unsigned [8]':
                case '[number]':
                case 'number []':
                    return {...acc, [key]: options[key].map(value => parseInt(value))}
                default:
                    return acc
            }
        }, {})
    }

    onSubmit = ({parentId, port, type, ...options}) => {
        const {onSubmit} = this.props

        return onSubmit(this.getPGMId(), type, this.getValidOptions(options))
    }

    getOptionsRules = () => {
        return this.options.reduce((rules, option) => {
            if (option.type !== 'boolean') {
                rules[option.key] = {
                    presence: {
                        message: __('Option required'),
                    },
                }
            }
            return rules
        }, {})
    }

    getRules = () => {
        const {pgm: {id}} = this.props

        return Object.assign({
            parentId: {
                presence: !id && {
                    message: __('You should define PGM Parent Device'),
                },
            },
            port: {
                presence: !id && {
                    message: __('You should define PGM Port'),
                },
            },
            type: {
                presence: {
                    message: __('You should define PGM Type'),
                },
            },
        }, this.getOptionsRules())
    }

    render() {
        const {pgm: {id}, isLoading, error} = this.props

        return (
            <Form isLoading={isLoading}
                  className="pgm-form"
                  onClose={this.onClose}
                  onSubmit={this.onSubmit}
                  rules={this.getRules()}
                  errors={error && error.errors}
            >
                {
                    !id &&
                    this.renderParent()
                }
                {
                    !id &&
                    this.renderPort()
                }
                {
                    this.renderType()
                }
                {
                    this.options && this.options.map(this.renderOption)
                }
                {/* <hr/> */}
                <Button className="pgm-form-submit" primary disabled={isLoading} type="submit">
                    {__('Save')}
                </Button>
            </Form>
        )
    }
}
