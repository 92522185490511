import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

export default function withForm(handler) {
    return connect(
        ({forms}, {isLoading}) => ({
            ...forms[handler],
            isLoading: isLoading || (forms[handler] && forms[handler].isLoading),
        }),
        dispatch => bindActionCreators({handle: handler}, dispatch),
    )
}