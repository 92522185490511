import React from 'react'
import {compose} from 'redux'

import {edit} from 'permissions/users/actions'
import list from 'permissions/users/list'
import selection from 'permissions/users/selection'

import withProps from 'containers/withProps'
import withUsers from 'containers/withUsers'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import withLifeCycle from 'containers/withLifeCycle'
import {withPermission, withRejection} from 'containers/withPermission'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'

import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import OnlineIcon from 'ipmp-react-ui/icons/online.svg'
import OfflineIcon from 'ipmp-react-ui/icons/offline.svg'

import EditCell from './TableCell/EditCell'

const columns = [
    {
        name: () => __('Name'),
        width: 270,
        render: ({isEnabled, isOnline, name, email}) => (
            <Definition
                iconClassName={isOnline ? 'success' : ''}
                Icon={isEnabled ? OnlineIcon : OfflineIcon}
                title={name}
                detail={email}/>
        ),
    },
    {
        name: () => __('Phone'),
        render: ({phone}) => phone,
    },
    {
        name: () => __('Last login'),
        render: ({lastLogin}) => <DateTime datetime={lastLogin}/>,
    },
    {
        name: () => __('Role'),
        render: ({roleName}) => roleName,
    },
    {
        name: () => __('Created by'),
        render: ({userCreatorName}) => userCreatorName || <span className="empty">&mdash;</span>,
    },
    {
        tooltip: false,
        permission: edit,
        width: 50,
        fixed: true,
        component: EditCell,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withUsers(),
    withSelection(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No users found'),
    }),
    withTableColumnsPermission(),
)(Table)
