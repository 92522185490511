import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withPushBasicConfigurations from 'containers/withPushBasicConfigurations'

import selection from 'permissions/pushBasicConfiguration/selection'

import SelectionDropDown from 'components/SelectionDropDown'
import {withRouter} from 'react-router'

export default compose(
    withRouter,
    withPermission({isVisible: selection}),
    withVisibility(),
    withSelection(),
    withPushBasicConfigurations(),
)(SelectionDropDown)