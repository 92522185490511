import CodeValue from 'components/Configuration/Row/CodeValue'
import {__} from 'utils/i18n'

const regexp = /^[A-F\d]*$/

export default class CodeHexValue extends CodeValue {

    validateCharaters(value) {
        if (!regexp.test(value)) {
            return __('Should contains only digits')
        }
    }

}