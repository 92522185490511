import {takeEvery, put, call} from 'redux-saga/effects'
import {fetch, setLoading} from './actions'
import * as api from 'api/groups'
import {update} from '../store/actions'

export default function* () {
    yield takeEvery(fetch, watchFetch)
}

function* watchFetch({payload: {prefix, count}}) {
    try {
        const {rows} = yield call(api.fetch, {
            count,
            query: prefix,
        })

        yield put(update(rows))
        yield put(setLoading(false))
    } catch (error) {
        yield put(setLoading(false))
    }
}
