import React, {Component} from 'react'
import {Route, Switch} from 'react-router'
import {compose} from 'redux'
import {connect} from 'react-redux'

import page from 'permissions/panel/configuration/page'
import {upload} from 'permissions/panel/configuration/actions'

import withCurrentConfiguration, {
    withBackupConfiguration,
    withCompareConfiguration,
    withConfigurationPreloader,
} from 'containers/withConfiguration'
import withConfigurationsList from 'containers/withConfigurationsList'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withProps from 'containers/withProps'

import path from 'utils/path'

import PanelConfigurationsList from './List/PanelConfigurationsList'
import PanelViewConfiguration from './View/PanelViewConfiguration'
import {selectUploadConfigurationFeature} from 'modules/features/store/selectors'
import withRunnerPoll from 'containers/withRunnerPoll'

const CurrentConfiguration = compose(
    withCurrentConfiguration(),
    connect(selectUploadConfigurationFeature),
    withProps(({isAvailable}) => ({
        isEditable: isAvailable,
    }))
)(PanelViewConfiguration)

const CompareConfiguration = compose(
    withCompareConfiguration(),
    withProps({
        isCompareWithBackup: true,
    }),
)(PanelViewConfiguration)

const BackupConfiguration = compose(
    withBackupConfiguration(),
    withProps({
        isEditable: false,
    }),
)(PanelViewConfiguration)

class ConfigurationsPage extends Component {
    renderList = () => <PanelConfigurationsList {...this.props}/>

    renderCurrent = () => {
        return (
            <CurrentConfiguration
                panelId={this.props.panelId}
                isEditable={this.props.isEditable}
            />
        )
    }

    renderView = ({match}) => {
        const id = parseInt(match.params.configId)

        return (
            <BackupConfiguration
                panelId={this.props.panelId}
                configId={id}
            />
        )
    }

    renderCompare = ({match}) => {
        const id = parseInt(match.params.configId)
        return <CompareConfiguration
            panelId={this.props.panelId}
            configId={id}
        />
    }

    componentDidMount() {
        const {panelId, pendingChanges, history} = this.props

        if (pendingChanges) {
            history.push(path('panel.configuration.current', {id: panelId}))
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path={path('panel.configuration')} component={this.renderList}/>
                <Route exact path={path('panel.configuration.current')} component={this.renderCurrent}/>
                <Route exact path={path('panel.configuration.view')} component={this.renderView}/>
                <Route exact path={path('panel.configuration.compare')} component={this.renderCompare}/>
            </Switch>
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
        isEditable: upload,
    }),
    withRejection(),
    withProps(({match}) => ({
        panelId: parseInt(match.params.id),
    })),
    withConfigurationsList(),
    withLoader(({fetch}) => fetch()),
    withRunnerPoll(),
    withConfigurationPreloader({
        isErrorCallback: ({rows}) => !rows || !rows.length,
    }),
)(ConfigurationsPage)
