import {compose} from 'redux'

import withSelection from 'containers/withSelection'
import withUpgradeStatusRunners from 'containers/withUpgradeStatusRunners'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withUpgradeStatusRunners(),
    withSelection(),
)(SelectionDropDown)
