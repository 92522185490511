import {compose} from 'redux'

import AllProcesses from 'components/Dashboard/AllProcesses'
import {withAllProcesses} from 'containers/withDashboard'
import {allProcesses} from 'permissions/dashboard/charts'
import {withPermission, withRejection} from 'containers/withPermission'

export default compose(
    withPermission({isAllowed: allProcesses}),
    withRejection(),
    withAllProcesses()
)(AllProcesses)