import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Filter from 'components/Search/Filters/Filter'
import BoardFilterValue from 'components/Search/Board/BoardFilterValue'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import {Option} from 'ipmp-react-ui/Select'
import {__} from 'utils/i18n'

export default class BoardFilter extends Component {

    static propTypes = {
        filter: PropTypes.instanceOf(Filter),
        selected: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
    }

    state = {}

    handleSet = (e, item) => {
        const {onSelect} = this.props
        onSelect(item)
        this.setState({prefix: ''})
    }

    getFastItems(fastItemsCount) {
        return this.props.filter.getItems().slice(0, fastItemsCount)
    }

    renderAutocomplete(fastItemsCount) {
        const {filter} = this.props
        const items = filter.getItems().slice(fastItemsCount)

        if (items.length === 0) {
            return null
        }

        return (
            <Autocomplete
                placeholder={__('More value')}
                onSet={this.handleSet}
                value={this.state.prefix}
            >
                {items.map(item => <Option key={item.$} label={item.label} value={item}/>)}
            </Autocomplete>
        )
    }

    renderFilterItem(item) {
        const {filter, onSelect, onDeselect, selected} = this.props

        return (
            <BoardFilterValue
                key={item.$}
                item={item}
                label={item.hasOwnProperty('label') ? item.label : filter.getItemLabel(item.value)}
                selected={selected.some(i => i.$ === item.$)}
                onSelect={onSelect}
                onDeselect={onDeselect}
            />
        )
    }

    renderFastItems(items) {
        return items.map(item => this.renderFilterItem(item))
    }

    renderSelectedItems(fastItems) {
        const {selected, filter} = this.props

        return selected
            .filter(({name}) => name === filter.name)
            .filter(({$}) => fastItems.every(item => item.$ !== $))
            .map(item => this.renderFilterItem(item))
    }

    render() {
        const {filter} = this.props

        const fastItemsCount = 5
        const items = this.getFastItems(fastItemsCount)

        return (
            <div className={classnames('search-filter', this.className)} key={filter.name}>
                <h3 className="hint">{filter.title}</h3>

                {this.renderFastItems(items)}
                {this.renderSelectedItems(items)}
                {this.renderAutocomplete(fastItemsCount)}
            </div>
        )
    }
}