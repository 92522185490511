import createPermission, {any} from 'permissions/createPermission'

import enable from './ssh/enable'
import disable from './ssh/disable'

export default createPermission(
    any(
        enable,
        disable,
    ),
)