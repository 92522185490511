import {all} from 'redux-saga/effects'

import list from './list/saga'
import one from './one/saga'
import select from './select/saga'
import push from './push/saga'

export default function*() {
    yield all([
        list(),
        one(),
        select(),
        push(),
    ])
}