
import {
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_POWER_LINK,
    DEVICE_TYPE_GSM,
    DEVICE_TYPE_EXPANDER_33,
    DEVICE_TYPE_ZONE_EXPANDER,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_GUARD_KEY,
    DEVICE_TYPE_PGM,
} from './deviceType'

import {
    DEVICE_SUBTYPE_PGM_ON_PANEL,
    DEVICE_SUBTYPE_PGM_ON_EXPANDER33,
    DEVICE_SUBTYPE_GENERIC_HSMX56K,
    DEVICE_SUBTYPE_GENERIC_FIREBELL,
    DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE,
    DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE,
    DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE,
    DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE,
    DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE,
} from './deviceSubtype'

export function isRemovable(deviceType, deviceSubtype) {
    switch (deviceType) {
        case DEVICE_TYPE_CONTROL_PANEL:
        case DEVICE_TYPE_POWER_LINK:
        case DEVICE_TYPE_GSM:
        case DEVICE_TYPE_EXPANDER_33:
        case DEVICE_TYPE_ZONE_EXPANDER:
        case DEVICE_TYPE_OUTPUT_EXPANDER:
        case DEVICE_TYPE_POWER_SUPPLY:
        case DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS:
        case DEVICE_TYPE_PGH:
        case DEVICE_TYPE_COMMUNICATOR:
        case DEVICE_TYPE_PGM:
        case DEVICE_TYPE_GUARD_KEY: return false
    }

    switch (deviceSubtype) {
        case DEVICE_SUBTYPE_PGM_ON_PANEL:
        case DEVICE_SUBTYPE_PGM_ON_EXPANDER33:
        case DEVICE_SUBTYPE_GENERIC_HSMX56K:
        case DEVICE_SUBTYPE_GENERIC_FIREBELL:
        case DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE:
        case DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE:
        case DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE:
        case DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE:
        case DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE: return false
    }

    return true
}

export function isRenameable(deviceType) {
    switch (deviceType) {
        case DEVICE_TYPE_PGM: return false
        default: return true
    }
}