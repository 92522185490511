import {put, all, call, race, select, take} from 'redux-saga/effects'
import {LOCATION_CHANGE} from 'react-router-redux'
import {delay} from 'redux-saga'
import get from 'lodash-es/get'

import {isOnline} from 'api/panel/info'
import * as infoApi from 'api/panel/info'
import {
    POLL_DEVICES,
    POLL_DEVICES_BACKGROUND,
    POLL_PANEL_IS_ONLINE,
    POLL_STATE,
    POLL_STATE_BACKGROUND,
} from 'configs/pollers'
import {
    PROCESS_TYPE_PMAXCANCELSOAKZONE,
    PROCESS_TYPE_PMAXCLEARBYPASSZONE,
    PROCESS_TYPE_PMAXSETBYPASSZONE,
    PROCESS_TYPE_PMAXSETSOAKZONE,
    PROCESS_TYPE_PMAXZONEADD,
    PROCESS_TYPE_PMAXZONEREMOVE,
    PROCESS_TYPE_PMAXZONERSSI,
} from 'constants/processTypes'

import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {watchFetch as fetchDevices} from 'modules/devices/list/saga'
import {watchFetch as fetchState} from 'modules/panels/state/saga'
import {takeProcessComplete, takeProcessCompleteSuccessful} from 'modules/processes/manager/takeProcess'

import watchPanelDiscoveryComplete from './watchers/watchPanelDiscoveryComplete'

import {check} from 'utils/path'

import {update} from '../store/actions'
import * as actions from './actions'

export default function* () {
    yield all([
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            devicesDelay,
            fetchDevices,
            true,
        ),
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            statesDelay,
            fetchState,
            true,
        ),
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            POLL_PANEL_IS_ONLINE,
            fetchIsOnline,
            true,
        ),
    ])
}

function* fetchIsOnline({payload: {panelId}}) {
    try {
        const data = yield call(isOnline, panelId)

        if (!data.isActivated) {
            const {isActivating} = yield call(infoApi.isPanelActivating, panelId)
            data.isActivating = isActivating
        }

        yield call(checkFeatures, data)

        yield put(update(data))
    } catch (error) {
        yield put(actions.setError(error))
    }
}

function* devicesDelay({payload: {panelId}}) {
    const {pathname} = yield select(state => state.router.location)
    const timeout = check('panel.devices', pathname) ? POLL_DEVICES : POLL_DEVICES_BACKGROUND

    yield race({
        delay: delay(timeout),
        zoneChanged: takeProcessComplete([
            PROCESS_TYPE_PMAXZONEADD,
            PROCESS_TYPE_PMAXZONEREMOVE,
            PROCESS_TYPE_PMAXSETSOAKZONE,
            PROCESS_TYPE_PMAXCANCELSOAKZONE,
            PROCESS_TYPE_PMAXSETBYPASSZONE,
            PROCESS_TYPE_PMAXCLEARBYPASSZONE,
        ], panelId),
        rssiRefreshed: takeProcessCompleteSuccessful(PROCESS_TYPE_PMAXZONERSSI, panelId),
        location: take(({type, payload}) => (type === LOCATION_CHANGE && check('panel.devices', payload && payload.pathname))),
    })
}

function* statesDelay() {
    const {pathname} = yield select(state => state.router.location)
    const timeout = check('panel.state', pathname) ? POLL_STATE : POLL_STATE_BACKGROUND

    yield race({
        delay: delay(timeout),
        location: take(({type, payload}) => (type === LOCATION_CHANGE && check('panel.state', payload && payload.pathname))),
    })
}

function* checkFeatures(data) {
    const panel = yield select(state => state.panels.store.byIds[data.id])

    const isDiscoveryRuns = get(panel, 'discoveryStatus.completed', true) === false
    const isDicoveryComplete = get(data, 'discoveryStatus.completed', true) === true

    if (isDiscoveryRuns && isDicoveryComplete) {
        yield call(watchPanelDiscoveryComplete, {panelId: data.id})
    }
}