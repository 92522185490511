import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {showSystemSettingsEditMessageBrokerModal} from 'modules/modals/actions'
import {removeMessageBroker} from 'modules/system/settings/actions'

import Table from 'ipmp-react-ui/Table'
import BrokersTableDeleteButton from './BrokersTableDeleteButton'


import {__} from 'utils/i18n'

const messageBrokersColumns = [
    {
        width: 50,
        name: () => __('ID'),
        render: ({id}) => id,
    },
    {
        width: 150,
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        width: 150,
        name: () => __('Description'),
        render: ({description}) => description,
    },
    {
        align: 'right',
        width: 50,
        fixed: true,
        render: ({id}, {onRemove}) => {
            return (
                <BrokersTableDeleteButton onClick={() => onRemove(id)}/>
            )
        },
    },
]

export class BrokersTable extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            sender: PropTypes.string,
            login: PropTypes.string,
            password: PropTypes.string,
            host: PropTypes.string,
            port: PropTypes.number,
            useTLS: PropTypes.bool,
            messageTemplate: PropTypes.string,
        })),
        edit: PropTypes.func,
        remove: PropTypes.func,
    }

    get brokers() {
        return this.props.data
    }

    render() {
        const {edit, remove} = this.props

        return (
            <Table
                fullHeight
                emptyMessage={__('No message brokers found')}
                rows={this.brokers}
                columns={messageBrokersColumns}
                onRowClick={edit}
                onRemove={remove}

            />
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {messageBrokers}}}) => ({...messageBrokers}),
        (dispatch) => bindActionCreators({
            edit: (broker) => showSystemSettingsEditMessageBrokerModal({
                data: broker,
            }),
            remove: (id) => removeMessageBroker(id),
        }, dispatch)
    ),
)(BrokersTable)