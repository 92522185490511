import withProps from 'containers/withProps'
import Spinner from 'ipmp-react-ui/Spinner'
import {TableGrouped} from 'ipmp-react-ui/Table'
import {CrossServerSearchContext} from 'pages/Panels/CrossServerSearch/CrossServerSearch'
import React from 'react'
import {__} from 'utils/i18n'

const Table = withProps({
    columns: [
        {
            render: ({account}) => account,
        },
        {
            render: ({serial}) => serial,
        },
        {
            render: ({model}) => model,
        },
    ],
    onRowClick: ({url, id}) => window.open(url + '/panel/' + id, '_blank'),
    separatorCallback: ({domain}) => domain,
    emptyMessage: () => __('No panels found'),
})(TableGrouped)

export default function CrossServerSearchResultsTable() {
    return (
        <CrossServerSearchContext.Consumer>
            {({results, isLoading}) => {
                if (isLoading) {
                    return <Spinner/>
                }

                return results && <Table className="crossServerBlind-table" rows={results}/>
            }}
        </CrossServerSearchContext.Consumer>
    )
}
