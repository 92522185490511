import React from 'react'
import {compose} from 'redux'

import bar from 'permissions/roles/bar'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import RolesSelectionDropDown from './GridBar/RolesSelectionDropDown'
import RemoveRolesButton from './GridBar/RemoveRolesButton'
import RolesPager from './GridBar/RolesPager'
import AddRoleButton from './GridBar/AddRoleButton'

export const RolesGridBar = () => (
    <Bar>
        <RolesSelectionDropDown/>
        <RemoveRolesButton/>

        <BarSpace/>

        <RolesPager/>

        <AddRoleButton/>
    </Bar>
)

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(RolesGridBar)