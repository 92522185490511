import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'

import Select, {Option} from 'ipmp-react-ui/Select'
import * as actions from 'modules/system/settings/actions'

import withLoader from 'containers/withLoader'

import {__} from 'utils/i18n'

export class SelectBroker extends Component {
    static propTypes = {
        messageBrokers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            sender: PropTypes.string,
            login: PropTypes.string,
            password: PropTypes.string,
            host: PropTypes.string,
            port: PropTypes.number,
            useTLS: PropTypes.bool,
            messageTemplate: PropTypes.string,
        })),
    }

    render() {
        const {messageBrokers, defaultValue, ...props} = this.props
        const value = messageBrokers.some((broker) => broker.id == defaultValue) ? defaultValue : null
        return (
            <Select
                optional={__('Without message broker')}
                defaultValue={value}
                {...props}
            >
                {messageBrokers.map(({id, name}) => <Option key={id} label={name} value={id}/>)}
            </Select>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {messageBrokers}}}) => ( {messageBrokers: messageBrokers.data}),
        dispatch => bindActionCreators({
            fetchMessageBrokers: actions.fetchMessageBrokers,
        }, dispatch),
    ),
    withLoader(
        ({fetchMessageBrokers}) => fetchMessageBrokers(),
    ),
)(SelectBroker)