import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash/get'
import reduce from 'lodash/reduce'

import Page from 'ipmp-react-ui/Page'
import ProcessLoader from 'ipmp-react-ui/ProcessLoader'

import {withLocationChange} from 'containers/withLocationChange'
import ActivatePanelInteractiveBar from 'pages/Panel/InteractiveBar/ActivatePanelInteractiveBar'
import ConfigurationChangesInteractiveBar from 'pages/Panel/InteractiveBar/ConfigurationChangesInteractiveBar'
import MakeBasicConfigurationInteractiveBar from 'pages/Panel/InteractiveBar/MakeBasicConfigurationInteractiveBar'
import {getItemsByKeys, getMenuItems} from 'pages/Panel/PanelPageRouting'
import {content} from 'permissions/panel/page'
import * as tabs from 'permissions/panel/navigation'

import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import {__} from 'utils/i18n'

import PanelPageTop from './PanelPageTop'
import PanelPageBar from './PanelPageBar'
import PanelPageContent from './PanelPageContent'
import PanelRemarks from './PanelRemarks'

import withFeatures, {filterPanelTabsWithFeature} from 'containers/withFeature'
import {selectDiscovery} from 'modules/features/store/selectors'

class PanelPage extends Page {

    static propTypes = {
        isDiscoveryApplicable: PropTypes.bool,
    }

    state = {
        isOpenedRemarks: false,
    }

    openRemarks = () => this.setState({isOpenedRemarks: true})

    closeRemarks = () => this.setState({isOpenedRemarks: false})

    renderTop() {
        return <PanelPageTop openRemarks={this.openRemarks} {...this.props}/>
    }

    get isContentHiden() {
        const {panel, isDiscoveryApplicable} = this.props

        return !panel.isActivated && isDiscoveryApplicable
    }

    renderBar() {
        if (this.isContentHiden) {
            return null
        }

        return <PanelPageBar menu={this.props.items}/>
    }

    renderContent() {
        const {panel} = this.props

        if (this.isContentHiden) {
            return <ProcessLoader message={__('Discovery in progress')}/>
        }

        return <Fragment>
            <PanelPageContent routes={this.props.items}/>

            <PanelRemarks
                panelId={panel.id}
                opened={this.state.isOpenedRemarks}
                onClose={this.closeRemarks}
            />
        </Fragment>
    }

    renderInteractiveBar() {
        const {id, isActivated} = this.props.panel

        if (!isActivated) {
            return [
                <ActivatePanelInteractiveBar id={id} key="activate-panel"/>,
            ]
        }

        return [
            <ConfigurationChangesInteractiveBar key="configuration"/>,
            <MakeBasicConfigurationInteractiveBar key="make-basic-configuration"/>,
        ]
    }
}

export default compose(
    withPermission({
        isAllowed: content,
        allowedItems: {
            ...tabs,
        },
    }),
    withRejection(),
    withPanel(),
    withRouterPanelId(),
    withFeatures(),
    connect(
        (state, {
            allowedItems,
            panel,
            match,
            isLoading,
            isFeatureLoading,
            panelId,
            features,
        }) => {
            const isPanelFeatures = Boolean(features)
            const isLoadingBeforeFetch = !panel || !isPanelFeatures

            if (isLoadingBeforeFetch) {
                return {isLoading: isLoadingBeforeFetch, isPanelFeatures}
            }

            const vendorItems = getMenuItems(panel.vendor)
            let mergedItems = reduce(
                allowedItems,
                (res, isAllowed, item) => {
                    if (isAllowed && get(vendorItems, item, false)) {
                        res.push(item)
                    }
                    return res
                },
                [],
            )

            const items = filterPanelTabsWithFeature(
                state,
                getItemsByKeys(mergedItems),
                match.params.id
            )

            return {
                items,
                key: match.params.id,
                isLoading: isLoading || isFeatureLoading,
                isDiscoveryApplicable: selectDiscovery(
                    state,
                    {panelId: match.params.id}
                ).isAvailable,
                isPanelFeatures,
            }
        }
    ),
    withLoader(({fetch, fetchFeatures, fetchFirmware, panelId, isPanelFeatures}) => {
        !isPanelFeatures && fetchFeatures(panelId)
        fetchFirmware()
        fetch()
    }),
    withLocationChange(
        ({location, match}, {remember}) => {
            remember(location.pathname)
        },
    ),
    withLifeCycle({
        onMount({startPollPanelData}) {
            startPollPanelData()
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },
    }),
)(PanelPage)
