import React, {Component} from 'react'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'

import {__, ordinals, months, weekdays} from 'utils/i18n'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'

const MODE_EVERY_MONTH = 'everyMonth'
const MODE_EVERY_DAY_IN_MONTH = 'everyDayInMonth'

export default class extends Component {
    state = {
        mode: MODE_EVERY_MONTH,
    }

    setMode = (mode) => {
        this.setState({mode})
    }

    render() {
        const {mode} = this.state

        return (
            <FormSection>
                <FormRow>
                    <span>{__('Recur every')}</span>

                    <Input {...{
                        type: 'number',
                        defaultValue: 1,
                        name: 'every',
                        min: 1,
                        max: 70,
                    }}/>

                    <span>{__('year')}</span>
                </FormRow>

                <FormRow>
                    <Checkbox {...{
                        checked: mode === MODE_EVERY_MONTH,
                        onChange: () => this.setMode(MODE_EVERY_MONTH),
                        defaultValue: 1,
                        name: '_yearlyDayMonth',
                    }}/>

                    <Select {...{onFocus: () => this.setMode(MODE_EVERY_MONTH), defaultValue: 1, name: 'yearlyMonth'}}>
                        {months().map((month, key) =>
                            <Option {...{key, label: __('Every %s', month), value: key + 1}}/>
                        )}
                    </Select>

                    <Input {...{type: 'number', min: 1, max: 30, defaultValue: 1, name: 'yearlyDay'}}/>
                </FormRow>

                <FormRow>
                    <Checkbox {...{
                        checked: mode === MODE_EVERY_DAY_IN_MONTH,
                        onChange: () => this.setMode(MODE_EVERY_DAY_IN_MONTH),
                        name: '_yearlyDayWeekMonth',
                    }}/>

                    <Select {...{onFocus: () => this.setMode(MODE_EVERY_DAY_IN_MONTH), defaultValue: 1, name: 'yearlyWeekNumber'}}>
                        {ordinals(4).map((ordinal, weekNumber) => (
                            <Option {...{key: weekNumber, value: weekNumber + 1, label: __('every %s', ordinal)}}/>
                        ))}
                    </Select>

                    <Select {...{onFocus: () => this.setMode(MODE_EVERY_DAY_IN_MONTH), defaultValue: 1, name: 'yearlyWeekDay'}}>
                        {weekdays('dddd').map(({label, day}) => (
                            <Option {...{key: day, value: day, label}}/>
                        ))}
                    </Select>

                    <span>{__('of')}</span>

                    <Select {...{onFocus: () => this.setMode(MODE_EVERY_DAY_IN_MONTH), defaultValue: 1, name: 'yearlyWeekMonth'}}>
                        {months().map((label, index) => <Option {...{key: index, label, value: index + 1}}/>)}
                    </Select>
                </FormRow>
            </FormSection>
        )
    }
}