import createRowsResponseParser from 'api/base/createRowsResponseParser'

import {get, post} from 'api/http'
import generateSuggestString from 'api/base/generateSuggestString'
import parseDate from 'api/base/parseDate'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import mapValidationErrors from 'api/base/mapValidationErrors'

import dataMapper from 'utils/dataMapper'
import {REPORT_ONE_TIME} from 'constants/reportTypes'
import {err} from 'utils/log'

const parseSchedule = ({ret_schedule_data: data, ret_id: reportId}) => {
    let schedule = {
        every: null,
        schedule: null,
    }

    try {
        data = JSON.parse(data)
        schedule = {
            ...schedule,
            ...data,
        }
        schedule.every = data.settings ? parseInt(data.settings.every) : 1
        schedule.schedule = parseDate(data.schedule)
    } catch (error) {
        err(`Report ${reportId} parse schedule data error: ${error}`)
    }

    return schedule
}

const mapExportTypes = (types) => {
    return types.split(',')
}

const mapReports = dataMapper((report) => {
    const schedule = parseSchedule(report)

    return {
        id: parseInt(report.ret_id),
        unitReportId: parseInt(report.utr_id),
        name: report.ret_name,
        exportTypes: mapExportTypes(report.export_types),
        isReviewed: parseInt(report.is_not_reviewed) === 0,
        isActive: report.ret_active === 'yes',
        created: parseDate(report.ret_created_timestamp),
        isFinished: report.ret_finished === 'y' || (report.utr_status == 'succeeded' && schedule.pattern == REPORT_ONE_TIME),
        next: parseDate(report.ret_next_report_timestamp),
        finishedAt: parseDate(report.utr_finished_timestamp),
        status: report.utr_status,
        percentage: parseInt(report.utr_status_percentages),
        schedule,
    }
})

const keysMap = {
    id: 'ret_id',
    unitReportId: 'utr_id',
    name: 'ret_name',
    exportTypes: 'export_types',
    isReviewed: 'is_not_reviewed',
    isActive: 'ret_active',
    created: 'ret_created_timestamp',
    isFinished: 'ret_finished',
    next: 'ret_next_report_timestamp',
    finishedAt: 'utr_finished_timestamp',
    status: 'utr_status',
    percentage: 'utr_status_percentages',
}

export function fetch({start = 0, perPage: count = 10, query, filters}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_report/getallreports', {start, count, query, filter})
        .then(createRowsResponseParser(mapReports))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/units_report/suggestreports', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function remove(ids) {
    return post('/units_report_remove/remove', {ret_id: ids})
}

export function stop(ids) {
    return post('/units_report_stop/stop', {ret_id: ids})
}

export function review(reportId) {
    return post('/units_report/review', {ret_id: [reportId]})
}

export function buildDownloadReportHref(reportId, exportType) {
    return `/api/units_report/download/id/${reportId}/type/${exportType}`
}

export function buildDownloadReportResultHref(unitId, reportResultId, exportType) {
    return `/api/unit_report_results/download/unt_id/${unitId}/id/${reportResultId}/type/${exportType}`
}

export function fetchReportEmails(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallemails', {prefix})
        .then(({rows, count}) => rows.map(({rte_id, rte_email: email}) => email))
    /* eslint-enable camelcase */
}

export function fetchReportFtpServers(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallftps', {prefix})
        .then(createRowsResponseParser(({rtf_id, ftp_string}) => ({
            id: parseInt(rtf_id),
            name: ftp_string,
        })))
    /* eslint-enable camelcase */
}

export function fetchReportContentOptions(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallcontentoptions', {prefix})
        .then(createRowsResponseParser(({rco_id, rco_name}) => ({
            id: parseInt(rco_id),
            name: rco_name,
        })))
    /* eslint-enable camelcase */
}

export function fetchReportPmaxIndexes(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallpmaxconfigoptions', {prefix})
        .then(createRowsResponseParser(({eii_id, eii_index_description}) => ({
            id: parseInt(eii_id),
            name: eii_index_description,
        })))
    /* eslint-enable camelcase */
}

export function createReport(data) {
    const mapPanels = ({panelIds}) => {
        if (Array.isArray(panelIds)) {
            return {allPanels: false, unt_id: panelIds, num_rows: panelIds.length}
        }

        return {allPanels: true}
    }

    const panels = mapPanels(data)
    delete(data.panelIds)

    return post('/units_report_create/create', {
        ...data,
        ...panels,
    })
        .catch(e => mapValidationErrors(e, keysMap))
}