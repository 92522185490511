import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {withPermission} from 'containers/withPermission'
import {view, exports} from 'permissions/panel/devices/video/actions'

import zoneTypeTitle from 'constants/zoneType'
import {deviceSubtype, deviceImage} from 'constants/deviceSubtype'
import {deviceSignalLevelIcon, signalLevelTitle} from 'constants/signalLevelType'

import WalktestMark from 'components/Devices/WalktestMark'
import TroublesList from 'components/Devices/TroublesList'

import Tooltip from 'ipmp-react-ui/Tooltip'
import Spinner from 'ipmp-react-ui/Spinner'

import IconCamera from 'ipmp-react-ui/icons/camera.svg'
import IconMeteo from 'ipmp-react-ui/icons/meteo.svg'

import {__} from 'utils/i18n'
import formatPartitions from 'utils/partitions'

import {DevicesContext} from 'pages/Panel/Devices/DevicesContext'


export class Device extends Component {

    static contextType = DevicesContext

    static propTypes = {
        device: PropTypes.shape({
            zone: PropTypes.number,
            zoneType: PropTypes.string,
            partitions: PropTypes.array,
            subtype: PropTypes.string,
            traits: PropTypes.object,
            open: PropTypes.bool,
            preenroll: PropTypes.bool,
            enrolling: PropTypes.bool,
            warnings: PropTypes.array,
        }).isRequired,
        active: PropTypes.bool,
        isChanged: PropTypes.bool,
        status: PropTypes.string,
        hasVideoOnDemand: PropTypes.bool,
        hasVideoOnDemandExport: PropTypes.bool,
    }

    static defaultProps = {
        hasVideoOnDemand: true,
        hasVideoOnDemandExport: true,
    }

    onClick = () => {
        const {device} = this.props
        const {handleSelect} = this.context

        handleSelect && handleSelect(device)
    }

    showVOD = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const {device} = this.props
        const {handleSelect} = this.context

        handleSelect && handleSelect(device, {startWithVideoOnDemandTab: true})
    }

    showMeteo = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const {device} = this.props
        const {handleSelect} = this.context

        handleSelect && handleSelect(device, {startWithMeteoTab: true})
    }

    renderRssiIcon() {
        const {traits} = this.props.device || {}

        if (!traits || !traits.rssi) {
            return null
        }

        const {current} = traits.rssi

        const Icon = deviceSignalLevelIcon(current)

        return (
            <Tooltip className="device-icon-holder" tooltip={signalLevelTitle(current)}>
                <Icon className="device-icon"/>
            </Tooltip>
        )
    }

    renderCameraIcon() {
        const {hasVideoOnDemand, device} = this.props
        const {traits, preenroll} = device || {}
        const vod = traits && traits.vod

        if (!hasVideoOnDemand || !vod || preenroll) {
            return null
        }

        return (
            <a className="device-icon-holder" onClick={this.showVOD}>
                <IconCamera className="device-icon"/>
            </a>
        )
    }

    renderMeteoIcon() {
        const {traits} = this.props.device || {}
        const hasMeteo = traits && traits.meteo_info

        if (!hasMeteo) {
            return null
        }

        return (
            <a className="device-icon-holder" onClick={this.showMeteo}>
                <IconMeteo className="device-icon"/>
            </a>
        )
    }

    renderIcons() {
        return (
            <div className="device-icons">
                {this.renderCameraIcon()}
                {this.renderMeteoIcon()}
                {this.renderRssiIcon()}
            </div>
        )
    }

    renderPorts() {
        const {parent} = this.props.device.traits || {}

        if (!parent) {
            return null
        }

        return (
            <div className="device-port">
                {__('Port: %s', parent.port)}
            </div>
        )
    }

    renderPartitions() {
        const device = this.props.device || {}
        const partitions = device.partitions

        if (!partitions || !partitions.length) {
            return null
        }

        return (
            <span className="partitions">
                 {formatPartitions(partitions).map(partition => (
                    <span key={partition} className="partition">{partition}<wbr/></span>
                 ))}
            </span>
        )
    }

    renderBottom(device) {
        const {warnings, preenroll, enrolling} = device

        return (
            <div>
                {this.renderPorts()}

                <TroublesList {...{warnings, preenroll, enrolling}}/>
            </div>
        )
    }

    renderHint() {
        const {device, status} = this.props
        const {traits, zone, zoneType, name} = device || {}

        let location = ''

        if (traits && traits.location && traits.location.hasOwnProperty('name')) {
            location = traits.location.name
        }

        return (
            <div className="hint device-hint">
                {zone && <div className="device-zone">{zone}</div>}
                {zoneType && <div className="device-hint-content">{zoneTypeTitle(zoneType)}</div>}
                <div className="device-hint-content">{name ? name : location}</div>
                {!status && this.renderIcons()}
            </div>
        )
    }

    render() {
        const {active, isChanged, device, status, className} = this.props
        const {subtype, deviceType, bypass, open, preenroll, enrolling, removing} = device || {}
        const backgroundImage = `url(${deviceImage(subtype, deviceType)})`

        return (
            <div className={classes('device', className, {
                'device--active': active,
                'device--changed': isChanged,
                'device--disabled': bypass || preenroll || removing,
            })} onClick={this.onClick}>
                {enrolling && <Spinner className="device-shadow" message={__('Enrolling')}/>}
                {removing && <Spinner className="device-shadow" message={__('Removing')}/>}

                {open ? <Tooltip className="device-openMark" tooltip={__('Open')}/> : null}

                <div className="device-image">
                    <div className="device-image-src" style={{backgroundImage}}/>
                    {bypass && <span className="device-image-overlay">{__('Bypassed')}</span>}
                </div>

                <div className="device-content">
                    {this.renderHint()}

                    <div className="device-title">
                        {deviceSubtype(subtype, deviceType)}
                        {this.renderPartitions()}
                    </div>

                    {this.renderBottom(device)}
                </div>

                {status && <WalktestMark status={status}/>}
            </div>
        )
    }
}

export default withPermission({
    hasVideoOnDemand: view,
    hasVideoOnDemandExport: exports,
})(Device)