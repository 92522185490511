import withProps from 'containers/withProps'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/basicConfiguration/list'
import {fetch, addFilters, removeFilters, clearFilters, fetchSuggests} from 'modules/basicConfiguration/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('name', __('Name')),
            new SuggestFilter('vendor', __('Vendor')),
            new DateFilter('created', __('Created')),
        ],
    })),
    connect(
        ({basicConfiguration: {list}}) => ({
            selected: list.filters,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)
