import React from 'react'

import {MODULE_TYPE_GPRS, MODULE_TYPE_BBA} from 'constants/moduleTypes'
import Communication from 'components/Panel/Communication'

const CommunicationCell = ({bba, gprs, model}) => {
    return (
        <span>
            <Communication
                moduleType={MODULE_TYPE_GPRS}
                module={gprs}
                model={model}
            />

            <Communication
                moduleType={MODULE_TYPE_BBA}
                module={bba}
                model={model}
            />
        </span>
    )
}

export default CommunicationCell