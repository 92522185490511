import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Daily from './Recurrence/Daily'
import Weekly from './Recurrence/Weekly'
import Yearly from './Recurrence/Yearly'
import Monthly from './Recurrence/Monthly'

import * as ReportTypes from 'constants/reportTypes'

export default class extends Component {
    static propTypes = {
        reportType: PropTypes.oneOf(Object.values(ReportTypes)).isRequired,
    }

    getRecurrenceComponent() {
        const {reportType} = this.props

        switch (reportType) {
            case ReportTypes.REPORT_DAILY:
                return Daily

            case ReportTypes.REPORT_WEEKLY:
                return Weekly

            case ReportTypes.REPORT_MONTHLY:
                return Monthly

            case ReportTypes.REPORT_YEARLY:
                return Yearly
        }

        return null
    }

    render() {
        const Component = this.getRecurrenceComponent()
        return Component && <Component/>
    }
}
