import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'

import InteractiveSession from './InteractiveSession'
import Advertisement from './Advertisement'
import UserNotifications from './UserNotifications'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class Interactive extends Component {
    render() {
        return (
            <ScrollView>
                <Card>
                    <InteractiveSession/>
                </Card>
                <Card>
                    <UserNotifications/>
                </Card>
                <Card>
                    <Advertisement/>
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({system: {settings: {interactiveSession, userNotifications, advertisement, messageBrokers}}}) => ({
            isLoading: interactiveSession.isLoading || userNotifications.isLoading || advertisement.isLoading || messageBrokers.isLoading,
        }),
        dispatch => bindActionCreators({
            interactiveSession: actions.fetchInteractiveSession,
            userNotification: actions.fetchUserNotifications,
            advertisement: actions.fetchAdvertisement,
            messageBrokers: actions.fetchMessageBrokers,
        }, dispatch),
    ),
    withLoader(({interactiveSession, userNotification, advertisement, messageBrokers}) => {
        messageBrokers()
        interactiveSession()
        userNotification()
        advertisement()
    }),
)(Interactive)