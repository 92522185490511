import {handleActions} from 'redux-actions'

import {receive, fetch} from './actions'

export default handleActions({
    [fetch](state) {
        return {
            ...state,
            isLoading: true,
        }
    },

    [receive](state, {payload}) {
        return {
            isLoading: false,
            emails: payload,
        }
    },
}, {isLoading: false, emails: []})