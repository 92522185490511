import {compose} from 'redux'
import get from 'lodash-es/get'

import {
    InitiateRemoteInspectionButton
} from 'pages/RemoteInspection/Bar/InitiateRemoteInspectionButton'
import withProps from 'containers/withProps'
import {ifSelection} from 'containers/withSelection'
import {__} from 'utils/i18n'
import {RESULT_RRI_PROGRESS} from 'constants/remoteInspection'
import withRemoteInspections from 'containers/withRemoteInspections'
import {withSelectionFeature} from 'containers/withFeature'
import {REMOTE_INSPECTION} from 'constants/features'

export default compose(
    withRemoteInspections(),
    withProps(({initiateNowBatch}) => ({
        onClick: initiateNowBatch,
        label: __('Run inspection'),
    })),
    ifSelection((id, {panels}) => (
        get(panels, ['store', 'byIds', id, 'rri']) !== RESULT_RRI_PROGRESS
    )),
    withSelectionFeature(REMOTE_INSPECTION),
)(InitiateRemoteInspectionButton)