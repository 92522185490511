import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {
    refresh, fetch, markAsBackup,
    refreshRunner
} from 'modules/panels/configuration/actions'
import {
    selectConfigurationsList,
    selectPendingChanges
} from 'modules/panels/configuration/selectors'

import {
    startPollRunnerData,
    stopPollRunnerData,
} from 'modules/runners/actions'

export default function withConfigurationsList() {
    return connect(
        (state, props) => ({
            ...selectConfigurationsList(state, props),
            ...selectPendingChanges(state, props),
        }),
        (dispatch, {panelId}) => bindActionCreators({
            fetch: () => fetch(panelId),
            markAsBackup: id => markAsBackup(panelId, id),
            refresh: () => refresh(panelId),
            startPollRunnerData,
            stopPollRunnerData,
            refreshRunner,
        }, dispatch),
    )
}