import React, {Component} from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

export default class PowerMasterCommunications extends Component {
    static propTypes = {
        settings: PropTypes.objectOf(PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })),
        isEncryptionEnabled: PropTypes.bool,
        pscConnection: PropTypes.bool,
    }

    render() {
        const {
            settings: {bba, gprs},
            isEncryptionEnabled,
            //pscConnection commented by PMN-6748
        } = this.props

        return (
            <List className="card-content" twoCols>
                {/* commented by PMN-6748
                <ListHintItem name={__('PSC Connection')}
                              text={pscConnection ? __('Enabled') : __('Disabled')}/>
                */}
                <ListHintItem name={__('Broadband Supervision')}
                              text={__n('%d second', '%d seconds', bba.supervision)}/>

                <ListHintItem name={__('Broadband Offline Timer')}
                              text={`${bba.timeout} seconds`}/>

                <ListHintItem name={__('Broadband Encryption')}
                              text={isEncryptionEnabled ? __('Enabled') : __('Disabled')}/>

                {gprs.isEnabled &&
                <ListHintItem name={__('Cellular Supervision')}
                              text={__n('%d second', '%d seconds', gprs.supervision)}/>}

                {gprs.isEnabled &&
                <ListHintItem name={__('Cellular Offline Timer')}
                              text={__n('%d second', '%d seconds', gprs.timeout)}/>}

                {!gprs.isEnabled &&
                <ListHintItem name={__('PowerMaster GPRS')} text={__('Disabled')}/>}
            </List>
        )
    }
}