import {mapGroup as mapBasicGroup} from 'api/groups'
import {mapRole} from 'api/roles'
import {get, post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

export const mapGroup = (group) => ({
    ...mapBasicGroup(group),
    videoFormatId: group.vof_id,
})

export const mapFormData = (formData) => (formData)

export const keysMap = {}

export function receiveGroup(id) {
    return get('/group/info', {id}).then((data) => mapGroup(data))
}

export function persistCentralStationLinks(groupId, centralStationId, profiles) {
    return post('/group/savecslinks', {
        form: {
            groupId,
            centralStationId,
            profiles,
        },
    }).catch(mapValidationErrors)
}

export function receiveGroupPermissions(groupIds) {
    return get('/group/getpermissions', {utg_id: groupIds})
        .then(data => Object.values(data).map(mapRole))
}
