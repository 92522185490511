import {all, takeEvery, put} from 'redux-saga/effects'
import {editGroupCsLinks} from 'modules/forms/handlers'
import {update} from './actions'

export default function* () {
    yield all([
        takeEvery(editGroupCsLinks.SUCCESS, watchEditGroupCsLinks),
    ])
}

function* watchEditGroupCsLinks({meta}) {
    const {groupId, centralStationId, profiles} = meta
    yield put(update(groupId, centralStationId, profiles))
}