import {select, put} from 'redux-saga/effects'
import uniqid from 'uniqid'

import {observe} from './actions'

export function* ensureProcesses(processes: Array): { id?: string } {
    if (processes.length === 0) {
        return []
    }

    const ids = yield select(state => state.processes.manager.ids)

    processes = processes.map(process => {
        if (ids[process.id]) {
            return {
                ...process,
                key: ids[process.id],
            }
        }

        return {
            ...process,
            key: uniqid(),
        }
    })

    yield put(observe(processes))

    return processes.reduce(
        (acc, process) => ({
            ...acc,
            [process.id]: process.key,
        }),
        {},
    )
}

export default function* ensureProcess(process: Object) {
    if (!process) {
        return null
    }

    const ids = yield select(state => state.processes.manager.ids)

    if (ids[process.id]) {
        return ids[process.id]
    }

    const key = uniqid()

    yield put(observe({
        key,
        ...process,
    }))

    return key
}
