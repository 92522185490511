import {createSelector} from 'reselect'

export const selectFirmwareListRows = createSelector(
    state => state.firmware.list.page,
    state => state.panels.store.byIds,
    (page, store) => page.map(id => store[id] || {})
)

export const selectFirmwareListTotal = createSelector(
    state => state.firmware.list.total,
    (total) => ({total})
)