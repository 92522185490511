import {compose} from 'redux'
import get from 'lodash-es/get'

import {initiate} from 'permissions/remoteInspections/selection'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import withRemoteInspections from 'containers/withRemoteInspections'

import IconRun from 'ipmp-react-ui/icons/run.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export const InitiateRemoteInspectionButton = compose(
    withPermission({isVisible: initiate}),
    withVisibility(),
    withProps(() => ({
        message: __('Do you want to initiate remote inspection now?'),
        positive: __('Begin Now'),
        title: __('Initiate inspection'),
        Icon: IconRun,
    })),
    withSelectionHandler(),
    withConfirmation(),
)(Button)

export default compose(
    withRemoteInspections(),
    withProps(({initiateNow}) => ({
        onClick: initiateNow,
        label: __('Run'),
    })),
    ifSelection((id, {remoteInspections}) => get(remoteInspections, ['store', id, 'progress']) === null),
)(InitiateRemoteInspectionButton)
