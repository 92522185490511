import {compose} from 'redux'

import disable from 'permissions/panel/devices/plink/ssh/disable'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {withPlinkDebugCommands} from 'containers/withDevices'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: disable,
    }),
    withVisibility(),
    withPlinkDebugCommands(),
    withProps(({disableSSH}) => ({
        onClick: disableSSH,
        title: __('SSH disable'),
        message: __('Do you want to disable SSH?'),
        positive: __('Disable'),
    })),
    withConfirmation(),
)(Button)