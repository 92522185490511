import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import {setSystemSettings} from 'modules/forms/handlers'
import {showSystemSettingsAddMessageBrokerModal} from 'modules/modals/actions'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withForm(setSystemSettings.addMessageBroker),
    connect(
        null,
        (dispatch, {handle}) => bindActionCreators({
            onClick: () => showSystemSettingsAddMessageBrokerModal({handle}),
        }, dispatch),
    ),
    withProps(() => ({
        flat: true,
        primary: true,
        label: __('Add Message Broker'),
    })),
)(Button)