import {compose} from 'redux'

import withForm from 'containers/withForm'
import withProps from 'containers/withProps'
import {addGroup} from 'modules/forms/handlers'

import GroupForm from './GroupForm'
import {__} from 'utils/i18n'

const defaultValues = {
    name: null,
    description: null,
    localWakeUp: true,
    upgradeMethod: 'gprs',
    BBAEncryption: true,
    timeSynchronization: false,
    serverMessagingLanguage: null,
    serverMessagingLanguageId: null,
    pscConnection: false,
    allowUpgradeOverGprsDSCPanels: false,
    communications: {
        pmaster: {
            bba: {
                isEnabled: true,
                supervision: 5,
                timeout: 60,
            },
            gprs: {
                isEnabled: true,
                supervision: 120,
                timeout: 240,
            },
        },
        quazar: {
            bba: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
            gprs: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
        },
        quazar53: {
            bba: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
            gprs: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
        },
    },
}

export default compose(
    withForm(addGroup),
    withProps(() => ({
        data: defaultValues,
        header: __('Add group'),
    }))
)(GroupForm)