import {compose} from 'redux'

import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import IconStop from 'ipmp-react-ui/icons/cross.svg'

import {__} from 'utils/i18n'

export default compose(
    withProps(() => ({
        small: true,
        flat: true,
        primary: true,
        Icon: IconStop,
        title: __('Remove broker'),
        message: __('Do you want to remove broker?'),
    })),
    withConfirmation()
)(Button)