import __ from 'utils/i18n'
import getBatchStatusTitle, {
    BATCHES_SUCCEEDED_STATUS, BATCHES_FAILED_STATUS, BATCHES_STARTED_STATUS,
    BATCHES_HANDLED_STATUS, BATCHES_FAILED_TO_START_STATUS,
    BATCHES_NOT_STARTED_STATUS
} from './batchesStatuses'

export const RUNNERS_SUCCEEDED_STATUS = BATCHES_SUCCEEDED_STATUS
export const RUNNERS_FAILED_STATUS = BATCHES_FAILED_STATUS
export const RUNNERS_STARTED_STATUS = BATCHES_STARTED_STATUS
export const RUNNERS_HANDLED_STATUS = BATCHES_HANDLED_STATUS
export const RUNNERS_FAILED_TO_START_STATUS = BATCHES_FAILED_TO_START_STATUS
export const RUNNERS_NOT_STARTED_STATUS = BATCHES_NOT_STARTED_STATUS
export const RUNNERS_CANCELED = 'canceled'

export const list = [
    RUNNERS_SUCCEEDED_STATUS,
    RUNNERS_FAILED_STATUS,
    RUNNERS_STARTED_STATUS,
    RUNNERS_HANDLED_STATUS,
    RUNNERS_FAILED_TO_START_STATUS,
    RUNNERS_NOT_STARTED_STATUS,
    RUNNERS_CANCELED
]

export function isStoppable(status) {
    switch (status) {
        case RUNNERS_NOT_STARTED_STATUS:
        case RUNNERS_STARTED_STATUS:
            return true
        default:
            return false
    }
}

export default function getRunnerStatusTitle(status) {
    switch (status) {
        case RUNNERS_SUCCEEDED_STATUS:
        case RUNNERS_FAILED_STATUS:
        case RUNNERS_STARTED_STATUS:
        case RUNNERS_HANDLED_STATUS:
        case RUNNERS_FAILED_TO_START_STATUS:
        case RUNNERS_NOT_STARTED_STATUS:
            return getBatchStatusTitle(status)
        case RUNNERS_CANCELED: return __('Canceled')

        default:
            console.warn(`Unknown runner status: ${status}`)
            return status
    }
}