import configurationValueFactory from 'components/Configuration/Row/configurationValueFactory'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import classes from 'classnames'
import Button from 'ipmp-react-ui/Button'

import IconDropDown from 'ipmp-react-ui/icons/drop-down.svg'
import IconUndo from 'ipmp-react-ui/icons/undo.svg'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {__} from 'utils/i18n'

import BitsetViewer from 'components/Configuration/RowViewer/BitsetViewer'

export default class ConfigurationRow extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        changed: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        backup: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        isShowExportCheckbox: PropTypes.bool,
        isExported: PropTypes.bool,
        isEditable: PropTypes.bool,
        isChanged: PropTypes.bool,
        hasUndo: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onSetExport: PropTypes.func,
        onUndo: PropTypes.func,
    }

    static defaultProps = {
        isEditable: true,
        hasUndo: true,
    }

    state = {}

    constructor(props, context) {
        super(props, context)
    }

    handleRestoreFromBackup = () => {
        const {onChange, item: {key}, backup} = this.props
        onChange(key, backup)
    }

    handleUndo = () => {
        const {onChange, item: {key, val}, onUndo} = this.props

        this.setState({error: false})

        if (onUndo) {
            onUndo(key)
        } else {
            onChange(key, val)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext): boolean {
        return !![
            'item',
            'backup',
            'changed',
            'isChanged',
            'isExported',
            'isEditable',
            'isShowExportCheckbox',
            'hasUndo',
        ].find(key => nextProps[key] !== this.props[key])
    }

    handleOnCheck = (e) => {
        const {item, onSetExport} = this.props
        const {key, val} = item
        onSetExport(key, e.target.checked, val)
    }

    isRfId() {
        const {key} = this.props.item
        return /RFID\+/.test(key)
    }

    handleChange = (value, isValid = true) => {
        const {item, onChange} = this.props
        onChange(item.key, value, isValid)
    }

    renderElement() {
        const {changed, item} = this.props
        const isChanged = changed !== undefined

        if (this.isRfId()) {
            // can't change RFID
            return item.val
        }

        const ValueComponent = configurationValueFactory(item.type)

        return (
            <ValueComponent
                item={item}
                value={isChanged ? changed : item.val}
                onChange={this.handleChange}
                isChanged={isChanged}
            />
        )
    }

    getValue() {
        const {item} = this.props
        const {type, val, items} = item

        let i

        switch (type) {
            case 'code_hex':
            case 'code':
                return '****'
            case 'list':
                i = items.find(([v]) => v == val)

                if (i) {
                    return i[1]
                }

                return val || <div className="empty">{__('Not set')}</div>
            case 'bitset':
                return <BitsetViewer item={item}/>
            default:
                return val || <div className="empty">{__('Not set')}</div>
        }
    }

    renderBackup() {
        const {backup, item} = this.props
        const {type, items} = item

        let i

        switch (type) {
            case 'code_hex':
            case 'code':
                return '****'
            case 'bitset':
                return <BitsetViewer item={{...item, val: backup}}/>
            case 'list':
                i = items.find(r => r[0] == backup)
                return i && i[1]
            default:
                return nl2br(backup)
        }
    }

    isCheckable() {
        const {isShowExportCheckbox, item} = this.props
        return isShowExportCheckbox && item.export
    }

    getRowContent() {
        const {isEditable} = this.props
        return isEditable ? this.renderElement() : this.getValue()
    }

    renderUndo() {
        const {isChanged, hasUndo} = this.props

        if (!hasUndo || !isChanged) {
            return null
        }

        return (
            <Button
                Icon={IconUndo}
                small
                borderless
                className="configuration-undo"
                onClick={this.handleUndo}/>
        )
    }

    render() {
        const {item, backup, isChanged, isEditable, isExported} = this.props

        return (
            <div className={classes('form-row', {
                'form-row--changed': isChanged,
                'form-row--highlight': backup !== undefined,
            })}>
                <div className="form-row-label">
                    {this.isCheckable()
                        ? <Checkbox label={item.name} onChange={this.handleOnCheck} checked={isExported || false}/>
                        : item.name}
                </div>

                <div className="form-row-content" key={this.state.key}>
                    {this.getRowContent()}
                    {this.renderUndo()}
                </div>

                {backup !== undefined &&
                <div className="form-row-content configuration-backup">
                    {!this.isRfId() && isEditable && (
                        <Button
                            Icon={IconDropDown}
                            small
                            borderless
                            className="configuration-backup-restore"
                            onClick={this.handleRestoreFromBackup}/>
                    )}

                    {this.renderBackup()}
                </div>
                }
            </div>
        )
    }
}