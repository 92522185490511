import withForm from 'containers/withForm'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {removeRole} from 'modules/forms/handlers'

import head from 'lodash-es/head'
import {compose} from 'redux'
import ModalCardForm from 'ui/ModalCardForm'
import decorate from 'utils/decorate'
import {__, __n} from 'utils/i18n'

class RemoveRole extends Component {
    static propTypes = {
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isRemovable: PropTypes.bool,
                relatedUsers: PropTypes.number,
                relatedRoles: PropTypes.number,
            }),
        ),
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func,
    }

    static defaultProps = {
        roles: [],
    }

    unRemovableRoles() {
        return this.props.roles.filter(({isRemovable}) => !isRemovable)
    }

    renderMessage(unRemovableRoles) {
        if (unRemovableRoles.length === 0) {
            return __n(
                'Do you really want to remove %s selected role?',
                'Do you really want to remove %s selected roles?',
                this.props.roles.length,
            )
        }

        if (unRemovableRoles.length > 1) {
            return decorate(
                __('Roles %s has related users or roles and cannot be removed. Remove related users and roles before.'),
                unRemovableRoles.map(({name}) => name).join(', '),
            )
        }

        const role = head(unRemovableRoles)

        if (!role.removable) {
            return decorate(
                __('Role %s cannot be deleted, because it is not allowed.'),
                role.name,
            )
        } else {
            return decorate(
                __('Role %s cannot be deleted, because it has %s child users and %s child roles. Remove them before delete this user'),
                role.name,
                role.relatedUsers,
                role.relatedRoles,
            )
        }
    }

    render() {
        const unRemovableRoles = this.unRemovableRoles()
        const {hide, handle, roles} = this.props

        return (
            <ModalCardForm
                header={roles.length > 1 ? __('Remove roles') : __('Remove role')}
                showDismiss
                hide={hide}
                editable={!unRemovableRoles.length}
                onSubmit={handle}>
                {this.renderMessage(unRemovableRoles)}
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(removeRole),
    connect(
        ({roles: {store: {byIds}}}, {roleIds, handle}) => ({
            roles: Object.values(byIds).filter(({id}) => roleIds.indexOf(id) !== -1),
            handle: () => handle(roleIds),
        }),
    ),
)(RemoveRole)

