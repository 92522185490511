import {all, put, takeEvery, call, select} from 'redux-saga/effects'

import {selectPerPage} from 'modules/settings/selectors'

import {fetchUpgradable as fetch, suggestUpgradable as suggest} from 'api/panels'
import {upgrade, upgradeByFilters} from 'api/firmware'
import * as actions from './actions'
import {update, purge} from 'modules/panels/store/actions'
import createListSaga from 'modules/higherOrder/createListSaga'
import {__} from 'utils/i18n'
import {snackShow} from 'modules/snacks'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'
import generateBatch from 'modules/batches/manager/generateBatch'
import {hide} from 'modules/modals/actions'

const selector = (state) => ({
    ...state.firmware.list,
    scopes: state.settings.scopes.firmware,
    perPage: selectPerPage(state, 'firmware'),
    listRoute: 'firmware',
})

export default function* () {
    yield all([
        createListSaga({fetch, suggest}, actions, update, selector, purge),
        takeEvery(actions.upgrade, watchUpgrade),
        takeEvery(actions.upgradeByFilters, watchUpgradeByFilters)
    ])
}

function* watchUpgrade({payload}) {
    const {panelIds} = payload
    if (panelIds.length === 0) {
        return
    }

    const {batchId} = yield generateBatch(PROCESS_TYPE_NEO_UPGRADE, panelIds)

    try {
        const {
            applianceId, upgradePackageId, timeout,
            failOnArmedState,
        } = payload

        yield put(hide())

        yield call(upgrade, panelIds, applianceId, upgradePackageId, timeout, failOnArmedState, batchId)

        yield put(snackShow(__('Firmware will be upgraded')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchUpgradeByFilters({payload}) {
    const {
        applianceId,
        upgradePackageId,
        timeout,
        failOnArmedState,
        panelIds,
    } = payload
    const filters = yield select(state => state.firmware.list.filters)
    const total = yield select(state => state.firmware.list.total)
    const page = yield select(state => state.firmware.list.page)

    const {batchId} = yield generateBatch(
        PROCESS_TYPE_NEO_UPGRADE,
        resolvePanelsIds(total, page, panelIds)
    )

    try {
        yield put(hide())

        yield call(upgradeByFilters, {
            batchId, filters, applianceId,
            upgradePackageId, timeout, failOnArmedState,
            panelIds,
        })

        yield put(snackShow(__('Firmware will be upgraded')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function resolvePanelsIds(total, page, panelIds) {
    const panelsToUpgrade = total - panelIds.length
    let panelIdsResolved

    // For optimistic UI
    if (panelsToUpgrade === 1) {
        // We can try to find it id for show serial in My Processes widget

        // Not garanted to find id
        if (total - page.length > 0) {
            panelIdsResolved = new Array(panelsToUpgrade)
        } else {
            const panelId = page.filter(id => !panelIds.includes(id))
            panelIdsResolved = [panelId]
        }
    } else {
        // fill fakes
        panelIdsResolved = new Array(panelsToUpgrade)
    }

    return panelIdsResolved
}