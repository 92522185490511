import classes from 'classnames'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Icon from 'ipmp-react-ui/icons/drop-down.svg'
import IconInfo from 'ipmp-react-ui/icons/info.svg'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import Tooltip from 'ipmp-react-ui/Tooltip'

import {__} from 'utils/i18n'

export default class PermissionGroupRow extends Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        isAllowByDefault: PropTypes.bool,
        isEditable: PropTypes.bool,
        isAllowed: PropTypes.bool,
        isActive: PropTypes.bool,
        isDenied: PropTypes.bool,
        hasAllowed: PropTypes.bool,
        hasExceptions: PropTypes.bool,
    }

    handleChange = e => {
        const {name, onChange} = this.props
        onChange(name, e.target.checked ? true : null)
    }

    handleToggle = () => {
        const {name, isActive, onToggle} = this.props
        onToggle(name, !isActive)
    }

    renderContent() {
        const {name, isAllowByDefault, isAllowed, isEditable, hasExceptions} = this.props

        const label = hasExceptions ? __('Allow by default with exceptions') : __('Allow by default')
        const labelOff = hasExceptions ? __('Deny by default with exceptions') : __('Deny by default')

        return (
            <div className="form-row-content">
                {!isAllowByDefault &&
                <Togglebox
                    name={name}
                    label={label}
                    labelOff={labelOff}
                    checked={isAllowed}
                    readOnly={!isEditable}
                    onChange={isEditable ? this.handleChange : null}
                />}
            </div>
        )
    }

    renderTitle() {
        const {title, tooltip} = this.props

        return (
            <React.Fragment>
                {title}

                {tooltip &&
                <Tooltip tooltip={tooltip}>
                    <IconInfo/>
                </Tooltip>
                }
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className={classes('form-row permissions-group', {
                'permissions-group--hasAllowed': this.props.hasAllowed,
            })}>
                <div className="form-row-label permissions-group--label" onClick={this.handleToggle}>
                    <Icon className={classes('permissions-group-icon', {
                        'permissions-group-icon--active': this.props.isActive,
                    })}/>

                    {this.renderTitle()}
                </div>

                {this.renderContent()}
            </div>
        )
    }
}