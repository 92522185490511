import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectPanelRemoteInspection = createSelector(
    (_, {panelId}) => panelId,
    (state, {panelId}) => get(state, ['panels', 'remoteInspections', panelId], {isLoading: true}),
    (state) => state.remoteInspections.store,
    (state) => state.remoteInspections.results,
    (panelId, {page, ...state}, remoteInspectionsStore, remoteInspectionsResults) => {
        const rows = page
            ? page.map(key => remoteInspectionsResults[key])
            : []

        return {
            panelId,
            rows,
            remoteInspection: remoteInspectionsStore[panelId],
            ...state,
        }
    }
)