import React, {Component} from 'react'
import {compose} from 'redux'

import InteractiveUsersPager from './GridBar/InteractiveUsersPager'
import InteractiveSuspendButton from './GridBar/InteractiveSuspendButton'
import InteractiveEnableButton from './GridBar/InteractiveEnableButton'
import InteractiveUsersSelectionDropDown from './GridBar/InteractiveUsersSelectionDropDown'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

export class InteractiveUsersBar extends Component {
    render() {
        return (
            <Bar>
                <InteractiveUsersSelectionDropDown/>
                <InteractiveSuspendButton/>
                <InteractiveEnableButton/>

                <BarSpace/>

                <InteractiveUsersPager/>
            </Bar>
        )
    }
}

export default compose()(InteractiveUsersBar)