import BoardFilter from 'components/Search/Board/BoardFilter'
import Filter from 'components/Search/Filters/Filter'

export default class ValuesFilter extends Filter {

    Component = BoardFilter

    constructor(name, title, values: Object) {
        super(name, title)
        this.values = values
    }

    getItems() {
        if (!this.values) {
            return []
        }

        return Object.keys(this.values).map(this.createItem.bind(this))
    }

    getItemLabel(value) {
        if (this.values.hasOwnProperty(value)) {
            return this.values[value]
        }

        return value
    }

}