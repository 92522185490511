import {handleActions} from 'redux-actions'
import {update, clear, purge} from './actions'
import keyBy from 'lodash-es/keyBy'
import map from 'lodash-es/map'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    appliances: {
        byIds: {},
    },
    upgradePackages: {
        byIds: {},
    },
}

export default handleActions({
    [clear]() {
        return defaultState
    },

    [update](state, {payload: {appliances}}) {
        return {
            ...state,
            upgradePackages: {
                byIds: {
                    ...state.upgradePackages.byIds,
                    ...keyBy(appliances.reduce((acc, {upgradePackages}) => acc.concat(upgradePackages), []), 'id'),
                },
            },
            appliances: {
                byIds: {
                    ...state.appliances.byIds,
                    ...keyBy(appliances.map(appliance => ({
                        ...appliance,
                        upgradePackages: map(appliance.upgradePackages, 'id'),
                    })), 'id'),
                },
            },
        }
    },
    ...createPurgeStoreHandler(purge),
}, defaultState)