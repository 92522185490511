import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Duration from 'ipmp-react-ui/Duration'

export default class BatchRunnerItem extends PureComponent {
    static propTypes = {
        id: PropTypes.number,
        panelId: PropTypes.number,
        onRowClick: PropTypes.func,
        serial: PropTypes.string,
        started: PropTypes.instanceOf(Date),
        finished: PropTypes.instanceOf(Date),
    }

    onClick = () => {
        const {panelId} = this.props

        this.props.onRowClick({panelId})
    }

    render() {
        const {
            serial,
            started,
            finished,
            children,
        } = this.props

        return (
            <div className="batchInfo-list-item"
                 onClick={this.onClick}
            >
                <header className="batchInfo-list-item-details">
                    <div className="batchInfo-list-item-details-panel">
                        {serial}
                    </div>
                    <div className="batchInfo-list-item-details-duration">
                        <Duration from={started} to={finished}/>
                    </div>
                </header>
                <div className="batchInfo-list-item-status">
                    {children}
                </div>
            </div>
        )
    }
}
