import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import IconGoBack from 'ipmp-react-ui/icons/go-back.svg'
import IconGoBackRtl from 'ipmp-react-ui/icons/go-back-rtl.svg'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import path from 'utils/path'

import UpgradeStatusRunnersPager from './GridBar/UpgradeStatusRunnersPager'
import Definition from 'ipmp-react-ui/Definition'
import withRunnerGridBar from 'containers/withRunnerGridBar'
import {humanDate} from 'ipmp-react-ui/humanTime/humanTime'
import UpgradeStatusRetryButton from './GridBar/UpgradeStatusRetryButton'
import UpgradeStatusSelectionDropDown from './GridBar/UpgradeStatusSelectionDropDown'

class RunnersGridBar extends Component {
    static propTypes = {
        history: PropTypes.object,
        batchInfo: PropTypes.shape({
            started: PropTypes.instanceOf(Date),
            batchType: PropTypes.string,
        }),
    }

    handleGoBack = () => {
        const {history} = this.props
        history.push(path('firmware.upgradeStatus', {batchId: null}))
    }

    renderTitle = () => {
        const {batchInfo} = this.props

        if (!batchInfo) {
            return null
        }

        return (
            <Definition className="title"
                title={batchInfo.batchType}
                detail={humanDate(batchInfo.started)}
                multiLine
            />
        )
    }

    render() {
        const IconBack = isRtl()
            ? IconGoBackRtl
            : IconGoBack

        return (
            <Fragment>
                <Bar>
                    <Button
                        shortcut="backspace"
                        onClick={this.handleGoBack}
                        className="btn--goBack"
                    >
                        <IconBack />
                    </Button>
                    {this.renderTitle()}

                    <BarSpace />

                    <UpgradeStatusRunnersPager />
                </Bar>
                <Bar>
                    <UpgradeStatusSelectionDropDown/>
                    <UpgradeStatusRetryButton/>
                </Bar>
            </Fragment>
        )
    }
}

export default compose(
    withRunnerGridBar()
)(RunnersGridBar)