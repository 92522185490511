import createPermission, {any} from 'permissions/createPermission'

import selection from './selection'
import values from './values'
import {uploadSchedule} from './actions'

export default createPermission(
    any(
        selection,
        uploadSchedule,
        values,
    ),
)