import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {__} from 'utils/i18n'

export default class BoardFilterValue extends Component {

    static propTypes = {
        item: PropTypes.object,
        label: PropTypes.string,
        selected: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
    }

    onClick = () => {
        const {item, selected, onSelect, onDeselect} = this.props

        !selected && onSelect(item)
        selected && onDeselect(item)
    }

    render() {
        const {label, selected} = this.props

        return (
            <div onClick={this.onClick}
                 className={classes('search-filter-value', {
                     'search-filter-value--active': selected,
                     'search-filter-value--empty': !label,
                 })}>
                {label || __('Empty')}
            </div>
        )
    }

}