import {compose} from 'redux'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'
import withPanels from 'containers/withPanels'

import PanelsGridBar from './PanelsGridBar'
import PanelsTable from './PanelsTable'
import PanelsSearch from './PanelsSearch'

import Page from 'ipmp-react-ui/Page'

export default compose(
    withPanels(),
    withLifeCycle({
        onMount: ({startPoll, init}) => {
            init()
            startPoll()
        },
        onUnmount: ({stopPoll}) => {
            stopPoll()
        },
    }),
    withProps({
        Top: PanelsSearch,
        Bar: PanelsGridBar,
        Content: PanelsTable,
    })
)(Page)