import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withProcesses from 'containers/withProcesses'

import selection from 'permissions/processes/selection'
import SelectionDropDown from 'components/SelectionDropDown'


export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withSelection(),
    withProcesses(),
)(SelectionDropDown)