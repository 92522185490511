import React from 'react'
import {__} from 'utils/i18n'
import classes from 'classnames'

const generateCaption = (result, progress, fails) => {
    switch (true) {
        case result === 'error':
            return __('Failed to complete')
        case result === 'progress' && progress === 0:
            return __('Pending')
        case result === 'progress' && progress > 0:
            return __('Running')
        case result === 'success':
            return resultsList(fails)
        default:
            return __('No RI yet')
    }
}

const resultsList = (fails) => {
    if (fails.length === 0) {
        return __('Completed successful')
    }

    return fails.map(({test}) => test).join(', ')
}

const ResultsCell = ({results, progress, result}) => {
    const fails = results.filter(({state}) => state === 'fail')

    return (
        <div className={classes('rri-caption', {
            'rri-caption--error': result === 'error',
            'rri-caption--pending': result === 'progress' && progress === 0,
            'rri-caption--running': result === 'progress' && progress > 0,
            'rri-caption--failed': result === 'success' && fails.length > 0,
            'rri-caption--success': result === 'success' && fails.length === 0,
        })}>
            {generateCaption(result, progress, fails)}
        </div>
    )
}

export default ResultsCell