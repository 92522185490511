import {createSelector} from 'reselect'

import {selectConnectedPanelsData} from 'modules/dashboard/connectedPanels/selectors'
import {selectEventsRateData} from 'modules/dashboard/eventsRate/selectors'
import {selectOnlineData} from 'modules/dashboard/online/selectors'
import {selectAllProcessesData} from 'modules/dashboard/allProcesses/selectors'

export const selectDashboard = createSelector(
    [
        selectConnectedPanelsData,
        selectEventsRateData,
        selectOnlineData,
        selectAllProcessesData,
    ],
    (
        {connectedPanels, eventsRate, online, allProcesses},
    ) => (
        {connectedPanels, eventsRate, online, allProcesses}
    ),
)