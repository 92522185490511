import SuggestFilter from 'components/Search/Filters/SuggestFilter'

/**
 * Works only for full list of suggests
 */
export default class TranslateSuggestFilter extends SuggestFilter {

    constructor(key, name, translate) {
        super(key, name)
        this.translate = translate
    }

    getItemLabel(value) {
        if (this.translate instanceof Function) {
            return this.translate(value)
        }

        if (this.translate.hasOwnProperty(value)) {
            return this.translate[value]
        }

        return value
    }
}