import {compose} from 'redux'

import withSelection from 'containers/withSelection'
import withInstallers from 'containers/withInstallers'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withInstallers(),
    withSelection(),
)(SelectionDropDown)