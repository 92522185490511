import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/roles/list'

import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/roles/list/actions'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('name', __('Name')),
            new SuggestFilter('parentName', __('Parent Role')),
            new SuggestFilter('userName', __('Creator')),
        ],
    })),
    connect(
        ({roles: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onQuery: setQuery,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)