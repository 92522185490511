import __ from 'utils/i18n'

export const RESULT_RRI_PROGRESS = 'progress'
export const RESULT_RRI_FAIL = 'fail'

export const RESULT_RRI_CALENDAR_ITEM_SUCCESS_COLOR = '#00b110'
export const RESULT_RRI_CALENDAR_ITEM_FAIL_COLOR = '#cc0000'
export const RESULT_RRI_CALENDAR_ITEM_SCHEDULED = '#9e9e9e'

export function getResultRriItemColor(fails: number) {
    switch (true) {
        case fails !== undefined && fails !== 0:
            return RESULT_RRI_CALENDAR_ITEM_FAIL_COLOR
        case fails === 0:
            return RESULT_RRI_CALENDAR_ITEM_SUCCESS_COLOR
        default:
            return RESULT_RRI_CALENDAR_ITEM_SCHEDULED
    }
}

export const RESULT_RRI_STATE_NOT_AVAILABLE = 'not_available'
export const RESULT_RRI_STATE_FAIL = 'fail'
export const RESULT_RRI_STATE_PASS = 'pass'

export const CALEDAR_COUNT_RESULT_RRI = 366 // Days in leap year for sure

export function isRriResultSuccess(fails: number) {
    return fails === 0
}

export function getResutlRriItemTitle(fails: number) {
    if (isRriResultSuccess(fails)) {
        return __('Successfully')
    }

    return __('Fail')
}