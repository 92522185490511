import * as infoApi from 'api/panel/info'

import * as api from 'api/panels'
import {PROCESS_TYPE_PMAXRESETRECEIVERIP} from 'constants/processTypes'
import * as processType from 'constants/processTypes'
import {activatePanel} from 'modules/forms/handlers'
import forgetPanels from 'modules/panels/one/watchers/forgetPanels'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import generateProcess from 'modules/processes/manager/generateProcess'
import generateBatch, {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'

import {snackShow} from 'modules/snacks'
import pathToRegexp from 'path-to-regexp'
import {push} from 'react-router-redux'
import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import path from 'utils/path'

import {setActivationFailed, setActivationStatus, setActivationSuccess, update} from '../store/actions'
import * as actions from './actions'
import pollers from './pollers'

export default function* () {
    yield all([
        takeEvery([
            actions.setUserAppState,
            actions.setConfigurationAppState,
        ], watchAppState),
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.refreshState, watchRefreshState),
        takeEvery(actions.remove, watchRemove),
        takeEvery(activatePanel.SUCCESS, watchActivatePanel),
        takeEveryProcessComplete(processType.PROCESS_TYPE_NEOACTIVATION, watchNeoActivation),
        takeEveryProcessComplete(processType.PROCESS_TYPE_PMAXSTATEGET, watchRefreshStateComplete),
        takeEvery(actions.fetchUsers, watchFetchUsers),
        pollers(),
    ])
}

function* watchFetch({payload: {panelId}}) {
    try {
        const [panel, online] = yield all([
            infoApi.retrieveData(panelId),
            infoApi.isOnline(panelId),
        ])

        if (!panel.isActivated) {
            const {isActivating} = yield call(infoApi.isPanelActivating, panelId)
            panel.isActivating = isActivating
        }

        const data = {
            id: panelId,
            ...panel,
            ...online,
        }

        if (data.refreshProcess) {
            data.refreshProcess = yield ensureProcess(data.refreshProcess)
        }

        yield put(update(data))
    } catch (error) {
        yield put(actions.setError(error))
    }
}

function* watchFetchUsers({payload: {panelId}}) {
    try {
        const userInfo = yield call(infoApi.getUsers, panelId)

        yield put(update({
            id: panelId,
            ...userInfo,
        }))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchRefreshState({payload: {panelId}}) {
    const {batchId} = yield generateBatchForOneProcess(
        processType.PROCESS_TYPE_PMAXSTATEGET,
        panelId
    )
    const {execute, process} = yield generateProcess(
        processType.PROCESS_TYPE_PMAXSTATEGET,
        panelId
    )

    yield put(update({
        id: panelId,
        refreshProcess: process,
    }))

    try {
        yield execute(infoApi.refreshState, panelId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(update({
            id: panelId,
            refreshProcess: null,
        }))
    }
}

function* watchRefreshStateComplete(process) {
    const {isFailed, panelId, errorMessage} = process

    if (isFailed) {
        yield put(snackShow(errorMessage))
    }

    yield put(update({
        id: panelId,
        refreshProcess: null,
    }))
}

function* watchRemove({payload: ids}) {
    if (ids.length === 0) {
        return
    }

    const {batchId} = yield generateBatch(PROCESS_TYPE_PMAXRESETRECEIVERIP, ids)

    try {
        yield call(api.remove, ids, batchId)

        yield redirectToList(ids)
        yield forgetPanels(ids)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchAppState({payload}) {
    const {ids, ...data} = payload
    const state = yield select(({panels}) => panels.store.byIds)
    yield put(update(ids.map(id => ({id, ...data}))))

    try {
        const promises = []

        if (data.hasOwnProperty('userApp')) {
            promises.push(call(api.processUserApp, ids, data.userApp))
        }

        if (data.hasOwnProperty('configuratorApp')) {
            promises.push(call(api.processConfiguratorApp, ids, data.configuratorApp))
        }

        yield all(promises)
    } catch (error) {
        // revert to old values
        yield put(update(createRevert(state, ids, data)))
        yield put(snackShow(error.message))
    }
}

function* watchActivatePanel({payload}) {
    const {result: process} = payload
    const {panelId} = process

    yield ensureProcess(process)

    yield put(setActivationStatus({panelId, isActivating: true}))
}

function* watchNeoActivation(process) {
    const {isFailed, isSuccessful, panelId, errorMessage} = process

    if (isFailed) {
        yield put(setActivationFailed({panelId, error: errorMessage}))
    }
    if (isSuccessful) {
        yield put(setActivationSuccess({panelId}))
    }
}

function* redirectToList(ids) {
    const {pathname} = yield select(state => state.router.location)

    const panelPath = pathToRegexp(path('panel'))
    const match = panelPath.exec(pathname)

    if (match && ids.includes(parseInt(match[1]))) {
        yield put(push(path('panels', null)))
    }
}

const createRevert = (state, ids, data) =>
    ids.map(id =>
        Object.keys(data).reduce((acc, key) => ({
                ...acc,
                [key]: state[id][key],
            }), {id},
        ),
    )