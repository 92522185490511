import {handleActions} from 'redux-actions'

import {send, receive} from './actions'

export default handleActions({
    [send](state, {payload}) {
        return {
            ...state,
            [payload]: {isLoading: true},
        }
    },

    [receive](state, {payload}) {
        const {name, ...data} = payload

        return {
            ...state,
            [name]: data,
        }
    },
}, {})