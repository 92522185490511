import withProps from 'containers/withProps'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {
    addFilters, clearFilters, fetch,
    removeFilters, fetchSuggests
} from 'modules/upgradeStatus/list/actions'

import {list} from 'permissions/firmware/upgradeStatus'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import Search from 'components/Search/Search'

import __ from 'utils/i18n'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import getBatchStatusTitle, {
    list as batchStatusesList
} from 'constants/runnerStatuses'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('userName', __('User')),
            new ValuesFilter('status', __('Status'),
                batchStatusesList.reduce((acc, value) => {
                    acc[value] = getBatchStatusTitle(value)
                    return acc
                }, {})
            ),
            new DateFilter('started', __('Started at')),
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('group', __('Group')),
            new DateFilter('finished', __('Finished at')),
        ],
    })),
    connect(
        ({upgradeStatus: {list}}) => ({
            selected: list.filters,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)
