import React, {Component} from 'react'
import PropTypes from 'prop-types'

import WarningIcon from 'components/Devices/WarningIcon'
import {__} from 'utils/i18n'

export default class TroublesList extends Component {

    static propTypes = {
        warnings: PropTypes.arrayOf(
            PropTypes.shape({
                severity: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                zoneType: PropTypes.string,
            }),
        ),
        preenroll: PropTypes.bool,
        enrolling: PropTypes.bool,
    }

    render() {
        const {warnings, preenroll, enrolling} = this.props

        if (preenroll) {
            return <div className="device-troubles device-troubles--preenroll">{__('PREENROLL')}</div>
        }

        if (enrolling) {
            return <div className="device-troubles device-troubles--enrolling">{__('ENROLLING')}</div>
        }

        if (!warnings || warnings.length === 0) {
            return <div className="device-troubles device-troubles--empty">{__('no troubles')}</div>
        }

        return (
            <div className="device-troubles worries">
                {warnings.map((warning, index) => (
                    <WarningIcon
                        key={`warning$${warning.type}$${index}`}
                        warning={warning}
                    />
                ))}
            </div>
        )
    }
}