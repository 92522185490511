import {createAction} from 'redux-actions'

export const reset = createAction('EVENTS/BY_SERIAL/RESET', serial => serial)
export const setLoading = createAction('EVENTS/BY_SERIAL/SET_LOADING')
export const receive = createAction('EVENTS/BY_SERIAL/RECEIVE', ids => ({ids}))
export const fetch = createAction('EVENTS/BY_SERIAL/FETCH', id => ({id}))
export const fetchNewer = createAction('EVENTS/BY_SERIAL/FETCH_NEWER')
export const fetchOlder = createAction('EVENTS/BY_SERIAL/FETCH_OLDER')
export const fetchRecent = createAction('EVENTS/BY_SERIAL/FETCH_RECENT')
export const setPerPage = createAction('EVENTS/BY_SERIAL/SET_PER_PAGE')
export const setHasOlder = createAction('EVENTS/BY_SERIAL/SET_HAS_OLDER', hasOlder => ({hasOlder}))
export const setHasNewer = createAction('EVENTS/BY_SERIAL/SET_HAS_NEWER', hasNewer => ({hasNewer}))
export const setNewerCount = createAction('EVENTS/BY_SERIAL/SET_NEWER_COUNT', newerCount => ({newerCount}))
export const startPoll = createAction('EVENTS/BY_SERIAL/START_POLL')
export const stopPoll = createAction('EVENTS/BY_SERIAL/STOP_POLL')