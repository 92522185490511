import {createSelector} from 'reselect'
import mapValues from 'lodash-es/mapValues'

const resolve = (permissions, resources) => mapValues(
    resources,
    resource => {
        if (typeof resource === 'function') {
            return resource(permissions)
        }

        if (typeof resource === 'object') {
            return resolve(permissions, resource)
        }

        return false
    },
)

const never = () => false

export const permissionSelector = createSelector(
    state => state.auth.permissions,
    (_, permission) => permission || never,
    (permissions, resource) => resource(permissions)
)

export const createPermissionsSelector =
    permissions => createSelector(
        state => state.auth.permissions,
        (resources) => resolve(resources, permissions),
    )
