import {changeLocale} from 'modules/locales/actions'
import {take, call, put, select, all} from 'redux-saga/effects'

import * as api from 'api/auth'
import {getLastKnownTheme} from 'storage/lastKnownTheme'
import * as actions from './actions'
import applyTheme from 'utils/applyTheme'

import {snackShow} from 'modules/snacks'

export default function* () {
    applyTheme(getLastKnownTheme())

    yield all([
        persistSettings(),
        applySettings(),
    ])
}

function* persistSettings() {
    let settings = yield select(state => state.settings)

    while (true) {
        const action = yield take(Object.values(actions))

        if (action.type === actions.update.toString() || action.type === actions.revert.toString()) {
            settings = yield select(state => state.settings)
            continue
        }

        const settingsToPersist = yield select(state => state.settings)

        try {
            yield call(api.setSettings, settingsToPersist)
            settings = settingsToPersist
        } catch (error) {
            yield put(snackShow(error.message))
            yield put(actions.revert(settings))
        }
    }
}

function* applyLanguage(locale) {
    yield put(changeLocale(locale))
}

function* applySettings() {
    let old = yield select(state => state.settings)

    const applyers = {
        theme: applyTheme,
        language: applyLanguage,
    }

    while (true) {
        yield take(Object.values(actions))
        let settings = yield select(state => state.settings)

        for (const key in applyers) {
            if (applyers.hasOwnProperty(key)) {
                const value = settings[key]

                if (old[key] !== value) {
                    yield call(applyers[key], value)
                }
            }
        }

        old = settings
    }
}