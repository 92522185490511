import PanelsRoute from './Panels/PanelsRoute'
import PanelRoute from './Panel/PanelRoute'
import GroupsRoute from './Groups/GroupsRoute'
import GroupRoute from './Group/GroupRoute'
import RemoteInspectionRoute from './RemoteInspection/RemoteInspectionRoute'
import EventsRoute from './Events/EventsRoute'
import PageNotFound from './PageNotFound'
import ReportsRoute from './Reports/ReportsRoute'
import ActionLog from './ActionLog/ActionLogRoute'
import ProcessesRoute from './Processes/ProcessesRoute'
import UsersRoute from './Users/UsersRoute'
import RolesRoute from './Roles/RolesRoute'
import CentralStationsRoute from './CentralStations/CentralStationsRoute'
import Firmware from './Firmware/FirmwareRoute'
import FirmwareUpgrade from './Firmware/FirmwareUpgradeRoute'
import UpgradeStatus from './UpgradeStatus/UpgradeStatusRoute'
import BasicConfigurationsRoute from './BasicConfigurations/BasicConfigurationsRoute'
import BasicConfigurationRoute from './BasicConfiguration/BasicConfigurationRoute'
import InstallersRoute from './Installers/InstallersRoute'
import InteractiveUsersRoute from './InteractiveUsers/InteractiveUsersRoute'
import DashboardRoute from './Dashboard/DashboardRoute'
import PushBasicConfigurationRoute from './PushBasicConfiguration/PushBasicConfigurationRoute'
import BatchesRoute from './Batches/BatchesRoute'
import SettingsRoute from './Settings/SettingsRoute'

export default [
    new PanelsRoute,
    new PanelRoute,
    new GroupsRoute,
    new GroupRoute,
    new RemoteInspectionRoute,
    new EventsRoute,
    new ReportsRoute,
    new ActionLog,
    new ProcessesRoute,
    new UsersRoute,
    new RolesRoute,
    new CentralStationsRoute,
    new FirmwareUpgrade,
    new UpgradeStatus,
    new Firmware,
    new BasicConfigurationsRoute,
    new BasicConfigurationRoute,
    new InstallersRoute,
    new InteractiveUsersRoute,
    new DashboardRoute,
    new PushBasicConfigurationRoute,
    new BatchesRoute,
    new SettingsRoute,
    new PageNotFound, // Must be last in list
]