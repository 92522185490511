import {PROCESS_TYPE_NEOLIVELOG, PROCESS_TYPE_PMAXLOGLEGACY} from 'constants/processTypes'
import {takeEvery, all, put, select, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/logs'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_PMAXLOGSTANDARD} from 'constants/processTypes'
import {snackShow} from 'modules/snacks'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import {takeEveryProcessCompleteSuccessful} from 'modules/processes/manager/takeProcess'
import generateProcess from 'modules/processes/manager/generateProcess'

export default function* () {
    yield all([
        takeEvery([actions.fetch, actions.more], watchFetch),
        takeEvery(actions.refresh, watchRefresh),
        takeEveryProcessCompleteSuccessful([
            PROCESS_TYPE_PMAXLOGLEGACY,
            PROCESS_TYPE_PMAXLOGSTANDARD,
            PROCESS_TYPE_NEOLIVELOG,
        ], watchProcessCompleteSuccessful),
    ])
}

function* watchFetch({payload: {panelId, type}}) {
    const {rows, perPage} = yield select(state => state.panels.logs[panelId] || {})

    try {
        const data = yield call(
            api.fetchLog,
            panelId,
            type,
            rows ? rows.length : 0,
            perPage,
        )

        yield put(actions.receive({
            ...data,
            process: yield ensureProcess(data.process),
        }, panelId, type))
    } catch (error) {
        yield put(actions.receive(error, panelId, type))
    }
}

function getProcessType(isNeo, type) {
    switch (true) {
        case isNeo:
            return PROCESS_TYPE_NEOLIVELOG

        case type === 'legacy':
            return PROCESS_TYPE_PMAXLOGLEGACY

        default:
            return PROCESS_TYPE_PMAXLOGSTANDARD
    }
}

function* watchRefresh({payload: {panelId, type}}) {
    const {isNeo} = yield select(state => state.panels.store.byIds[panelId] || {})
    const {batchId} = yield generateBatchForOneProcess(
        getProcessType(isNeo, type),
        panelId
    )
    const {process, execute} = yield generateProcess(getProcessType(isNeo, type), panelId)

    yield put(actions.update({process}, panelId, type))

    try {
        yield execute(api.refreshLog, panelId, type, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update({process: null}, panelId, type))
    }
}

function* watchProcessCompleteSuccessful({panelId, type}) {
    yield put(actions.fetch(
        panelId,
        type === PROCESS_TYPE_PMAXLOGLEGACY ? 'legacy' : 'standard',
    ))
}