import {
    VENDOR_FIBRO_TRANSMITTER,
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH
} from 'constants/panelVendorType'
import {
    PANEL_FAMILY_DUALPATH,
    PANEL_FAMILY_NEO_1,
    PANEL_FAMILY_NEO_2,
    PANEL_FAMILY_FIBRO,
    PANEL_FAMILY_POWER_MASTER,
    PANEL_FAMILY_UNKNOWN,
} from 'constants/panelFamily'

export const isNeoSerial = serial => serial.toString().length === 12

export function getVendor({family}) {
    switch (family) {
        case PANEL_FAMILY_NEO_1:
        case PANEL_FAMILY_NEO_2:
            return VENDOR_NEO
        case PANEL_FAMILY_DUALPATH:
            return VENDOR_DUAL_PATH
        case PANEL_FAMILY_FIBRO:
            return VENDOR_FIBRO_TRANSMITTER
        case PANEL_FAMILY_POWER_MASTER:
        case PANEL_FAMILY_UNKNOWN:
        default:
            return VENDOR_POWER_MASTER
    }
}
