import {takeEvery, select, all, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/remarks'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.add, watchAdd),
        takeEvery(actions.remove, watchRemove)
    ])
}

function* watchFetch(action) {
    const panelId = action.payload
    try {
        const remarks = yield call(api.fetch, panelId)
        yield put(actions.update(remarks, panelId))
    } catch (e) {
        yield put(snackShow(e.message))
    }
}

function* watchAdd({meta, payload}) {
    const {panelId} = meta
    const {key, text, isNew} = payload

    if (!isNew) {
        return
    }

    try {
        const remark = yield call(api.add, panelId, text)

        yield put(actions.update({
            key,
            ...remark,
        }, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.remove(key, panelId))
    }
}

function* watchRemove({meta, payload}) {
    const {panelId} = meta

    const remarks = yield select(state => state.panels.remarks[panelId])
    const remark = remarks && remarks.rows[payload]

    if (!remark || remark.isNew) {
        return
    }

    try {
        yield call(api.remove, panelId, remark.id)
    } catch (e) {
        yield put(actions.add(remark, panelId))
        yield put(snackShow(e.message))
    }
}