import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import * as permissions from 'permissions/panel/navigation'
import {withPermission} from 'containers/withPermission'
import path from 'utils/path'

const pages = Object.keys(permissions)

class PanelLink extends PureComponent {

    static propTypes = {
        id: PropTypes.number,
        permissions: PropTypes.shape(
            pages.reduce((acc, key) => ({
                ...acc,
                [key]: PropTypes.bool.isRequired,
            }), {}),
        ).isRequired,
        to: PropTypes.oneOf(pages),
    }

    getPermittedRoute(route) {
        const {permissions} = this.props

        if (permissions[route]) {
            return route
        }

        return pages.find(key => permissions[key])
    }

    getPath() {
        const {id, to} = this.props

        const route = this.getPermittedRoute(to)

        if (route && id) {
            return path('panel.' + route, {id})
        }

        return false
    }

    render() {
        const {className, children, onClick} = this.props

        const path = this.getPath()

        if (path) {
            return (
                <Link className={className} to={path} onClick={onClick}>
                    {children}
                </Link>
            )
        }

        return (
            <span className={className}>
                {children}
            </span>
        )
    }
}

export default withPermission({permissions})(PanelLink)