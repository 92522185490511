import React from 'react'
import PropTypes from 'prop-types'
import {Device} from './Device'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {__} from 'utils/i18n'

class StateDevice extends Device {
    static propTypes = {
        ...Device.propTypes,
        onBypassChange: PropTypes.func.isRequired,
        bypassEnabled: PropTypes.bool,
    }

    onBypassChange = (e) => {
        this.props.onBypassChange(this.props.device.id, e.target.checked)
    }

    renderIcons() {
        const {device: {traits}, bypassEnabled} = this.props
        const bypass = traits && traits.bypass

        if (!bypass) {
            return null
        }

        const enabled = typeof bypassEnabled !== 'undefined' ? bypassEnabled : bypass.enabled

        return (
            <div className="device-icons">
                <Checkbox
                    onChange={this.onBypassChange}
                    label={__('Bypass')}
                    progress={bypass.progress}
                    checked={enabled}/>
            </div>
        )
    }
}

export default StateDevice