import React, {Component, Fragment} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import configuration from 'permissions/panel/configuration/page'
import video from 'permissions/panel/devices/video/tab'
import meteo from 'permissions/panel/devices/meteo/tab'

import {withPermission} from 'containers/withPermission'

import GeneralInfoTab from './Tabs/GeneralInfoTab'
import ParentTab from './Tabs/ParentTab'
import ChildrenTab from './Tabs/ChildrenTab'
import ConfigurationTab from './Tabs/ConfigurationTab'
import ChartsTab from './Tabs/ChartsTab'
import VideoOnDemandTab from './Tabs/VideoOnDemandTab'
// PMN-6880
// import FirmwareTab from 'pages/Panel/Devices/Sidebar/Tabs/FirmwareTab'

import Tabs from 'ipmp-react-ui/Tabs'
import {__} from 'utils/i18n'
import deviceSectionName from 'utils/configuration/deviceSectionName'

import {selectDeviceInfo} from 'modules/devices/list/selectors'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'
import {selectDownloadConfigutionFeature} from 'modules/features/store/selectors'
import ScrollView from 'ipmp-react-ui/Layout'

const TabsLayout = ({nav, tab}) => {
    return (
        <Fragment>
            {nav}

            <ScrollView className="sidebar-content">
                {tab}
            </ScrollView>
        </Fragment>
    )
}

export class DeviceSidebar extends Component {

    /**
     * IMPORTANT
     * returns index according with order in render method
     */
    get defaultTabIndex() {
        const {startWithVideoOnDemandTab, startWithMeteoTab} = this.props

        // first tab is general
        const tabsAvailable = [
            this.hasParent,
            this.hasChildren,
            this.hasConfiguration,
            this.hasCharts,
            this.hasFirmware,
            this.hasVOD,
        ]

        if (startWithVideoOnDemandTab && this.hasVOD) {
            return tabsAvailable.filter(isAvailable => !!isAvailable).length
        } else if (startWithMeteoTab && this.hasCharts) {
            return tabsAvailable.slice(0, -1)// remove last VOD tab
                .filter(isAvailable => !!isAvailable).length
        }

        return 0
    }

    get hasParent() {
        return this.props.parent
    }

    get hasChildren() {
        return this.props.childDevices && this.props.childDevices.length > 0
    }

    get hasConfiguration() {
        return this.props.isConfigurationAllowed && deviceSectionName(this.props.device)
    }

    get hasCharts() {
        return this.props.hasMeteo && this.props.isMeteoAllowed
    }

    get hasVOD() {
        return this.props.isVideoOnDemandAllowed && this.props.hasVideoOnDemand && !this.props.device.preenroll
    }

    get hasFirmware() {
        return this.props.firmware && this.props.firmware.hasOwnProperty('packages')
    }

    render() {
        const {
            device,
            // firmware,
            // firmwareRunner,
            childDevices,
            parent,
            onSelect,
            panelId,
            hasTemperature,
            hasBrightness,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
        } = this.props

        const configurationSectionName = deviceSectionName(device)

        return (
            <Tabs Layout={TabsLayout} defaultTabIndex={this.defaultTabIndex}>
                {/* Tabs order is important. See defaultTabIndex method */}
                <GeneralInfoTab name={__('General')} device={device} panelId={panelId} parent={parent}/>

                {this.hasParent && <ParentTab
                    name={__('Parent')}
                    device={parent}
                    onSelect={onSelect}/>
                }

                {this.hasChildren > 0 && <ChildrenTab
                    name={__('Children')}
                    devices={childDevices}
                    onSelect={onSelect}/>
                }

                {this.hasConfiguration && <ConfigurationTab
                    name={__('Configuration')}
                    sectionName={configurationSectionName}
                    panelId={panelId}/>
                }

                {this.hasCharts && <ChartsTab
                    name={__('Meteo')}
                    panelId={panelId}
                    device={device}
                    isDefaultTab={startWithMeteoTab}
                    hasTemperature={hasTemperature}
                    hasBrightness={hasBrightness}/>
                }

                {this.hasVOD && <VideoOnDemandTab
                    name={__('Video on demand')}
                    panelId={panelId}
                    device={device}
                    isDefaultTab={startWithVideoOnDemandTab}/>
                }

                {/* {this.hasFirmware && <FirmwareTab
                    name={__('Firmware')}
                    panelId={panelId}
                    runner={firmwareRunner}
                    {...firmware}
                />} */}
            </Tabs>
        )
    }
}

export default compose(
    withPermission({
        isConfigurationAllowed: configuration,
        isVideoOnDemandAllowed: video,
        isMeteoAllowed: meteo,
    }),
    connect(
        (state, {deviceId, panelId, isConfigurationAllowed}) => {
            const {device, parent, childDevices} = selectDeviceInfo(state, {panelId, deviceId})
            const {isAvailable} = selectDownloadConfigutionFeature(state, {panelId})
            const {firmware, runner: firmwareRunner} = selectPanelFirmwareByDeviceType(state, {panelId, device})

            const hasMeteo = device.traits && !!device.traits.meteo_info
            const hasTemperature = hasMeteo && !!device.traits.meteo_info.temperature
            const hasBrightness = hasMeteo && !!device.traits.meteo_info.brightness
            const hasVideoOnDemand = device.traits && device.traits.vod

            return {
                device,
                firmware,
                firmwareRunner,
                parent,
                childDevices,
                hasMeteo,
                hasTemperature,
                hasBrightness,
                hasVideoOnDemand,
                isConfigurationAllowed: isAvailable && isConfigurationAllowed,
            }
        },
    ),
)(DeviceSidebar)
