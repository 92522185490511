import {combineReducers} from 'redux'

import store from './store/reducer'
import list from './list/reducer'
import one from './one/reducer'
import links from './links/reducer'
import select from './select/reducer'

export default combineReducers({
    list,
    one,
    store,
    links,
    select,
})