import withConfirmationModal from 'containers/withConfirmationModal'
import {compose} from 'redux'

import {remove as isVisible} from 'permissions/panels/selection'

import {remove} from 'modules/panels/list/actions'
import {deselectAll} from 'modules/selection/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'

import {MenuItem} from 'ipmp-react-ui/Menu'
import IconRemove from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible}),
    withVisibility(),
    withSelection(),
    withProps(() => ({
        Icon: IconRemove,
        children: __('Remove'),
    })),
    withConfirmationModal(
        ({selection}) => [
            deselectAll(),
            remove(selection.toJS()),
        ],
        () => ({
            title: __('Removing panels'),
            message: __('Do you really want to remove selected panels?'),
            positive: __('Remove'),
        }),
    ),
)(MenuItem)
