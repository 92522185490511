import {createAction} from 'redux-actions'

export const fetch = createAction('PANELS/CONFIGURATION/FETCH', panelId => panelId)
export const receive = createAction('PANELS/CONFIGURATION/RECEIVE', data => data, (_, panelId) => ({panelId}))
export const markAsBackup = createAction('PANELS/CONFIGURATION/MARK_AS_BACKUP', (panelId, id) => ({panelId, id}))
export const revertBackup = createAction('PANELS/CONFIGURATION/REVERT_BACKUP', (panelId) => ({panelId}))

export const update = createAction('PANELS/CONFIGURATIONS/UPDATE',
    payload => payload,
    (_, panelId) => ({panelId}),
)

export const refresh = createAction('PANELS/CONFIGURATION/REFRESH',
    panelIds => Array.isArray(panelIds) ? panelIds : [panelIds],
)

export const receiveCurrent = createAction('PANELS/CONFIGURATION/RECEIVE_CURRENT',
    configuration => configuration,
    (_, panelId) => ({panelId}),
)

export const fetchOne = createAction('PANELS/CONFIGURATION/FETCH_ONE', (panelId, configId) => ({panelId, configId}))
export const receiveOne = createAction('PANELS/CONFIGURATION/RECEIVE_ONE',
    config => config,
    (_, panelId, configId) => ({
        panelId,
        configId,
    }),
)

export const changeValue = createAction('PANELS/CONFIGURATION/CHANGE_VALUE',
    (panelId, key, value, valid = true) => ({
        panelId,
        key,
        value,
        valid,
    }),
)

export const restoreBackup = createAction('PANELS/CONFIGURATION/RESTORE_BACKUP',
    (panelId, backup) => ({panelId, backup}),
)

export const setMakeBasic = createAction('PANELS/CONFIGURATION/SET_MAKE_BASIC', (panelId, enabled) => ({
    panelId,
    enabled,
}))

export const setExport = createAction('PANELS/CONFIGURATION/SET_EXPORT',
    (panelId, key, exported, value) => ({panelId, key, exported, value}),
)

export const upload = createAction('PANELS/CONFIGURATION/UPLOAD',
    (panelId) => ({panelId}),
)

export const dismissChanges = createAction('PANELS/CONFIGURATION/DISMISS_CHANGES',
    (panelId) => ({panelId}),
)

export const clearFilters = createAction('PANELS/CONFIGURATION/CLEAR_FILTERS',
    (panelId) => ({panelId}),
)

export const outdated = createAction('PANELS/CONFIGURATION/OUTDATED',
    (panelId) => ({panelId}),
)

export const setQuery = createAction('PANELS/CONFIGURATION/SET_QUERY',
    (panelId, query) => ({panelId, query}),
)

export const setShowExportable = createAction('PANELS/CONFIGURATION/SET_SHOW_EXPORTABLE',
    (panelId, enabled) => ({
        panelId,
        enabled,
    }),
)

export const setShowChanges = createAction(
    'PANELS/CONFIGURATION/SET_SHOW_CHANGES',
    (panelId, enabled) => ({
        panelId,
        enabled,
    }),
)
export const refreshRunner = createAction('PANELS/CONFIGURATION/REFRESH_RUNNER')