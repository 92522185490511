import {all, takeEvery, call, put, select} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'
import {receive as receiveLinks} from 'modules/groups/links/actions'
import {update} from 'modules/groups/store/actions'

import * as api from 'api/group'
import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.fetchGroupRoles, watchFetchGroupRoles),
    ])
}

function* watchFetch({payload: {groupId}}) {
    try {
        const {links, ...data} = yield call(api.receiveGroup, groupId)
        yield put(receiveLinks(groupId, links))
        yield put(update(data))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchFetchGroupRoles({payload: id}) {
    const {groupRoles} = yield select(({groups}) => groups.one)

    if (groupRoles.hasOwnProperty(id)) {
        yield put(actions.setLoading(false))
    }

    try {
        const roles = yield call(api.receiveGroupPermissions, id)
        yield put(actions.receiveGroupRoles({roles, id}))
    } catch (error) {
        yield put(actions.receiveGroupRoles(error))
        yield put(snackShow(error.message))
    }
}