import React from 'react'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import IconCheck from 'ipmp-react-ui/icons/check.svg'
import IconNotAvailable from 'ipmp-react-ui/icons/not-available.svg'

import {
    CrossServerSearchContext,
    SERVER_STATUS_CONNECTING,
    SERVER_STATUS_ERROR,
    SERVER_STATUS_READY,
    SERVER_STATUS_UNAUTHORIZED,
} from 'pages/Panels/CrossServerSearch/CrossServerSearch'
import {__} from 'utils/i18n'

function ServerStatus({status, domain, errorText, retry, login}) {
    let icon
    let detail
    let action

    switch (status) {
        case SERVER_STATUS_READY:
            icon = <IconCheck className="icon success"/>
            detail = __('Connected')
            break
        case SERVER_STATUS_CONNECTING:
            icon = <Spinner className="icon"/>
            break
        case SERVER_STATUS_UNAUTHORIZED:
            icon = <IconNotAvailable className="icon danger"/>
            detail = __('Not authorized')
            action = <Button small flat onClick={login}>{__('Login')}</Button>
            break
        case SERVER_STATUS_ERROR:
        default:
            icon = <IconNotAvailable className="icon danger"/>
            detail = errorText
            action = <Button small flat onClick={retry}>{__('Retry')}</Button>
    }

    return (
        <div className="crossServerBlind-server">
            {icon}
            <p>
                <span className="crossServerBlind-server-title">{domain}</span>
                {detail && <span className="crossServerBlind-server-detail">— {detail}</span>}
            </p>
            {action}
        </div>
    )
}

export default function CrossServerStatus() {
    return (
        <div className="crossServerBlind-servers">
            <h1 className="hint hint--gray">{__('Available servers:')}</h1>

            <CrossServerSearchContext.Consumer>
                {
                    ({servers}) => servers.map(server => (
                        <ServerStatus
                            key={server.url}
                            {...server}
                        />
                    ))
                }
            </CrossServerSearchContext.Consumer>
        </div>
    )
}
