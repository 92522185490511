import {get, post} from './http'
import {mapPGM, mapPGMTypes} from './pgm.map'
import {mapProcess} from 'api/processes'

export function getPGMList(panelId) {
    return get('pgm/list', {unt_id: panelId})
        .then(data => data.map(mapPGM))
        .then(data => data.reduce(
            (acc, {deviceId, deviceType, pgms}) => acc.concat(
                pgms.map(pgm => ({...pgm, deviceId, deviceType})),
            ), [],
        ))
}

export function getPGMTypes(panelId, type = null, restrictions = null) {
    return get('pgm/types', {
        unt_id: panelId,
        ...(type ? {type} : {}),
        ...(restrictions ? {restrictions} : {}),
    })
        .then(mapPGMTypes)
}

export function editPGM(panelId, id, type, options, batchId) {
    return post('pgm/edit', {
        unt_id: panelId,
        id,
        type,
        options,
        batch_id: batchId,
    }).then(mapProcess)
}

export function disablePGM(panelId, id, batchId) {
    return post('pgm/disable', {
        unt_id: panelId,
        id,
        batch_id: batchId,
    })
        .then(mapProcess)
}
