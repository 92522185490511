import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const PROCESS_ERROR_INTERNAL_ERROR = 'INTERNAL_ERROR'
export const PROCESS_ERROR_PANEL_ERROR = 'PANEL_ERROR'
export const PROCESS_ERROR_ACCESS_CODE_REQUIRED = 'ACCESS_CODE_REQUIRED'
export const PROCESS_ERROR_BAD_PACKAGE = 'BAD_PACKAGE'
export const PROCESS_ERROR_CANCELED_BY_CONNECTION_RESET = 'CANCELED_BY_CONNECTION_RESET'
export const PROCESS_ERROR_CANCELED_BY_REQUEST = 'CANCELED_BY_REQUEST'
export const PROCESS_ERROR_CANCELED_BY_TIMEOUT = 'CANCELED_BY_TIMEOUT'
export const PROCESS_ERROR_CANCELED = 'CANCELED'
export const PROCESS_ERROR_COMMAND_TIMED_OUT = 'COMMAND_TIMED_OUT'
export const PROCESS_ERROR_COMMUNICATION_ERROR = 'COMMUNICATION_ERROR'
export const PROCESS_ERROR_DENIED_BY_UPGRADE_REPOSITORY = 'DENIED_BY_UPGRADE_REPOSITORY'
export const PROCESS_ERROR_FAILED_TO_START_PROCESS = 'FAILED_TO_START_PROCESS'
export const PROCESS_ERROR_FUNCTION_NOT_AVAILABLE = 'FUNCTION_NOT_AVAILABLE'
export const PROCESS_ERROR_INVALID_ACCESS_CODE_LENGTH = 'INVALID_ACCESS_CODE_LENGTH'
export const PROCESS_ERROR_INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE'
export const PROCESS_ERROR_INVALID_PARTITION = 'INVALID_PARTITION'
export const PROCESS_ERROR_MASTER_CODE_UNKNOWN = 'MASTER_CODE_UNKNOWN'
export const PROCESS_ERROR_NO_ACCESS_CODE = 'NO_ACCESS_CODE'
export const PROCESS_ERROR_NOT_SUPPORTED = 'NOT_SUPPORTED'
export const PROCESS_ERROR_PANEL_REMOVED = 'PANEL_REMOVED'
export const PROCESS_ERROR_PARTITION_FAILED_TO_ARM = 'PARTITION_FAILED_TO_ARM'
export const PROCESS_ERROR_PARTITION_FAILED_TO_DISARM = 'PARTITION_FAILED_TO_DISARM'
export const PROCESS_ERROR_SYSTEM_BUSY = 'SYSTEM_BUSY'
export const PROCESS_ERROR_TIMED_OUT = 'TIMED_OUT'
export const PROCESS_ERROR_UNSUPPORTED_MEDIA = 'UNSUPPORTED_MEDIA'
export const PROCESS_ERROR_UNSUPPORTED_MODULE = 'UNSUPPORTED_MODULE'
export const PROCESS_ERROR_UPGRADE_DISABLED = 'UPGRADE_DISABLED'
export const PROCESS_ERROR_USER_CODE_DUPLICATED = 'USER_CODE_DUPLICATED'
export const PROCESS_ERROR_VKPAD_BUSY = 'VKPAD_BUSY'
export const PROCESS_ERROR_WRONG_PROGRAMMING_MODE = 'WRONG_PROGRAMMING_MODE'
export const PROCESS_ERROR_ZONE_CANNOT_BYPASS = 'ZONE_CANNOT_BYPASS'
export const PROCESS_ERROR_INVALID_ZONE = 'INVALID_ZONE'
export const PROCESS_ERROR_INVALID_BYPASS_STATE = 'INVALID_BYPASS_STATE'
export const PROCESS_ERROR_CANNOT_PUT_IN_MODE = 'CANNOT_PUT_IN_MODE'
export const PROCESS_ERROR_UNSUPPORTED_MODE = 'UNSUPPORTED_MODE'

export default function processErrorMessage(error, fallback) {
    switch (error) {
        case PROCESS_ERROR_INTERNAL_ERROR: return __('Internal error')
        case PROCESS_ERROR_PANEL_ERROR: return __('Panel error')
        case PROCESS_ERROR_ACCESS_CODE_REQUIRED: return __('Access code required')
        case PROCESS_ERROR_BAD_PACKAGE: return __('Bad package')
        case PROCESS_ERROR_CANCELED_BY_CONNECTION_RESET: return __('Canceled by connection reset')
        case PROCESS_ERROR_CANCELED_BY_REQUEST: return __('Canceled by request')
        case PROCESS_ERROR_CANCELED_BY_TIMEOUT: return __('Canceled by timeout')
        case PROCESS_ERROR_CANCELED: return __('Canceled')
        case PROCESS_ERROR_COMMAND_TIMED_OUT: return __('Command timed out')
        case PROCESS_ERROR_COMMUNICATION_ERROR: return __('Communication error')
        case PROCESS_ERROR_DENIED_BY_UPGRADE_REPOSITORY: return __('Denied by upgrade repository')
        case PROCESS_ERROR_FAILED_TO_START_PROCESS: return __('Failed to start process')
        case PROCESS_ERROR_FUNCTION_NOT_AVAILABLE: return __('Function not available')
        case PROCESS_ERROR_INVALID_ACCESS_CODE_LENGTH: return __('Invalid access code length')
        case PROCESS_ERROR_INVALID_ACCESS_CODE: return __('Invalid access code')
        case PROCESS_ERROR_INVALID_PARTITION: return __('Invalid partition')
        case PROCESS_ERROR_MASTER_CODE_UNKNOWN: return __('Master code unknown')
        case PROCESS_ERROR_NO_ACCESS_CODE: return __('No access code')
        case PROCESS_ERROR_NOT_SUPPORTED: return __('Not supported')
        case PROCESS_ERROR_PANEL_REMOVED: return __('Panel removed')
        case PROCESS_ERROR_PARTITION_FAILED_TO_ARM: return __('Partition failed to arm')
        case PROCESS_ERROR_PARTITION_FAILED_TO_DISARM: return __('Partition failed to disarm')
        case PROCESS_ERROR_SYSTEM_BUSY: return __('System busy')
        case PROCESS_ERROR_TIMED_OUT: return __('Timed out')
        case PROCESS_ERROR_UNSUPPORTED_MEDIA: return __('Unsupported media')
        case PROCESS_ERROR_UNSUPPORTED_MODULE: return __('Unsupported module')
        case PROCESS_ERROR_UPGRADE_DISABLED: return __('Upgrade disabled')
        case PROCESS_ERROR_USER_CODE_DUPLICATED: return __('User code duplicated')
        case PROCESS_ERROR_VKPAD_BUSY: return __('Vkpad busy')
        case PROCESS_ERROR_WRONG_PROGRAMMING_MODE: return __('Wrong programming mode')
        case PROCESS_ERROR_ZONE_CANNOT_BYPASS: return __('Zone cannot bypass')
        case PROCESS_ERROR_INVALID_ZONE: return __('Invalid zone')
        case PROCESS_ERROR_INVALID_BYPASS_STATE: return __('Invalid bypass state')
        case PROCESS_ERROR_CANNOT_PUT_IN_MODE: return __('Cannot put in mode')
        case PROCESS_ERROR_UNSUPPORTED_MODE: return __('Unsupported mode')
    }

    if (error) {
        warn(`Unknown process error ${error}`)
    }

    return fallback || error
}