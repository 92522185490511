import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import withForm from 'containers/withForm'
import {createBasicConfiguration} from 'modules/forms/handlers'

export class MakeBasicConfiguration extends Component {

    static propTypes = {
        handle: PropTypes.func,
        panelId: PropTypes.number,
        version: PropTypes.number,
        values: PropTypes.object,
    }

    rules = {
        name: {
            presence: true,

            length: {
                maximum: 200,
            },
        },
    }

    handle = ({name}) => {
        const {panelId, handle, version, values} = this.props
        handle(panelId, name, version, values)
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Edit Panel')}
                rules={this.rules}
                {...this.props}
            >
                <Input name="name" label={__('Basic configuration name')}/>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        (state, {panelId}) => {
        const {configuration, exporting} = state.panels.configuration[panelId] || {}

        return {
            values: exporting,
            version: configuration && configuration.version,
        }
    }),
    withForm(createBasicConfiguration),
)(MakeBasicConfiguration)