import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import RRIFailedIcon from 'ipmp-react-ui/icons/rri-failed.svg'
import RRISucceededIcon from 'ipmp-react-ui/icons/rri-succeeded.svg'
import RRIUnknownIcon from 'ipmp-react-ui/icons/rri-unknown.svg'
import PanelLink from 'components/PanelLink'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRRIFeature} from 'modules/panels/store/selectors'

class RRICell extends PureComponent {

    static propTypes = {
        panelId: PropTypes.number.isRequired,
    }

    getIcon() {
        switch (this.props.rri) {
            case 'success':
                return <RRISucceededIcon/>
            case 'fail':
                return <RRIFailedIcon className="remoteInspection-icon--fail"/>
            default:
                return <RRIUnknownIcon className="remoteInspection-icon--unknown"/>
        }
    }

    render() {
        const {panelId} = this.props

        return (
            <PanelLink to="remoteInspections" id={panelId}>
                {this.getIcon()}
            </PanelLink>
        )
    }
}

export default compose(
    withFeatureRejection(selectRRIFeature)
)(RRICell)