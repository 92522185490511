import createPermission, {any} from 'permissions/createPermission'

import list from './list'
import {schedule, initiate} from './actions'

export default createPermission(
    any(
        list,
        initiate,
        schedule
    ),
)