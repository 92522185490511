import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import Button from 'ipmp-react-ui/Button'

import {setSystemSettings} from 'modules/forms/handlers'
import {showSystemSettingsSupervisionModal} from 'modules/modals/actions'

import {__} from 'utils/i18n'

export default compose(
    withForm(setSystemSettings.Supervision),
    connect(
        ({system: {settings: {supervision}}}) => (supervision),
        dispatch => bindActionCreators({
            modal: showSystemSettingsSupervisionModal,
        }, dispatch),
        (props, {modal}) => ({
            onClick: () => modal({
                ...props,
            }),
        }),
    ),
    withProps(() => ({
        borderless: true,
        primary: true,
        small: true,
        label: __('Edit'),
    })),
)(Button)