import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Line} from 'react-chartjs-2'

import __ from 'utils/i18n'
import {timeXAxes, timeTitleCallback} from 'utils/chartsOption'

export default class EventsRateChartComponent extends Component {
    static propTypes = {
        alarms: PropTypes.array,
        alerts: PropTypes.array,
        other: PropTypes.array,
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    get data() {
        const {alarms, alerts, other} = this.props

        return {
            datasets: [{
                label: __('Alarms'),
                borderColor: 'rgba(202,35,57,1)',
                backgroundColor: 'rgba(202,35,57,0.65)',
                data: alarms,
            }, {
                label: __('Alerts'),
                borderColor: 'rgba(233,203,0,1)',
                backgroundColor: 'rgba(233,203,0,0.65)',
                data: alerts,
            }, {
                label: __('Other'),
                data: other,
                borderColor: 'rgba(45,114,186,1)',
                backgroundColor: 'rgba(45,114,186,.65)',
            }],
        }
    }

    get options() {
        const {from, to} = this.props

        return {
            animation: false,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: timeTitleCallback,
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
                xAxes: timeXAxes(from, to),
            },
        }
    }

    render() {
        return (
            <div className="card dashboard-main">
                <h3 className="dashboard-chartTitle">{__('Events Rate')}</h3>
                <Line data={this.data}
                    options={this.options}/>
            </div>
        )
    }
}