import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Input from 'ipmp-react-ui/Input'

class AdvertisementSettings extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            url: PropTypes.string,
        }),
    }

    static defaultProps = {
        data: {},
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {
        }
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Advertisement Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__('URL')}
                    name="url"
                    defaultValue={data.url}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(setSystemSettings.Advertisement)
)(AdvertisementSettings)
