import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'

const defaultState = {
    isLoading: false,
    error: null,
    appliances: [],
}

export default handleActions({
    [fetch](state) {
        return {
            ...state,
            isLoading: true,
        }
    },

    [receive](state, {error, payload: {appliances}}) {
        if (error) {
            return {
                ...state,
                isLoading: false,
                error,
            }
        }

        return {
            ...state,
            error: null,
            isLoading: false,
            appliances,
        }
    },
}, defaultState)