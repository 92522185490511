import {takeEvery, all, call, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import {
    fetchError,
    fetchOneData,
    receiveOneData
} from './actions'

import {getCentralStation} from 'api/centralStations'

export default function*() {
    yield all([
        takeEvery([fetchOneData], watchFetch),
    ])
}

function* watchFetch({payload}) {
    try {
        const {data} = yield call(getCentralStation, payload.centralStationId)

        yield put(receiveOneData({data}))
    } catch (error) {
        yield put(fetchError(error))
        yield put(snackShow(error.message))
    }
}