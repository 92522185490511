import {compose} from 'redux'
import {connect} from 'react-redux'

import RoleForm from './RoleForm'
import {editRole} from 'modules/forms/handlers'
import withForm from 'containers/withForm'
import {__} from 'utils/i18n'

export default compose(
    withForm(editRole),
    connect(
        ({roles}, {roleId}) => ({
            data: roles.store.byIds[roleId],
            header: __('Edit role'),
        }),
        (dispatcher, {roleId, handle}) => ({
            handle: (data) => handle(roleId, data),
        }),
    ),
)(RoleForm)