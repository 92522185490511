import EventsTable from 'components/Table/EventsTable'
import {connect} from 'react-redux'

export default connect(
    ({events, panels}, {selected}) => {
        const rows = panels.events.rows.map(id => events.store.byIds[id] || {})
        const active = events.store.byIds[selected]

        return {rows, active}
    }
)(EventsTable)
