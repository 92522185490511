import {createSelector} from 'reselect'
import {USER_APPLICATIONS, INSTALLER_APPLICATIONS, isAvailable, APPLICATIONS, REMOTE_INSPECTION, EVENTS} from 'constants/features'
import {err} from 'utils/log'

const createFeatureSelector = (feature) => {
    return createSelector(
        (state, {panelId}) => state.panels.store.byIds[panelId],
        (panel) => {
            if (!panel) {
                err('No panel in store')

                return {isAvailable: false}
            }

            return {isAvailable: isAvailable(panel.features, feature)}
        }
    )
}

export const selectUserAppFeature = createFeatureSelector(USER_APPLICATIONS)

export const selectInstallerAppFeature = createFeatureSelector(
    INSTALLER_APPLICATIONS
)

export const selectApplicationFeature = createFeatureSelector(APPLICATIONS)

export const selectRRIFeature = createFeatureSelector(REMOTE_INSPECTION)

export const selectEventFeature = createFeatureSelector(EVENTS)

export const selectSelectedFeature = createSelector(
    ({selection}) => selection,
    ({panels: {store: {byIds}}}) => byIds,
    (state, {feature}) => feature,
    (selection, panelsByIds, feature) => (
        resolveSelectionFeature(selection.toJS(), panelsByIds, feature)
    )
)

export const selectSelectedRunnersFeature = createSelector(
    ({selection}) => selection,
    ({panels: {store: {byIds}}}) => byIds,
    ({runners: {store: {byIds}}}) => byIds,
    (state, {feature}) => feature,
    (selection, panelsByIds, runnersByIds, feature) => {
        const formattedSelection = selection.toJS()
        const resolvedPanelsByIds = formattedSelection
            .map((runnerId) => runnersByIds[runnerId].panelId)

        return resolveSelectionFeature(
            resolvedPanelsByIds,
            panelsByIds,
            feature,
            formattedSelection
        )
    }
)

function resolveSelectionFeature(
    selection: Array,
    panelsByIds: Object,
    feature: String,
    runners:? Array
) {
    const result = {
        isFeatureAvailable: true,
        unavailablePanels: [],
        availableIds: [],
    }

    return selection.reduce((acc, panelId, index) => {
        const panel = panelsByIds[panelId]
        const isFeauterAvailable = isAvailable(panel.features, feature)

        if (!isFeauterAvailable) {
            if (acc.isFeatureAvailable) {
                acc.isFeatureAvailable = false
            }

            acc.unavailablePanels.push(panel)
        } else {
            if (runners) {
                acc.availableIds.push(runners[index])
            } else {
                acc.availableIds.push(panelId)
            }
        }

        return acc
    }, result)
}