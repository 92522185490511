//TODO: remove cross server search module, implement horizontal scaling

import React, {Component, Fragment} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import PropTypes from 'prop-types'

import {fetch} from 'modules/servers/store/actions'

import {withVisibility} from 'containers/withVisibility'
import withLifeCycle from 'containers/withLifeCycle'

import Button from 'ipmp-react-ui/Button'
import IconSearch from 'ipmp-react-ui/icons/search.svg'

import __ from 'utils/i18n'

import CrossBlind from './CrossBlind'

export class CrossServerSearchButton extends Component {
    static propTypes = {
        servers: PropTypes.arrayOf(PropTypes.string),
    }

    state = {
        isOpened: false,
    }

    showBlind = () => {
        this.setState({
            isOpened: true,
        })
    }

    onClose = () => {
        this.setState({
            isOpened: false,
        })
    }

    render() {
        const {isOpened} = this.state
        const {servers} = this.props
        return (
            <Fragment>
                <Button onClick={this.showBlind} Icon={IconSearch}>
                    {__('Search by account')}
                </Button>
                <CrossBlind servers={servers} opened={isOpened} onClose={this.onClose}/>
            </Fragment>
        )
    }
}

export default compose(
    connect(
        ({servers: {store: {list, isLoading}}}) => {
            return {
                servers: list,
                isVisible: Array.isArray(list) && (list.length > 0),
            }
        },
        dispatch => bindActionCreators({fetch}, dispatch),
    ),
    withLifeCycle({
        onMount: ({fetch, list, isLoading}) => {
            if (!Array.isArray(list) && !isLoading) {
                fetch()
            }
        },
    }),
    withVisibility(),
)(CrossServerSearchButton)
