import * as api from 'api/devices'
import * as actions from './actions'

import {POLL_WALKTEST} from 'configs/pollers'
import {PROCESS_TYPE_PMAXZONEWALKTEST} from 'constants/processTypes'

import ensureProcess from 'modules/processes/manager/ensureProcess'
import generateProcess from 'modules/processes/manager/generateProcess'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'

import {takeProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'
import {delay} from 'redux-saga'
import {all, call, fork, put, race, take, takeEvery} from 'redux-saga/effects'

export default function* () {
    yield all([
        takeEvery(actions.fetchWalktest, watchFetch),
        takeEvery(actions.startWalktest, watchStart),
        takeEvery(actions.stopWalktest, watchStop),
    ])
}

function* watchFetch({payload: {panelId}}) {
    try {
        const result = yield call(api.getWalktestStatus, panelId)
        yield put(actions.receiveWalktest(result, panelId))

        if (result.process && result.process.isRunning) {
            yield ensureProcess(result.process)
            yield fork(startPoller, panelId)
        }
    } catch (error) {
        yield put(actions.receiveWalktest(error, panelId))
    }
}

function* watchStart({payload: {panelId}}) {
    try {
        const {batchId} = yield generateBatchForOneProcess(
            PROCESS_TYPE_PMAXZONEWALKTEST,
            panelId
        )
        const {execute} = yield generateProcess(
            PROCESS_TYPE_PMAXZONEWALKTEST,
            panelId
        )

        yield execute(api.startWalktest, panelId, batchId)
        yield fork(startPoller, panelId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.fetchWalktest(panelId))
    }
}

function* fetchStatus(panelId) {
    try {
        const result = yield call(api.getWalktestStatus, panelId)
        yield put(actions.receiveWalktest(result, panelId))
    } catch (error) {
        yield put(actions.receiveWalktest(error, panelId))
    }
}

function* startPoller(panelId) {
    const {end} = yield race({
        poll: call(poller, panelId),
        take: take([actions.toggleWalktest]),
        end: takeProcessComplete(PROCESS_TYPE_PMAXZONEWALKTEST, panelId),
    })

    if (end) {
        // process completed, fetch last status
        yield fetchStatus(panelId)
    }
}

function* poller(panelId) {
    while (true) {
        yield race({
            delay: delay(POLL_WALKTEST),
        })
        yield fetchStatus(panelId)
    }
}

function* watchStop({payload: {panelId}}) {
    try {
        yield call(api.stopWalktest, panelId)
        yield put(actions.fetchWalktest(panelId))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
