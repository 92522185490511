import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapSuggests from 'api/base/mapSuggests'
import parseDate from 'api/base/parseDate'
import {get, post} from 'api/http'

import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'

import dataMapper from 'utils/dataMapper'

const mapInteractiveUser = dataMapper(user => ({
    id: parseInt(user.pru_id),
    email: user.pru_email,
    status: user.pru_status,
    createdAt: parseDate(user.pru_created_timestamp),
    panels: parseInt(user.panels),
}))

const keysMap = {
    id: 'id',
    email: 'pru_email',
    status: 'pru_status',
    createdAt: 'pru_created_timestamp',
}

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/interactiveusers/list', {start, count, query, filter})
        .then(createRowsResponseParser(mapInteractiveUser))
}

export function suspend(userId) {
    return post('/interactiveusers/suspend', {pru_id: userId}).then(mapInteractiveUser)
}

export function enable(userId) {
    return post('/interactiveusers/enable', {pru_id: userId}).then(mapInteractiveUser)
}

// TODO: checkout usage this method
export function suggest(fields, prefix = '', start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/interactiveusers/suggest', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}