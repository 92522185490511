import {put, call, takeEvery} from 'redux-saga/effects'

import {fetch, receive} from './actions'
import {snackShow} from 'modules/snacks'

import {fetchReportEmails} from 'api/reports'

export default function* () {
    yield takeEvery([fetch], watchFetch)
}

function* watchFetch() {
    try {
        const emails = yield call(fetchReportEmails)

        yield put(receive(emails))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}