export function toId(obj) {
    if (obj instanceof Object) {
        return obj.id
    }

    return obj
}

export default function toIds(obj) {
    if (!obj) {
        return []
    }

    if (Array.isArray(obj) || (obj.map instanceof Function)) {
        return obj.map(toId)
    }

    return [toId(obj)]
}