import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {selectPerPage} from 'modules/settings/selectors'
import {select, all, call, put} from 'redux-saga/effects'

import {POLL_BATCHES_LIST} from 'configs/pollers'
import toIds from 'utils/toIds'

import * as actions from './actions'
import * as api from 'api/batches'
import {update} from 'modules/batches/store/actions'

import listSaga from 'modules/higherOrder/createListSaga'

import isEqual from 'lodash-es/isEqual'

const selector = (state) => ({
    ...state.batches.list,
    perPage: selectPerPage(state, 'batches'),
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector),
        createListPollerSaga(actions, POLL_BATCHES_LIST, pollBatches),
    ])
}

function* pollBatches(params) {
    const state = yield select(selector)

    try {
        const {count, rows} = yield call(api.fetch, state)

        const byIds = yield select(state => state.batches.store.byIds)

        const updatedRows = rows.filter(
            row => !byIds.hasOwnProperty(row.id) || !isEqual(byIds[row.id], row),
        )

        yield put(actions.receive(toIds(rows), count))

        if (updatedRows.length) {
            yield put(update(updatedRows))
        }
    } catch (error) {
        yield put(actions.receive(error))
    }
}