import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import classes from 'classnames'

import withRoute from 'containers/withRoute'

class NavLink extends PureComponent {

    static propTypes = {
        to: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        isPermitted: PropTypes.bool,
    }

    static defaultProps = {
        isPermitted: true,
    }

    render() {
        const {
            className, match, to,
            isPermitted, name, children,
            Icon,
        } = this.props

        if (!isPermitted) {
            return null
        }

        return (
            <div className={classes('nav-link-wrapper', className)}>
                <Link className={classes('nav-link', {'nav-link--active': match})} to={to}>
                    {Icon && <Icon className="nav-link-icon" />}
                    <div className="nav-link-content">{name}</div>
                </Link>

                {children}
            </div>
        )
    }
}

export default withRoute()(NavLink)