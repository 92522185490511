import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import IconUser from 'ipmp-react-ui/icons/user.svg'
import IconRemark from 'ipmp-react-ui/icons/remark.svg'
import IconLocation from 'ipmp-react-ui/icons/location.svg'

import {withPermission} from 'containers/withPermission'
import DropDown, {ALIGN_TOP, DropDownContent} from 'ipmp-react-ui/DropDown'
import {info} from 'permissions/panel/info/page'

import {withVisibility} from 'containers/withVisibility'
import List, {ListItem} from 'ipmp-react-ui/List'
import nl2br from 'react-nl2br'
import Empty from 'ui/Empty'

class ApplicationCell extends Component {
    static propTypes = {
        setUserAppState: PropTypes.func,
        setConfigurationAppState: PropTypes.func,
        row: PropTypes.object,
        isPermitted: PropTypes.bool,
    }

    handleUserAppChange = (e) => {
        const {setUserAppState, row} = this.props
        setUserAppState(row, e.target.checked)
    }

    handleInstallerAppChange = (e) => {
        const {setConfigurationAppState, row} = this.props
        setConfigurationAppState(row, e.target.checked)
    }

    get trigger() {

        const {row} = this.props
        const {
            customerName,
        } = row

        return (
            <Fragment>
                {customerName}
            </Fragment>
        )
    }

    render() {
        const {row} = this.props
        const {
            customerName,
            customerEmail,
            customerPhone,
            customerAddress,
            customerRemark,
        } = row

        return (
            <DropDown trigger={this.trigger} align={ALIGN_TOP}>
                <DropDownContent className="panels-customer-info">
                    <List>
                        <ListItem Icon={IconUser}>
                            {customerName ? <strong>{customerName}</strong> : <Empty />}

                            <div className="list-item-info">
                                {customerEmail
                                && <p>
                                    <a href={'mailto:' + customerEmail}
                                        className="link">
                                        {customerEmail}
                                    </a>
                                </p>}
                                {customerPhone && <p>{customerPhone}</p>}
                            </div>
                        </ListItem>

                        <ListItem Icon={IconRemark}>
                            {customerRemark ? nl2br(customerRemark) : <Empty />}
                        </ListItem>

                        <ListItem Icon={IconLocation}>
                            {customerAddress ? nl2br(customerAddress) : <Empty />}
                        </ListItem>
                    </List>
                </DropDownContent>
            </DropDown>
        )
    }
}

export default compose(
    withPermission({
        isVisible: info,
    }),
    withVisibility(),
)(ApplicationCell)
