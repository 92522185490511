import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProcessBatch from 'components/Processes/ProcessBatch'
import {__} from 'utils/i18n'

import IconDoneAll from 'ipmp-react-ui/icons/check-all.svg'

export default class ProcessBatches extends Component {

    static propTypes = {
        batches: PropTypes.array.isRequired,
        untrack: PropTypes.func.isRequired,
        untrackFinished: PropTypes.func.isRequired,
        showInfo: PropTypes.func.isRequired,
        processRefHandler: PropTypes.func.isRequired,
        batchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }

    onUntrack = (id) => {
        const {hide, untrack} = this.props
        hide()
        untrack(id)
    }

    onUntrackFinished = () => {
        const {hide, untrackFinished} = this.props
        hide()
        untrackFinished()
    }

    render() {
        const {batches, showInfo, processRefHandler, batchId} = this.props

        if (batches.length === 0) {
            return null
        }

        return (
            <div className="processes" ref={processRefHandler}>
                <h3 className="app-aside-title">
                    <span className="app-aside-title-content">
                        {__('My Jobs')}
                    </span>

                    <IconDoneAll onClick={this.onUntrackFinished}
                        className="app-aside-title-icon"/>
                </h3>

                <div className="processes-content">
                    {batches.map(batch => (
                        <ProcessBatch
                            key={batch.id}
                            batch={batch}
                            untrack={this.onUntrack}
                            showInfo={showInfo}
                            currentBatchId={batchId}
                        />
                    ))}
                </div>
            </div>
        )
    }
}
