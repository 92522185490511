import {createSelector} from 'reselect'
import mapValues from 'lodash-es/mapValues'
import get from 'lodash-es/get'

import memoizeResult from 'utils/memoizeResult'
import filterConfiguration, {createDependencyFilter} from 'utils/configuration/filterConfiguration'
import getChangedDeviceSections from 'utils/configuration/getChangedDeviceSections'
import findNotResolvedKeys, {getDependencyKeys} from 'utils/configuration/findNotResolvedKeys'
import configurationToHash from 'utils/configuration/configurationToHash'

const selectPanelConfiguration = (state, {panelId}) => state.panels.configuration[panelId] || {}

const selectPanelConfigurationTree = (state, {panelId}) => get(
    state.panels.configuration,
    [panelId, 'configuration'],
)

const selectConfigurationValues = (state, {panelId}) => get(
    state.panels.configuration,
    [panelId, 'values'],
    {},
)

const selectConfigurationChanges = (state, {panelId}) => get(
    state.panels.configuration,
    [panelId, 'changes'],
    {},
)

export const selectDependencyKeys = createSelector(
    selectPanelConfigurationTree,
    getDependencyKeys,
)

const selectDependencyValues = memoizeResult(
    createSelector(
        selectDependencyKeys,
        selectConfigurationValues,
        selectConfigurationChanges,
        (keys, values, changes) => {
            let res = mapValues(
                keys,
                (_, key) => changes.hasOwnProperty(key) ? changes[key] : values[key],
            )

            return res
        },
    ),
)

const selectNotResolvedKeys = createSelector(
    selectPanelConfigurationTree,
    selectDependencyValues,
    findNotResolvedKeys,
)

export const selectReadyChanges = createSelector(
    selectConfigurationChanges,
    selectNotResolvedKeys,
    (changes, keysToRemove) => {
        if (!changes) {
            return
        }

        const toRemove = keysToRemove.filter(
            key => changes.hasOwnProperty(key),
        )

        if (toRemove.length === 0) {
            return changes
        }

        const result = {...changes}

        toRemove.forEach(
            key => delete result[key],
        )

        return result
    },
)

export const selectPendingChanges = createSelector(
    selectPanelConfiguration,
    selectReadyChanges,
    ({exporting, makeBasic}, readyChanges) => ({
        pendingChanges: Object.keys(readyChanges).length,
        pendingExport: exporting ? Object.keys(exporting).length : 0,
        makeBasic,
    }),
)

export const selectConfigurationsList = createSelector(
    selectPanelConfiguration,
    ({rows, runner, isLoading, error, outdated}) => {
        if (!rows) {
            return {isLoading: true}
        }

        return {
            rows,
            runner,
            isLoading,
            error,
            outdated,
        }
    },
)

export const selectResolvedConfiguration = createSelector(
    selectPanelConfigurationTree,
    selectDependencyValues,
    (configuration, values) => {
        if (!configuration || !configuration.val) {
            return null
        }

        return filterConfiguration(
            configuration,
            createDependencyFilter(values),
        )
    },
)

export const selectCurrentConfiguration = createSelector(
    selectPanelConfiguration,
    selectResolvedConfiguration,
    (state, configuration) => {
        const {
            values,
            makeBasic,
            exporting,
            isShowExportable,
            isShowChanged,
            query,
            changes,
            runner,
            outdated,
            isLoading,
        } = state

        return ({
            values,
            makeBasic,
            exporting,
            isShowExportable,
            isShowChanged,
            query,
            changes,
            runner,
            outdated,
            configuration,
            isLoading,
        })
    },
)

export const selectChangedDeviceSections = createSelector(
    selectPanelConfiguration,
    selectReadyChanges,
    ({configuration}, values) => getChangedDeviceSections(configuration, values),
)

const selectBackup = (state, {panelId, configId}) => get(state.panels.configuration, [
    panelId,
    'backups',
    configId,
    'configuration',
])

export const selectBackupConfigurationValues = createSelector(
    selectBackup,
    (configuration) => configuration && configurationToHash(configuration),
)

const selectBackupConfigurationTree = createSelector(
    selectBackup,
    selectBackupConfigurationValues,
    (configuration, values) => configuration && filterConfiguration(
        configuration,
        createDependencyFilter(values),
    ),
)

export const selectBackupConfiguration = createSelector(
    selectPanelConfiguration,
    selectBackupConfigurationTree,
    selectBackupConfigurationValues,
    ({makeBasic, exporting, isShowExportable, query}, configuration, values) => {
        return ({
            makeBasic,
            exporting,
            configuration,
            values,
            isShowExportable,
            query,
        })
    },
)
