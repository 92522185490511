import createPermission, {any} from 'permissions/createPermission'

import list from 'permissions/panel/devices/list'
import {setLabel} from 'permissions/panel/labels'

export default createPermission(
    any(
        list,
        setLabel,
    ),
)