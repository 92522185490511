import {all} from 'redux-saga/effects'

import batchRunners from './batchRunners/saga'
import manager from './manager/saga'
import list from './list/saga'
import widget from './widget/saga'

export default function* () {
    yield all([
        batchRunners(),
        manager(),
        list(),
        widget(),
    ])
}