import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import CardForm from 'ipmp-react-ui/CardForm'
import Portal from 'ipmp-react-ui/Portal'
import Input from 'ipmp-react-ui/Input'

import CrossHttp from 'pages/Panels/CrossServerSearch/CrossHttp'
import __ from 'utils/i18n'

const rules = {
    email: {
        presence: true,
        email: true,
    },
    password: {
        presence: true,
        length: {minimum: 6},
    },
}

class LoginForm extends PureComponent {

    static propTypes = {
        http: PropTypes.instanceOf(CrossHttp).isRequired,
        onClose: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        domain: PropTypes.string.isRequired,
    }

    state = {
        isLoading: false,
    }

    handleSubmit = ({email, password}) => {
        const {http, onSuccess, onClose} = this.props

        this.setState({isLoading: true})

        http.login(email, password).then((response) => {
            onSuccess(response.user)
            onClose()
        }, (error) => {
            this.setState({
                isLoading: false,
                error,
            })
        })
    }

    render() {
        const {onClose, domain} = this.props
        const {isLoading, error} = this.state

        return (
            <Portal className="modal">
                <div className="blackout"/>

                <div className="modal-scroll">
                    <div className="modal-content">
                        <CardForm
                            className="login-form"
                            isLoading={isLoading}
                            onSubmit={this.handleSubmit}
                            errors={error && error.errors}
                            rules={rules}
                            onClose={onClose}
                            header={__('Login to %s', [domain])}
                            submitLabel={__('Login')}
                        >
                            <Input
                                name="email"
                                label={__('Email')}/>

                            <Input
                                name="password"
                                type="password"
                                label={__('Password')}/>
                        </CardForm>
                    </div>
                </div>
            </Portal>
        )
    }

}

export default LoginForm
