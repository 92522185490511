import React from 'react'
import {compose} from 'redux'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'

import map from 'lodash-es/map'
import head from 'lodash-es/head'

import {content} from 'permissions/panel/page'

import withProps from 'containers/withProps'
import {withPermission, withRejection} from 'containers/withPermission'

import Logs from './Logs/Logs'
import State from './PanelState/PanelState'
import Reports from './Reports/Reports'
import Devices from './Devices/Devices'
import Info from './Info/Info'
import Processes from './Processes/Processes'
import Locations from './Locations/Locations'
import Configuration from './Configuration/ConfigurationsPage'
import RemoteInspections from './RemoteInspections/RemoteInspections'
import Events from './Events/Events'
import Firmware from './Firmware/Firmware'
import Keypad from './Keypad/Keypad'

import path from 'utils/path'

const componentsMap = {
    devices: Devices,
    info: Info,
    state: State,
    log: Logs,
    processes: Processes,
    remoteInspections: RemoteInspections,
    events: Events,
    firmware: Firmware,
    configuration: Configuration,
    locations: Locations,
    reports: Reports,
    keypad: Keypad,
}

export default compose(
    withRouter,
    withPermission({isAllowed: content}),
    withRejection(),
    withProps(({routes, ...props}) => {
        const children = map(routes, ({path: pathName, exact}, route) => (
            <Route exact={exact} path={path(pathName)} component={componentsMap[route]} key={route}/>
        ))

        const {path: pathName} = head(Object.values(routes))
        children.push(<Redirect to={path(pathName, {...props.match.params})}/>)

        return {
            children,
        }
    }),
)(Switch)