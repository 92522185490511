import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {showChangePanelGroupModal} from 'modules/runners/store/actions'

import {ChangeGroupItem} from 'pages/Panels/GridBar/DropDowns/Items/ChangeGroupItem'

export default connect(
    null,
    dispatch => bindActionCreators({
        onClick: showChangePanelGroupModal,
    }, dispatch),
)(ChangeGroupItem)