import {POLL_PROCESS_MANAGER} from 'configs/pollers'
import {delay} from 'redux-saga'
import {all, take, select, put} from 'redux-saga/effects'
import isEqual from 'lodash-es/isEqual'

import * as actions from './actions'
import getChunkToRefresh from './helpers/getChunkToRefresh'

export default function* () {
    yield all([
        manager(),
    ])
}

function* manager() {
    while (true) {
        const {byKeys, ids} = yield select(state => state.processes.manager)

        const toRefresh = Object.values(byKeys)
            .filter(process => process.isRunning)
            .map(process => process.id)

        if (toRefresh.length) {
            try {
                const processes = yield all(getChunkToRefresh(toRefresh))

                const updated = processes
                    .flat()
                    .map(process => ({
                        key: ids[process.id],
                        ...process,
                    }))
                    .filter(process => !isEqual(process, byKeys[process.key]))

                if (updated.length > 0) {
                    yield put(actions.receive(updated))
                }
            } catch (error) {
                // something went wrong
                // what should we do?
                console.error(error)
            }
        } else {
            // wait for new processes
            yield take([actions.observe, actions.restore])
        }

        yield delay(POLL_PROCESS_MANAGER)
    }
}
