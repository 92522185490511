import {BAD_REQUEST_PARAMS} from 'constants/errorType'
import {takeEvery, all, select, call, put} from 'redux-saga/effects'

import * as api from 'api/remoteInspection'
import * as actions from './actions'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.markAsViewed, watchMarkAsViewed),
        takeEvery(actions.sendEmail, watchSendEmail),
    ])
}

function* watchMarkAsViewed({payload: ids}) {
    const rows = yield select(
        state => ids
            .map(id => state.remoteInspections.results[id])
            .filter(({isReviewed}) => !isReviewed),
    )

    if (!rows.length) {
        return
    }

    yield put(actions.update(
        rows.map(({id}) => ({id, isReviewed: true})),
    ))

    try {
        yield call(api.markAsViewed, ids)
    } catch (error) {
        yield put(actions.update(
            rows.map(({id}) => ({id, isReviewed: false})),
        ))
        yield put(snackShow(error.message))
    }
}

function* watchSendEmail({payload: ids}) {
    const rows = yield select(
        state => ids
            .map(id => state.remoteInspections.results[id])
            .filter(({isEmailSent}) => !isEmailSent),
    )

    if (!rows.length) {
        return
    }

    yield put(actions.update(
        rows.map(({id}) => ({id, isEmailSent: true})),
    ))

    try {
        yield call(api.sendEmail, ids)
    } catch (error) {
        yield put(actions.update(
            rows.map(({id}) => ({id, isEmailSent: false})),
        ))

        if (error.type === BAD_REQUEST_PARAMS) {
            yield put(snackShow(error.details))
        }
        else {
            yield put(snackShow(error.message))
        }
    }
}