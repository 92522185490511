import {selectDeviceByCategories} from 'modules/devices/list/selectors'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import {fetch as fetchReference} from 'modules/devices/reference/actions'
import {fetch as fetchVideo, erase, start} from 'modules/devices/vod/actions'

import {disablePlinkSSH, enablePlinkSSH, sendPlinkLog} from 'modules/panels/plink/actions'

import {
    showAddDeviceModal,
    showDisablePlinkLogging,
    showEnablePlinkLogging,
    showRebootPlink,
} from 'modules/modals/actions'

import {fetch as fetchDevices, refreshRssi, refreshGSM} from 'modules/devices/list/actions'
import {withRouterPanelId} from 'containers/withPanel'
import {fetchWalktest, startWalktest, stopWalktest, toggleWalktest} from 'modules/devices/walktest/actions'

export default function withDevices() {
    return compose(
        withRouterPanelId(),
        connect(
            (state, {panelId}) => {
                const {isLoading, error, byIds} = state.devices.list[panelId] || {isLoading: true}
                const byCategories = selectDeviceByCategories(state, panelId)
                const panel = state.panels.store.byIds[panelId] || {}
                const walktest = state.devices.walktest[panelId] || {}
                const {current, changes} = state.panels.configuration[panelId] || {}

                return {
                    panelId,
                    panel,
                    configuration: current,
                    changes,
                    isLoading,
                    error,
                    devices: byCategories,
                    byId: byIds,
                    showWalktest: !!walktest.isActive,
                    walktest,
                }
            },
            (dispatch, {panelId}) => bindActionCreators({
                fetch: () => fetchDevices(panelId),
                showAddDevice: () => showAddDeviceModal(panelId),
                refreshRssi: () => refreshRssi(panelId),
                refreshGSM: () => refreshGSM(panelId),
                toggleWalktest: () => toggleWalktest(panelId),
                fetchWalktest: () => fetchWalktest(panelId),
                startWalktest: () => startWalktest(panelId),
                stopWalktest: () => stopWalktest(panelId),
            }, dispatch),
        ),
    )
}

export function withDeviceVideoOnDemand() {
    return connect(
        (state, {panelId, device: {zone: zoneId}}) => get(state.devices.vod, `${panelId}.${zoneId}`, {
            isLoading: true,
        }),
        (dispatch, {panelId, device: {zone: zoneId}}) => bindActionCreators({
            fetch: () => fetchVideo(panelId, zoneId),
            erase: () => erase(panelId, zoneId),
            onStartRecord: () => start(panelId, zoneId),
        }, dispatch),
    )
}

export function withPlinkDebugCommands() {
    return connect(
        null,
        (dispatch, {panelId}) => bindActionCreators({
            reboot: () => showRebootPlink(panelId),
            enableLogging: () => showEnablePlinkLogging(panelId),
            disableLogging: () => showDisablePlinkLogging(panelId),
            sendLog: () => sendPlinkLog(panelId),
            enableSSH: () => enablePlinkSSH(panelId),
            disableSSH: () => disablePlinkSSH(panelId),
        }, dispatch),
    )
}

export function withDeviceReference() {
    return connect(
        ({devices: {reference}}, {vendor}) => {
            const ref = get(reference, vendor, {})

            return {
                isLoading: !Object.keys(ref).length,
                reference: ref,
            }
        },

        (dispatch, {vendor}) => bindActionCreators({
            fetchReference: () => fetchReference(vendor),
        }, dispatch),
    )
}