import {handleActions} from 'redux-actions'
import {update, purge} from './actions'
import keyBy from 'lodash-es/keyBy'
import {set} from 'immutable-modify'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [update](state, {payload}) {
        return set(state, 'byIds', keyBy(payload, 'id'))
    },
    ...createPurgeStoreHandler(purge),
}, defaultState)