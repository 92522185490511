import createPermission, {any} from 'permissions/createPermission'
import selection, {markForService, reassign, resolveFaults, suspendFaults, resumeFaults} from './selection'

export const add = createPermission('units.create')

export default createPermission(
    any(
        selection,
        add,
    ),
)

export const services = createPermission(
    any(
        reassign,
        markForService,
        resolveFaults,
        suspendFaults,
        resumeFaults,
    ),
)