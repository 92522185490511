import {handleActions} from 'redux-actions'
import {receive, setLoading} from './actions'

const defaultState = {
    isLoading: true,
}

export default handleActions({
    [setLoading](state) {
        return {
            ...state,
            isLoading: true,
        }
    },

    [receive](state, {error, payload}) {
        if (error) {
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
        }

        return {
            ...state,
            isLoading: false,
            data: payload,
        }
    },
}, defaultState)