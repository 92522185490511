import {handleActions} from 'redux-actions'
import {set, remove} from 'immutable-modify'

import * as action from './actions'

export default handleActions({
    [action.setError](state, {payload: {panelId, deviceId, type, error}}) {
        return set(state, [panelId, deviceId, type], {
            isLoading: false,
            error,
            data: [],
        })
    },

    [action.setLoading](state, {payload: {panelId, deviceId, type}}) {
        return set(state, [panelId, deviceId, type], {
            isLoading: true,
            error: null,
            data: [],
        })
    },

    [action.receive](state, {payload: {panelId, deviceId, type, data}}) {
        return set(state, [panelId, deviceId, type], {
            isLoading: false,
            error: null,
            data,
        })
    },

    [action.purge](state, {payload: {panelId}}) {
        return remove(state, panelId)
    },
}, {})