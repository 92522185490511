import {combineReducers, compose} from 'redux'

import list from './list/reducer'
import types from './types/reducer'

const PGMReducer = combineReducers({
    list,
    types,
})

export default compose()(PGMReducer)