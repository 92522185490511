import React from 'react'
import {compose} from 'redux'

import {stop} from 'permissions/processes/actions'

import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'
import Duration from 'ipmp-react-ui/Duration'
import Table from 'ipmp-react-ui/Table'

import PanelCell from 'components/TableCells/PanelCell'

import {__} from 'utils/i18n'
import {withAutoFilters} from 'containers/withSearchScopeLoader'
import processType from 'constants/processType'
import withRunners from 'containers/withRunners'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'
import withLoader from 'containers/withLoader'
import RunnerStatus from 'components/Runners/RunnerStatus'
import ProcessStopButton from 'components/Processes/ProcessStopButton'
import withSelection from 'containers/withSelection'

const empty = <span className="empty">—</span>

export const columns = [
    {
        maxWidth: 150,
        name: () => __('Started'),
        render: ({started}) => <DateTime datetime={started} timeFirst/>,
    },
    {
        maxWidth: 150,
        name: () => __('Panel'),
        render: ({panelId, serial}) => panelId
            ? <PanelCell id={panelId} serial={serial}/>
            : empty,
    },
    {
        width: 150,
        name: () => __('Process'),
        render: ({type, description}) => <Definition title={processType(type)} detail={description}/>,
    },
    {
        width: 110,
        name: () => __('Status'),
        render: (row, {stoppingIds}) => {
            return (
                <RunnerStatus row={row} isStopping={stoppingIds.includes(row.id)}/>
            )
        },
    },
    {
        tooltip: false,
        width: 30,
        maxWidth: 150,
        name: () => __('Duration'),
        render: ({started, finished}) => (
            <Duration from={started} to={finished} />
        ),
    },
    {
        tooltip: false,
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, id}, {stop}) => (
            <ProcessStopButton onClick={() => stop(id)}
                disabled={!isStoppable}
            />
        ),
    },
]

export default compose(
    withRunners(),
    withSelection(),
    withProps(() => ({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No batch runners'),
    })),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withAutoFilters(),
    withLoader(
        ({init, filters}) => {
            return init(null, 'PUSH', filters)
        },
    ),
)(Table)
