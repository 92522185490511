import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'


import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

class InteractiveSessionSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            doAutoEnableUserApp: PropTypes.bool,
            doAutoEnableConfiguratorApp: PropTypes.bool,
            isDlsServiceEnabled: PropTypes.bool,
            globalServerApptype: PropTypes.string,
            maxInteractiveConnections: PropTypes.number,
            maxInteractiveConnectionsPerPanel: PropTypes.number,
            doRequireUserInstallerApproval: PropTypes.bool,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {
            globalServerApptype: {
            },
            maxInteractiveConnections: {
                presence: {
                    message: __('You should define Limit of Concurrent Interactive Connections per Server'),
                },
                numericality: {
                    onlyInteger: true,
                },
            },
            maxInteractiveConnectionsPerPanel: {
                presence: {
                    message: __('You should define Limit of Concurrent Interactive Sessions per Panel'),
                },
                numericality: {
                    onlyInteger: true,
                },
            },
        }
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Iteractive Session Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__('Application Type')}
                    name="globalServerApptype"
                    defaultValue={data.globalServerApptype}
                />

                <Input
                    label={__('Limit of Concurrent Interactive Connections per Server')}
                    name="maxInteractiveConnections"
                    defaultValue={data.maxInteractiveConnections}
                />

                <Input
                    label={__('Limit of Concurrent Interactive Sessions per Panel')}
                    name="maxInteractiveConnectionsPerPanel"
                    defaultValue={data.maxInteractiveConnectionsPerPanel}
                />

                <Checkbox
                    label={__('Enable User App for New Incoming Panels')}
                    name="doAutoEnableUserApp"
                    defaultChecked={data.doAutoEnableUserApp}
                />

                <Checkbox
                    label={__('Enable Installer App for New Incoming Panels')}
                    name="doAutoEnableConfiguratorApp"
                    defaultChecked={data.doAutoEnableConfiguratorApp}
                />

                <Checkbox
                    label={__('Enable DLS service')}
                    name="isDlsServiceEnabled"
                    defaultChecked={data.isDlsServiceEnabled}
                />

                <Checkbox
                    label={__('Approve Installer Access by User')}
                    name="doRequireUserInstallerApproval"
                    defaultChecked={data.doRequireUserInstallerApproval}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(setSystemSettings.InteractiveSession)
)(InteractiveSessionSettings)
