import {PARTITION_ALL} from 'constants/partitions'
import React, {Component, Fragment} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Error from 'ipmp-react-ui/Error'

import {ScrollView, SideBar} from 'ipmp-react-ui/Layout'
import Checkbox from 'ipmp-react-ui/Checkbox'
import SetLabelButton from './Buttons/SetLabelButton'
import SetBypassButton from './Buttons/SetBypassButton'
import StateDevice from 'components/Devices/StateDevice'
import {__} from 'utils/i18n'
import {withPermission, withRejection} from 'containers/withPermission'
import sidebar from 'permissions/panel/state/sidebar'
import list from 'permissions/panel/devices/list'
import {connect} from 'react-redux'
import {selectBypassableDevices, selectBypassableDevicesByPartition} from 'modules/devices/list/selectors'
import withLoader from 'containers/withLoader'

class PanelStateSideBar extends Component {
    static propTypes = {
        partition: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        isAbstract: PropTypes.bool,
        devices: PropTypes.array.isRequired,
        panelId: PropTypes.number.isRequired,
        onClose: PropTypes.func,
    }

    state = {
        onlyTroubled: true,
        deviceBypassStates: {},
    }

    onBypassChange = (deviceId, enabled) => {
        const {deviceBypassStates} = this.state

        if (!deviceBypassStates.hasOwnProperty(deviceId)) {
            this.setState({
                deviceBypassStates: {
                    ...deviceBypassStates,
                    [deviceId]: enabled,
                },
            })
        } else if (enabled !== deviceBypassStates[deviceId]) {
            this.setState({
                deviceBypassStates: this.filterBypassStates(deviceBypassStates, deviceId),
            })
        }
    }

    filterBypassStates(deviceBypassStates, deviceId) {
        return Object.keys(deviceBypassStates).reduce((acc, key) => {
            if (key != deviceId) {
                acc[key] = deviceBypassStates[key]
            }

            return acc
        }, {})
    }

    onOnlyTroubledChange = (e) => {
        this.setState({onlyTroubled: e.target.checked})
    }

    render() {
        const {onClose, devices} = this.props
        const {onlyTroubled} = this.state

        return (
            <SideBar className="partitionDevices-sidebar" filled>
                <div className="sidebar-header">
                    <a onClick={onClose} className="sidebar-close">×</a>

                    <h1 className="sidebar-title">
                        {this.renderTitle()}
                    </h1>

                    {list && !!devices.length && <Checkbox
                        onChange={this.onOnlyTroubledChange}
                        label={__('Show devices with troubles')}
                        checked={onlyTroubled}/>}
                </div>

                {list && this.renderByPassButtonBlock()}

                <ScrollView className="sidebar-content">
                    {this.renderDevices()}
                </ScrollView>
            </SideBar>
        )
    }

    renderTitle() {
        const {partition, panelId} = this.props

        if (partition.id === PARTITION_ALL) {
            return __('All')
        }

        return (
            <Fragment>
                {partition.name}
                <SetLabelButton
                    primary
                    className="partitionDevices-sidebar-button"
                    partition={partition}
                    panelId={panelId}>
                    {__('Rename')}
                </SetLabelButton>
            </Fragment>
        )
    }

    renderByPassButtonBlock() {
        const {panelId} = this.props
        const {deviceBypassStates} = this.state
        const selectedToEnableCount = Object.values(deviceBypassStates).filter(enabled => enabled).length
        const selectedToDisableCount = Object.values(deviceBypassStates).filter(enabled => !enabled).length

        if (!list || !selectedToEnableCount && !selectedToDisableCount) {
            return null
        }
        return (
            <div className="devices-sidebar-bypass">
                <div>
                    {!!selectedToEnableCount && (
                        <p>{__('Enable for %d devices', selectedToEnableCount)}</p>
                    )}
                    {!!selectedToDisableCount && (
                        <p>{__('Disable for %d devices', selectedToDisableCount)}</p>
                    )}
                </div>

                <SetBypassButton
                    primary
                    small
                    className="partitionDevices-sidebar-button"
                    deviceBypassStates={deviceBypassStates}
                    panelId={panelId}
                    resetSelection={() => this.setState({deviceBypassStates: {}})}>
                    {__('Set Bypass')}
                </SetBypassButton>
            </div>
        )
    }

    renderDevices() {
        const {deviceBypassStates, onlyTroubled} = this.state
        const devices = onlyTroubled
            ? this.props.devices.filter(device => device.hasTroubles)
            : this.props.devices

        if (devices.length === 0) {
            return (
                <div>
                    <Error title={__('No devices in that partition')}/>
                </div>
            )
        }

        return devices.map(device => (
            <StateDevice
                key={device.id}
                device={device}
                bypassEnabled={deviceBypassStates[device.id]}
                isChanged={deviceBypassStates.hasOwnProperty(device.id)}
                onBypassChange={this.onBypassChange}/>
        ))
    }
}

export default compose(
    withPermission({isAllowed: sidebar}),
    withRejection(),
    connect((state, {panelId, partition}) => {
        const devices = partition.id === PARTITION_ALL
            ? selectBypassableDevices(state, panelId)
            : selectBypassableDevicesByPartition(state, panelId, partition.id)

        return {
            devices,
            isLoading: !devices,
        }
    }),
    withLoader(),
)(PanelStateSideBar)