import {combineActions, handleActions} from 'redux-actions'

import * as actions from './actions'

const defaultState = {}

export default handleActions({
    [actions.reset](state, {meta: {panelId}}) {
        return {
            ...state,
            [panelId]: null,
        }
    },

    [actions.fetch](state, {payload, meta: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                date: payload.date,
                isLoading: true,
            },
        }
    },

    [actions.receive](state, {payload, error, meta: {panelId}}) {
        if (error) {
            return {
                ...state,
                [panelId]: {error: payload},
            }
        }

        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                isLoading: false,
                data: payload,
            },
        }
    },

    [combineActions(actions.review, actions.unreview)](state, {payload: {id, isReviewed}, error, meta: {panelId}}) {
        const old = state[panelId] || {}

        return {
            ...state,
            [panelId]: {
                ...old,
                data: old.data && old.data.map(report => {
                    if (report.id !== id) {
                        return report
                    }

                    return {...report, isReviewed}
                }),
            },
        }
    },
}, defaultState)