import {handleActions} from 'redux-actions'

import {fetch, setError} from './actions'

export default handleActions({
    [fetch]() {
        return {}
    },

    [setError](state, {payload}) {
        return {
            ...state,
            error: payload,
        }
    },
}, {})