import {get, post} from 'api/http'
import {mapBatches, mapBatch} from './batches.map'
import createRowsResponseParser from './base/createRowsResponseParser'
import generateFilterString from './base/generateFilterString'
import generateSuggestString from './base/generateSuggestString'
import mapSuggests from './base/mapSuggests'

const keysMap = {
    type: 'batch_type',
    started: 'batch_created_timestamp',
    serial: '_unt_serial',
    finished: 'batch_finished_timestamp',
    group: '_utg_name',
    status: 'batch_status',
}

export function status() {
    return get('/batch/mine')
        .then(mapBatches)
}

export function untrack(batchId) {
    return post('/batch/hide', {batch_id: batchId})
}

export function untrackFinished() {
    return post('/batch/hideall')
}

export function fetch({start = 0, perPage: count = 15, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/batch/list', {start, count, filter, query})
        .then(createRowsResponseParser(mapBatch))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('batch/suggest', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}