import concat from 'lodash-es/concat'
import {sequenced} from 'utils/saga-effects'
import {call} from 'redux-saga/effects'

export function* simple(fn, ...arg) {
    return yield call(fn, ...arg)
}

// Deprecated
export function* batch(fn, iterable, ...arg) {
    const data = yield sequenced(fn, iterable, ...arg)

    return data.reduce((acc, {lastError, processes}) => ({
        lastError: (lastError) ? lastError : acc.lastError,
        processes: concat(acc.processes, processes),
    }), {lastError: null, processes: []})
}