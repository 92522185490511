import withProps from 'containers/withProps'

import InteractiveUsersBar from './InteractiveUsersBar'
import InteractiveUsersSearch from './InteractiveUsersSearch'
import InteractiveUsersTable from './InteractiveUsersTable'

import Page from 'ipmp-react-ui/Page'

export default withProps({
    Bar: InteractiveUsersBar,
    Top: InteractiveUsersSearch,
    Content: InteractiveUsersTable,
})(Page)