import {compose} from 'redux'

import {markAsViewed} from 'permissions/remoteInspections/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export const ReviewButton = compose(
    withPermission({isVisible: markAsViewed}),
    withVisibility(),
)(Button)

export default compose(
    withRemoteInspectionsResult(),
    withProps(({review, result}) => ({
        borderless: true,
        onClick: review,
        disabled: !result || result.isReviewed,
        label: __('Mark reviewed'),
    })),
)(ReviewButton)