import timezones from 'utils/timezones'

export default function makeNotStartedProcess(process, errorMessage) {
    return {
        ...process,
        status: 'notStarted',
        finished: timezones.server(),
        isFailed: true,
        isRunning: false,
        isFailedToStart: true,
        errorMessage,
    }
}