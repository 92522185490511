import withPerPage from 'containers/withPerPage'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {init, fetch, reset, setStart} from 'modules/interactiveUsers/list/actions'
import {enable, suspend} from 'modules/interactiveUsers/store/actions'

export default function withInteractiveUsers() {
    return compose(
        withPerPage('interactiveUsers'),
        connect(
            (state) => {
                const {isLoading, page, error, total, start} = state.interactiveUsers.list

                return {
                    rows: page.map(id => state.interactiveUsers.store.byIds[id]),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            dispatch => bindActionCreators({
                init,
                fetch,
                reset,
                enable,
                suspend,
                onPageChange: setStart,
            }, dispatch),
        ),
    )
}