import React from 'react'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import {FormRow} from 'ipmp-react-ui/Form'
import Checkbox from 'ipmp-react-ui/Checkbox'
import withSelectLoader from 'containers/withSelectLoader'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import {fetch as fetchGroups} from 'modules/groups/select/actions'
import {
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
} from 'constants/panelVendorType'

const validationCommon = {
    groupId: {
        presence: true,
    },
    type: {
        presence: () => __('You must select at least one communication module'),
    },
    simNumber: {
        phone: true,
    },
}

export const validation = {
    [VENDOR_POWER_MASTER]: {
        ...validationCommon,
        serial: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{6}',
                flags: 'i',
                message: () => __('Panel ID must be a 6 digit hexadecimal'),
            },
        },
        account: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{6}',
                flags: 'i',
                message: () => __('Must be a 6 digit hexadecimal'),
            },
        },
    },

    [VENDOR_NEO]: {
        ...validationCommon,
        serial: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{12}',
                flags: 'i',
                message: () => __('Must be a 12 digit hexadecimal'),
            },
        },
        account: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{4}([a-f0-9]{2})?',
                flags: 'i',
                message: () => __('Must be a 4 or 6 digit hexadecimal'),
            },
        },
    },
    [VENDOR_DUAL_PATH]: {
        ...validationCommon,
        serial: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{8}',
                flags: 'i',
                message: () => __('Must be a 8 digit hexadecimal'),
            },
        },
        account: {
            presence: true,
            format: {
                pattern: '[a-f0-9]{4}',
                flags: 'i',
                message: () => __('Must be a 4 digit hexadecimal'),
            },
        },
    },
}

const GroupSelect = withSelectLoader(
    fetchGroups,
    (store) => store.groups.select.isLoading,
    ({groups}) => Object.values(groups.store.byIds).map(({id, name}) => ({label: name, value: id})),
    10,
)(Autocomplete)

const PanelFieldSet = ({isNew, data}) => {
    return (
        <div className="form-field">
            <Input
                readOnly={!isNew}
                label={__('Panel Id')}
                name="serial"
                defaultValue={data.serial}
            />

            <Input
                readOnly={!isNew}
                label={__('Account')}
                name="account"
                defaultValue={data.account}
            />

            <GroupSelect
                label={__('Group')}
                name="groupId"
                defaultValue={data.groupId}
                defaultLabel={data.group}/>

            <FormRow label={__('Client Type')} name="type">
                <Checkbox
                    label={__('Cellular')}
                    name="type[]"
                    value="gprs"
                    defaultChecked={data.modules && data.modules.gprs}
                />

                <Checkbox
                    label={__('Ethernet')}
                    name="type[]"
                    value="bba"
                    defaultChecked={data.modules && data.modules.bba}
                />
            </FormRow>

            <Input
                label={__('Sim Number')}
                name="simNumber"
                defaultValue={data.simNumber}
            />
        </div>
    )
}

PanelFieldSet.propTypes = {
    groups: PropTypes.array,
}

PanelFieldSet.defaultProps = {
    groups: [],
    data: {},
}

export default PanelFieldSet