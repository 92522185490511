import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ConfigurationRow from 'components/Configuration/ConfigurationRow'

export default class ConfigurationRows extends Component {

    static propTypes = {
        nodes: PropTypes.array.isRequired,
        keyPrefix: PropTypes.string,
        level: PropTypes.number,
        exporting: PropTypes.object,
        changes: PropTypes.object,
        backup: PropTypes.object,
        onChange: PropTypes.func,
        onSetExport: PropTypes.func,
        onTitleRef: PropTypes.func,
        makeBasic: PropTypes.bool,
        readonly: PropTypes.bool,
    }

    static defaultProps = {
        level: 0,
    }

    render() {
        const {
            nodes,
            keyPrefix,
            level,
            exporting,
            onChange,
            onSetExport,
            changes,
            backup,
            makeBasic,
            onTitleRef,
            readonly,
        } = this.props

        const leafs = nodes.filter(({type}) => type != 'menu')
        const sections = nodes.filter(({type}) => type == 'menu')

        return (
            <section>
                {leafs.map(item => (
                    <ConfigurationRow
                        key={item.key}
                        item={item}
                        onChange={onChange}
                        onSetExport={onSetExport}
                        changed={(changes && changes.hasOwnProperty(item.key)) ? changes[item.key] : undefined}
                        isChanged={changes && changes.hasOwnProperty(item.key)}
                        backup={(backup && backup.hasOwnProperty(item.key)) ? backup[item.key] : undefined}
                        isExporting={exporting && exporting.hasOwnProperty(item.key)}
                        makeBasic={makeBasic}
                        readonly={readonly}
                    />
                ))}

                {sections.map(({key, val, name}) => (
                    <div key={key} className="configuration-rows">
                        <h3 ref={onTitleRef ? (element => onTitleRef(keyPrefix + '/' + key, element)) : null}
                            className={'configuration-title configuration-title--' + level}>{name}</h3>

                        <ConfigurationRows
                            {...this.props}
                            nodes={val}
                            keyPrefix={keyPrefix + '/' + key}
                            level={level + 1}
                        />
                    </div>
                ))}
            </section>
        )
    }
}