import React from 'react'
import {compose} from 'redux'

import {MAIN_GROUP_ID} from 'constants/groups'

import list from 'permissions/groups/list'
import group from 'permissions/group/page'
import selection from 'permissions/groups/selection'
import {roles} from 'permissions/groups/actions'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'

import withSelection from 'containers/withSelection'
import withProps from 'containers/withProps'
import withGroups from 'containers/withGroups'
import withLoader from 'containers/withLoader'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'
import GroupRoles from './TableCell/GroupRoles'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'

const columns = [
    {
        name: () => __('Name'),
        width: 150,
        render: ({name}) => name,
    },
    {
        name: () => __('Description'),
        width: 350,
        render: ({description}) => description || <span className="empty">—</span>,
    },
    {
        name: () => __('Panels'),
        tooltip: false,
        width: 100,
        render: ({total}) => {
            if (total === 0) {
                return <span className="empty">{__('No panels')}</span>
            }

            return <Definition title={total} detail={__n('panel', 'panels', total)}/>
        },
    },
    {
        tooltip: false,
        permission: roles,
        fixed: true,
        width: 70,
        render: ({id}) => <GroupRoles groupId={id}/>,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
        hasGroupLink: group,
    }),
    withRejection(),
    withGroups(),
    withSelection(),
    withLoader(({init}) => init()),
    withProps(({history, hasGroupLink}) => ({
        fullHeight: true,
        columns,
        disabledRowsIds: [MAIN_GROUP_ID],
        emptyMessage: __('No groups found'),
        onRowClick: hasGroupLink && (
            ({id}) => history.push(path('system.group', {id}))
        ),
    })),
    withTableColumnsPermission(),
)(Table)