import {all} from 'redux-saga/effects'

import locales from './locales/saga'
import panels from './panels/saga'
import processes from './processes/saga'
import groups from './groups/saga'
import events from './events/saga'
import modals from './modals/saga'
import remoteInspections from './remoteInspections/saga'
import users from './users/saga'
import roles from './roles/saga'
import settings from './settings/saga'
import reports from './reports/saga'
import auth from './auth/saga'
import centralStations from './centralStations/saga'
import devices from './devices/saga'
import pgm from './pgm/saga'
import countries from './countries/saga'
import languages from './languages/saga'
import actionLog from './actionLog/saga'
import firmware from './firmware/saga'
import basicConfiguration from './basicConfiguration/saga'
import installers from './installers/saga'
import interactiveUsers from './interactiveUsers/saga'
import upgradeStatus from './upgradeStatus/saga'
import selection from './selection/saga'
import dashboard from './dashboard/saga'
import features from './features/saga'
import batches from './batches/saga'
import runners from './runners/saga'
import system from './system/saga'
import servers from './servers/saga'

export default function*() {
    yield all([
        processes(), // process saga should be first
        locales(),
        panels(),
        groups(),
        devices(),
        pgm(),
        events(),
        remoteInspections(),
        modals(),
        reports(),
        auth(),
        users(),
        roles(),
        settings(),
        centralStations(),
        countries(),
        languages(),
        actionLog(),
        firmware(),
        basicConfiguration(),
        installers(),
        interactiveUsers(),
        upgradeStatus(),
        selection(),
        dashboard(),
        batches(),
        runners(),
        features(),
        system(),
        servers(),
    ])
}
