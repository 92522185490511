import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {selectPerPage} from 'modules/settings/selectors'
import {select, all, call, put} from 'redux-saga/effects'

import {POLL_PROCESSES} from 'configs/pollers'
import toIds from 'utils/toIds'

import * as actions from './actions'
import * as api from 'api/processes'
import {update, purge} from 'modules/processes/store/actions'

import listSaga from 'modules/higherOrder/createListSaga'

import isEqual from 'lodash-es/isEqual'

const selector = (state) => ({
    ...state.processes.list,
    perPage: selectPerPage(state, 'processes'),
    listRoute: 'processes',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_PROCESSES, pollProcesses),
    ])
}

function* pollProcesses() {
    const state = yield select(selector)

    if (state.start > 0) {
        return
    }

    try {
        const {count, rows} = yield call(api.fetch, state)

        const byIds = yield select(state => state.processes.store.byIds)

        const updatedRows = rows.filter(
            row => !byIds.hasOwnProperty(row.id) || !isEqual(byIds[row.id], row),
        )

        if (updatedRows.length) {
            yield put(update(updatedRows))
            yield put(actions.receive(toIds(rows), count))
        }
    } catch (error) {
        yield put(actions.receive(error))
    }
}