import {createSelector} from 'reselect'

export const selectBatchesListRows = createSelector(
    state => state.batches.list.page,
    state => state.batches.store.byIds,
    (page, store) => page.reduce((acc, id) => {
        if (store.hasOwnProperty(id)) {
            acc.push(store[id])
        }
        return acc
    }, []),
)