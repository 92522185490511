import {all, takeEvery, put, call} from 'redux-saga/effects'

import {fetch, fetchWithoutLoader, update} from './actions'

import * as api from 'api/panel/features'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(fetch, watchFetch),
        takeEvery(fetchWithoutLoader, watchFetch),
    ])
}

function* watchFetch({payload: panelId}) {
    try {
        const data = yield call(api.fetch, panelId)

        yield put(update({panelId, ...data}))
    } catch (error) {
        put(snackShow(error.message))
        put(update(error))
    }
}