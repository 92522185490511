import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {stop as stopPermission} from 'permissions/processes/actions'

import {selectRunnersRowsByBatchId} from 'modules/batches/batchRunners/selectors'
import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'
import {stop} from 'modules/runners/store/actions'
import {withPermission} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'

import {reset, startPoll, stopPoll} from 'modules/batches/batchRunners/actions'

import BatchInfo from 'components/Processes/BatchInfo'

export default compose(
    withRouter,
    withPermission({
        isStopPermitted: stopPermission,
    }),
    connect(
        selectRunnersRowsByBatchId,
        (dispatch, {batchId}) => bindActionCreators({
            stop,
            reset: () => reset(batchId),
            startPoll,
            stopPoll,
        }, dispatch),
    ),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withProps(({batchId}) => ({key: batchId})),
    withLifeCycle({
        onMount({reset}) {
            reset()
        },
    }),
    connect(
        (state, {isLoading, batchId}) => ({
            isLoading: isLoading || selectBatchIsTemporary(state, batchId),
        }),
    ),
)(BatchInfo)
