import createAction from 'redux-actions/es/createAction'
import toArray from 'utils/toArray'

export const fetch = createAction('DEVICES/LIST/FETCH', panelId => ({panelId}))
export const receive = createAction('DEVICES/LIST/RECEIVE', null, (_, panelId) => ({panelId}))
export const update = createAction('DEVICES/LIST/UPDATE',
    toArray,
    (_, panelId) => ({panelId}),
)

export const refreshRssi = createAction('DEVICES/LIST/REFRESH_RSSI', panelId => ({panelId}))
export const refreshGSM = createAction('DEVICES/LIST/REFRESH_GSM', panelId => ({panelId}))
export const setRarelyUsed = createAction('DEVICES/LIST/SET_RARELY_USED', (panelId, deviceId, enabled) => ({
    panelId,
    deviceId,
    enabled,
}))
export const removeDevice = createAction('DEVICES/LIST/REMOVE_DEVICE', (panelId, deviceId) => ({panelId, deviceId}))
export const setSoak = createAction('DEVICES/LIST/SET_SOAK', (panelId, deviceId, enabled) => ({
    panelId,
    deviceId,
    enabled,
}))
export const setBypass = createAction('DEVICES/LIST/SET_BYPASS', (panelId, states) => ({
    panelId,
    states,
}))
export const confirmProgress = createAction(
    'DEVICES/LIST/CONFIRM_PROGRESS',
    (panelId, deviceIds, type) => ({panelId, deviceIds: toArray(deviceIds), type}),
)
export const revertProgress = createAction(
    'DEVICES/LIST/REVERT_PROGRESS',
    (panelId, deviceIds, type) => ({panelId, deviceIds: toArray(deviceIds), type}),
)
export const renameDevice = createAction(
    'DEVICES/LIST/RENAME_DEVICE',
    (panelId, deviceType, zone, name) => ({panelId, deviceType, zone, name}),
)
