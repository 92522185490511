import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import generateFilterString from 'api/base/generateFilterString'
import parseDate, {formatDate} from 'api/base/parseDate'
import {isNeoSerial} from 'utils/panelType'
import mapValidationErrors from './base/mapValidationErrors'
import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

import {mapPanel, keysMap} from './panels.map'

export function fetch({start = 0, perPage: count, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_all/getallunits', {start, count, filter, query})
        .then(createRowsResponseParser(mapPanel))
}

const processApp = (method, ids, state) => {
    return post(method, {
        form: {
            data: ids.map((id) => ({unitId: id, state})),
        },
    })
}

export function processUserApp(ids, state = true) {
    return processApp('/units_all/guirestriction', ids, state)
}

export function processConfiguratorApp(ids, state = true) {
    return processApp('/units_all/installerapprestriction', ids, state)
}

export function setEncryption(id, channel, encrypted) {
    return post('/unit_diagnostic/setencryption', {'unt_id': id, channel, encrypted})
}

export function remove(ids, batchId) {
    return post('units_all/remove', {
        form: {unitId: ids},
        batch_id: batchId,
    })
}

export function markForService(unitIds, comment, userId = null) {
    return post('units_all/markforservice', {
        form: {
            unitId: unitIds,
            userId: userId,
            comment: comment,
        },
    }).catch(mapValidationErrors)
}

export function reassign(unitIds, comment, userId = null) {
    return post('units_faulty/reassign', {
        form: {
            unitId: unitIds,
            userId: userId,
            comment: comment,
        },
    }).catch(mapValidationErrors)
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestunits', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function panelMaintainers(groupIds) {
    return get('/units_faulty/getusersforassign', {
        'utg_id[]': groupIds,
    }).then(data => data.map(
        dataMapper(data => ({
            id: parseInt(data.usr_id),
            name: data.usr_name,
            isEnabled: data.usr_status === 'enabled',
        })),
    ))
}

export function changeGroup(unitIds, groupId) {
    return post('/units_all/changegroup', {
        form: {
            unitId: unitIds,
            groupId: groupId,
        },
    }).catch(mapValidationErrors)
}

export function discovery(unitIds, timeout, batchId) {
    return post('/units_all/discovery', {
        form: {
            unt_id: unitIds,
            timeout,
        },
        batch_id: batchId,
    })
    .catch(mapValidationErrors)
}

export function refreshState(panelIds, batchId) {
    return post('/units_faulty/refresh', {
        unt_id: panelIds,
        batch_id: batchId,
    })
}

export function resolveFaults(faultIds) {
    return post('/units_faulty/resolve', {form: {faults: faultIds}})
        .catch(mapValidationErrors)
}

export function suspendFaults(faultIds, date) {
    return post('/units_faulty/suspend', {faults: faultIds, date: formatDate(date)})
        .catch(mapValidationErrors)
}

export function resumeFaults(faultIds) {
    return post('/units_faulty/resume', {faults: faultIds})
        .catch(mapValidationErrors)
}

export function pushBasicConfiguration(panelIds, basicConfigId, batchId) {
    return post('pmaxconfigbasic/upload', {
        unt_id: panelIds,
        pca_id: basicConfigId,
        batch_id: batchId,
    }).catch(mapValidationErrors)
}

//eslint-disable-next-line camelcase
export function fetchUpgradable({start = 0, perPage: count, applianceId: utd_id, upgradePackageId: uep_id, filters}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_all/getupgradableunits', {start, count, utd_id, uep_id, filter})
        .then(createRowsResponseParser(mapPanel))
}

//eslint-disable-next-line camelcase
export function suggestUpgradable(fields, start = 0, count = 5, {applianceId: utd_id, upgradePackageId: uep_id}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestupgradableunits', {utd_id, uep_id, suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

const mapCompatibleunits = dataMapper((data) => ({
    id: parseInt(data.unt_id),
    family: data.cgm_name || data.family,
    fault: data.iow_rest_name,
    isSupported: data.isSupported,
    faultsSince: parseDate(data.ufs_created_timestamp),
    account: data.unt_account,
    isActivated: data.unt_activated === '1',
    configuratorApp: data.unt_installer_remote_access === 'allow',
    model: data.unt_model,
    userApp: data.unt_remote_access === 'allow',
    serial: data.unt_serial,
    isNeo: isNeoSerial(data.unt_serial),
    userId: data.usr_id ? parseInt(data.usr_id) : null,
    user: data.usr_name,
    groupId: parseInt(data.utg_id),
    alarms: parseInt(data._unt_alarms),
    alerts: parseInt(data._unt_alerts),
    group: data._utg_name,
}))

//eslint-disable-next-line camelcase
export function fetchConfigCompatible({basicConfigId: pca_id, start = 0, perPage: count = 10, filters}) {
    filters = generateFilterString(filters, keysMap)

    return get('units_all/getconfigcompatibleunits', {
        pca_id,
        start,
        count,
        filter: filters,
        sort: '-unt_id',
    }).then(createRowsResponseParser(mapCompatibleunits))
}

//eslint-disable-next-line camelcase
export function suggestConfigCompatible(fields, start = 0, count = 5, {basicConfigId: pca_id}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestconfigcompatibleunits', {pca_id, suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}
