import {createAction} from 'redux-actions'

export const observe = createAction('PROCESSES/MANAGER/OBSERVE',
    processes => Array.isArray(processes) ? processes : [processes],
    (_, batchId) => ({batchId}),
)

export const receive = createAction('PROCESSES/MANAGER/RECEIVE',
    processes => Array.isArray(processes) ? processes : [processes],
)

export const restore = createAction('PROCESSES/MANAGER/RESTORE')

export const track = createAction(
    'PROCESSES/MANAGER/TRACK',
    ids => Array.isArray(ids) ? ids : [ids],
)

export const untrack = createAction(
    'PROCESSES/MANAGER/UNTRACK',
    ids => Array.isArray(ids) ? ids : [ids],
)
