import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {
    fetch,
    receive,
    startPoll,
    stopPoll,
} from './actions'

const defaultState = {
    isLoading: false,
    data: [],
    error: null,
}

export default handleActions({
    [fetch](state) {
        return set(state, 'isLoading', true)
    },

    [startPoll](state) {
        return set(state, 'isLoading', true)
    },

    [stopPoll](state) {
        return set(state, 'isLoading', false)
    },

    [receive](state, {payload, error}) {
        if (error) {
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
        }

        return {
            ...state,
            isLoading: false,
            data: payload,
            error: null,
        }
    },
}, defaultState)
