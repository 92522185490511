import {combineReducers} from 'redux'

import store from './store/reducer'
import profiles from './profiles/reducer'
import serialPorts from './serialPorts/reducer'
import protocols from './protocols/reducer'
import one from './one/reducer'
import list from './list/reducer'
import licensing from './licensing/reducer'

export default combineReducers({
    store,
    profiles,
    one,
    list,
    serialPorts,
    protocols,
    licensing,
})