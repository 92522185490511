import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {setSystemSettings} from 'modules/forms/handlers'

import withForm from 'containers/withForm'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'

import Checkbox from 'ipmp-react-ui/Checkbox'

class BroadbandConnectedSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            isAutoEnrollmentEnabled: PropTypes.bool,
        }),
    }

    get rules() {
        return {
        }
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Broadband Connected Settings')}
                confirmOnDismiss
                {...props}
            >
                <Checkbox
                    label={__('Enable Auto-Enroll')}
                    name="isAutoEnrollmentEnabled"
                    defaultChecked={data.isAutoEnrollmentEnabled}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(setSystemSettings.BroadbandConnected)
)(BroadbandConnectedSettings)
