import parseDate from 'api/base/parseDate'
import mapper from 'utils/dataMapper'
import {
    post,
} from 'api/http'
import {mapProcess} from 'api/processes'
import {get} from 'api/http'

const mapLog = mapper(data => ({
    id: parseInt(data.pxl_id),
    text: data.pxl_event_text,
    eventId: parseInt(data.pxl_event),
    partition: data.pxl_event_partition,
    timestamp: parseDate(data.pxl_event_timestamp),
    appointment: data.pxl_event_appointment,
}))

export function fetchLog(panelId, type, start, count) {
    return get('pmaxlog/getlog', {
        unt_id: panelId,
        log: type,
        start,
        count,
    })
        .then(({rows, count, process}) => ({
            rows: rows.map(mapLog),
            count: parseInt(count),
            process: process ? mapProcess(process) : null,
        }))
}

export function refreshLog(panelId, type, batchId) {
    return post('pmaxlog/refresh', {
        unt_id: panelId,
        log: type,
        batch_id: batchId,
    }).then(mapProcess)
}

export function downloadLog(panelId, type) {
    window.open(`/api/pmaxlog/export/unt_id/${panelId}/log/${type}`)
}