import {compose} from 'redux'

import withSelection from 'containers/withSelection'
import withInteractiveUsers from 'containers/withInteractiveUsers'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withInteractiveUsers(),
    withSelection(),
)(SelectionDropDown)