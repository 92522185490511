import {connect} from 'react-redux'
import {compose} from 'redux'

import StateButton from 'components/Button/State'

import {withRouterPanelId} from 'containers/withPanel'
import {selectStateCanGet, selectStateCanSet} from 'modules/features/store/selectors'
import withProps from 'containers/withProps'

export default compose(
    withRouterPanelId(),
    connect(
        (...args) => ({
            ...selectStateCanGet(...args),
            ...selectStateCanSet(...args),
        })
    ),
    withProps(({isCanGetAvailable, isCanSetAvailable, active, disabled}) => {
        return ({
            active: isCanGetAvailable && active,
            disabled: disabled || !isCanSetAvailable,
        })
    })
)(StateButton)