export const MODAL_ABOUT = 'ABOUT'
export const MODAL_ABOUT_LICENSE_FEATURES = 'ABOUT_LICENSE_FEATURES'
export const MODAL_BLOCKED_NOTIFICATIONS_HELP = 'BLOCKED_NOTIFICATIONS_HELP'

export const MODAL_CONFIRMATION = 'CONFIRMATION'

export const MODAL_MARK_FOR_SERVICE = 'MARK_FOR_SERVICE'
export const MODAL_REASSIGN_SERVICE = 'MODAL_REASSIGN_SERVICE'
export const MODAL_CHANGE_PANEL_GROUP = 'CHANGE_GROUP'
export const MODAL_FAULTS_RESOLVE = 'FAULTS_RESOLVE'
export const MODAL_FAULTS_SUSPEND = 'FAULTS_SUSPEND'
export const MODAL_FAULTS_RESUME = 'FAULTS_RESUME'

export const MODAL_ADD_PANEL = 'ADD_PANEL'
export const MODAL_EDIT_PANEL_INFO = 'EDIT_PANEL_INFO'
export const MODAL_EDIT_PANEL_CUSTOMER_INFO = 'EDIT_PANEL_CUSTOMER_INFO'
export const MODAL_MAKE_BASIC_CONFIGURATION = 'CREATE_BASIC_CONFIGURATION'
export const MODAL_PUSH_BASIC_CONFIGURATION = 'PUSH_BASIC_CONFIGURATION'
export const MODAL_ACTIVATE_PANEL = 'ACTIVATE_PANEL'

export const MODAL_ADD_GROUP = 'ADD_GROUP'
export const MODAL_EDIT_GROUP = 'EDIT_GROUP'

export const MODAL_EDIT_CENTRAL_STATION_LINKS = 'EDIT_CENTRAL_STATION_LINKS'
export const MODAL_ADD_CENTRAL_STATION = 'ADD_CENTRAL_STATION'
export const MODAL_EDIT_CENTRAL_STATION = 'EDIT_CENTRAL_STATION'

export const MODAL_SCHEDULE_RRI = 'SCHEDULE_RRI'
export const MODAL_SCHEDULE_RRI_CSV = 'SCHEDULE_RRI_CSV'

export const MODAL_CREATE_REPORT = 'CREATE_REPORT'

export const MODAL_REMOTE_INSPECTION_VALUES = 'REMOTE_INSPECTION_VALUES'

export const MODAL_ADD_USER = 'ADD_USER'
export const MODAL_EDIT_USER = 'EDIT_USER'
export const MODAL_REMOVE_USER = 'REMOVE_USER'

export const MODAL_ADD_DEVICE = 'ADD_DEVICE'
export const MODAL_SHOW_DEVICE_METEO_CHART = 'SHOW_DEVICE_METEO_CHART'
export const MODAL_RENAME_PARTITION = 'MODAL_RENAME_PARTITION'
export const MODAL_RENAME_DEVICE = 'MODAL_RENAME_DEVICE'

export const MODAL_EDIT_PROFILE_INFO = 'EDIT_PROFILE_INFO'
export const MODAL_EDIT_SETTINGS = 'MODAL_EDIT_SETTINGS'
export const MODAL_CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const MODAL_REBOOT_PLINK = 'REBOOT_PLINK'
export const MODAL_ENABLE_PLINK_LOGGING = 'ENABLE_PLINK_LOGGING'
export const MODAL_DISABLE_PLINK_LOGGING = 'DISABLE_PLINK_LOGGING'

export const MODAL_ADD_ROLE = 'ADD_ROLE'
export const MODAL_EDIT_ROLE = 'EDIT_ROLE'
export const MODAL_REMOVE_ROLE = 'REMOVE_ROLE'
export const MODAL_EDIT_ROLE_PERMISSIONS = 'EDIT_ROLE_PERMISSIONS'

export const MODAL_SAVE_SEARCH_SCOPE = 'SAVE_SEARCH_SCOPE'

export const MODAL_BATCH_INFO = 'BATCH_INFO'
export const MODAL_UNAVAILABLE_FEATURE = 'UNAVAILABLE_FEATURE'

export const MODAL_FIRMWARE_UPGRADE = 'FIRMWARE_UPGRADE'

export const MODAL_TRIGGER_DISCOVERY = 'TRIGGER_DISCOVERY'

export const MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED = 'SYSTEM_SETTINGS_CELLULAR_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED = 'SYSTEM_SETTINGS_BROADBAND_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED = 'SYSTEM_SETTINGS_COMMON_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_SUPERVISION = 'SYSTEM_SETTINGS_SUPERVISION'
export const MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION = 'SYSTEM_SETTINGS_REMOTE_INSPECTION'
export const MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION = 'SYSTEM_SETTINGS_INTERACTIVE_SESSION'
export const MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS = 'SYSTEM_SETTINGS_USER_NOTIFICATIONS'
export const MODAL_SYSTEM_SETTINGS_ADVERTISEMENT = 'SYSTEM_SETTINGS_ADVERTISEMENT'
export const MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER = 'SYSTEM_SETTINGS_MESSAGE_BROKER'

