import {all, call, put, takeEvery, select} from 'redux-saga/effects'
import {__, __n} from 'utils/i18n'
import {push} from 'react-router-redux'
import path from 'utils/path'

import * as actions from './actions'
import * as api from 'api/basicConfiguration'

import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.save, watchSave),
    ])
}

function* watchFetch({payload: {basicConfigId}}) {
    try {
        const data = yield call(api.fetchOne, basicConfigId)

        yield put(actions.receive(data, basicConfigId))
    } catch (error) {
        yield put(actions.receive(error, basicConfigId))
    }
}

function* watchSave({payload}) {
    const {basicConfigId} = payload
    const {exporting, changes, values, errors, version} = yield select(state => state.basicConfiguration.one[basicConfigId])
    const errorsCount = errors ? Object.keys(errors).length : 0

    if (errorsCount > 0) {
        const message = __n('You have %d error in configuration', 'You have %d errors in configuration', errorsCount)
        yield put(snackShow(message))
        return
    }

    const diff = prepareDiff(exporting, changes, values)

    if (diff.length === 0) {
        const message = __('Not selected a single row')
        yield put(snackShow(message))
        return
    }

    try {
        yield put(actions.setLoading({basicConfigId, isLoading: true}))
        yield call(api.save, {basicConfigId, diff, version})
        yield put(push(path('system.basicConfigurations', {})))
    } catch (error) {
        yield put(actions.setLoading({basicConfigId, isLoading: false}))
        yield put(snackShow(error.message))
    }
}

function prepareDiff(exporting, changes, values) {
    return Object.keys(exporting).reduce((acc, key) => ({
        ...acc,
        [key]: changes && changes.hasOwnProperty(key) ? changes[key] : values[key],
    }), {})
}