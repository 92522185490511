import mapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'

export const mapStatus = mapper(data => ({
    failed: parseInt(data.failed),
    failedToStart: parseInt(data.failed_to_start),
    handled: parseInt(data.handled),
    notStarted: parseInt(data.not_started),
    start: parseInt(data.start),
    succeeded: parseInt(data.succeeded),
    canceled: parseInt(data.canceled),
    pending: parseInt(data.handled) + parseInt(data.not_started) + parseInt(data.start),
}))

export const mapBatch = mapper(data => ({
    id: data.batch_id,
    started: parseDate(data.batch_created_timestamp),
    finished: parseDate(data.batch_finished_timestamp),
    type: data.batch_type[0],
    isObservable: data.is_observable === '1',
    processCount: parseInt(data.prs_count),
    stats: mapStatus(data.stats),
    userId: parseInt(data.usr_id),
    panelSerial: data.unt_serial,
    panels: parseInt(data.unt_count),
}))

export const mapBatches = ({rows}) => ({
    rows: rows.map(mapBatch),
})
