import {all, put, call, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/pgm'

import {POLL_PGMS} from 'configs/pollers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {snackShow} from 'modules/snacks'

export default function*() {
    yield all([
        yield takeEvery(actions.fetch, watchPGMs),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_PGMS, watchPGMs, true)
    ])
}

export function* watchPGMs({payload: panelId}) {
    try {
        const pgms = yield call(api.getPGMList, panelId)

        yield put(actions.receive(pgms, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.receive(error))
    }
}
