import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash-es/get'

import Bar from 'ipmp-react-ui/Bar'
import ProgressBar from 'ipmp-react-ui/ProgressBar'
import Calendar, {YEAR, CalendarItem, REVERSE} from 'ipmp-react-ui/Calendar'

import InitiateNowRemoteInspectionButton from './RemoteInspectionBar/InitiateNowRemoteInspectionButton'
import ScheduleRemoteInspectionButton from './RemoteInspectionBar/ScheduleRemoteInspectionButton'
import ViewedRRI from 'components/Cell/RemoteInspections/Actions/ViewedRRI'
import ResendEmail from 'components/Cell/RemoteInspections/Actions/ResendEmail'

import {getResultRriItemColor, getResutlRriItemTitle} from 'constants/remoteInspection'
import __ from 'utils/i18n'
import momentPropType from 'utils/momentPropType'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'


class RemoteInspectionsCalendar extends Component {

    static propTypes = {
        remoteInspection: PropTypes.shape({
            progress: PropTypes.number,
            next: PropTypes.instanceOf(Date),
            repetition: PropTypes.number,
        }),
        date: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            momentPropType
        ]),
        isLoading: PropTypes.bool.isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape({
            created: PropTypes.instanceOf(Date),
            fails: PropTypes.number,
        })).isRequired,
        onItemClick: PropTypes.func.isRequired,
    }

    state = {
        view: YEAR,
    }

    getCalendarItemIcons = (result) => {
        const {permissions, sendEmail, markAsViewed} = this.props
        const icons = []

        if (permissions.sendEmail) {
            icons.push({
                Icon: () => (
                    <ResendEmail result={result} sendEmail={sendEmail} />
                ),
            })
        }

        if (permissions.markAsViewed) {
            icons.push({
                Icon: () => (
                    <ViewedRRI id={result.id}
                        result={result}
                        markAsViewed={markAsViewed}
                    />
                ),
            })
        }

        return icons
    }

    prepareScheduledProps = () => {
        const {remoteInspection, date} = this.props

        if (remoteInspection.next === null) {
            return []
        }

        if (remoteInspection.repetition === 0) {
            return [{
                key: 'scheduled-0',
                color: getResultRriItemColor(),
                date: remoteInspection.next,
                title: __('Scheduled'),
            }]
        }

        const result = []
        let nextRepetition = moment(remoteInspection.next)

        if (nextRepetition.isAfter(date, 'year')) {
            return result
        }

        if (!nextRepetition.isSame(date, 'year')) {
            while (!nextRepetition.isSame(date, 'year')) {
                nextRepetition = nextRepetition.month(
                    nextRepetition.month() + remoteInspection.repetition
                )
            }
        }

        let index = 0
        while (nextRepetition.isSame(date, 'year')) {
            result.push({
                key: `scheduled-${index}`,
                color: getResultRriItemColor(),
                date: moment(nextRepetition),//prevent object refs issues
                title: __('Scheduled'),
            })
            nextRepetition = nextRepetition.month(
                nextRepetition.month() + remoteInspection.repetition
            )
            index++
        }

        return result
    }

    prepareCommonRriResultProps = (result, index) => (
        {
            key: `result-${index}`,
            color: getResultRriItemColor(result.fails),
            date: moment(result.created),
            onClick: () => this.props.onItemClick(result),
            title: getResutlRriItemTitle(result.fails),
            icons: this.getCalendarItemIcons(result),
        }
    )

    prepareCurrentRriResultProps = (result, index) => {
        const {remoteInspection} = this.props

        if (remoteInspection.progress === null) {
            return []
        }

        const currentRriDate = get(remoteInspection, 'process.started', new Date)

        return [{
            key: `result-${index}`,
            date: moment(currentRriDate),
            customTrigger: (
                <ProgressBar className="remoteInspection-rriResultProgress"
                    value={remoteInspection.progress}
                />
            ),
        }]
    }

    prepareProps = () => {
        const {rows} = this.props
        const rriResultProps = rows.map(this.prepareCommonRriResultProps)

        const currentRri = this.prepareCurrentRriResultProps()

        const scheduledProps = this.prepareScheduledProps()

        return [...currentRri, ...rriResultProps, ...scheduledProps]
    }

    renderItems = () => {
        if (!this.props.remoteInspection) {
            return null
        }

        const props = this.prepareProps()

        return props.map(props => (
            <CalendarItem {...props} />
        ))
    }

    get additionToBar() {
        return (
            <Bar className="remoteInspection-btns">
                <InitiateNowRemoteInspectionButton />
                <ScheduleRemoteInspectionButton />
            </Bar>
        )
    }

    onSelectDate = (date, view) => {
        const {fetch} = this.props

        this.setState({view})
        fetch({date: moment(date), view})
    }

    render() {
        const {date, isLoading} = this.props

        return (
            <Calendar
                date={date}
                isLoading={isLoading}
                onSelectDate={this.onSelectDate}
                additionToBar={this.additionToBar}
                sort={REVERSE}
            >
                {this.renderItems()}
            </Calendar>
        )
    }
}

export default withPanelRemoteInspection()(RemoteInspectionsCalendar)