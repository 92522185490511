import {delay} from 'redux-saga'
import {all, takeEvery, call, put, fork, take, cancel, select} from 'redux-saga/effects'
import get from 'lodash-es/get'

import * as actions from './actions'
import * as api from 'api/vod'

import {POLL_VIDEO_ON_DEMAND} from 'configs/pollers'
import {PROCESS_TYPE_PMAXFILMONDEMAND} from 'constants/processTypes'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import generateProcess from 'modules/processes/manager/generateProcess'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.start, watchStartVideo),
        takeEvery(actions.fetch, watchFetchVideo),
    ])
}

function* watchStartVideo({payload: {panelId, unitZoneId}}) {
    const {batchId} = yield generateBatchForOneProcess(
        PROCESS_TYPE_PMAXFILMONDEMAND,
        panelId
    )
    const data = yield select(state => get(state.panels.vod, `${panelId}, ${unitZoneId}`, {}))
    const {execute} = yield generateProcess(
        PROCESS_TYPE_PMAXFILMONDEMAND,
        panelId
    )

    yield put(actions.receive({
        process: {
            isRunning: true,
            started: new Date,
        },
        video: null,
    }, panelId, unitZoneId))

    try {
        yield execute(api.requestVideo, panelId, unitZoneId, batchId)

        yield fork(startPollVideoOnDemand, panelId, unitZoneId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.receive(data, panelId, unitZoneId))
    }
}

function* watchFetchVideo({payload: {panelId, unitZoneId}}) {
    yield put(actions.setLoading({panelId, unitZoneId}))

    try {
        const {process, video} = yield call(api.getVideoOnDemandInfo, panelId, unitZoneId)

        if (process && process.isRunning) {
            yield fork(startPollVideoOnDemand, panelId, unitZoneId)
        }

        yield put(actions.receive({
            process,
            video,
        }, panelId, unitZoneId))
    } catch (e) {
        yield put(snackShow(e.message))
    }
}

function* startPollVideoOnDemand(panelId, unitZoneId) {
    const task = yield fork(pollVideoOnDemand, panelId, unitZoneId)
    yield take(actions.erase)
    yield cancel(task)
}

function* pollVideoOnDemand(panelId, unitZoneId) {
    while (true) {
        yield delay(POLL_VIDEO_ON_DEMAND)

        try {
            const {process, video} = yield call(api.getVideoOnDemandInfo, panelId, unitZoneId)

            yield put(actions.receive({
                process,
                video,
            }, panelId, unitZoneId))

            if (!process.isRunning) {
                return
            }
        } catch (e) {
            yield put(snackShow(e.message))
        }
    }
}