import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'

import RemoteInspectionResults from 'components/RemoteInspection/Results'

import {__} from 'utils/i18n'

class RemoteInspectionsResultContent extends Component {
    static propTypes = {
        result: PropTypes.object,
        active: PropTypes.shape({
            progress: PropTypes.number,
        }),
    }

    render() {
        const {result, inspection} = this.props

        if (!inspection) {
            return (
                <Error title={__('Select Remote inspection')}/>
            )
        }

        if (inspection.progress !== null) {
            return (
                <Spinner message={__('Running')}/>
            )
        }

        if (!result) {
            return (
                <Error title={__('No Results')}/>
            )
        }

        return (
            <RemoteInspectionResults remoteInspectionResult={result}/>
        )
    }
}

export default compose(
    withRemoteInspectionsResult(),
)(RemoteInspectionsResultContent)