import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Checkbox from 'ipmp-react-ui/Checkbox'

class SupervisionSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            doSendEmailOnOnlineOffline: PropTypes.bool,
            doSendSmsOnOnlineOffline: PropTypes.bool,
            doSendOneChannelPanelOnlineOffline: PropTypes.bool,
            doSendTwoChannelPanelOnlineOffline: PropTypes.bool,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {
        }
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Supervision Settings')}
                confirmOnDismiss
                {...props}
            >
                <Checkbox
                    label={__('Send Notification Email on Online/Offline Event')}
                    name="doSendEmailOnOnlineOffline"
                    defaultChecked={data.doSendEmailOnOnlineOffline}
                />

                <Checkbox
                    label={__('Send Notification SMS on Online/Offline Event')}
                    name="doSendSmsOnOnlineOffline"
                    defaultChecked={data.doSendSmsOnOnlineOffline}
                />

                <Checkbox
                    label={__('Generate `SYSTEM OFFLINE/ONLINE` Events for One-Channel Panels')}
                    name="doSendOneChannelPanelOnlineOffline"
                    defaultChecked={data.doSendOneChannelPanelOnlineOffline}
                />

                <Checkbox
                    label={__('Generate `SYSTEM OFFLINE/ONLINE` Events for Two-Channel Panels')}
                    name="doSendTwoChannelPanelOnlineOffline"
                    defaultChecked={data.doSendTwoChannelPanelOnlineOffline}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(setSystemSettings.Supervision)
)(SupervisionSettings)

