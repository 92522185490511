import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showMarkForServiceModal} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import withProps from 'containers/withProps'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {markForService} from 'permissions/panels/selection'

export const MarkForServiceItem = compose(
    withPermission({isVisible: markForService}),
    withVisibility(),
    withSelectionHandler(),
    withProps(() => ({
        children: __('Mark for service'),
    }))
)(MenuItem)

export default connect(
    null,
    dispatch => bindActionCreators({
        onClick: showMarkForServiceModal,
    }, dispatch),
)(MarkForServiceItem)