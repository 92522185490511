import {compose} from 'redux'

import withReports from 'containers/withReports'
import withProps from 'containers/withProps'

import IconMarkViewed from 'ipmp-react-ui/icons/viewed.svg'
import {ActionCell} from 'ui/TableCells'

export default compose(
    withReports(),
    withProps(({review, row: {id, status, isReviewed}}) => ({
        isExported: status && isReviewed,
        disabled: !status,
        onClick: () => review(id),
        Icon: IconMarkViewed,
        isChecked: isReviewed,
    })),
)(ActionCell)