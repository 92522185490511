import {all} from 'redux-saga/effects'

import list from './list/saga'
import store from './store/saga'
import transportEmails from './transportEmails/saga'

export default function* () {
    yield all([
        list(),
        store(),
        transportEmails(),
    ])
}