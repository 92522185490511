import instance from 'api/http'

import apiFormat from 'api/interceptors/apiFormat'
import acceptLanguage from 'api/interceptors/acceptLanguage'
import translatedErrors from 'api/interceptors/translatedErrors'

export default function interceptors() {
    apiFormat(instance)
    acceptLanguage(instance)
    translatedErrors(instance)
}