import {selectPerPage} from 'modules/settings/selectors'
import {takeEvery, all, put} from 'redux-saga/effects'

import * as actions from './actions'
import {update, purge} from 'modules/groups/store/actions'
import * as api from 'api/groups'

import {snackShow} from 'modules/snacks'
import {addGroup} from 'modules/forms/handlers'
import listSaga from 'modules/higherOrder/createListSaga'

import {__} from 'utils/i18n'

const selector = (state) => ({
    ...state.groups.list,
    perPage: selectPerPage(state, 'groups'),
    listRoute: 'groups',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        takeEvery(addGroup.SUCCESS, watchAdd),
    ])
}

function* watchAdd() {
    yield put(actions.fetch())
    yield put(snackShow(__('Group was created successfully')))
}