import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import classes from 'classnames'

import {MODULE_TYPE_GPRS, MODULE_TYPE_BBA} from 'constants/moduleTypes'

import {panelImage} from 'constants/panelType'
import TroublesList from 'components/Devices/TroublesList'
import DeviceModule, {DeviceModules} from './DeviceModule'

export default class Panel extends Component {

    static propTypes = {
        panel: PropTypes.shape({
            serial: PropTypes.string,
            modules: PropTypes.shape({
                bba: PropTypes.shape({
                    connected: PropTypes.bool,
                    state: PropTypes.string,
                }),
                gprs: PropTypes.shape({
                    connected: PropTypes.bool,
                    state: PropTypes.string,
                }),
            }).isRequired,
            info: PropTypes.shape({
                model: PropTypes.string,
            }).isRequired,
        }).isRequired,
        onSelect: PropTypes.func,
        active: PropTypes.bool,
        className: PropTypes.string,
    }

    getRssiLevel = () => {
        const {panel} = this.props

        return get(panel, 'gsm.traits.signal_level')
    }

    render() {
        const {panel, onSelect, active, className} = this.props
        const {info, serial, modules, warnings} = panel
        const image = panelImage(info && info.model)

        return (
            <div className={classes('device device--panel', className, {
                'device--active': active,
            })} onClick={onSelect}>
                <div className="device-image">
                    <div className="device-image-src" style={{backgroundImage: `url(${image})`}}></div>
                </div>

                <div className="device-content">
                    <div className="hint device-hint">
                        <div className="device-hint-content">{serial}</div>
                    </div>

                    <div className="device-title">
                        {info && info.model}
                    </div>

                    <TroublesList warnings={warnings}/>
                </div>

                <DeviceModules>
                    <DeviceModule
                        moduleType={MODULE_TYPE_BBA}
                        module={modules && modules.bba}/>

                    <DeviceModule
                        moduleType={MODULE_TYPE_GPRS}
                        module={modules && modules.gprs}
                        rssiLevel={this.getRssiLevel()}/>
                </DeviceModules>
            </div>
        )
    }
}