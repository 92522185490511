import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withUpgradablePanels from 'containers/withUpgradablePanels'

import selection from 'permissions/firmware/selection'

import SelectionDropDown from 'components/SelectionDropDown'
import {withRouter} from 'react-router'
import withProps from 'containers/withProps'

export default compose(
    withRouter,
    withPermission({isVisible: selection}),
    withVisibility(),
    withSelection(),
    withUpgradablePanels(),
    withProps({
        isSelectEverythingAvailabel: true,
    })
)(SelectionDropDown)