import {select, all, call, put, takeEvery} from 'redux-saga/effects'
import {selectPerPage} from 'modules/settings/selectors'
import listSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import isEqual from 'lodash-es/isEqual'
import {createMatchSelector} from 'react-router-redux'

import * as api from 'api/runners'
import {fetch as panelsFetch} from 'api/panels'

import * as actions from './actions'
import {update, purge} from 'modules/runners/store/actions'
import {update as updatePanles} from 'modules/panels/store/actions'
import {POLL_RUNNERS_LIST} from 'configs/pollers'
import toIds from 'utils/toIds'
import {snackShow} from 'modules/snacks'
import get from 'lodash-es/get'

const batchIdMatchSelector = createMatchSelector(
    '/batches/:batchId/runners'
)

const selector = (state) => {
    const match = batchIdMatchSelector(state)
    const batchId = get(match, 'params.batchId', null)

    return {
        ...state.runners.list,
        perPage: selectPerPage(state, 'runners'),
        batchId,
        listRoute: 'runners',
    }
}

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_RUNNERS_LIST, pollRunners),
        takeEvery(actions.receive, watchRunnersReceive)
    ])
}

function* pollRunners() {
    const state = yield select(selector)
    const batchId = state.batchId

    try {
        const {count, rows} = yield call(api.fetch, {batchId, ...state})

        const byIds = yield select(state => state.runners.store.byIds)

        const updatedRows = rows.filter(
            row => !byIds.hasOwnProperty(row.id) || !isEqual(byIds[row.id], row),
        )

        yield put(update(updatedRows))
        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

// Needs info about panels for operations like "Service"
function* watchRunnersReceive({payload: {page, total}, error}) {
    if (error) {
        return
    }

    const runnersByIds = yield select(state => state.runners.store.byIds)
    const panelsIds = page.map(runnerId => runnersByIds[runnerId].panelId)

    try {
        const {rows} = yield call(panelsFetch, {filters: {
            id: panelsIds,
        }})

        const store = yield select(state => state.panels.store.byIds)

        const updatedRows = rows.filter(
            row => !isEqual(store[row.id], row),
        )

        if (updatedRows.length > 0) {
            yield put(updatePanles(updatedRows))
        }
    } catch (error) {
        yield put(snackShow(error))
    }
}