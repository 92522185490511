import React from 'react'

import Input from 'ipmp-react-ui/Input'
import {__} from 'utils/i18n'
import StringValue from 'components/Configuration/Row/StringValue'

const regexp = /^\d*$/

export default class CodeValue extends StringValue {

    state = {}

    getMaxLength() {
        return super.getMaxLength() || '0000'.length
    }

    validate(value) {
        if (!this.props.isChanged) {
            return
        }

        if (value) {
            return this.validateCharaters(value) || super.validate(value)
        }

        if (this.isRequired()) {
            return __('Required')
        }
    }

    validateCharaters(value) {
        if (!regexp.test(value)) {
            return __('Should contains only digits')
        }
    }

    sanitize(value) {
        return value.toUpperCase()
    }

    getValue() {
        if (this.state.focus) {
            return this.state.value
        }

        return super.getValue()
    }

    handleInputChange = (e) => {
        this.setState({value: e.target.value})
        this.handleChange(e)
    }

    handleFocus = e => {
        this.setState({focus: true})

        if (!this.props.isChanged) {
            this.setState({value: ''})
        }
    }

    handleBlur = e => {
        this.setState({focus: false})
    }

    render() {
        const value = this.getValue()
        const maxLength = this.getMaxLength()

        return (
            <Input
                value={value}
                secure
                placeholder={__('Not set')}
                maxLength={maxLength}
                onChange={this.handleInputChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                error={this.validate(value)}
            />
        )
    }

}