import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {download} from 'permissions/panel/configuration/actions'

import {
    refresh,
    dismissChanges,
    clearFilters,
    setMakeBasic,
    setExport,
    setQuery,
    setShowExportable,
    setShowChanges,
    fetch,
    fetchOne,
    changeValue,
    restoreBackup,
    refreshRunner
} from 'modules/panels/configuration/actions'
import {
    selectBackupConfiguration,
    selectBackupConfigurationValues,
    selectCurrentConfiguration,
} from 'modules/panels/configuration/selectors'
import {
    startPollRunnerData,
    stopPollRunnerData,
} from 'modules/runners/actions'


import {PROCESS_TYPE_PMAXCONFIGUPLOAD} from 'constants/processTypes'
import {__} from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRunnerLoader from 'containers/withRunnerLoader'
import {withFeatureRejection} from './withFeature'
import {selectDownloadConfigutionFeature} from 'modules/features/store/selectors'
import Void from 'components/Void'

const DownloadButton = compose(
    withPermission({isVisible: download}),
    withVisibility(),
    withFeatureRejection(selectDownloadConfigutionFeature, Void)
)(Button)

export function withConfigurationActions() {
    return connect(
        null,
        (dispatch, {panelId}) =>
            bindActionCreators({
                refresh: (...arg) => refresh(panelId, ...arg),
                onChange: (...arg) => changeValue(panelId, ...arg),
                onSetExport: (...arg) => setExport(panelId, ...arg),
                onSearch: (...arg) => setQuery(panelId, ...arg),
                setMakeBasic: (...arg) => setMakeBasic(panelId, ...arg),
                setShowExportable: (...arg) => setShowExportable(panelId, ...arg),
                setShowChanges: (...arg) => setShowChanges(panelId, ...arg),
                dismissChanges: () => dismissChanges(panelId),
                clearFilters: () => clearFilters(panelId),
                restoreBackup: (...arg) => restoreBackup(panelId, ...arg),
                startPollRunnerData,
                stopPollRunnerData,
                refreshRunner,
            }, dispatch),
    )
}

export default function withCurrentConfiguration() {
    return connect(
        (state, props) => selectCurrentConfiguration(state, props),
        (dispatch, {panelId}) =>
            bindActionCreators({
                fetch: () => fetch(panelId),
            }, dispatch),
    )
}

export function withCompareConfiguration() {
    return connect(
        (state, props) => {
            const configuration = selectCurrentConfiguration(state, props)
            const backup = selectBackupConfigurationValues(state, props)

            if (!backup) {
                return {}
            }

            return {
                ...configuration,
                backup,
            }
        },
        (dispatch, {panelId, configId}) =>
            bindActionCreators({
                fetch: () => fetchOne(panelId, configId),
            }, dispatch),
    )
}

export function withBackupConfiguration() {
    return connect(
        (state, props) => selectBackupConfiguration(state, props),
        (dispatch, {panelId, configId}) =>
            bindActionCreators({
                fetch: () => fetchOne(panelId, configId),
            }, dispatch),
    )
}

export function withConfigurationPreloader({isErrorCallback, buttonProps}) {
    return WrappedComponent => {
        class EmptyConfigurationHandler extends Component {
            componentDidUpdate() {
                const {outdated, fetch} = this.props
                outdated && fetch()
            }

            render() {
                const {refresh, panelId} = this.props

                if (isErrorCallback(this.props)) {
                    return (
                        <Error title={__('System Configuration not available yet')}>
                            <DownloadButton panelId={panelId}
                                onClick={refresh} {...buttonProps}
                            >
                                {__('Download now')}
                            </DownloadButton>
                        </Error>
                    )
                }

                return <WrappedComponent {...this.props}/>
            }
        }

        return compose(
            withRunnerLoader(
                ({type}) => (
                    type === PROCESS_TYPE_PMAXCONFIGUPLOAD
                        ? __('Configuration is uploading')
                        : __('Configuration is downloading')
                ),
                ({fetch}) => fetch(),
                buttonProps
            )
        )(EmptyConfigurationHandler)
    }
}
