import {combineReducers, compose} from 'redux'

import list from './list/reducer'
import walktest from './walktest/reducer'
import reference from './reference/reducer'
import meteo from './meteo/reducer'
import vod from './vod/reducer'
import createPurgeReducer from 'modules/higherOrder/createPurgeReducer'
import {purgeStore} from 'modules/panels/one/actions'

const purgeDevicesStoreReducer = (reducer) => createPurgeReducer(
    reducer,
    purgeStore
)

const devicesReducer = combineReducers({
    list,
    walktest,
    reference,
    meteo,
    vod,
})

export default compose(
    purgeDevicesStoreReducer,
)(devicesReducer)