import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {showSystemSettingsCellularConnectedModal} from 'modules/modals/actions'

import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    connect(
        ({system: {settings: {cellularConnected}}}) => (cellularConnected),
        dispatch => bindActionCreators({
            modal: showSystemSettingsCellularConnectedModal,
        }, dispatch),
        (props, {modal}) => {
            return {
                onClick: () => modal({
                    ...props,
                }),
            }
        },
    ),
    withProps(() => ({
        borderless: true,
        primary: true,
        small: true,
        label: __('Edit'),
    })),
)(Button)