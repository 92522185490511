import {categoryByDeviceType} from 'constants/deviceCategory'
import {isRemovable, isRenameable} from 'constants/panelModules'
import {PROCESS_TYPE_PMAXANONYMOUS, PROCESS_TYPE_SET_LABEL} from 'constants/processTypes'
import sortBy from 'lodash-es/sortBy'

import {post, get} from 'api/http'
import {mapProcess, mapProcessBatch} from 'api/processes'
import mapValidationErrors from 'api/base/mapValidationErrors'

import {SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE} from 'constants/severityType'
import {BAD_REQUEST_PARAMS} from 'constants/errorType'
import {VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'
import {DEVICE_TYPE_CONTROL_PANEL, DEVICE_TYPE_GSM} from 'constants/deviceType'
import {isDeviceOpened} from 'constants/troubleType'

import mapper from 'utils/dataMapper'
import {TEMPERATURE} from 'constants/meteoTypes'

const mapPanelInfo = mapper(data => ({
    serial: data.serial,
    info: {
        model: data.model,
    },
    firmware: data.firmware,
}))

const mapPanelState = mapper(data => ({
    modules: {
        bba: data.bba,
        gprs: data.gprs,
    },
}))

const mapPanelRelatedDevices = devices => {
    const controlPanel = devices.find(({device_type: deviceType}) => deviceType === DEVICE_TYPE_CONTROL_PANEL) || {}
    const gsm = devices.find(({device_type: deviceType}) => deviceType === DEVICE_TYPE_GSM) || {}

    return {
        warnings: [...mapWarnings(controlPanel.warnings), ...mapWarnings(gsm.warnings)],
        ...(gsm.traits && gsm.traits.rssi ? {gprsRssi: gsm.traits.rssi} : {}),
    }
}

const mapDevice = mapper(data => {
    const zoneType = data.zone_type && data.zone_type.toUpperCase && data.zone_type.toUpperCase()
    const deviceType = data.device_type && data.device_type.toUpperCase && data.device_type.toUpperCase().replace(' ', '_')
    const traits = data.hasOwnProperty('traits') ? data.traits : {}

    return {
        id: parseInt(data.id),
        name: data.name,
        zone: data.device_number,
        deviceId: data.enrollment_id,
        deviceType,
        deviceTypeId: parseInt(data.device_type_id),
        category: categoryByDeviceType(deviceType),
        zoneType,
        subtype: data.subtype,
        preenroll: data.preenroll,
        enrolling: data.enrolling,
        removing: data.removing,
        partitions: data.partitions,
        traits,
        warnings: mapWarnings(data.warnings, zoneType),
        open: data.warnings ? isDeviceOpened(data.warnings) : false,
        hasTroubles: !!(data.warnings && data.warnings.length),
        isRemovable: isRemovable(deviceType, data.subtype),
        isRenameable: isRenameable(deviceType),
    }
})

const mapReference = mapper(data => ({
    code: data.code,
    subtype: data.subtype,
    users: parseInt(data.users),
    partitions: parseInt(data.partitions),
}))

const mapWarning = mapper((warning, zoneType) => ({
    comment: warning.comment,
    inMemory: warning.in_memory,
    severity: warning.severity,
    type: warning.type,
    zoneType,
}))

const warningsOrder = [SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE]

const mapWarnings = (warnings, zoneType) => {
    if (!warnings || warnings.length === 0) {
        return []
    }

    return sortBy(
        warnings.map(warning => mapWarning(warning, zoneType)),
        warning => warningsOrder.indexOf(warning.severity),
    )
}

const getGsm = (devices) => {
    const filtered = devices.filter(device => device.device_type === DEVICE_TYPE_GSM)

    return filtered.length ? filtered[0] : null
}

const map = ({panelInfo, panelState, devices, rssiRefreshProcess, gsmRefreshProcess}) => ({
    panel: {
        ...mapPanelInfo(panelInfo),
        ...mapPanelState(panelState),
        ...mapPanelRelatedDevices(devices),
        gsm: getGsm(devices),
    },
    devices: devices.filter(device => device.device_type !== DEVICE_TYPE_GSM).map(mapDevice),
    rssiRefreshProcess: rssiRefreshProcess && mapProcess(rssiRefreshProcess),
    GSMRefreshProcess: gsmRefreshProcess && mapProcess(gsmRefreshProcess),
})

export function retrieveDevices(unitId) {
    return get('/unit_diagnostic/list', {unt_id: unitId}).then(map)
}

export function setBypassZones(zoneIds, enabled, batchId) {
    return post('/unit_diagnostic/setbypasszones', {
        utz_id: zoneIds,
        enabled,
        batch_id: batchId,
    }).then(mapProcessBatch)
}

export function setSoakZone(deviceId, enabled, batchId) {
    return post('/unit_diagnostic/setsoakzone', {
        utz_id: deviceId,
        enabled,
        batch_id: batchId,
    }).then(mapProcess)
}

export function removeDevice(deviceId, batchId) {
    return post('/unit_diagnostic/zoneremove', {
        utz_id: deviceId,
        batch_id: batchId,
    })
        .then(mapProcess)
}

export function setRarelyUsedZone(panelId, deviceId, state) {
    return post('/unit_diagnostic/rarelyused', {
        unt_id: panelId,
        utz_id: deviceId,
        utz_rarely_used: state,
    })
}

export function getWalktestStatus(unitId) {
    return get('/unit_diagnostic/walktest', {unt_id: unitId})
        .then(({process, ...data}) => ({
            process: process && mapProcess(process),
            ...data,
        }))
}

export function startWalktest(unitId, batchId) {
    return post('/unit_diagnostic/walkteststart', {
        unt_id: unitId,
        batch_id: batchId,
    })
        .then(mapProcess)
}

export function stopWalktest(unitId) {
    return post('/unit_diagnostic/walkteststop', {unt_id: unitId})
}

export function addDevice(panelId, zoneId, deviceId, partitions, userId, batchId) {
    const form = {
        deviceId,
        zoneId,
    }
    if (partitions) {
        form.partitions = partitions
    }
    if (userId) {
        form.userId = userId
    }

    return post('/unit_diagnostic/zoneadd', {
        unt_id: panelId,
        form,
        batch_id: batchId,
    }).then(({process, device}) => ({
        process: mapProcess(process),
        device: mapDevice(device),
    })).catch(mapValidationErrors)
}

export function refreshZoneRssi(panelId, batchId) {
    return post('/unit_diagnostic/zonerssi', {
        unt_id: panelId,
        batch_id: batchId,
    })
        .then(mapProcess)
}

export function refreshGSM(panelId, batchId) {
    return post('/unit_diagnostic/refreshgsm', {
        unt_id: panelId,
        batch_id: batchId,
    })
        .then(mapProcess)
}

export function fetchMeteo(panelId, deviceId, type = TEMPERATURE) {
    return get(`/unit_diagnostic/${type}`, {
        unt_id: panelId,
        utz_id: deviceId,
        period: 'all',
    })
}

export function renameEntity(panelId, entityClass, entityId, name, batchId) {
    return post('/unit_diagnostic/setlabel', {
        unitId: panelId,
        entityClass,
        entityId,
        name: name === null ? '' : name,
        batch_id: batchId,
    })
        .then(process => mapProcess(process))
        .then(process => {
            // workaround
            if (process.type === PROCESS_TYPE_PMAXANONYMOUS) {
                process.type = PROCESS_TYPE_SET_LABEL
            }

            return process
        })
        .catch(mapValidationErrors)
}

export function renamePartition(panelId, partitionId, name, batchId) {
    return renameEntity(panelId, 'partition', partitionId, name, batchId)
}

const mapVendor = panelType => {
    switch (panelType) {
        case VENDOR_NEO:
            return 'neo'
        case VENDOR_POWER_MASTER:
            return 'pmax'
    }

    throw BAD_REQUEST_PARAMS
}

export function fetchDeviceReferences(vendor) {
    return get('/unit_diagnostic/devicereferences', {
        vendor: mapVendor(vendor),
    }).then(references => references.map(mapReference))
}