import {combineActions, handleActions} from 'redux-actions'

import {
    receive,
    reset,
    setPerPage,
    fetchNewer,
    fetchOlder,
    setHasNewer,
    setHasOlder,
    setNewerCount,
    fetchRecent,
} from './actions'

const defaultState = {
    error: null,
    start: 0,
    page: [],
    perPage: 10,
    total: null,
    newerCount: 0,
}

export default handleActions({
    [reset](state, {payload}) {
        return {
            ...defaultState,
            isLoading: true,
            serial: payload,
        }
    },

    [combineActions(fetchNewer, fetchRecent)](state) {
        return {
            ...state,
            isNewerLoading: true,
        }
    },

    [fetchOlder](state) {
        return {
            ...state,
            isOlderLoading: true,
        }
    },

    [setHasNewer](state, {payload: {hasNewer}}) {
        return {
            ...state,
            hasNewer,
            isNewerLoading: false,
        }
    },

    [setHasOlder](state, {payload: {hasOlder}}) {
        return {
            ...state,
            hasOlder,
            isOlderLoading: false,
        }
    },

    [receive](state, {payload, error}) {
        if (error) {
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
        }

        const page = payload.ids
            .filter(id => state.page.indexOf(id) === -1)
            .concat(state.page)
            .sort((a, b) => b - a)

        return {
            ...state,
            page,
            isLoading: false,
            error: null,
        }
    },

    [setPerPage](state, {payload}) {
        return {
            ...state,
            total: null,
            perPage: payload,
        }
    },

    [setNewerCount]: (state, {payload: {newerCount}}) => ({
        ...state,
        newerCount,
        isNewerLoading: false,
    }),

}, defaultState)