import {PROCESS_ERROR_CANCELED_BY_REQUEST} from 'constants/processError'
import {all, takeEvery, select, put, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/runners'

import {refreshState} from 'modules/panels/store/actions'
import {refresh as refreshConfiguration} from 'modules/panels/configuration/actions'
import {initiateNow} from 'modules/remoteInspections/store/actions.js'
import {
    showPushBasicConfigurationModal,
    showChangePanelGroupModal,
    showMarkForServiceModal,
    showCreateReportModal,
    showTriggerDiscoveryModal
} from 'modules/modals/actions'

import {snackShow} from 'modules/snacks'

import {__n} from 'utils/i18n'

const proxyActionsMap = new Map([
    [actions.refreshState, refreshState],
    [actions.showTriggerDiscoveryModal, showTriggerDiscoveryModal],
    [actions.refreshConfiguration, refreshConfiguration],
    [actions.showPushBasicConfigurationModal, showPushBasicConfigurationModal],
    [actions.showChangePanelGroupModal, showChangePanelGroupModal],
    [actions.showMarkForServiceModal, showMarkForServiceModal],
    [actions.showCreateReportModal, showCreateReportModal],
    [actions.initiateNow, initiateNow]
])

export default function* () {
    yield all([
        takeEvery(actions.stop, watchStop),
        ...createProxyForPanelServices(proxyActionsMap)
    ])
}

function createProxyForPanelServices(proxyActionsMap) {
    const actionWatchers = []

    for (const entry of proxyActionsMap) {
        const proxyAction = entry[0]
        const originalAction = entry[1]

        actionWatchers.push(takeEvery(
            proxyAction,
            createCommonProxy(originalAction)
        ))
    }

    return actionWatchers
}

const createCommonProxy = (action) => {
    return function* commonProxy({payload: runnerIds}) {
        const byIds = yield select(state => state.runners.store.byIds)
        const panelIds = runnerIds.map(runnerId => byIds[runnerId].panelId)

        yield put(action(panelIds))
    }
}

function* watchStop({payload: runnerIds}) {
    const toStop = yield select(
        state => runnerIds.map(id => state.runners.store.byIds[id]).filter(runner => runner && runner.isStoppable),
    )

    if (!toStop.length) {
        return
    }

    yield put(actions.update(
        toStop.map(runner => ({
            id: runner.id,
            error: PROCESS_ERROR_CANCELED_BY_REQUEST,
            errorMessage: 'Cancelled',
            isStoppable: false,
            isRunning: false,
            isFailed: true,
            finishedAt: new Date,
        })),
    ))

    try {
        const result = yield call(api.stop, toStop.map(runner => runner.id))
        const partiallyProcessedCount = Object.values(result).filter((value) => !value).length
        if (partiallyProcessedCount) {
            yield put(snackShow(__n(
                'Partially processed',
                'Partially processed %d processes',
                partiallyProcessedCount
            )))
        }
        yield put(actions.resultStop(Object.keys(result)))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(toStop))
    }
}