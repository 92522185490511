import {post} from 'api/http'

export function enable(panelId) {
    return post('/unit_keypad/enable', {unt_id: parseInt(panelId)})
}

export function disable(panelId) {
    return post('/unit_keypad/disable', {unt_id: parseInt(panelId)})
}

const wsProtocol = location.protocol === 'https:' ? 'wss:' : 'ws:'
const {host} = location

export function startSession(serial, token) {
    return new WebSocket(`${wsProtocol}//${host}/vkpad/${serial}/${token}`)
}