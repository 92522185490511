import React, {Component} from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import {set, remove} from 'immutable-modify'

import deviceType from 'constants/deviceType'
import {troubleTitle} from 'constants/troubleType'

import Checkbox from 'ipmp-react-ui/Checkbox'
import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'

class FaultRow extends Component {

    handleChange = (e) => {
        const {onChange, fault} = this.props
        onChange(fault.id, e.target.checked)
    }

    render() {
        const {fault, checked, panel} = this.props
        const type = fault.deviceType ? deviceType(fault.deviceType) : ''
        const zone = fault.zone ? ' #' + fault.zone : ''

        const detail = panel.serial + (panel.account ? ' / ' + panel.account : '')

        return (
            <li className="faultChecklist-row">
                <Checkbox name={'faultId[]'} value={fault.id} checked={checked} onChange={this.handleChange}>
                    <Definition title={troubleTitle(fault.type)} detail={detail}/>
                </Checkbox>

                <div className="faultChecklist-row-text">
                    {fault.comment
                        ? nl2br(fault.comment)
                        : (type + zone)
                    }
                </div>
            </li>
        )
    }
}

class FaultChecklist extends Component {

    static propTypes = {
        panels: PropTypes.array.isRequired,
        emptyMessage: PropTypes.string.isRequired,
        filter: PropTypes.func,
    }

    static defaultProps = {
        filter: () => true,
    }

    state = {
        active: {},
    }

    handleSelectAllChange = e => {
        const active = {}
        const {panels, filter} = this.props

        if (e.target.checked) {
            panels.forEach(
                panel => panel.faults
                    .filter(filter)
                    .forEach(
                        fault => active[fault.id] = true,
                    ),
            )
        }

        this.setState({active})
    }

    handleChange = (id, enabled) => {
        this.setState(
            ({active}) => ({
                active: enabled
                    ? set(active, id, true)
                    : remove(active, id),
            }),
        )
    }

    render() {
        const {active} = this.state
        const {panels, filter, emptyMessage} = this.props

        const faults = panels.reduce(
            (acc, panel) => acc.concat(
                panel.faults.filter(filter).map(
                    fault => (
                        <FaultRow
                            key={fault.id}
                            checked={active[fault.id] || false}
                            onChange={this.handleChange}
                            fault={fault}
                            panel={panel}
                        />
                    ),
                ),
            ),
            [],
        )

        if (!faults || !faults.length) {
            return <div className="faultChecklist">{emptyMessage}</div>
        }

        const selectedCount = Object.keys(active).length

        return (
            <div className="faultChecklist">
                <Checkbox
                    checked={selectedCount === faults.length}
                    indeterminate={selectedCount > 0}
                    onChange={this.handleSelectAllChange}
                    label={__('All')}
                />

                <ul className="form-scroll">
                    {faults}
                </ul>
            </div>
        )
    }
}

export default FaultChecklist
