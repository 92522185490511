import {all, takeEvery, put, select} from 'redux-saga/effects'
import {editGroup} from 'modules/forms/handlers'
import {update} from './actions'

export default function*() {
    yield all([
        takeEvery(editGroup.SUCCESS, watchEditGroup)
    ])
}

function* watchEditGroup({meta}) {
    const languages = yield select(state => state.languages.byIds)
    const serverMessagingLanguage = languages[meta.serverMessagingLanguageId].name

    yield put(update({serverMessagingLanguage, ...meta}))
}