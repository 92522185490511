import {compose} from 'redux'
import {parse} from 'query-string'

import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'

export function withAutoFilters() {
    return withProps(
        ({history}) => {
            if (!history) {
                return {}
            }

            const search = parse(history.location.search) || {}

            const filters = Object.keys(search).map(
                filter => ({
                    $: filter + '$' + search[filter],
                    name: filter,
                    value: search[filter],
                }),
            )

            return {filters}
        },
    )
}

export function withSearchScope() {
    const dispatchInit = ({init, scope, history, filters}) => {
        init(scope, history.action, filters)
    }

    return compose(
        withAutoFilters(),

        withProps(({match}) => ({
            scope: match && match.params && match.params.scope,
        })),

        withLifeCycle({
            onMount: dispatchInit,

            onReceiveProps(props, {scope}) {
                if (props.scope !== scope) {
                    dispatchInit(props)
                }
            },
        }),
    )
}

export default function withSearchScopeLoader() {
    return compose(
        withSearchScope(...arguments),
        withLoader(),
    )
}
