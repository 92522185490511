import {
    combineReducers
} from 'redux'

import list from './list/reducer'
import store from './store/reducer'
import results from './results/reducer'
import values from './values/reducer'

export default combineReducers({
    list,
    store,
    results,
    values,
})