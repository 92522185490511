import React from 'react'
import {Route} from 'react-router'

const defaultMergeProps = (props, {match, history}) => ({
    ...props,
    match,
    history,
})

export default function withRoute(
    path = null,
    mergeProps: (props: Object, routeProps: Object) => Object = defaultMergeProps,
) {
    return WrappedComponent => (props) => {
        return (
            <Route path={path || props.path || props.to}>
                {routeProps => <WrappedComponent {...mergeProps(props, routeProps)}/>}
            </Route>
        )
    }
}