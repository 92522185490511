import {post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

const mapFormData = ({phone, countryId})=> ({
    usr_phone: phone,
    coy_id: countryId,
})

const mapPasswordData = ({currentPassword, newPassword, newPasswordConfirmation})=> ({
    password: currentPassword,
    usr_password: newPassword,
})

export const keysMap = {
    phone: 'usr_phone',
    countryId: 'coy_id',
}


export const passwordKeysMap = {
    currentPassword: 'password',
    newPassword: 'usr_password',
}

export const editProfile = (data) => post('/settings/edit', mapFormData(data))
    .catch(e => mapValidationErrors(e, keysMap))

export const changePassword = (data) => post('/settings/changepassword', mapPasswordData(data))
    .catch(e => mapValidationErrors(e, passwordKeysMap))