import {createSelector} from 'reselect'

import {
    PROCESS_TYPE_DISABLEPGM,
    PROCESS_TYPE_EDITPGM,
} from 'constants/processTypes'

import {selectRunningProcessesByType} from 'modules/processes/manager/selectors'

export const selectPGMProcesses = createSelector(
    state => selectRunningProcessesByType(state, [PROCESS_TYPE_DISABLEPGM]),
    state => selectRunningProcessesByType(state, [PROCESS_TYPE_EDITPGM]),
    (state, PGMId) => PGMId,
    (disableProcesses, editProcesses, PGMId) => ({
        isDisabling: inProcess(editProcesses, PGMId),
        isSaving: inProcess(editProcesses, PGMId),
    })
)

function inProcess(processes, PGMId) {
    return Array.isArray(processes) && !!processes.length
    //return processes.some(({details}) => details.id === PGMId)
}

export const selectPGMIsSaving = createSelector(
    [
        selectPGMProcesses,
    ],
    ({isSaving}) => isSaving
)

export const selectPGMIsDisabling = createSelector(
    [
        selectPGMProcesses,
    ],
    ({isDisabling}) => isDisabling
)
