import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import __ from 'utils/i18n'

import withForm from 'containers/withForm'
import {
    setLoginScreenForm,
} from 'modules/auth/sign/actions'
import {remindPassword} from 'modules/forms/handlers'
import {LOGIN_SCREEN} from 'constants/loginScreen'

const rules = {
    email: {
        presence: true,
        email: true,
    },
}

class ForgotPasswordForm extends Component {

    static propTypes = {
        handle: PropTypes.func.isRequired,
        setLoginScreenForm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    }

    handleSubmit = ({email}) => {
        this.props.handle({email})
    }

    handleLogin = () => {
        this.props.setLoginScreenForm(LOGIN_SCREEN)
    }

    render() {
        const {isLoading, errors} = this.props

        return (
            <Form
                className="card login-form"
                isLoading={isLoading}
                rules={rules}
                errors={errors}
                onSubmit={this.handleSubmit}>

                <CardHeader onBack={this.handleLogin}>
                    {__('Forgot Password')}
                </CardHeader>

                <CardContent dir="ltr">
                    <Input
                        name="email"
                        label={__('Email')}
                        autocomplete={'off'}
                        />
                </CardContent>

                <CardActions>
                    <Button primary disabled={isLoading} type="submit">{__('Remind')}</Button>
                </CardActions>
            </Form>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}}) =>({...sign}),
        (dispatch) => bindActionCreators({
            setLoginScreenForm,
            remindPassword,
        }, dispatch)
    ),
    withForm(remindPassword),
)(ForgotPasswordForm)