import React, {Component} from 'react'

import getHocName from 'utils/getHocName'
import {__} from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import ProcessLoader from 'ipmp-react-ui/ProcessLoader'
import processErrorMessage from 'constants/processError'

export default function withRunnerLoader(
    messageBuilder: ? (process: Object) => String,
    onSuppress: ? (props: Object) => any,
    buttonProps: ? Object,
) {

    if (!messageBuilder) {
        messageBuilder = () => __('Synchronizing with panel')
    }

    return WrappedComponent => class extends Component {
        displayName = getHocName('withRunnerLoader', WrappedComponent)

        handleSuppress = () => {
            onSuppress && onSuppress(this.props)
        }

        render() {
            const {runner, ...props} = this.props
            if (!runner || runner.isSuccessful) {
                return <WrappedComponent {...props}/>
            }

            if (runner.isFailed) {
                return (
                    <Error title={__('Process failed')}
                            message={processErrorMessage(runner.error, runner.errorMessage)}>
                        {onSuppress &&
                        <Button onClick={this.handleSuppress} {...buttonProps}>{__('Suppress')}</Button>}
                    </Error>
                )
            }

            return (
                <ProcessLoader
                    message={messageBuilder(runner)}
                    percentage={runner.percentage}
                    details={runner.details}
                />
            )
        }
    }
}
