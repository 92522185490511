import 'css-reset-and-normalize-sass'

import React from 'react'
import ReactDOM from 'react-dom'
import {AppContainer} from 'react-hot-loader'

import App from './pages/App'

// import it after the App!
import 'styles/index.sass'

import {createBrowserHistory as createHistory} from 'history'
import createAppStore from './modules/createAppStore'

import 'configs/init'

const history = createHistory()
const store = createAppStore(history)

const root = document.getElementById('root')
let key = 0

const render = Component => {
    ReactDOM.render(
        <AppContainer>
            <Component key={key++} store={store} history={history}/>
        </AppContainer>,
        root
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./pages/App', () => {
        console.log('next')
        const NextApp = require('./pages/App').default
        render(NextApp)
    })
}
