import {takeEvery, all, put, call} from 'redux-saga/effects'
import {getValues} from 'api/remoteInspection'
import {snackShow} from 'modules/snacks'
import {fetch, setLoading, update} from './actions'

export default function* () {
    yield all([
        takeEvery(fetch, watchFetch)
    ])
}

function* watchFetch() {
    yield put(setLoading(true))

    try {
        const values = yield call(getValues)
        yield put(update(values))
        yield put(setLoading(false))
    } catch ({message}) {
        yield put(setLoading(false))
        yield put(snackShow(message))
    }
}