import {compose} from 'redux'

import {schedule} from 'permissions/remoteInspections/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import ScheduleOrCancelRRI from 'components/Cell/RemoteInspections/Actions/ScheduleOrCancelRRI'
import withRemoteInspections from 'containers/withRemoteInspections'

export default compose(
    withPermission({isVisible: schedule}),
    withVisibility(),
    withRemoteInspections(),
)(ScheduleOrCancelRRI)