import createPermission, {any} from 'permissions/createPermission'

import list from './list'
import {remove} from './actions'

export default createPermission(
    list,
    any(
        remove
    ),
)