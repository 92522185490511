import {handleActions} from 'redux-actions'
import uniqid from 'uniqid'

import {update, remove, add} from './actions'
import keyBy from 'lodash-es/keyBy'
import sortBy from 'lodash-es/sortBy'
import sortedUniq from 'lodash-es/sortedUniq'

export default handleActions({
    [update](state, {payload, meta}) {
        const {panelId} = meta
        const old = state[panelId] || {}

        const byIds = old.rows
            ? keyBy(Object.values(old.rows), 'id')
            : {}

        payload = payload.map(remark => {
            if (!remark.key && remark.id && byIds[remark.id]) {
                // assign previously generated key
                remark.key = byIds[remark.id].key
            }

            if (!remark.key) {
                remark.key = uniqid()
            }

            return remark
        })

        const newRows = keyBy(payload, 'key')

        const rows = {...old.rows, ...newRows}
        const page = (old.page || []).concat(Object.keys(newRows))

        return {
            ...state,
            [panelId]: {
                rows,
                page: sortedUniq(sortBy(page, key => rows[key].time)),
            },
        }
    },

    [remove](state, {payload, meta}) {
        const {panelId} = meta
        const old = state[panelId] || {}

        return {
            ...state,
            [panelId]: {
                ...old,
                page: old.page.filter(key => key != payload),
            },
        }
    },

    [add](state, {payload, meta}) {
        const {panelId} = meta
        const {key} = payload
        const old = state[panelId] || {}
        const rows = {...old.rows, [key]: payload}
        const page = old.page.concat(key)

        return {
            ...state,
            [panelId]: {
                rows,
                page: sortBy(page, key => rows[key].time),
            },
        }
    },
}, {})