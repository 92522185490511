import React, {Component} from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

export default class Quazar extends Component {
    static propTypes = {
        settings: PropTypes.objectOf(PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })),
    }

    render() {
        const {settings: {bba, gprs}} = this.props

        return (
            <List className="card-content" twoCols>
                {!bba.isEnabled && <ListHintItem name={__('Broadband Supervision')} text={__('Disabled')}/>}

                {bba.isEnabled &&
                <ListHintItem name={__('Broadband Supervision Period')}
                              text={__n('%d second', '%d seconds', bba.supervision)}/>
                }

                {bba.isEnabled &&
                <ListHintItem name={__('Broadband Offline Timer')}
                              text={__n('%d second', '%d seconds', bba.timeout)}/>
                }

                {!gprs.isEnabled && <ListHintItem name={__('Cellular Supervision')} text={__('Disabled')}/>}

                {gprs.isEnabled &&
                <ListHintItem name={__('Cellular Supervision Period')}
                              text={__n('%d second', '%d seconds', gprs.supervision)}/>
                }

                {gprs.isEnabled &&
                <ListHintItem name={__('Cellular Offline Timer')}
                              text={__n('%d second', '%d seconds', gprs.timeout)}/>
                }
            </List>
        )
    }
}