import {
    createAction
} from 'redux-actions'

import {
    fetchReportContentOptions
} from 'api/reports'

export const setLoading = createAction('REPORTS/CONTENT_OPTIONS/SET_LOADING', (isLoading = true) => ({isLoading}))
export const persist = createAction('REPORTS/CONTENT_OPTIONS/PERSIST', (data) => ({data}))

export function fetch() {
    return dispatch => {
        dispatch(setLoading())

        return fetchReportContentOptions().then(({rows}) => {
            dispatch(persist(rows))
            dispatch(setLoading(false))
        })
    }
}