import * as api from 'api/centralStations'
import {createAction} from 'redux-actions'

export const setLoading = createAction('CENTRAL_STATIONS/PROTOCOLS/SET_LOADING')
export const receive = createAction('CENTRAL_STATIONS/PROTOCOLS/RECEIVE', api.getProtocols)

export function fetch() {
    return dispatch => {
        dispatch(setLoading())
        dispatch(receive())
    }
}