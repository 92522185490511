import {all} from 'redux-saga/effects'

import connectedPanels from './connectedPanels/saga'
import eventsRate from './eventsRate/saga'
import online from './online/saga'
import allProcesses from './allProcesses/saga'

export default function*() {
    yield all([
        connectedPanels(),
        eventsRate(),
        online(),
        allProcesses(),
    ])
}